import { TagList } from 'components/tags/TagList';
import { Content } from '@visikon/core-models';
import moment from 'moment';
import { Popup } from 'semantic-ui-react';
import { ImageFile } from './ImageFile';

interface IProps {
  onTagAdded: (tag: Content.Tag) => void;
  onTagRemoved: (tag: Content.Tag) => void;
  onClick?: () => void;
  image: Content.Image;
  language: Content.LanguageCode;
  readOnly?: boolean;
  className?: string;
}

export function ImageView(props: IProps) {
  const { image, onClick, language, readOnly, className, onTagAdded, onTagRemoved } = props;
  const cursor = onClick ? 'pointer' : 'default';

  return (
    <div className="card">
      <div className="ui image" onClick={onClick} style={{ cursor }}>
        <ImageFile image={image} language={language} className={className} />
      </div>
      <div className="content" style={{ position: 'relative' }}>
        <div className="header" onClick={onClick} style={{ cursor }}>
          {image.name}
        </div>
        <div className="meta" onClick={onClick} style={{ cursor }}>
          {/* Show fromNow() time with actual timestamp in popup */}
          <Popup
            trigger={<span>{moment(image.createdAt).fromNow()}</span>}
            content={<div>{moment(image.createdAt).format('LLL')}</div>}
            position="top right"
            hideOnScroll
            mouseEnterDelay={500}
          />
        </div>
        <div className="description">
          <TagList readOnly={readOnly} onTagAdded={onTagAdded} onTagRemoved={onTagRemoved} tagList={image.tags ? image.tags : []} header="Tags" />
        </div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            fontSize: '0.7em',
            lineHeight: '0.7em',
            color: '#0005',
          }}
        >
          NS: {image.namespace ? image.namespace : 'N/A'}
        </div>
      </div>
    </div>
  );
}
