import { Block, BlockCreation, LanguageCode } from '@visikon/core-models/content';
import { SaveButton } from 'components/SaveButton';
import { useRef, useState } from 'react';
import { Button, Confirm, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { BlockEntryEditor } from './BlockEntryEditor';
import { BlockEntryUsage } from './BlockEntryUsage';

interface IProps {
  open: boolean;
  lang: LanguageCode;
  initialBlock?: Block;
  videoVariation?: string;
  speakVariation?: string;
  onSave(state: BlockCreation): void;
  onDismiss(): void;
}

const HeaderLeft = styled.div`
  flex: 1;
`;

const HeaderRight = styled(HeaderLeft)`
  display: flex;
  justify-content: right;
`;

export const BlockEditModal = (props: IProps) => {
  const blockRef = useRef<BlockCreation>(props.initialBlock as BlockCreation);
  const [isSaveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(true);
  const modalRef = useRef<any>(null);
  const isCreatingNew = props.initialBlock === undefined;
  const title = isCreatingNew ? 'Create block' : 'Edit block';

  const handleOnSaveClick = () => {
    setHasChanges(false);
    if (blockRef.current) {
      props.onSave(blockRef.current);
      setSaveDisabled(true);
    }
  };

  const handleOnChange = (blockChange: BlockCreation) => {
    blockRef.current = blockChange;
    setHasChanges(true);
    setSaveDisabled(!isBlockValid() || hasEmptyBlockVariations());
  };

  const handleOnDismiss = () => {
    if (hasChanges) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
      props.onDismiss();
    }
  };

  function testEmpty(arr: undefined | string[]) {
    return arr === undefined || arr.length === 0 || arr[0].trim() === '';
  }

  function hasEmptyBlockVariations() {
    const { current: block } = blockRef;
    if (block === undefined) {
      return false;
    }

    const speakVal = block.speaks.reduce((acc, s) => testEmpty(s.variations) || acc, false);
    const videoVal = block.videos.reduce((acc, v) => testEmpty(v.variations) || acc, false);
    return speakVal || videoVal;
  }

  function isBlockValid() {
    const { current: block } = blockRef;
    return block !== undefined && block.name !== undefined && block.name.trim() !== '' && block.tags !== undefined && block.tags.length > 0;
  }

  function renderUsageButton() {
    if (isCreatingNew) {
      return null;
    }

    const buttonTxt = showEdit ? 'Show usages' : 'Back to edit';
    return <Button onClick={() => setShowEdit(!showEdit)}>{buttonTxt}</Button>;
  }

  function getContentHeight(): number | undefined {
    if (modalRef && modalRef.current) {
      return modalRef.current.ref.clientHeight - 120;
    }

    return undefined;
  }

  return (
    <>
      <Modal open={props.open} onClose={handleOnDismiss} closeOnEscape size="large" ref={modalRef}>
        <Modal.Header style={{ display: 'flex' }}>
          <HeaderLeft>{title}</HeaderLeft>
          <HeaderRight>
            {renderUsageButton()}
            <SaveButton
              id={props.initialBlock?._id}
              lastModified={props.initialBlock?.lastModified}
              disabled={isSaveDisabled}
              onSave={handleOnSaveClick}
            />
          </HeaderRight>
        </Modal.Header>
        <Modal.Content style={{ padding: 0, position: 'relative' }}>
          {/* <Transition.Group animation="horizontal flip" duration={400}> */}
          <BlockEntryEditor
            hidden={!showEdit}
            initialValue={props.initialBlock}
            onChange={handleOnChange}
            lang={props.lang}
            activeVideoVariation={props.videoVariation}
            activeSpeakVariation={props.speakVariation}
          />
          {!showEdit && <BlockEntryUsage block={props.initialBlock!} height={getContentHeight()} />}
          {/* </Transition.Group> */}
        </Modal.Content>
      </Modal>
      <Confirm
        open={showConfirm}
        confirmButton={<Button primary={false} content="Leave without save" />}
        onConfirm={() => {
          setShowConfirm(false);
          props.onDismiss();
        }}
        onCancel={() => setShowConfirm(false)}
        cancelButton={<Button primary content="Stay" />}
        header="Unsaved changes"
        content="You are about to leave without saving your changes. Do you wanna stay?"
      />
    </>
  );
};
