import { LanguageSelector } from 'components/LanguageSelector';
import { ImageFileWrapper } from 'containers/mediaLibrary/ImageFileWrapper';
import { Content } from '@visikon/core-models';
import React from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import { Schema, Value } from 'slate';
import EditList from 'slate-edit-list';
import { Editor as SlateEditor } from 'slate-react';
import styled from 'styled-components';
import { EditorContainer } from './EditorContainers';
import { DeleteButton } from '../DeleteButton';

const EditorImageWrapper = styled.div`
  img {
    max-width: 100%;
    height: auto;
  }
`;

const ArchiveButtonContainer = styled.div`
  background-color: pink;
  display: inline-block;
  button {
    margin: 0px !important;
    position: inherit !important;
    padding: 8px  !important;
    }
`;

const linkStyle: React.CSSProperties = {
  color: 'blue',
  textDecoration: 'underline',
  cursor: 'pointer',
};

interface IProps {
  value: Value;
  language: Content.LanguageCode;
  languageChoices: DropdownItemProps[];
  namespace?: string;
  schema?: Schema;
  readOnly?: boolean;
  translateMode?: boolean;
  showArchive? :boolean;
  onLanguageChange(language: Content.LanguageCode): void;
  onValueChange(change: Value, language: Content.LanguageCode): void;
}

export class Editor extends React.Component<IProps> {
  listPlugin: any;

  plugins: any[];

  // eslint-disable-next-line react/no-unused-class-component-methods
  firstLoad: boolean;

  constructor(props: IProps) {
    super(props);

    // eslint-disable-next-line react/no-unused-class-component-methods
    this.firstLoad = true;

    this.listPlugin = EditList();
    this.plugins = [this.listPlugin];

    this.handleChange = this.handleChange.bind(this);
    this.renderNode = this.renderNode.bind(this);
    this.renderNodeDefaultMode = this.renderNodeDefaultMode.bind(this);
    this.renderNodeTranslateMode = this.renderNodeTranslateMode.bind(this);
  }

  componentDidMount() {
    if (this.props.translateMode) {
      const editors = document.querySelectorAll('[data-slate-editor]');
      if (editors.length === 2) {
        // Disable TAB key
        (editors[1] as any).onkeydown = (e: KeyboardEvent) => {
          if (e.key === 'Tab') {
            e.preventDefault();
          }
        };
        // Hack: Disable contenteditable on whole editor, it's enabled on each "root node" individually in translate mode
        // editors[1].setAttribute("contenteditable", "false");
      }
    }
  }

  handleChange(change: any) {
    this.props.onValueChange(change, this.props.language);
  }

  /* *************************** *
   *          Rendering          *
   * *************************** */

  renderNode(props: any) {
    if (this.props.translateMode) {
      return this.renderNodeTranslateMode(props);
    }

    return this.renderNodeDefaultMode(props);
  }

  // eslint-disable-next-line class-methods-use-this
  renderNodeDefaultMode(props: any) {
    const { attributes, children, node } = props;
    switch (node.type) {
      // General
      case 'title':
        return <h1 {...attributes}>{children}</h1>;
      case 'header':
        return <h3 {...attributes}>{children}</h3>;
      case 'center':
        return (
          <div style={{ textAlign: 'center' }} {...attributes}>
            {children}
          </div>
        );
      // Lists
      case 'ul_list':
        return <ul {...attributes}>{children}</ul>;
      case 'ol_list':
        return <ol {...attributes}>{children}</ol>;
      case 'list_item':
        return <li {...attributes}>{children}</li>;
      case 'image':
        return (
          <EditorImageWrapper {...attributes}>
            {children}
            <ImageFileWrapper imageId={node.data.get('idRef')} />
          </EditorImageWrapper>
        );
      case 'externalLink':
        return (
          <span style={linkStyle} {...attributes}>
            {children}
          </span>
        );
      default:
        return <p {...attributes}>{children}</p>;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderNodeTranslateMode(props: any) {
    const { attributes, children, node } = props;
    switch (node.type) {
      // General
      case 'title':
        return <h1 {...attributes}>{children}</h1>;
      case 'header':
        return <h3 {...attributes}>{children}</h3>;
      case 'center':
        return (
          <div style={{ textAlign: 'center' }} {...attributes}>
            {children}
          </div>
        );
      // Lists
      case 'ul_list':
        return <ul {...attributes}>{children}</ul>;
      case 'ol_list':
        return <ol {...attributes}>{children}</ol>;
      case 'list_item':
        return <li {...attributes}>{children}</li>;
      case 'image':
        return (
          <EditorImageWrapper {...attributes}>
            {children}
            <ImageFileWrapper imageId={node.data.get('idRef')} />
          </EditorImageWrapper>
        );
      case 'externalLink':
        return (
          <span style={linkStyle} {...attributes}>
            {children}
          </span>
        );
      default:
        return <p {...attributes}>{children}</p>;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderMark(props: any) {
    const { children, mark } = props;
    switch (mark.type) {
      case 'bold':
        return <strong>{children}</strong>;
      case 'italic':
        return <em>{children}</em>;
      default:
        return { children };
    }
  }

  render() {
    const {
      readOnly,
      translateMode,
      language,
      onLanguageChange,
      showArchive,
      languageChoices,
      value,
      schema,
      namespace
    } = this.props;
    if (!value) {
      return null;
    }

    const className = translateMode ? `trans-lang-${namespace}-${language}` : '';

    // setting the value to null deletes the content for selected language
    const archiveButton = showArchive ? (<ArchiveButtonContainer>
      <DeleteButton onDelete={ () => this.handleChange( { value: null } )}  />
      </ArchiveButtonContainer>
    ) : null;

    return (
      <EditorContainer readOnly={readOnly} translateMode={translateMode} className={className}>
        <LanguageSelector readOnly={readOnly} activeLang={language} onLanguageChanged={onLanguageChange} languageChoices={languageChoices} />
        { archiveButton }
        <SlateEditor
          readOnly={readOnly}
          plugins={this.plugins}
          className={`editor ${readOnly ? 'readOnly' : ''}`}
          value={value}
          schema={schema}
          onChange={this.handleChange}
          renderMark={this.renderMark}
          renderNode={this.renderNode}
          spellCheck={false}
        />
      </EditorContainer>
    );
  }
}
