import { TagList } from "components/tags/TagList";

type Props = {
    tags: string[],
    onAdd: (tag: string) => void,
    onRemove: (tag: string) => void,
};

export function Tags({ tags, onAdd, onRemove }: Props) {
    return (
        <TagList
            header="Composition tags:"
            onTagAdded={onAdd}
            onTagRemoved={onRemove}
            tagList={tags}
        />
    );
}
