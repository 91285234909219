import { Encoding } from "@visikon/core-models/encoding";
import { useToggle } from "commons/toggle";
import * as Semantic from 'semantic-ui-react';
import { LanguageCode } from '@visikon/core-models/content';
import { EncodedVideoModal } from "./EncodedVideo.modal";

type Props = {
  encodings?: {[key: string]: Encoding},
  language: LanguageCode,
};

export function EncodedVideoButton({ encodings, language }: Props) {
    const [modalVisible, toggleModal] = useToggle();

    const hasEncoding = encodings && encodings[language];

    return (
        <>
            <EncodedVideoModal
                openVideoModal={modalVisible}
                closeVideoModal={toggleModal}
                encodings={encodings}
                language={language}
            />
            {hasEncoding &&
                <Semantic.Dropdown.Item
                    icon="video play"
                    text="View encoded video"
                    onClick={toggleModal}
                />
            }
        </>
    );
}
