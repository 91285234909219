import React, { useRef, useState, useEffect } from 'react';
import * as Styles from './progress-bar.styles';

interface IProps {
  progress: number;
  markers?: number[];
  disabled?: boolean;
  onSeek(percentage: number): void;
}
export const ProgressBar = ({
  progress, markers, onSeek, disabled,
}: IProps) => {
  const [markerElements, setMarkers] = useState<JSX.Element[]>([]);
  const [isMouseDown, setMouseDown] = useState<boolean>(false);
  const [localProgress, setLocalProgress] = useState<number>(0);
  const bar = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setMarkers(drawMarkers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers]);

  function drawMarkers() {
    if (markers === undefined) {
      return [];
    }

    if (bar.current) {
      const nonEmptyMarkers = (markers || []).filter((m) => m !== 0);
      const barWidth = bar.current.clientWidth;
      const totalMarkerSum = nonEmptyMarkers.reduce((acc, m) => (acc + m), 0);
      let runningSum = 0;
      const runningMarkers = nonEmptyMarkers.map((m) => { runningSum += m; return runningSum; });
      const markersPixel = runningMarkers.map((m) => (m / totalMarkerSum) * barWidth);
      markersPixel.pop(); // Remove last
      return markersPixel.map((m) => (<Styles.Marker key={m} style={{ left: m }} />));
    }

    return [];
  }

  const handleProgressBarClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (bar.current && !disabled) {
      const rect = bar.current.getBoundingClientRect();
      const xMeasure = event.clientX - rect.left; // x position within the element.
      const x = xMeasure > 0 ? xMeasure : 0;
      const percentage = x / (rect.width || 1);
      onSeek(percentage);
    }
  };

  const handleDrag = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disabled || !isMouseDown || bar.current === null) {
      return;
    }
    const rect = bar.current.getBoundingClientRect();
    const xMeasure = event.clientX - rect.left; // x position within the element.
    const x = xMeasure > 0 ? xMeasure : 0;

    // TODO: Clarify with parenthesis
    // eslint-disable-next-line no-mixed-operators
    const percentage = x / (rect.width || 1) * 100;
    setLocalProgress(percentage);
  };

  return (
    <Styles.ProgressBarWrapper
      onClick={handleProgressBarClick}
      onMouseDown={() => setMouseDown(true)}
      onMouseUp={() => { setMouseDown(false); setLocalProgress(0); }}
      onMouseMove={handleDrag}
    >
      <Styles.ProgressBarOuter ref={bar}>
        <Styles.ProgressBarInner style={{ width: `${localProgress || progress}%` }} />
        {markerElements}
      </Styles.ProgressBarOuter>
    </Styles.ProgressBarWrapper>
  );
};
