import { Button, Header, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export function BlocksHeader({ showList, useAsPicker, onCreateBlock, onToogleList: onToggleList, children }: { showList: boolean, useAsPicker: boolean | undefined, onCreateBlock: () => void, onToogleList: () => void, children?: any | null }) {
    return (
      <>
        {!useAsPicker && <Header as="h1">Blocks</Header>}
        <Container>
          <div>
            {!useAsPicker && (
              <Button color="green" onClick={onCreateBlock}>
                Create block
              </Button>
            )}
          </div>
          <div>
            { children }
          </div>
          <div>
            <Button icon active={!showList} onClick={onToggleList}>
              <Icon name="th" />
            </Button>
            <Button icon active={showList} onClick={onToggleList}>
              <Icon name="list" />
            </Button>
          </div>
        </Container>
      </>
    );
  }