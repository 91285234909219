import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'reducers/reducers';
import { CustomTypeValue, ITypeHeaderProps } from 'components/contentTypes/CustomTypeInput';
import { CustomTypeWithPublishState } from '../CustomTypeEditor';
import { ArchiveButton } from './ArchiveButton';
import { toggleArchived } from 'actions/ContentActions';
import { HeaderWrapper } from './ListHeaderWrapper';

function entrySelector(id: string, type: string) {
  return (state: IState) => {
    const data = state.content.data[type] ?? [];

    return data.find((d) => d._id === id);
  };
}

export function ArchiveHeader(props: ITypeHeaderProps) {
  const { id, type, typeDescription } = props;
  const entry = useSelector(entrySelector(id, type));
  const [entryLocal, setEntryLocal] = useState<CustomTypeWithPublishState | undefined>(entry);
  const dispatch = useDispatch();

  const onToggleArchived = (value: CustomTypeValue) => {
    dispatch(toggleArchived(value));
  };

  useEffect(() => {
    if (entry !== entryLocal) {
      setEntryLocal(entry);
    }
  }, [entryLocal, entry]);

  if (!typeDescription) return null;

  return <HeaderWrapper>{entryLocal && <ArchiveButton entry={entryLocal} onToggle={onToggleArchived} />}</HeaderWrapper>;
}
