import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IState } from 'reducers/reducers';
import { Block, BlockSpeak } from '@visikon/core-models/content';
import { useEffect } from 'react';
import { SpeakManuscriptsTable } from './SpeaksTable';
import { listBlocks } from '../../../../actions/BlockActions';

function useYellowManuscripts() {
  const dispatch = useDispatch();
  const { blocks } = useSelector((state: IState) => ({
    blocks: state.blocks.all,
  }));

  useEffect(() => {
    if (blocks?.length === 0) {
      dispatch(listBlocks());
    }
  }, [dispatch, blocks]);

  return blocks;
}

export function SpeakManuscripts() {
  const blocks = useYellowManuscripts();
  const history = useHistory();

  const handleOnClick = (id: string) => {
    history.push(`/block/overview/${id}`);
  };

  const filtersApplied = (speak: BlockSpeak) => filters.statusYellow(speak) && filters.speakTextEmpty(speak);

  const mapper = (block: Block) => ({
    _id: block._id,
    name: block.name,
    speaks: block.speaks.filter(filtersApplied).map((speak) => ({
      variation: speak.variations[0],
      text: speak.text,
    })),
  });

  const yellowBlockSpeaks = blocks.map(mapper).filter((item) => item.speaks.length > 0);

  return <SpeakManuscriptsTable items={yellowBlockSpeaks} onClick={handleOnClick} />;
}

const filters = {
  statusYellow: (speak: BlockSpeak) => speak.text?.trim() && speak.status === 'YELLOW',
  speakTextEmpty: (speak: BlockSpeak) => speak.text !== '',
};
