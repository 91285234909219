import React from 'react';
import { connect } from 'react-redux';
import { RouteProps, withRouter } from 'react-router';
import { IState } from 'reducers/reducers';
import { Redirect, Route } from 'react-router-dom';

interface IProps extends RouteProps {
  isLoggedIn: boolean;
}

function AuthRouteHat(props: IProps) {
  const { component, isLoggedIn, ...rest } = props;
  const Component: React.ComponentType<any> = component!;

  return (
    <Route
      {...rest}
      render={(routeProps) => (
        isLoggedIn ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: routeProps.location } }} />
        )
      )}
    />
  );
}

const mapStateToProps = (state: IState) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

export const AuthRoute: React.ComponentClass<any> = withRouter(connect(mapStateToProps)(AuthRouteHat));
