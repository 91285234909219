export const staticVoiceData = [
  {
    value: 'da',
    code: 'da-DK',
    text: 'Danish',
    listOfVoices: { Male: ['Jeppe'], Female: ['Christel'] },
    preferredVoice: { Female: 'Christel', Male: 'Jeppe' }, // The order of gender sets the default voice and the order of gender voices in the list
  },
  {
    value: 'en',
    code: 'en-US',
    text: 'English',
    listOfVoices: {
      Male: ['Christopher', 'Brandon', 'Davis', 'Eric', 'Jacob', 'Jason', 'Roger', 'Steffan', 'Tony', 'AIGenerate1', 'Guy'], // Usable voices: 'Christopher', 'Eric', 'Jacob'
      Female: ['Jenny', 'Aria', 'Amber', 'Ana', 'Ashley', 'Cora', 'Elizabeth', 'Jane', 'Michelle', 'Monica', 'Nancy', 'Sara', 'AIGenerate2', 'Blue'],
    },
    preferredVoice: { Male: 'Christopher', Female: 'Jenny' },
  },
  {
    value: 'de',
    code: 'de-DE',
    text: 'German',
    listOfVoices: {
      Male: ['Conrad', 'Bernd', 'Christoph', 'Kasper', 'Killian', 'Klaus', 'Ralf'], // Usable voices: 'Christoph', 'Killian', 'Ralf'
      Female: ['Amala', 'Katja', 'Elke', 'Gisela', 'Klarissa', 'Louisa', 'Maja', 'Tanja'],
    },
    preferredVoice: { Male: 'Killian', Female: 'Katja' },
  },
  {
    value: 'fr',
    code: 'fr-FR',
    text: 'French',
    listOfVoices: {
      Male: ['Henri', 'Alain', 'Claude', 'Jerome', 'Maurice', 'Yves'],
      Female: ['Denise', 'Brigitte', 'Celeste', 'Coralie', 'Eloise', 'Jacqueline', 'Josephine', 'Yvette'],
    },
    preferredVoice: { Male: 'Henri', Female: 'Denise' },
  },
  {
    value: 'hi',
    code: 'hi-IN',
    text: 'Hindi',
    listOfVoices: {
      Male: ['Madhur'],
      Female: ['Swara'],
    },
    preferredVoice: { Male: 'Madhur', Female: 'Swara' },
  },
  {
    value: 'sv',
    code: 'sv-SE',
    text: 'Swedish',
    listOfVoices: {
      Male: ['Mattias'],
      Female: ['Sofie', 'Hillevi'],
    },
    preferredVoice: { Male: 'Mattias', Female: 'Sofie' },
  },
  {
    value: 'no',
    code: 'nb-NO',
    text: 'Norwegian',
    listOfVoices: {
      Male: ['Finn'],
      Female: ['Pernille', 'Iselin'],
    },
    preferredVoice: { Male: 'Finn', Female: 'Pernille' },
  },
  {
    value: 'my',
    code: 'my-MM',
    text: 'Burmese',
    listOfVoices: {
      Male: ['Thiha'],
      Female: ['Nilar'],
    },
    preferredVoice: { Male: 'Thiha', Female: 'Nilar' },
  },
  {
    value: 'pt',
    code: 'pt-BR',
    text: 'Portuguese',
    listOfVoices: {
      Male: ['Antonio', 'Donato', 'Fabio', 'Humberto', 'Julio', 'Nicolau', 'Valerio'],
      Female: ['Francisca', 'Brenda', 'Elza', 'Giovanna', 'Leila', 'Leticia', 'Manuela', 'Yara'],
    },
    preferredVoice: { Male: 'Antonio', Female: 'Francisca' },
  },
  {
    value: 'es',
    code: 'es-ES',
    text: 'Spain',
    listOfVoices: {
      Male: ['Alvaro', 'Arnau', 'Dario', 'Elias', 'Nil', 'Saul', 'Teo'],
      Female: ['Elvira', 'Abril', 'Estrella', 'Irene', 'Laia', 'Lia', 'Triana', 'Vera'],
    },
    preferredVoice: { Male: 'Alvaro', Female: 'Laia' },
  },
];
