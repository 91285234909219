import { Reducer } from 'redux';
import { Content } from '@visikon/core-models';
import { isType } from 'typescript-fsa';
import {
  listContentByTypeSuccess,
  createContentSuccess,
  updateContentSuccess,
  loadContentEntrySuccess,
  contentApiError,
  clearApiError,
  toggleArchivedSuccess,
  listProgramCodesSuccess,
} from 'actions/ContentActions';
import { ProgramAction } from '@visikon/core-models/content';

export interface IState {
  data: {
    [type: string]: Content.CustomType[];
  };
  programActions?: ProgramAction[];
  error?: string;
}

const initialState: IState = {
  data: {},
};

export const contentReducer: Reducer<IState> = (state = initialState, action) => {
  // Handle error!
  if (isType(action, contentApiError)) {
    return {
      ...state,
      error: action.payload.msg,
    };
  }
  if (isType(action, clearApiError)) {
    return {
      ...state,
      error: undefined,
    };
  }

  if (isType(action, listContentByTypeSuccess)) {
    return {
      error: undefined,
      data: {
        ...state.data,
        [action.payload.type]: action.payload.result,
      },
    };
  }

  if (isType(action, listProgramCodesSuccess)) {
    return {
      error: undefined,
      data: {
        ...state.data,
      },
      programActions: action.payload,
    };
  }

  if (isType(action, createContentSuccess)) {
    const type = action.payload.typeDescriptor;
    const listOfType = [...(state.data[type] || []), action.payload];
    return {
      error: undefined,
      data: {
        ...state.data,
        [type]: listOfType,
      },
    };
  }

  if (isType(action, updateContentSuccess) || isType(action, loadContentEntrySuccess) || isType(action, toggleArchivedSuccess)) {
    const type = action.payload.typeDescriptor;
    // Remove old
    const listOfType = (state.data[type] || []).filter((l) => l._id !== action.payload._id);
    const result = {
      error: undefined,
      data: {
        ...state.data,
        [type]: [...listOfType, action.payload],
      },
    };
    return result;
  }

  return state;
};
