import * as React from 'react';
import * as Semantic from 'semantic-ui-react';
import * as Styles from '../variationParts/speak/SpeakVariation.styles';
import { getAllLexemes, useUpdatePhoneticsXML } from 'api/v2/text2speechApi';
import { cleanWrapperTag, sortAlphabetically } from 'containers/composer/block/text2speech/utils';
import { LexemeContents } from './LexemeContents';
import { Spinner } from 'components/spinner';
import { LanguageCode } from '@visikon/core-models/languageTypes';

type Props = {
  onUpdateEditor: (graphemeText: string, phonemeText?: string) => void;
  serverChanges: boolean;
  lang?: LanguageCode;
};

export default function PhoneticsList({ onUpdateEditor, serverChanges, lang = 'da' }: Props) {
  const [lexemeItems, setLexemeItems] = React.useState<HTMLCollectionOf<Element> | null>(null);
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const mutator = useUpdatePhoneticsXML();

  React.useEffect(() => {
    const graphemeData = async () => {
      setIsLoading(true);

      try {
        const data = await getAllLexemes(lang);
        setLexemeItems(data);
      } finally {
        setIsLoading(false);
      }
    };

    graphemeData();
  }, [mutator.isSuccess, serverChanges, lang]);

  const handleSelectLexeme = (index: number, lexeme: Element, updateEditor: (graphemeText: string, phonemeText?: string) => void) => {
    setActiveIndex(index);

    const graphemes = lexeme?.getElementsByTagName('grapheme');
    const phonemes = lexeme?.getElementsByTagName('phoneme');

    if (!graphemes || !phonemes) {
      return;
    }

    const foundGraphemes = Array.from(graphemes).map((e) => `${e.textContent}`);
    const [phoneme] = Array.from(phonemes).map((e) => `${e.textContent}`);
    const combinedGraphemeText = foundGraphemes.join('\n');

    if (!combinedGraphemeText) {
      return;
    }

    updateEditor(combinedGraphemeText, phoneme);
  };

  const handleDeleteLexeme = (lexeme: Element) => {
    const graphemes = lexeme?.getElementsByTagName('grapheme');
    const [firstGrapheme] = Array.from(graphemes);

    const selectedLexemeString = cleanWrapperTag(lexeme.outerHTML, 'lexeme');
    const selectedGraphemeString = cleanWrapperTag(firstGrapheme.innerHTML, 'grapheme');

    const xmlUpdateRequest = {
      lexeme: selectedLexemeString,
      grapheme: selectedGraphemeString,
      deleteLexeme: true,
      lang,
    };

    mutator.mutate(xmlUpdateRequest);
  };

  const sortedLexemeItems = sortAlphabetically(Array.from(lexemeItems || []), lang);
  const loadingState = () => mutator.isLoading || isLoading;

  return (
    <>
      <Spinner visible={loadingState()} />
      <Styles.TableWrapper>
        <Semantic.Table compact>
          <Semantic.Table.Header>
            <Semantic.Table.Row>
              <Semantic.Table.HeaderCell>Lexemes</Semantic.Table.HeaderCell>
            </Semantic.Table.Row>
          </Semantic.Table.Header>

          <Semantic.Table.Body>
            {sortedLexemeItems.map((lexme, index) => (
              <Styles.PhoneticRow key={index} onClick={() => handleSelectLexeme(index, lexme, onUpdateEditor)} selected={activeIndex === index}>
                <Semantic.Table.Cell style={{ position: 'relative' }}>
                  <LexemeContents entry={lexme} />
                  <Styles.DeleteBtn color="red" onClick={() => handleDeleteLexeme(lexme)}>
                    X
                  </Styles.DeleteBtn>
                </Semantic.Table.Cell>
              </Styles.PhoneticRow>
            ))}
          </Semantic.Table.Body>
        </Semantic.Table>
      </Styles.TableWrapper>
    </>
  );
}
