import * as Semantic from 'semantic-ui-react';
import { ContentActionsContainer, ContentContainer } from './Thumbnail.styles';

export function FrameGrabView({player, blockListId, onPick}: {player: JSX.Element, blockListId: any, onPick: (refId: string, thumbSrc?: string) => void}) {
  const handleGrab = () => {
    if (blockListId) {
      const canvas: any = document.createElement('canvas');
      const video: any = document.getElementById(blockListId);
      const ctx = canvas.getContext('2d');
      canvas.width = video.videoWidth * 0.5;
      canvas.height = video.videoHeight * 0.5;

      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      onPick('', canvas.toDataURL());
    }
  }

  return (<ContentContainer>
    <div>
      {player}

      <ContentActionsContainer>
        <Semantic.Button onClick={handleGrab}>Capture frame</Semantic.Button>
      </ContentActionsContainer>
    </div>
  </ContentContainer>);
}
