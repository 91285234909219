import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';

export const ButtonContainer = styled.div`
    min-height: 50px;
    display: flex;
    justify-content: center;
`;

export const GridContainer = styled(Segment)`
  border: 0;
  box-shadow: none;
`;
