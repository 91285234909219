/**
 * CustomType renderes input forms based on a JSON description.
 *
 */
import React from 'react';
import { Content } from '@visikon/core-models';
import { StringTypeInput, IParametersForString } from './TypeComponents/StringType';
import { LongStringTypeInput, IParametersForStringLong } from './TypeComponents/LongStringType';
import { ListTypeInput, IParametersForList } from './TypeComponents/ListType';
import { IParametersForImage, ImageTypeInput } from './TypeComponents/ImageType';
import { IParametersForVideo, VideoTypeInput } from './TypeComponents/VideoType';
import { IParametersForMap, MapTypeInput } from './TypeComponents/MapType';
import { IParametersForBool, BoolTypeInput } from './TypeComponents/BoolType';
import { IParametersForChoice, ChoiceTypeInput } from './TypeComponents/ChoiceType';
import { IParametersForText, TextTypeInput } from './TypeComponents/TextType';
import { IParametersForNumber, NumberTypeInput } from './TypeComponents/NumberType';
import { IParametersForAnyList, AnyListTypeInput } from './TypeComponents/AnyListType';

/**
 * The resulting value of a CustomType is unknown so we use a type alias of `any`.
 */
export type CustomTypeValue = any; // tslint:disable-line:no-any

/**
 * Describes a complete type.
 * Example description:
 *
 *      const faqDescription: ITypeDescription = {
 *          meta: {
 *              name: "faq",
 *              description: "FAQ with QAs and a title. No sections",
 *          },
 *          fields: {
 *              title: {
 *                  type: "string",
 *                  desc: "FAQ title",
 *                  maxLength: 10,
 *              },
 *              questions: {
 *                  type: "list",
 *                  desc: "Questions and answers",
 *                  params: {
 *                      question: { type: "string", desc: "Question" },
 *                      answer: { type: "string", desc: "Answers" },
 *                  }
 *              }
 *          }
 *      };
 *
 * This type description results in data that looks like this:
 *
 *      {
 *          "title": "Sample FAQ",
 *          "questions": [
 *              {
 *                  "question": "Question 1",
 *                  "answer": "Answer 1"
 *              },
 *              {
 *                  "question": "Question 2",
 *                  "answer": "Answer no two"
 *              },
 *              {
 *                  "question": "Question 3",
 *                  "answer": "Answer 3"
 *              }
 *          ]
 *      }
 *
 */
export interface ITypeHeaderProps {
  id: string;
  type: string;
  typeDescription: ITypeDescription;
}

export interface ITypeDescription {
  meta: {
    name: string;
    displayName?: string;
    description?: string;
    header?: React.ComponentType<ITypeHeaderProps>;
  };
  fields: ITypeField;
}

export type IParameters =
  | IParametersForList
  | IParametersForMap
  | IParametersForAnyList
  | IParametersForString
  | IParametersForStringLong
  | IParametersForNumber
  | IParametersForText
  | IParametersForBool
  | IParametersForChoice
  | IParametersForImage
  | IParametersForVideo;
export interface ITypeField {
  [key: string]: IParameters;
}

export interface ICommonBaseProps {
  pKey: string;
  isTranslation?: boolean;
  isReadOnly?: boolean;
  lang?: Content.LanguageCode;
  namespace?: string;
}

interface ICustomTypeProps extends ICommonBaseProps {
  fields: ITypeField;
  depth?: number;
  value?: CustomTypeValue;
  onChange(key: string, val: CustomTypeValue): void;
}
export class CustomTypeInput extends React.Component<ICustomTypeProps> {
  constructor(props: ICustomTypeProps) {
    super(props);

    this.renderParam = this.renderParam.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(key: string, val: CustomTypeValue) {
    const newVal = { ...this.props.value, [key]: val };
    this.props.onChange(this.props.pKey, newVal);
  }

  // eslint-disable-next-line class-methods-use-this
  findValue(key: string, value: CustomTypeValue) {
    if (!value) {
      return null;
    }
    if (typeof value === 'object' && key in value) {
      // return value[key];

      // NB: Yes, dates are not handled correctly, but we have only simple types - mostly objects and arrays with strings
      if (value[key] !== undefined) {
        return JSON.parse(JSON.stringify(value[key]));
      }
    }
    return null;
  }

  renderParam(key: string, parameters: IParameters, value: CustomTypeValue): React.ReactNode {
    switch (parameters.type) {
      case 'list':
        return (
          <ListTypeInput
            key={key}
            pKey={key}
            {...parameters}
            onChange={this.handleChange}
            value={this.findValue(key, value)}
            isReadOnly={this.props.isReadOnly}
            isTranslation={this.props.isTranslation}
            lang={this.props.lang}
            namespace={this.props.namespace}
            depth={this.props.depth || 0}
          />
        );
      case 'anyList':
        return (
          <AnyListTypeInput
            key={key}
            pKey={key}
            {...parameters}
            onChange={this.handleChange}
            value={this.findValue(key, value)}
            isReadOnly={this.props.isReadOnly}
            isTranslation={this.props.isTranslation}
          />
        );
      case 'map':
        return (
          <MapTypeInput
            key={key}
            pKey={key}
            {...parameters}
            onChange={this.handleChange}
            value={this.findValue(key, value)}
            isReadOnly={this.props.isReadOnly}
            isTranslation={this.props.isTranslation}
          />
        );
      case 'boolean':
        return (
          <BoolTypeInput
            key={key}
            pKey={key}
            {...parameters}
            onChange={this.handleChange}
            value={this.findValue(key, value)}
            isReadOnly={this.props.isReadOnly}
            isTranslation={this.props.isTranslation}
          />
        );
      case 'choice':
        return (
          <ChoiceTypeInput
            key={key}
            pKey={key}
            {...parameters}
            onChange={this.handleChange}
            value={this.findValue(key, value)}
            isReadOnly={this.props.isReadOnly}
            isTranslation={this.props.isTranslation}
          />
        );
      case 'string':
        return (
          <StringTypeInput
            key={key}
            pKey={key}
            {...parameters}
            onChange={this.handleChange}
            value={this.findValue(key, value)}
            isReadOnly={this.props.isReadOnly}
            isTranslation={this.props.isTranslation}
            lang={this.props.lang}
            namespace={this.props.namespace}
          />
        );
      case 'long-string':
        return (
          <LongStringTypeInput
            key={key}
            pKey={key}
            {...parameters}
            onChange={this.handleChange}
            value={this.findValue(key, value)}
            isReadOnly={this.props.isReadOnly}
            isTranslation={this.props.isTranslation}
            lang={this.props.lang}
            namespace={this.props.namespace}
          />
        );
      case 'number':
        return (
          <NumberTypeInput
            key={key}
            pKey={key}
            {...parameters}
            onChange={this.handleChange}
            value={this.findValue(key, value)}
            isReadOnly={this.props.isReadOnly}
            isTranslation={this.props.isTranslation}
          />
        );
      case 'text':
        return (
          <TextTypeInput
            key={key}
            pKey={key}
            {...parameters}
            onChange={this.handleChange}
            value={this.findValue(key, value)}
            isReadOnly={this.props.isReadOnly}
            isTranslation={this.props.isTranslation}
          />
        );
      case 'image':
        return (
          <ImageTypeInput
            key={key}
            pKey={key}
            {...parameters}
            onChange={this.handleChange}
            value={this.findValue(key, value)}
            isReadOnly={this.props.isReadOnly}
            isTranslation={this.props.isTranslation}
          />
        );
      case 'video':
        return (
          <VideoTypeInput
            key={key}
            pKey={key}
            {...parameters}
            onChange={this.handleChange}
            value={this.findValue(key, value)}
            isReadOnly={this.props.isReadOnly}
            isTranslation={this.props.isTranslation}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { fields } = this.props;
    const result = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in fields) {
      // eslint-disable-next-line no-prototype-builtins
      if (fields.hasOwnProperty(key)) {
        result.push(this.renderParam(key, fields[key], this.props.value));
        result.push(
          <div key={`spacing_between_${key}`}>
            <br />
          </div>,
        );
      }
    }
    return result;
  }
}
