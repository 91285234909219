import React from 'react';
import { Button, Icon, Menu, Modal, Tab } from 'semantic-ui-react';
import { Video } from '@visikon/core-models/content';
import { VideoLibraryContainer } from 'containers/mediaLibrary/VideoLibraryContainer';
import { PLACEHOLDER_VIDEO } from 'commons/utils';
import { BlockListOverview } from 'containers/composer/compositions/CompositionList';
import { VideoFileWrapper } from 'containers/mediaLibrary/VideoFileWrapper';
import { CompositionWrapper } from 'containers/mediaLibrary/CompositionWrapper';
import { ICommonBaseProps, CustomTypeValue } from '../CustomTypeInput';

export interface IParametersForVideo {
  type: 'video';
  desc: string;
}
export interface IComponentVideoInputProps extends ICommonBaseProps, IParametersForVideo {
  value?: {
    type: 'video' | 'BlockList';
    _idRef: string;
  };

  onChange(key: string, val: CustomTypeValue): void;
}

interface IComponentState {
  isPickerOpen: boolean;
}
interface IResourceLinkProps {
  id?: string;
  type?: string;
}
export class VideoTypeInput extends React.Component<IComponentVideoInputProps, IComponentState> {
  constructor(props: IComponentVideoInputProps) {
    super(props);

    this.state = { isPickerOpen: false };

    this.dismissPicker = this.dismissPicker.bind(this);
    this.handleVideoPicked = this.handleVideoPicked.bind(this);
    this.handleCompositionPicked = this.handleCompositionPicked.bind(this);
    this.onVideoClear = this.onVideoClear.bind(this);
    this.renderVideoArea = this.renderVideoArea.bind(this);
    this.renderCompositionArea = this.renderCompositionArea.bind(this);
  }

  dismissPicker() {
    this.setState({ isPickerOpen: false });
  }

  handleVideoPicked(video: Video) {
    this.props.onChange(this.props.pKey, { _idRef: video._id, type: 'video' });
    this.setState({ isPickerOpen: false });
  }

  handleCompositionPicked(id: string) {
    this.props.onChange(this.props.pKey, { _idRef: id, type: 'BlockList' });
    this.setState({ isPickerOpen: false });
  }

  onVideoClear() {
    this.props.onChange(this.props.pKey, undefined);
  }

  renderVideoArea() {
    if (this.props.value === undefined) {
      return null;
    }

    return <VideoFileWrapper className="ui large bordered image" videoId={this.props.value._idRef} />;
  }

  renderCompositionArea() {
    if (this.props.value === undefined) {
      return null;
    }

    return <CompositionWrapper compositionId={this.props.value._idRef} />;
  }

  render() {
    if (this.props.isTranslation) {
      return null;
    }

    const hasResource = this.props.value;
    const isVideo = hasResource && this.props.value?.type === 'video';
    const isComposition = hasResource && this.props.value?.type === 'BlockList';
    const buttonText = hasResource ? 'Change resource' : 'Pick resource';

    const tabPanes = [
      {
        menuItem: <Menu.Item key="1_CompositionLibrary">Composition Library</Menu.Item>,
        render: () => (
          <Tab.Pane>
            <BlockListOverview
              useAsPicker
              onCompositionPick={(c) => this.handleCompositionPicked(c)}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: <Menu.Item key="0_VideoLibrary">Video Library</Menu.Item>,
        render: () => (
          <Tab.Pane>
            <VideoLibraryContainer onlyPicker onVideoPick={(v) => this.handleVideoPicked(v)} />
          </Tab.Pane>
        ),
      },
    ];

    return (
      <>
        <FieldTitle isComposition={isComposition} hasResource={!!hasResource} />

        {isVideo && this.renderVideoArea()}
        {isComposition && this.renderCompositionArea()}

        {!hasResource && <img alt="" className="ui large bordered image" src={PLACEHOLDER_VIDEO} />}
        <br />
        <Button.Group size="large">
          <Button
            onClick={() => {
              this.setState((prevState) => ({ isPickerOpen: !prevState.isPickerOpen }));
            }}
          >
            {buttonText}
          </Button>

          {hasResource && <>
            <Button onClick={this.onVideoClear} color="yellow">Remove resource</Button>
            <JumpToResourceButton id={this.props.value?._idRef} type={this.props.value?.type} />
          </>}
        </Button.Group>
        <Modal open={this.state.isPickerOpen} onClose={() => this.dismissPicker()} size="fullscreen">
          <Modal.Content style={{ maxHeight: '90vh', overflow: 'auto' }}>
            <Tab panes={tabPanes} />
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

const FieldTitle = ({ isComposition, hasResource }: { isComposition?: boolean, hasResource: boolean }) => (
  <h4>
    {hasResource &&
      <ResourceIcon type={isComposition ? 'BlockList' : 'video'} />
    }
    {isComposition && 'Composition'}
    {!isComposition && 'Video'}
  </h4>
);

function JumpToResourceButton({ id, type }: IResourceLinkProps) {
  const link = { video: `/media/videos/${id}`, BlockList: `/block/composer/${id}` };

  if (!type) {
    return null;
  }

  return (
    <a className='ui button' href={link[type]} rel="noreferrer">
      <ResourceIcon type={type} />
      Go to resource
    </a>
  );
}

function ResourceIcon({ type }: { type: string }) {
  return (<>
    {type === 'BlockList' &&
      <Icon name="file video outline" />
    }
    {type === 'video' &&
      <Icon name="video" />
    }
  </>);
}
