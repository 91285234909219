import { VideoContainer } from 'containers/mediaLibrary/VideoContainer';
import { Content } from '@visikon/core-models';
import React, { ChangeEvent } from 'react';
import { Button, Dimmer, Dropdown, DropdownProps, Icon, Loader, Modal, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import { languages } from 'commons/languages';
import { PLACEHOLDER_IMAGE } from 'commons/utils';
import { useHistory } from 'react-router-dom';
import { ArchiveButton } from 'components/ArchiveButton';
import { ObjectId } from '@visikon/core-models/base';
import { LanguageSelector } from '../../LanguageSelector';
import { FilePicker } from '../../FilePicker';
import { VideoViewSize } from './VideoView';
import { VideoThumbnailFile } from './VideoThumbnailFile';
import { useGetIdByTypeAndName } from '../../../api/contentApi';
import { ThumbnailFramePickerModal } from './ThumbnailFramePickerProps';
import { getEmbedCode } from 'containers/embed/EmbedVideoDialog';
import { CopyOnClick } from 'components/CopyOnClick';

export interface VideoDetailsProps {
  onDismiss: () => void;
  onUploadTranslation: (language: Content.LanguageCode, variation: ObjectId, file: File, duration: number) => void;
  onUploadThumbnail: (language: Content.LanguageCode, variation: ObjectId, file: any) => void;
  video: Content.Video;
  language: Content.LanguageCode; // This is the language selected in the whole CMS GUI
  mediaVariations: Content.MediaVariation[];
  isUploading: boolean;
  mediaError?: string;
  size?: VideoViewSize;
  toggleArchiveVideo: (toggleState: boolean, activeVideo: Content.Video) => void;
}

export interface IState {
  isSelectingVariation: boolean;
  activeLanguage: Content.LanguageCode;
  activeVariation: Content.MediaVariation;
  openVideoModal: boolean;
}

export class VideoDetails extends React.Component<VideoDetailsProps, IState> {
  videoRef: React.RefObject<HTMLVideoElement>;

  constructor(props: VideoDetailsProps) {
    super(props);

    this.state = {
      openVideoModal: false,
      isSelectingVariation: false,
      activeLanguage: this.props.language,
      activeVariation: this.props.mediaVariations[0] || '',
    };

    this.videoRef = React.createRef();

    this.renderVideoSection = this.renderVideoSection.bind(this);
    this.renderThumbnailSection = this.renderThumbnailSection.bind(this);
    this.renderVariationsSection = this.renderVariationsSection.bind(this);
    this.renderUploadSection = this.renderUploadSection.bind(this);

    this.mediaVariationsChoice = this.mediaVariationsChoice.bind(this);

    this.handleUploadVideo = this.handleUploadVideo.bind(this);
    this.handleUploadThumbnail = this.handleUploadThumbnail.bind(this);

    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleVariationChange = this.handleVariationChange.bind(this);

    this.renderThumbnailModal = this.renderThumbnailModal.bind(this);
    this.toggleArchived = this.toggleArchived.bind(this);
  }

  componentDidUpdate(oldProps: VideoDetailsProps) {
    if (JSON.stringify(oldProps.video) !== JSON.stringify(this.props.video)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ openVideoModal: false });
    }
  }

  handleUploadVideo(files: File[]) {
    const callback = this.props.onUploadTranslation;
    const { activeLanguage, activeVariation } = this.state;

    const file = files[0];
    if (file) {
      const vid = this.videoRef.current;
      if (vid) {
        vid.src = URL.createObjectURL(file);
        vid.ondurationchange = () => {
          callback(activeLanguage, activeVariation._id, file, vid.duration);
        };
      } else {
        callback(activeLanguage, activeVariation._id, file, -1);
      }
    }
  }

  handleUploadThumbnail(change: React.ChangeEvent<HTMLInputElement>) {
    const { onUploadThumbnail } = this.props;
    const { activeLanguage, activeVariation } = this.state;
    if (change.target.files && change.target.files.length > 0) {
      const file = change.target.files[0];
      onUploadThumbnail(activeLanguage, activeVariation._id, file);
    }
  }

  handleLanguageChange(code: Content.LanguageCode) {
    this.setState({ activeLanguage: code });
  }

  handleVariationChange(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) {
    const choice = this.props.mediaVariations.find((v) => v._id === data.value);
    if (choice) {
      this.setState({ isSelectingVariation: false, activeVariation: choice });
    } else {
      this.setState({ isSelectingVariation: false });
    }
  }

  languageDropdownChoice() {
    const translations = this.props.video.translations.map((t) => t.language);
    return languages.map((l) => {
      const hasTranslation = translations.indexOf(l.value as Content.LanguageCode) !== -1;
      const icon: SemanticICONS = hasTranslation ? 'check circle outline' : 'circle outline';
      const color: SemanticCOLORS = hasTranslation ? 'green' : 'grey';
      return { ...l, icon: <Icon name={icon} color={color} /> };
    });
  }

  mediaVariationsChoice() {
    const variations = this.props.video.translations.map((t) => t.mediaVariation);
    return this.props.mediaVariations.map((v) => {
      const hasVariation = variations.indexOf(v._id) !== -1;
      const active = v._id === this.state.activeVariation._id;
      const icon: SemanticICONS = hasVariation ? 'check circle outline' : 'circle outline';
      const color: SemanticCOLORS = hasVariation ? 'green' : 'grey';
      return {
        text: v.name,
        value: v._id,
        active,
        selected: active,
        icon: <Icon name={icon} color={color} />,
      };
    });
  }

  renderVariationsSection() {
    const { activeVariation } = this.state;
    const langChoices = this.languageDropdownChoice();
    const variationChoices = this.mediaVariationsChoice();
    return (
      <>
        <h2 className="ui header">Variations</h2>
        <h4>Language</h4>
        <LanguageSelector activeLang={this.state.activeLanguage} onLanguageChanged={this.handleLanguageChange} languageChoices={langChoices} />

        <h4>Media variation</h4>
        {this.state.isSelectingVariation && (
          <Dropdown
            className="mini icon"
            floating
            fluid
            labeled
            options={variationChoices}
            search
            searchInput={{ autoFocus: true }}
            selectOnBlur={false}
            onBlur={() => {
              this.setState({ isSelectingVariation: false });
            }}
            onChange={this.handleVariationChange}
            selection
            text="Search..."
          />
        )}
        {!this.state.isSelectingVariation && (
          <button
            type="button"
            className="ui mini left button"
            onClick={() => {
              this.setState({ isSelectingVariation: true });
            }}
          >
            {activeVariation.name}
          </button>
        )}
      </>
    );
  }

  renderUploadSection() {
    const translationExists = this.props.video.translations.find((t) => t.language === this.state.activeLanguage) !== undefined;
    const uploadText = translationExists ? 'Replace video file' : 'Upload video file';
    const { isUploading } = this.props;

    return (
      <>
        <h4>{uploadText}</h4>
        <div style={{ position: 'relative', width: '200px', height: '200px' }}>
          {isUploading && (
            <div
              style={{
                position: 'absolute',
                top: 2,
                left: 2,
                right: 2,
                bottom: 2,
              }}
            >
              <Dimmer active inverted>
                <Loader size="large" inverted>
                  Uploading
                </Loader>
              </Dimmer>
            </div>
          )}
          <div style={{ position: 'absolute', top: 0, left: 0 }}>
            <FilePicker disabled={isUploading} accept="video/mp4,video/webm,video/ogg" onFilePick={this.handleUploadVideo} multiple={false} />
          </div>
        </div>
      </>
    );
  }

  renderVideoSection() {
    return (
      <div className="ui cards">
        <VideoContainer
          showThumbnail={false}
          showControls
          size="large"
          video={this.props.video}
          language={this.state.activeLanguage}
          mediaVariation={this.state.activeVariation._id}
        />
      </div>
    );
  }

  renderThumbnailSection() {
    const imgClasses = 'ui medium image';
    const buttonStyle = { verticalAlign: 'top', marginLeft: 15 };
    const translation = this.props.video.translations.find((t) => t.language === this.state.activeLanguage);
    // If no translation we render disabled thumbnails section
    if (!translation) {
      return (
        <>
          <h3 style={{ color: 'grey' }}>Thumbnail</h3>
          <div style={{ display: 'inline-block', position: 'relative' }}>
            <img alt="" className={imgClasses} src={PLACEHOLDER_IMAGE} />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255,255, 0.6)',
              }}
            />
          </div>
          <div style={{ ...buttonStyle, display: 'inline-block', width: 285 }}>
            <Button className="ui button" disabled>
              Add thumbnail
            </Button>
          </div>
        </>
      );
    }

    if (translation.thumbnailSrc === undefined) {
      return (
        <>
          <h3>Thumbnail</h3>
          <img alt="" className={imgClasses} style={{ display: 'inline-block' }} src={PLACEHOLDER_IMAGE} />
          <div style={{ display: 'inline-block', width: 285, verticalAlign: 'top' }}>
            <label className="ui button" style={buttonStyle} htmlFor="thumbnail_upload">
              Upload image file as thumbnail
            </label>
            <UploadThumbnailInput onChange={this.handleUploadThumbnail} />
            <div style={{ marginLeft: 15 }}>
              <Button onClick={() => this.setState({ openVideoModal: true })}>Create thumbnail from video</Button>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <h3>Thumbnail</h3>
        <VideoThumbnailFile
          video={this.props.video}
          language={translation.language}
          variation={translation.mediaVariation}
          lastModified={translation.lastModified}
          className={imgClasses}
          style={{ display: 'inline-block' }}
        />

        <div style={{ display: 'inline-block', width: 285, verticalAlign: 'top' }}>
          <label className="ui button" style={buttonStyle} htmlFor="thumbnail_upload">
            Upload image file as thumbnail
          </label>
          <UploadThumbnailInput onChange={this.handleUploadThumbnail} />
          <div style={{ marginLeft: 15 }}>
            <Button onClick={() => this.setState({ openVideoModal: true })}>Create thumbnail from video</Button>
          </div>
        </div>
      </>
    );
  }

  renderThumbnailModal() {
    if (!this.state.openVideoModal) return null;

    return (
      <ThumbnailFramePickerModal
        onUploadThumbnail={this.props.onUploadThumbnail}
        onClose={() => this.setState({ openVideoModal: false })}
        open
        video={this.props.video}
        language={this.state.activeLanguage}
        variationId={this.state.activeVariation._id}
      />
    );
  }

  toggleArchived(val: boolean) {
    this.props.toggleArchiveVideo(val, this.props.video);
  }

  render() {
    return (
      <Modal open onClose={this.props.onDismiss} size="large">
        <Modal.Header>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>Video details</div>
            <div>
              <CopyEmbedCode video={this.props.video} />
              <CompositionLink video={this.props.video} />
            </div>
          </div>
        </Modal.Header>
        <ArchiveButton entry={this.props.video} onToggle={this.toggleArchived} />
        <Modal.Content scrolling style={{ minHeight: 880 }}>
          <video width={0} height={0} ref={this.videoRef} />
          <div style={{ display: 'flex' }}>
            <div style={{ width: 620 }}>
              {this.renderVideoSection()}
              {this.renderThumbnailSection()}
            </div>
            <div style={{ flex: 1 }}>
              {this.renderVariationsSection()}
              {this.renderUploadSection()}
            </div>
          </div>
          {this.renderThumbnailModal()}
        </Modal.Content>
      </Modal>
    );
  }
}

function CopyEmbedCode({ video }: { video: Content.Video }) {
  return (
    <CopyOnClick text={() => getEmbedCode(video)}>
      <Button basic size="medium" icon="code" title="Copy embed code" />
    </CopyOnClick>
  );
}

function CompositionLink({ video }: { video: Content.Video }) {
  const { data } = useGetIdByTypeAndName('BlockList', video.name);
  const history = useHistory();

  return (
    <>
      {data?.found && (
        <Button
          basic
          size="medium"
          icon="film"
          title="Go to composition"
          onClick={() => {
            history.push(`/block/composer/${data?.id}`);
          }}
        />
      )}
    </>
  );
}

const UploadThumbnailInput = ({ onChange }: { onChange: (change: ChangeEvent<HTMLInputElement>) => void }) => (
  <input type="file" onChange={onChange} id="thumbnail_upload" name="thumbnail_upload" accept=".jpg, .jpeg, .png" style={{ opacity: 0 }} />
);
