import { useEffect, useRef, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { PLACEHOLDER_VIDEO } from '../../commons/utils';
import { VideoPlayerControls } from './video-player-controls';
import * as Styles from './video-player.styles';
import { checkIfImageExists } from '../../containers/composer/compositions/components/player/BlockListPlayer';
import { getDurations } from './video-player.helpers';
import { VideoClip } from './video-player.models';

type Props = {
  clips: VideoClip[],
  audioDelay: number,
  thumbnail?: string,
  notifyPlaying?(index: number): void,
  notifyStopping?(): void
};

export function VideoPlayer({ clips, notifyPlaying, notifyStopping, audioDelay, thumbnail }: Props){
  const [isPlaying, setPlaying] = useState(false);
  const [playIndex, setPlayIndex] = useState(0);
  const [currentPos, setCurrentPos] = useState(0);
  const videoElm = useRef<HTMLVideoElement>(null);
  const audioElm = useRef<HTMLVideoElement>(null);
  // eslint-disable-next-line no-return-assign,no-param-reassign
  const updateElement = (mediaType: any, attribute: string, value: any) => (mediaType[attribute] = value);

  const isTheLastBlock = clips.length === playIndex + 1;

  useEffect(() => {
    if (clips.length > 0) {
      const currVideo = videoElm.current;
      const currAudio = audioElm.current;
      const videoHasNoVideoClip = currVideo && !currVideo.src;
      const audioHasNoVideoClip = currAudio && !currAudio.src;

      checkIfImageExists(currVideo!.src, (imgExists) => {
        if (imgExists) {
          updateElement(currVideo, 'poster', currVideo!.src);
          updateElement(currVideo, 'ontimeupdate', null);
          updateElement(currAudio, 'ontimeupdate', () => onTimeUpdate(currAudio!.currentTime));
          updateElement(currAudio, 'onended', () => {
            onPlayEnd();
            if (currVideo && !isTheLastBlock) playVideo(currVideo!);
          });
        }

        if (!imgExists) {
          updateElement(currAudio, 'ontimeupdate', null);
          updateElement(currAudio, 'onended', () => { });
          updateElement(currVideo, 'ontimeupdate', () => onTimeUpdate(currVideo!.currentTime));
          updateElement(currVideo, 'onended', () => {
            onPlayEnd();
            if (currVideo && !isTheLastBlock) playVideo(currVideo!);
          });

          if (videoHasNoVideoClip) {
            updateElement(currVideo, 'src', 'undefined');
            updateElement(currVideo, 'poster', PLACEHOLDER_VIDEO);
            updateElement(currAudio, 'ontimeupdate', () => onTimeUpdate(currAudio!.currentTime));
            updateElement(currAudio, 'onended', () => {
              onPlayEnd();
              if (currVideo && !isTheLastBlock) playVideo(currVideo!);
            });

            if (audioHasNoVideoClip) {
              playVideoBlockIndex(playIndex + 1);
            }
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clips, playIndex]);

  function isDisabled() {
    return clips.length === 0;
  }

  const currentAudio = audioElm.current;
  const secondsFromMs = (ms: number) => (ms * 0.001);
  const msFromSeconds = (sec: number) => (sec / 0.001);

  const playAudio = (delay: number | undefined, currentAudioTime: number, seekingOffset?: number) => {
    const DELAY = secondsFromMs(delay || 0);
    const variableOffset = (currentTime: number) => (currentTime <= DELAY ? msFromSeconds(DELAY - currentTime) : 0);
    const variableDelayInMs = variableOffset(seekingOffset || currentAudioTime);

    if (!seekingOffset) {
      setTimeout(() => {
          if (variableDelayInMs > 0) updateElement(currentAudio, 'currentTime', 0);

          if (currentAudio?.src) {
            currentAudio.play();
          }
        },
        variableDelayInMs);
    }

      if (currentAudio && seekingOffset) {
      currentAudio.currentTime = seekingOffset;
      currentAudio.pause();
    }
  };

  const initialVideoClip = clips.length > 0 ? clips[0] : {};

  const onPlayPauseClick = () => {
    const videoExists = videoElm.current;
    const audioExists = audioElm.current;
    const videoHasNoVideoClip = videoExists && !videoElm.current.src;
    const audioHasNoVideoClip = audioExists && !audioElm.current.src;
    if (isDisabled()) {
      return;
    }
    if (audioHasNoVideoClip && videoHasNoVideoClip) {
      playVideoBlockIndex(playIndex + 1);
    }
    if (videoElm.current) {
      const currentAudioTime = currentAudio ? currentAudio.currentTime : 0;

      if (isPlaying) {
        videoElm.current.pause();
        currentAudio!.pause();
        (notifyStopping && notifyStopping()); // eslint-disable-line
      } else {
        playVideo(videoElm.current);
        playAudio(audioDelay, currentAudioTime);
        (notifyPlaying && notifyPlaying(playIndex)); // eslint-disable-line
        // @ts-ignore
        videoElm.current.poster = undefined;
      }
      setPlaying(!isPlaying);
    }
  };

  const onRewindClick = () => {
    if (isDisabled() || videoElm.current === null) {
      return;
    }

    if (isPlaying) {
      playVideoBlockIndex(0);
    } else {
      stopPlayer();
    }
  };

  function updateVideoSrc(src: VideoClip) {
    if (videoElm.current === undefined) {
      return;
    }

    if (src.video) {
      videoElm.current!.src = src.video.src as string;
    } else {
      videoElm.current!.removeAttribute('src');
    }
  }

  function updateAudioSrc(src: VideoClip) {
    if (audioElm.current === undefined) {
      return;
    }

    if (src.audio) {
      audioElm.current!.src = src.audio.src as string;
    } else {
      audioElm.current!.src = '';
      audioElm.current!.removeAttribute('src');
    }
  }

  function playVideoBlockIndex(idx: number, offset?: number) {
    if (videoElm.current && idx < clips.length) {
      const newVideoClip = clips[idx];
      if (newVideoClip.video?.src !== videoElm.current.src) {
        updateVideoSrc(newVideoClip);
        updateAudioSrc(newVideoClip);
      }
      videoElm.current.currentTime = offset || 0;
      playAudio(audioDelay, currentAudio!.currentTime, offset);

      if (isPlaying) {
        playVideo(videoElm.current);
        playAudio(audioDelay, currentAudio!.currentTime);
      }
      setPlayIndex(idx);
      setCurrentPos(0); // Reset time when index changes
      (notifyPlaying && notifyPlaying(idx)); // eslint-disable-line
    }
  }

  function stopPlayer() {
    if (videoElm.current === null) {
      return;
    }
    if (clips.length === 0) {
      return;
    }

    if (videoElm.current.src !== clips[0].video?.src) {
      updateVideoSrc(clips[0]);
      updateAudioSrc(clips[0]);
    }
    videoElm.current.currentTime = 0;
    audioElm.current!.currentTime = 0;
    setCurrentPos(0);
    setPlayIndex(0);
    setPlaying(false);
    (notifyStopping && notifyStopping()); // eslint-disable-line
  }

  const onPlayEnd = () => {
    if (videoElm.current) {
      const nextIndex = playIndex + 1;
      if (nextIndex < clips.length) {
        playVideoBlockIndex(nextIndex);
      } else {
        stopPlayer();
      }
    }
  };

  const onTimeUpdate = (currTime: number) => {
    setCurrentPos(currTime);
  };

  const handleOnSeek = (seekToSeconds: number) => {
    const durations = getDurations(clips);

    const runningBlockDuration = durations.reduce((acc: number[], duration: number) => {
      const previousVal = acc.slice(-1).pop() || 0;
      const nextVal = duration + previousVal;
      return [...acc, nextVal];
    }, []);

    const idx = runningBlockDuration.findIndex((d) => d > seekToSeconds);
    if (idx === -1) {
      return;
    }

    const jumpTo = durations[idx] - (runningBlockDuration[idx] - seekToSeconds);
    playVideoBlockIndex(idx, jumpTo);
  };

  return (
    <>
      <Styles.VideoWrapper>
        <Styles.VideoPlayer
          poster={thumbnail}
          muted
          ref={videoElm}
          crossOrigin="anonymous"
          src={initialVideoClip.video?.src}
          onClick={onPlayPauseClick} />
        <audio ref={audioElm} src={initialVideoClip.audio?.src} />
        <PauseOverlay paused={isDisabled() || isPlaying} onClick={onPlayPauseClick} />
      </Styles.VideoWrapper>

      <VideoPlayerControls
        currentPos={currentPos}
        currentIndex={playIndex}
        isPlaying={isPlaying}
        onPlayPauseClick={onPlayPauseClick}
        onRewindClick={onRewindClick}
        onSeek={handleOnSeek}
        clips={clips}
      />
    </>
  );
}

function PauseOverlay({paused, onClick}: {paused: boolean, onClick: () => void}) {
  if (paused) {
    return null;
  }

  return (
    <Styles.PauseOverlay onClick={onClick}>
      <Icon size="huge" name="play circle outline" style={{ fontSize: '8em', color: 'white' }} />
    </Styles.PauseOverlay>
  );
}

const playVideo = (videoElem: HTMLVideoElement) => {
  const videoPlayPromise = videoElem.play();

  if (videoElem) {
    videoPlayPromise.then(() => {
      // Automatic playback started!
    }).catch(() => {
      // Do nothing;
    });
  }
};
