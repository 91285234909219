import React from 'react';
import { Form, TextArea, TextAreaProps } from 'semantic-ui-react';
import { ICommonBaseProps } from '../CustomTypeInput';

export interface IParametersForStringLong {
  type: 'long-string';
  maxLength?: number;
  desc: string;
}

interface IComponentStringLongInputProps extends ICommonBaseProps, IParametersForStringLong {
  value?: string;
  onChange(key: string, val: string): void;
}
export class LongStringTypeInput extends React.Component<IComponentStringLongInputProps> {
  constructor(props: IComponentStringLongInputProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event: React.FormEvent<HTMLTextAreaElement>, data: TextAreaProps) {
    if (data.value !== undefined) {
      this.props.onChange(this.props.pKey, String(data.value));
    }
  }

  render() {
    if (this.props.isTranslation && this.props.value === null) {
      return null;
    }

    if (this.props.isReadOnly) {
      return (
        <label>
          <h4>{this.props.desc}</h4>
          <textarea rows={5} disabled style={{ background: '#ddd', margin: '0 0 1em' }} value={this.props.value} />
        </label>
      );
    }

    const className = this.props.lang ? `trans-lang-${this.props.namespace}-${this.props.lang}` : '';

    return (
      <Form.Field
        maxLength={this.props.maxLength || -1}
        value={this.props.value || ''}
        control={TextArea}
        rows={5}
        onChange={this.handleChange}
        label={<h4>{this.props.desc}</h4>}
        className={className}
      />
    );
  }
}

interface IComponentStringLongRenderProps extends ICommonBaseProps, IParametersForStringLong {
  value: string;
}
export function LongStringTypeCompact({ desc, value }: IComponentStringLongRenderProps) {
  return (
    <>
      <h4 className="ui header" style={{ margin: 0 }}>{desc}</h4>
      <p>
        {(value || '').substring(0, 100)}
        ...
      </p>
    </>
  );
}
