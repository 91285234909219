import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const OVERFLOW_OVERLAY_SIZE = 150;

const TimeLineOuterWrapper = styled.div`
    position: relative;
    flex: 1;
    overflow: auto;
`;
const TimeLineInnerWrapper = styled.div`
    overflow-x: scroll;
`;

const TimeLineOverflowOverlayStart = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: ${OVERFLOW_OVERLAY_SIZE}px;
    background-image: linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255,1));
    z-index: 1;
    pointer-events: none;
`;
const TimeLineOverflowOverlayEnd = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: ${OVERFLOW_OVERLAY_SIZE}px;
    background-image: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));
    z-index: 1;
    pointer-events: none;
`;

export const TimeLine = (props: any) => {
  const selfRef = useRef<HTMLDivElement>(null);
  const [showStart, setShowStart] = useState(false);
  const [showEnd, setShowEnd] = useState(true);

  useEffect(() => {
    const elm = selfRef.current;
    if (elm) {
      elm.addEventListener('scroll', handleScroll);
      return () => {
        elm.removeEventListener('scroll', handleScroll);
      };
    }
    return () => { }; // tslint:disable-line
  });

  function handleScroll() {
    const elm = selfRef.current!;
    setShowEnd(elm.clientWidth + elm.scrollLeft < elm.scrollWidth);
    setShowStart(elm.scrollLeft !== 0);
  }

  function renderStartOverlay() {
    if (!showStart) {
      return null;
    }
    return (<TimeLineOverflowOverlayStart />);
  }

  function renderEndOverlay() {
    if (!showEnd) {
      return null;
    }
    return (<TimeLineOverflowOverlayEnd />);
  }
  return (
    <TimeLineOuterWrapper>
      {renderStartOverlay()}
      <TimeLineInnerWrapper ref={selfRef}>
        {props.children}
      </TimeLineInnerWrapper>
      {renderEndOverlay()}
    </TimeLineOuterWrapper>
  );
};
