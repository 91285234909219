import styled from 'styled-components';
import { Content } from '@visikon/core-models';
import { Button, Confirm, Divider } from 'semantic-ui-react';
import { LanguageSelector } from 'components/LanguageSelector';
import { languages } from 'commons/languages';
import { CustomTypeInput, CustomTypeValue, ITypeDescription } from './CustomTypeInput';
import { ToolTip } from '../PublishStatus';

interface IProps {
  activeLanguage: Content.LanguageCode;
  typeDescription: ITypeDescription;

  handleFormChange(pKey: string, value: CustomTypeValue): void;

  handleLanguageChange(lang: Content.LanguageCode): void;

  confirmLanguageChange(): void;

  setConfirmChangeTo(lang: Content.LanguageCode | undefined): void;

  confirmChangeTo: Content.LanguageCode | undefined;
  canDelete: boolean;

  deleteLanguage(): void;

  val: CustomTypeValue;
}

const LanguageSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const FormWrapper = styled.div`
  margin-top: 40px;
`;

export const CustomTypeForm = (props: IProps) => (
    <>
      <Divider />
      <LanguageSelectorWrapper>
          <LanguageSelector activeLang={props.activeLanguage} onLanguageChanged={props.handleLanguageChange}
                            languageChoices={languages} />{' '}
        <ToolTip
          attr={{'data-position' : "left center"}}
          text={ props.canDelete ? "Danger! Will delete the chosen language" : "You cannot delete a language if there is only one, OR if there are unsaved changes" } isLive  >
          <Button
            icon
            negative
            disabled={!props.canDelete}
            size='tiny'
            title='Delete language'
            style={{ right: '4px' }}
            onClick={props.deleteLanguage}>
            <i className='close icon' />delete
          </Button>
        </ToolTip>

      </LanguageSelectorWrapper>


      <FormWrapper className='ui form'>
        <CustomTypeInput
          pKey='root'
          lang={props.activeLanguage}
          value={props.val}
          fields={props.typeDescription.fields}
          onChange={props.handleFormChange}
        />

        <Confirm
          content='You have unsaved changes. Discard?'
          open={props.confirmChangeTo !== undefined}
          onCancel={() => props.setConfirmChangeTo(undefined)}
          onConfirm={() => props.confirmLanguageChange()}
        />
      </FormWrapper>
    </>
  );

