import {
  clearCreateSuccess,
  clearUserActionError,
  createBatchSuccess,
  createCMSUserSuccessful,
  createNamedUserSuccess,
  createNamespaceSuccessful,
  createSpecificKeySuccessful,
  deleteANamespaceSuccessful,
  deleteCMSUserSuccessful,
  editCMSUserSuccessful,
  editReviewerSuccess,
  editUserSuccessful,
  listAllNamespacesSuccessful,
  listCMSUsersSuccessful,
  turnOnTwoFactorAuthSuccessful,
  updateNamespaceSuccessful,
  userActionError,
} from 'actions/UserActions';
import { Users } from '@visikon/core-models';
import { Reducer } from 'redux';
import { isType } from 'typescript-fsa';

export interface IState {
  readonly creationSuccess: boolean;
  readonly namespaces: Users.Namespace[];
  readonly cmsUsers: Users.CMSUser[];
  readonly error?: string;
  readonly qrCode?: string;
  tfaToken?: string;
}

const initialState: IState = {
  namespaces: [],
  cmsUsers: [],
  creationSuccess: false,
};

export const userReducer: Reducer<IState> = (state = initialState, action) => {
  if (isType(action, createBatchSuccess)) {
    return { ...state, creationSuccess: true };
  }

  if (isType(action, createNamedUserSuccess)) {
    return { ...state, creationSuccess: true };
  }

  if (isType(action, clearCreateSuccess)) {
    return { ...state, creationSuccess: false };
  }

  if (isType(action, listAllNamespacesSuccessful)) {
    return { ...state, namespaces: action.payload };
  }
  if (isType(action, userActionError)) {
    return { ...state, error: action.payload };
  }

  if (isType(action, clearUserActionError)) {
    return { ...state, error: undefined };
  }

  if (isType(action, createNamespaceSuccessful)) {
    return { ...state, creationSuccess: true };
  }

  if (isType(action, createSpecificKeySuccessful)) {
    return { ...state, creationSuccess: true };
  }

  if (isType(action, deleteANamespaceSuccessful)) {
    return { ...state, creationSuccess: true };
  }

  if (isType(action, updateNamespaceSuccessful)) {
    return { ...state, creationSuccess: true };
  }

  if (isType(action, createCMSUserSuccessful)) {
    return { ...state, creationSuccess: true };
  }

  if (isType(action, listCMSUsersSuccessful)) {
    return { ...state, cmsUsers: action.payload };
  }

  if (isType(action, deleteCMSUserSuccessful)) {
    return { ...state, creationSuccess: true };
  }

  if (isType(action, editCMSUserSuccessful)) {
    return { ...state, creationSuccess: true };
  }
  if (isType(action, editUserSuccessful)) {
    return { ...state, creationSuccess: true };
  }

  if (isType(action, turnOnTwoFactorAuthSuccessful)) {
    return { ...state, creationSuccess: true };
  }

  if (isType(action, editReviewerSuccess)) {
    return { ...state, data: action.payload };
  }

  return state;
};
