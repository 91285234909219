import { LanguageCode } from '@visikon/core-models/content';
import { getTranslationFromArray } from '@visikon/core-models/typeUtils';
import { Flag, Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { ProgramValidationWarning } from '@visikon/core-models/publisher';
import { VideoPlayListContent, VideoPlayListVideo } from '@visikon/core-models/models/content/video-lists';
import { langFromCode } from '../../../commons/languages';
import { BlobUrls } from '../../../commons/local-storage';

type PreviewProps = {
  language: LanguageCode,
  sectionList?: VideoPlayListContent,
  warnings: Array<ProgramValidationWarning>
};
export function VideosPreview({language, sectionList, warnings}: PreviewProps) {
  if(!sectionList) {
    return <>No translation found for {language}</>
  }

  return (<>
    <h3 style={{margin: 0}}>
      <ValidatedText text={sectionList.data.name} warning="Missing playlist name"/>
    </h3>
    {sectionList.data.sections?.map((section, i) => <div key={i}>
      <h4 style={{marginBottom: 0, marginTop: 8}}>
        <ValidatedText text={section.sectionName} warning="Missing section name in playlist"/>
      </h4>
      <Grid>
        {section.videoList.map((video, j) =>
          <VideoView key={j} language={language} data={video} warnings={warnings.filter(warning => warning.id === video.video?._id)}  />
        )}
      </Grid>
    </div>)}
  </>);
}

type VideoProps = {
  language: LanguageCode,
  data: VideoPlayListVideo,
  warnings: Array<ProgramValidationWarning>
};

function VideoView({language, data, warnings}: VideoProps) {
  const [src, setSrc] = useState<string>();
  const [thumbnailSrc, setThumbnailSrc] = useState<string>();

  const translation = getTranslationFromArray(data.video?.translations, language);
  const flag = translation && translation?.language !== language &&
    <Flag name={langFromCode(translation?.language).flag as any} />;

  useEffect(() => {
    if (!data.video) {
      return;
    }

    if(data.video._type === 'BlockList') {
      setThumbnailSrc(BlobUrls.encodedResourceUrl(translation?.thumbnailSrc));
      setSrc(BlobUrls.encodedResourceUrl(translation?.src));
    } else {
      setThumbnailSrc(BlobUrls.thumbnailUrl(data.video._id, translation));
      setSrc(BlobUrls.videoUrl(data.video._id, translation));
    }
  }, [translation, data.video])

  return (<div>
    <VideoLabel>
      <Name style={warnings.length > 0 ? {color: 'red'} : {}}>
        {flag} <ValidatedText text={data.name} warning="Missing video name in playlist"/>
      </Name>
      <VideoInfoPopup
        playlistEntryName={data.name}
        videoResourceName={data.video?.name}
        videoResourceType={data.video?._type}
        videoResourceId={data.video?._id}
        warnings={warnings} />
    </VideoLabel>
    {data.video?._id && src &&
      <VideoElem
        controls
        className="ui large bordered image"
        crossOrigin="anonymous"
        poster={thumbnailSrc}
        src={src} />
    }
    {(!data.video?._id || !src) &&
      <NoVideo />
    }
  </div>);
}

type PopupProps = {
  playlistEntryName?: string,
  videoResourceName?: string,
  videoResourceId?: string,
  videoResourceType?: string,
  warnings: Array<ProgramValidationWarning>
};
function VideoInfoPopup({playlistEntryName, videoResourceName, videoResourceId, videoResourceType, warnings}: PopupProps) {
  return (<Popup
    flowing
    hoverable
    trigger={(
      <Icon
        style={{ cursor: 'pointer', margin: 0, color: warnings.length > 0 ? 'red' : 'black' }}
        name="info circle"
        className="right floated"
      />
    )}
    content={<div>
      <b>
        <ValidatedText text={playlistEntryName} warning="Missing video name in playlist"/>
      </b>
      <div style={!videoResourceName ? {color: 'red'} : {}}>
        {videoResourceType === 'BlockList' &&
          <Icon name="file video outline" />
        }
        {videoResourceType === 'Video' &&
          <Icon name="video" />
        }
        <a href={videoResourceType === 'BlockList' ? `/block/composer/${videoResourceId}` : `/media/videos/${videoResourceId}`}> {videoResourceName?.trim() || 'UNNAMED VIDEO'}</a>
      </div>
      {warnings.length > 0 && <div style={{marginTop: 8}}>
        <b>Errors:</b>
        {warnings.map((warning, i) =>
          <div key={i} style={{color: 'red'}}>{warning.text}</div>
        )}
      </div>}
    </div>}
  />);
}

function ValidatedText({text, warning}: { text: string | undefined, warning: string }) {
  return (<span style={text?.trim()? {} : {color: 'red'}}>
    {text?.trim() || warning}
  </span>);
}

const VideoLabel = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 4px;
  width: 100%;
`;

const Grid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, 140px);
  grid-gap: 1rem;
`;

const VideoElem = styled.video`
  height: 80px!important;
  width: auto!important;
  border: 1px solid gray;
`;

const NoVideo = styled.div`
  height: 80px;
  width: 140px;
  border: 1px solid gray;
`;
