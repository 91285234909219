import { Blueprint } from '@visikon/core-models/encoding';
import { useEncodeFromBlueprint, useEncoderStatus } from 'api/v2/encoderApi';
import * as Semantic from 'semantic-ui-react';
import { langFromCode } from '../../../../../commons/languages';
import { BlueprintTable } from './EncodeNewBlueprintTable';
import * as Styles from './EncodeNew.modal.styles';
import { isValidBlueprint } from '@visikon/core-models/helpers/composer/blueprint-helpers';
import { BlobUrls } from 'commons/local-storage';

type Props = {
  blueprint: Blueprint;
  open: boolean;
  onDismiss(): void;
};

export function EncodeNewDialog({ blueprint, open, onDismiss }: Props) {
  const { data, isLoading } = useEncoderStatus();
  const mutation = useEncodeFromBlueprint();

  const isFetching = isLoading || mutation.isLoading;
  const isRunning = data?.status === 'running';

  const onEncode = () => {
    mutation.mutate(blueprint);
    onDismiss();
  };

  const isValid = isValidBlueprint(blueprint);

  return (
    <Semantic.Modal open={open} onClose={onDismiss} closeOnEscape>
      <Semantic.Modal.Header>Encode video</Semantic.Modal.Header>
      <Semantic.Modal.Content>
        {!isValid && <div style={{ color: 'red' }}>There are blocks with missing media</div>}
        {isFetching && <Spinner />}
        {isRunning && <Running />}
        {!isFetching && !isLoading && !isRunning && <Confirm blueprint={blueprint} onEncode={onEncode} onDismiss={onDismiss} />}
      </Semantic.Modal.Content>
    </Semantic.Modal>
  );
}

function Spinner() {
  return <div>Loading ...</div>;
}

function Running() {
  return <h4>Encoding is running!</h4>;
}

function Confirm({ blueprint, onEncode, onDismiss }: { blueprint: Blueprint; onEncode: () => void; onDismiss: () => void }) {
  return (
    <Styles.ConfirmContainer>
      <div>Do you want to encode this video?</div>
      <SettingsTable blueprint={blueprint} />
      <BlueprintTable blueprint={blueprint} />
      <div>
        <Semantic.Button primary onClick={onEncode}>
          Yes
        </Semantic.Button>
        <Semantic.Button negative onClick={onDismiss}>
          No..
        </Semantic.Button>
      </div>
    </Styles.ConfirmContainer>
  );
}

function SettingsTable({ blueprint }: { blueprint: Blueprint }) {
  return (
    <Semantic.Table celled>
      <Semantic.Table.Header>
        <Semantic.Table.Row>
          <Semantic.Table.HeaderCell>Encoding language</Semantic.Table.HeaderCell>
          <Semantic.Table.HeaderCell>Audio delay</Semantic.Table.HeaderCell>
          <Semantic.Table.HeaderCell>Thumbnail</Semantic.Table.HeaderCell>
        </Semantic.Table.Row>
      </Semantic.Table.Header>
      <Semantic.Table.Body>
        <Semantic.Table.Row>
          <Semantic.Table.Cell>
            <Semantic.Flag name={langFromCode(blueprint.header.language).flag as any} />
          </Semantic.Table.Cell>
          <Semantic.Table.Cell>{blueprint.body.audioDelay}</Semantic.Table.Cell>
          <Semantic.Table.Cell>
            {blueprint.body.thumbnailSrc && <img src={BlobUrls.genericUrl(blueprint.body.thumbnailSrc)} alt="" height={64} />}
            {!blueprint.body.thumbnailSrc && <div style={{ color: 'red' }}>Missing thumbnail</div>}
          </Semantic.Table.Cell>
        </Semantic.Table.Row>
      </Semantic.Table.Body>
    </Semantic.Table>
  );
}
