import * as Actions from 'actions/BlockActions';
import * as QueryClientActions from 'actions/ReactQueryActions';
import axios, { AxiosResponse } from 'axios';
import { APIUtils } from 'commons/utils';
import { API, templateImage, videoFileTemplateImage } from 'config';
import { Block, BlockCreation, BlockSpeakChange, BlockVideoChange, BlockPlaceholderChange } from '@visikon/core-models/content';
import { IState } from 'reducers/reducers';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import * as ContentAPI from 'api/contentApi';
import { BLOCK_CACHE_KEY } from 'api/blocksApi';
import { logger } from '../commons/logger';
import { FetchState } from 'reducers/blocksReducer';

const getToken = (state: IState) => state.auth.token;

function* uploadVideoFiles(blockId: string, videos: BlockVideoChange[]) {
  // @ts-ignore
  const token = yield select(getToken);

  const videosWithFiles = videos.filter((v) => v.videoFile !== undefined);

  for (const vid of videosWithFiles) {
    const headers = {
      'Content-Type': 'multipart/form-data',
      'upload-key': vid.key,
      'upload-mime': vid.videoFile!.file.type,
      'upload-name': vid.videoFile!.file.name,
      'upload-size': vid.videoFile!.file.size,
      'upload-duration': vid.videoFile!.duration,
    };
    const videoUrl = new URL(`./blocks/${blockId}/video`, API.baseURL);
    yield call(axios.post, videoUrl.toString(), vid.videoFile!.file, APIUtils.tokenHeader(token, headers));
  }
}

function* uploadImageFiles(blockId: string, images: BlockPlaceholderChange[]) {
  // @ts-ignore
  const token = yield select(getToken);

  const videosWithFiles = images.filter((v) => v.imageFile !== undefined);

  for (const img of videosWithFiles) {
    const headers = {
      'Content-Type': 'multipart/form-data',
      'upload-key': img.key,
      'upload-mime': img.imageFile!.file.type,
      'upload-name': img.imageFile!.file.name,
      'upload-size': img.imageFile!.file.size,
    };
    const videoUrl = new URL(`./blocks/${blockId}/video`, API.baseURL);
    yield call(axios.post, videoUrl.toString(), img.imageFile!.file, APIUtils.tokenHeader(token, headers));
  }
}

function* uploadAudioFiles(blockId: string, speaks: BlockSpeakChange[]) {
  // @ts-ignore
  const token = yield select(getToken);

  for (const speak of speaks) {
    if (speak.audioFile) {
      const headers = {
        'Content-Type': 'multipart/form-data',
        'upload-key': speak.key,
        'upload-mime': speak.audioFile.file.type,
        'upload-name': speak.audioFile.file.name,
        'upload-size': speak.audioFile.size,
        'upload-duration': speak.audioFile.duration,
      };
      const audioUrl = new URL(`./blocks/${blockId}/audio`, API.baseURL);
      yield call(axios.post, audioUrl.toString(), speak.audioFile.file, APIUtils.tokenHeader(token, headers));
    }
  }
}

function hasTemplateImg(video: BlockVideoChange) {
  return video.existingSource?.indexOf(templateImage) !== -1 || video.fileNameForShow?.indexOf(templateImage) !== -1;
}

async function createBlockAPICall(token: string, blockCreation: BlockCreation): Promise<Block> {
  const blockUrl = new URL('./blocks/create', API.baseURL);

  // Filter out the video/audio on the payload. Those are used on the frontend to send video files.
  const creation = {
    ...blockCreation,
    videos: blockCreation.videos.map((v) => {
      const { videoFile, ...rest } = v;
      if (hasTemplateImg(v)) {
        return { ...videoFileTemplateImage, ...rest };
      }
      return rest;
    }),
    speaks: blockCreation.speaks.map((s) => {
      const { audioFile, existingSource, ...rest } = s;
      return rest;
    }),
  };

  // Create block model
  const blockResult: AxiosResponse = await axios.post(blockUrl.toString(), creation, APIUtils.tokenHeader(token));
  return blockResult.data as Block;
}

function hasMediaFiles(blockCreation: BlockCreation) {
  const someWithVideoFile = (blockCreation.videos || []).find((v) => v.videoFile !== undefined);
  const someWithImageFile = (blockCreation.videos || []).find((v) => v.imageFile !== undefined);
  const someWithAudioFile = (blockCreation.speaks || []).find((s) => s.audioFile !== undefined);
  return someWithAudioFile || someWithVideoFile || someWithImageFile;
}

function* createBlockSaga(action: Action<BlockCreation>) {
  yield put(Actions.setLoadingStatus(true));
  // @ts-ignore
  const token = yield select(getToken);
  try {
    let block: Block = yield call(createBlockAPICall, token, action.payload);

    // Upload media file
    yield uploadVideoFiles(block._id, action.payload.videos);
    yield uploadImageFiles(block._id, action.payload.videos);
    yield uploadAudioFiles(block._id, action.payload.speaks);

    // Media files were updated - get new block from backend
    if (hasMediaFiles(action.payload)) {
      block = yield call(getBlockById, block);
    }

    // Update block in state
    yield put(Actions.updateBlockInState(block));
    yield put(Actions.createBlockSuccessful(block));
    yield put(QueryClientActions.invalidateQueries([BLOCK_CACHE_KEY, 'list']));
  } catch (e) {
    if (e.response && e.response.data) {
      logger.error("Couldn't create block:", e.response.data);
    } else {
      // eslint-disable-next-line no-console
      console.log(e);
      logger.error("Couldn't create block: Reason unknown.", e);
    }
  } finally {
    yield put(Actions.setLoadingStatus(false));
  }
}

function* addNewBlockToComposer(action: Action<BlockCreation>) {
  // @ts-ignore
  const token = yield select(getToken);

  try {
    let block: Block = yield call(createBlockAPICall, token, action.payload);

    // Upload media file
    yield uploadVideoFiles(block._id, action.payload.videos);
    yield uploadAudioFiles(block._id, action.payload.speaks);

    // Media files were updated - get new block from backend
    if (hasMediaFiles(action.payload)) {
      block = yield call(getBlockById, block);
    }

    // Update block in state
    yield put(Actions.updateBlockInState(block));
    yield put(Actions.addNewBlockToComposerSuccessful(block._id));
  } catch (e) {
    if (e.response && e.response.data) {
      logger.error("Couldn't create block:", e.response.data);
    } else {
      logger.error("Couldn't create block: Reason unknown.", e);
    }
    yield put(Actions.addNewBlockToComposerError());
  }
}

function* updateBlock(action: Action<BlockCreation>) {
  if (action.payload.updateId === undefined) {
    return;
  }

  yield put(Actions.setLoadingStatus(true));

  // @ts-ignore
  const token = yield select(getToken);
  const blockUrl = new URL(`./blocks/${action.payload.updateId}`, API.baseURL);
  try {
    // Filter out the video/audio on the payload. Those are used on the frontend to send video files.
    const creation = {
      ...action.payload,
      videos: action.payload.videos.map((v) => {
        const { videoFile, existingSource, ...rest } = v;
        const updated = hasTemplateImg(v) ? { ...videoFileTemplateImage, ...{ existingSource }, ...rest } : rest;
        return updated;
      }),
      speaks: action.payload.speaks.map((s) => {
        const { audioFile, existingSource, ...rest } = s;
        return rest;
      }),
    };

    // Update block model
    const blockResult: AxiosResponse = yield call(axios.post, blockUrl.toString(), creation, APIUtils.tokenHeader(token));

    let block = blockResult.data as Block;

    // Upload media file
    yield uploadVideoFiles(block._id, action.payload.videos);
    yield uploadImageFiles(block._id, action.payload.videos);
    yield uploadAudioFiles(block._id, action.payload.speaks);

    // Media files were updated - get new block from backend
    if (hasMediaFiles(action.payload)) {
      block = yield call(getBlockById, block);
    }

    // TODO remove this - Update block in state
    logger.debug('updated', block);
    yield put(Actions.updateBlockInState(block));
    yield put(Actions.updateBlockSuccessful(block));
    yield put(QueryClientActions.invalidateQueries([BLOCK_CACHE_KEY, 'single', block._id]));
    yield put(QueryClientActions.invalidateQueries([BLOCK_CACHE_KEY, 'list']));
  } catch (e) {
    logger.error("Couldn't update block:", e);
  } finally {
    yield put(Actions.setLoadingStatus(false));
  }
}

function* listBlocks() {
  // Create block in backend
  try {
    const currentFetchState: FetchState = yield select((state: IState) => state.blocks.fetchState);
    if (currentFetchState === FetchState.FETCHING) {
      // eslint-disable-next-line no-console
      console.debug('Blocks saga is already fetching list of blocks. Ignoring listBlocks request');
      return;
    }

    yield put(Actions.setBlocksFetchState(FetchState.FETCHING));
    yield put(Actions.setLoadingStatus(true));
    // @ts-ignore
    const token = yield select(getToken);
    const url = new URL('./blocks/all', API.baseURL);
    // @ts-ignore
    const result = yield call(axios.get, url.toString(), APIUtils.tokenHeader(token));

    yield put(Actions.listBlocksSuccessful(result.data));
    yield put(Actions.setLoadingStatus(false));
    yield put(Actions.setBlocksFetchState(FetchState.SUCCEEDED));
  } catch (e) {
    logger.error("Couldn't load blocks");
    yield put(Actions.listBlocksSuccessful([]));
    yield put(Actions.setLoadingStatus(false));
    yield put(Actions.setBlocksFetchState(FetchState.FAILED));
  }
}

function* getBlockById(block: Block) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const url = new URL(`./blocks/${block._id}`, API.baseURL);
    const response: AxiosResponse<Block> = yield call(axios.get, url.toString(), APIUtils.tokenHeader(token));
    return response.data;
  } catch (e) {
    logger.error("Couldn't load variation stats");
    return undefined;
  }
}

function* generateThumbnail(action: Action<{ id: string; thumbnailSrc: string }>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const { id, thumbnailSrc } = action.payload;
    const response: AxiosResponse = yield call(ContentAPI.thumbnailBlock, token, id, thumbnailSrc);

    if (response.status === 200) {
      yield put(Actions.generateThumbnailSuccess());
    } else {
      yield put(Actions.thumbnailApiError({ msg: response.data }));
    }
  } catch (error) {
    logger.error('Error in generateThumbnail()', error);
  }
}

export function* blocksSaga() {
  yield takeEvery(Actions.createBlock, createBlockSaga);
  yield takeEvery(Actions.updateBlock, updateBlock);
  yield takeEvery(Actions.listBlocks, listBlocks);
  // From composer
  yield takeEvery(Actions.addNewBlockToComposer, addNewBlockToComposer);
  yield takeEvery(Actions.generateThumbnail, generateThumbnail);
}
