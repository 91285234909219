import { BlockSpeakChange } from '@visikon/core-models/content';
import useRefState from 'commons/useRefState';
import { useRef, useState, useEffect } from 'react';
import { Dropdown, Icon, Menu, Popup, Table } from 'semantic-ui-react';
import { ClipboardLinkButton } from '../../../../../components/ClipboardLinkButton';
import Text2SpeechModal from '../../text2speech';
import { FileUploadButton } from '../FileUpload';
import { VariationProps } from '../SharedVarationParts';
import { StatusPicker } from '../StatusPicker';
import { VariationComments } from '../VariationComments';
import { VariationDropDown } from '../VariationDropDown';
import * as Styles from './SpeakVariation.styles';
import { downloadFile } from 'commons/download-file';
import { PreviewReadyToast } from '../../text2speech/toast';
import { LanguageCode } from '@visikon/core-models/languageTypes';
import { VoiceDropdown } from '../../text2speech/VoiceDropdown';
import { staticVoiceData } from '../../text2speech/StaticVoiceData';
import PhoneticsTestModal from '../../text2speech/PhoneticsTestModal';
import { usePreferredVoice } from '../../text2speech/UsePreferredVoice';

type Props = VariationProps<BlockSpeakChange, BlockSpeakChange> & { activeLanguage: LanguageCode };

export const SpeakVariation = ({ variation, isActiveVariation, blockVariations, activeLanguage, onChange, onRemove }: Props) => {
  const mediaRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setPlaying] = useState<boolean>(false);
  const [speak, setSpeak, getSpeak] = useRefState<Props['variation']>(variation);
  const [voiceName, setVoiceName] = useState('');
  const [voiceGender, setVoiceGender] = useState('');

  function onEnded() {
    if (mediaRef.current === null) {
      return;
    }

    // @ts-ignore
    delete mediaRef.current.src;
    setPlaying(false);
  }

  const handleFilePicked = (files: File[]) => {
    const file = files[0];
    const mediaTag = mediaRef.current;
    const currentSpeak = getSpeak();

    if (file && mediaTag && currentSpeak) {
      mediaTag.src = URL.createObjectURL(file);
      mediaTag.onloadedmetadata = () => {
        handleVariationChange({
          ...currentSpeak,
          audioFile: {
            contentType: file.type,
            preview: (file as any).preview,
            size: file.size,
            file,
            duration: mediaTag.duration,
          },
        });
      };
    }
  };

  const handleSpeechAttached = (blob: Blob) => {
    const mediaTag = mediaRef.current;
    const currentSpeak = getSpeak();

    const variationName = currentSpeak?.variations?.[0];
    const langCode = staticVoiceData.find((lang) => lang.value === activeLanguage)?.code;

    if (blob && mediaTag && currentSpeak && variationName && langCode) {
      const customFileName = `Generated_Audio_[${variationName}]-${langCode}-${voiceName}.wav`;
      const filename = speak.fileNameForShow || customFileName;
      const file = new File([blob], filename, { type: blob.type });
      const tempUrl = URL.createObjectURL(file);

      mediaTag.src = tempUrl;
      mediaTag.onloadedmetadata = () => {
        handleVariationChange({
          ...currentSpeak,
          audioFile: {
            contentType: file.type,
            preview: tempUrl,
            size: file.size,
            file,
            duration: mediaTag.duration,
          },
        });
      };
    }
  };

  const handlePlayClicked = () => {
    if (mediaRef.current === null) {
      return;
    }

    if (isPlaying) {
      mediaRef.current.pause();
      setPlaying(false);
    } else {
      mediaRef.current.src = (speak.audioFile ? speak.audioFile.preview : speak.existingSource) || '';
      mediaRef.current.play();
      setPlaying(true);
    }
  };

  const handleVariationChange = (speakChange: Partial<Props['variation']>) => {
    const newSpeak = { ...getSpeak(), ...speakChange };

    setSpeak(newSpeak);
    onChange(newSpeak);
  };

  const handleRemoveSpeak = () => {
    const modifiedSpeak = { ...getSpeak() };
    onRemove?.(modifiedSpeak.key);
  };

  const hasSpeak = speak.existingSource || speak.audioFile;

  return (
    <Styles.Container style={{ border: isActiveVariation ? '4px solid #9fcb34' : '' }}>
      <Styles.Content>
        <Table compact basic="very">
          <Table.Body>
            <Table.Row>
              <Table.Cell style={{ width: 44 }}>
                <StatusPicker onChange={(status) => handleVariationChange({ status })} active={speak.status} />
              </Table.Cell>
              <Table.Cell>
                <VariationDropDown
                  defaultValue={speak.variations}
                  blockVariations={blockVariations}
                  onChange={(variations) => handleVariationChange({ variations })}
                />
              </Table.Cell>
            </Table.Row>

            <Table.Row style={{ border: 0 }}>
              <Table.Cell colSpan={2}>
                <Menu style={{ alignItems: 'center' }}>
                  <Dropdown item icon="bars" style={hasSpeak ? {} : { color: 'red' }}>
                    <Dropdown.Menu>
                      <FileUploadButton text="Upload audio" onFilePick={handleFilePicked} />
                      <DownloadButton speak={speak} />
                      <Text2SpeechButton
                        text={speak.text}
                        onAttached={handleSpeechAttached}
                        hasSpeak={!!hasSpeak}
                        activeLanguage={activeLanguage}
                        selectedVoiceName={voiceName}
                        selectedVoiceGender={voiceGender}
                        setVoiceName={setVoiceName}
                        setVoiceGender={setVoiceGender}
                      />
                      <Text2SpeechPhoneticsButton activeLanguage={activeLanguage} selectedVoiceName={voiceName} selectedVoiceGender={voiceGender} />
                      <DeleteButton hasSpeak={!!hasSpeak} handleRemoveSpeak={handleRemoveSpeak} />
                    </Dropdown.Menu>
                  </Dropdown>
                  <PlayButton hasSpeak={!!hasSpeak} isPlaying={isPlaying} handlePlayClicked={handlePlayClicked} />
                  <PreviewReadyToast toastText="Preview ready!" success={!!speak.audioFile} />
                  <WorkfilesButton fileNameForShow={speak.fileNameForShow} />
                </Menu>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell colSpan={2}>
                <Styles.Editor
                  onChange={({ target }) => handleVariationChange({ text: target.value })}
                  defaultValue={speak.text}
                  placeholder="Enter manuscript text..."
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Styles.Content>

      <VariationComments
        title="Comment"
        content={speak.notes}
        sendWrittenNotes={(notes) => handleVariationChange({ notes })}
        placeholder="Write here..."
        shouldExpand={isActiveVariation}
      />

      <video ref={mediaRef} style={{ display: 'none' }} onEnded={onEnded} preload="metadata" crossOrigin="anonymous" />
    </Styles.Container>
  );
};

function PlayButton({ hasSpeak, isPlaying, handlePlayClicked }: { hasSpeak: boolean; isPlaying: boolean; handlePlayClicked: () => void }) {
  return (
    <>
      {hasSpeak && (
        <>
          <Menu.Item name="play" onClick={handlePlayClicked}>
            <Icon name={isPlaying ? 'pause circle' : 'play circle'} />
          </Menu.Item>
        </>
      )}
    </>
  );
}

function WorkfilesButton({ fileNameForShow }: { fileNameForShow?: string }) {
  return (
    <>
      {fileNameForShow && (
        <Popup
          trigger={
            <Menu.Item position="right">
              <Icon name="file" />
            </Menu.Item>
          }
          content={<ClipboardLinkButton cleanup>{fileNameForShow}</ClipboardLinkButton>}
          header="Original filename"
          on="click"
          size="small"
          position="bottom right"
        />
      )}
    </>
  );
}

function DeleteButton({ hasSpeak, handleRemoveSpeak }: { hasSpeak: boolean; handleRemoveSpeak: () => void }) {
  const featureToggle = { disabled: false };

  return (
    <>
      {!featureToggle.disabled && hasSpeak && (
        <>
          <Dropdown.Divider />
          <Dropdown.Item onClick={handleRemoveSpeak}>
            <Icon name="trash" />
            <span className="text">Remove audio</span>
          </Dropdown.Item>
        </>
      )}
    </>
  );
}

function DownloadButton({ speak }: { speak?: BlockSpeakChange }) {
  const name = speak?.fileNameForShow;
  const src = speak && (speak.audioFile ? speak.audioFile.preview : speak.existingSource);

  return (
    <>
      {src && name && (
        <>
          <Dropdown.Item onClick={() => downloadFile(src, name)}>
            <Icon name="download" />
            <span className="text">Download audio</span>
          </Dropdown.Item>
        </>
      )}
    </>
  );
}

interface Text2SpeechButtonProps {
  text: string;
  onAttached: (blob: Blob) => void;
  hasSpeak: boolean;
  activeLanguage: LanguageCode;
  selectedVoiceName: string;
  selectedVoiceGender: string;
  setVoiceName: (voiceName: string) => void;
  setVoiceGender: (voiceGender: string) => void;
}

const Text2SpeechButton = ({
  text,
  onAttached,
  hasSpeak,
  activeLanguage,
  selectedVoiceName,
  selectedVoiceGender,
  setVoiceName,
  setVoiceGender,
}: Text2SpeechButtonProps) => {
  const handleVoiceChange = (e: any, data: { value: string }) => {
    if (typeof data.value === 'string') {
      const voice = data.value.split('-').shift() as string;
      const gender = data.value.split('-').pop() as string;
      setVoiceName(voice);
      setVoiceGender(gender);
    }
  };

  const { voiceName, voiceGender } = usePreferredVoice({
    selectedLanguage: activeLanguage,
    selectedVoiceName,
    selectedVoiceGender,
  });

  useEffect(() => {
    setVoiceName(voiceName);
    setVoiceGender(voiceGender);
  }, [voiceName, voiceGender, setVoiceName, setVoiceGender]);

  return (
    <Text2SpeechModal
      text={text}
      onAttached={onAttached}
      activeLanguage={activeLanguage}
      selectedVoiceName={selectedVoiceName}
      selectedVoiceGender={selectedVoiceGender}
    >
      <Dropdown.Item disabled={!!hasSpeak || !text}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon name="comment" />
          <span className="text">Generate speech</span>
          <VoiceDropdown activeLanguage={activeLanguage} onChange={handleVoiceChange} />
        </div>
      </Dropdown.Item>
    </Text2SpeechModal>
  );
};
const Text2SpeechPhoneticsButton = ({ activeLanguage, selectedVoiceName, selectedVoiceGender }: Partial<Text2SpeechButtonProps>) => (
  <PhoneticsTestModal
    activeLanguage={activeLanguage || 'da'}
    selectedVoiceName={selectedVoiceName || ''}
    selectedVoiceGender={selectedVoiceGender || ''}
  >
    <Dropdown.Item>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon name="ald" />
        <span className="text">Test Phonetics</span>
      </div>
    </Dropdown.Item>
  </PhoneticsTestModal>
);
