import { CMSUser } from '@visikon/core-models/user';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

interface IState {
  activeUser: CMSUser;
}

const IconWrapper = styled.span`
    i.user.circular.icon {
        background: white;
        height: 2.3em !important;
        width: 2.3em !important;
    }
`;

export class ProfileSelector extends React.Component<any, IState> {
  trigger = (
    <IconWrapper>
      <Icon circular name="user" />
    </IconWrapper>
  );

  render() {
    return (
      <>
        <Dropdown inline trigger={this.trigger}>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to="/profilesetting">
              Profile setting
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as={Link} to="/logout">
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
}
