import { Content } from '@visikon/core-models';
import React from 'react';
import { Button, Input, Modal, Popup } from 'semantic-ui-react';
import { TagList } from '../../tags/TagList';
import { FilePicker } from '../../FilePicker';

interface IProps {
  isUploading: boolean;
  onCreate: (name: string, tags: Content.Tag[], file: File, duration: number) => void;
}

interface IState {
  modalShown: boolean;
  name: string;
  tags: Content.Tag[];
  fileChosen?: File;
  duration: number;
}

const initialState: IState = {
  modalShown: false,
  name: '',
  tags: [],
  fileChosen: undefined,
  duration: -1,
};

export class VideoCreateDialog extends React.Component<IProps, IState> {
  videoRef: React.RefObject<HTMLVideoElement>;

  constructor(props: IProps) {
    super(props);

    this.state = { ...initialState };

    this.videoRef = React.createRef();

    this.handleNameChange = this.handleNameChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onFileChoosen = this.onFileChoosen.bind(this);
    this.onTagAdded = this.onTagAdded.bind(this);
    this.onTagRemoved = this.onTagRemoved.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onOpen = this.onOpen.bind(this);

    this.renderVideoArea = this.renderVideoArea.bind(this);
    this.renderEnabledSaveButton = this.renderEnabledSaveButton.bind(this);
    this.renderDisabledSaveButton = this.renderDisabledSaveButton.bind(this);
  }

  componentDidUpdate(prevProps: IProps) {
    if (!prevProps.isUploading && this.props.isUploading) {
      this.onClose();
    }
  }

  handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ name: event.target.value });
  }

  onTagAdded(tag: Content.Tag) {
    this.setState((prevState) => ({
      tags: [
        ...prevState.tags,
        tag,
      ],
    }));
  }

  onTagRemoved(tag: Content.Tag) {
    this.setState((prevState) => ({
      tags: prevState.tags.filter((t) => t !== tag),
    }));
  }

  onFileChoosen(files: File[]) {
    const file = files[0];
    // const reader = new FileReader();

    // If no name was chosen yet, use file name
    if (this.state.name.trim() === '') {
      this.setState({ name: file.name });
    }

    const vid = this.videoRef.current;
    if (vid) {
      vid.src = URL.createObjectURL(file);
      vid.ondurationchange = () => {
        this.setState({ fileChosen: file, duration: vid.duration });
      };
    } else {
      this.setState({ fileChosen: file, duration: -1 });
    }
    // reader.onload = (e: any) => {
    //     this.setState({ fileChosen: file });

    // };

    // reader.readAsDataURL(file);
  }

  onClose() {
    this.setState({ ...initialState });
  }

  onOpen() {
    this.setState({ modalShown: true });
  }

  onSave() {
    const {
      name, tags, fileChosen, duration,
    } = this.state;
    const isNameEmpty = name.trim() === '';
    const isTagListEmpty = tags.length === 0;
    const isFileEmpty = fileChosen === undefined;
    if (isNameEmpty || isTagListEmpty || isFileEmpty) {
      return;
    }

    this.props.onCreate(name, tags, fileChosen!, duration);
  }

  // eslint-disable-next-line class-methods-use-this
  getDisabledExplanation(isFileEmpty: boolean, isNameEmpty: boolean, isTagListEmpty: boolean) {
    if (isFileEmpty) {
      return 'You need a video';
    }
    if (isNameEmpty && isTagListEmpty) {
      return 'Please add both a name and at least one tag';
    }
    if (isNameEmpty) {
      return 'Please enter a name';
    }
    return 'Please add at least one tag';
  }

  renderEnabledSaveButton() {
    return (
      <div style={{ display: 'inline-block' }}>
        <Button
          icon="save"
          content="Save Video"
          positive
          onClick={this.onSave}
        />
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderDisabledSaveButton(explanation: string) {
    return (
      <Popup
        content={explanation}
        wide
        position="top center"
        trigger={(
          <div style={{ display: 'inline-block' }}>
            <Button
              icon="save"
              content="Save Video"
              positive
              disabled
            />
          </div>
        )}
      />
    );
  }

  renderVideoArea() {
    const { fileChosen } = this.state;
    return (
      <div style={{ width: 400, display: 'flex', justifyContent: 'center' }}>
        <video ref={this.videoRef} style={{ display: fileChosen ? 'inherit' : 'none' }} width={380} controls />
        {!fileChosen
                    && (
                    <FilePicker
                      fileTypeText="video file"
                      accept="video/mp4,video/webm,video/ogg"
                      onFilePick={this.onFileChoosen}
                      multiple={false}
                    />
                    )}
      </div>
    );
  }

  render() {
    const { name, tags, fileChosen } = this.state;
    const isNameEmpty = name.trim() === '';
    const isTagListEmpty = tags.length === 0;
    const isFileEmpty = fileChosen === undefined;
    const isSaveDisabled = isNameEmpty || isTagListEmpty || isFileEmpty;
    const explanation = this.getDisabledExplanation(isFileEmpty, isNameEmpty, isTagListEmpty);
    return (
      <Modal
        open={this.state.modalShown}
        onOpen={this.onOpen}
        onClose={this.onClose}
        trigger={<Button icon="add" content="New Video" positive />}
      >
        <Modal.Header>Create new video</Modal.Header>
        <Modal.Content image>
          {this.renderVideoArea()}
          <Modal.Description>
            <div style={{ width: 400 }}>
              <Input size="large" fluid autoFocus label="Name" value={name} onChange={this.handleNameChange} />
            </div>
            <br />
            <TagList
              readOnly={false}
              onTagAdded={this.onTagAdded}
              onTagRemoved={this.onTagRemoved}
              tagList={this.state.tags}
              header="Tags"
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" onClick={this.onClose} />
          {!isSaveDisabled && this.renderEnabledSaveButton()}
          {isSaveDisabled && this.renderDisabledSaveButton(explanation)}
        </Modal.Actions>
      </Modal>
    );
  }
}
