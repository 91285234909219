import styled from "styled-components";
import * as Semantic from 'semantic-ui-react';

export const VideoPreviewArea = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ModalHeader = styled(Semantic.Modal.Header)`
  display: flex !important;
  justify-content: space-between;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 4px;

  > * {
    margin: 0 !important;
  }
`;
