import React from 'react';
import { useSearchState } from '../search/search-state';

export interface PaginationProps {
  activePage: number;
  totalPages: number;
  onPageChange: (event: React.MouseEvent<HTMLAnchorElement>, paginationProps: PaginationProps) => void;
}

export interface PaginationState<T> {
  paginationProps: PaginationProps;
  getPage: (items: T[]) => T[];
}

export function usePaginationState<T>(initialItems: T[], pageSize = 15): PaginationState<T> {
  const { searchFilter, setSearchFilter } = useSearchState();
  const activePage = searchFilter.page || 1;

  const itemCount = initialItems.length;
  const totalPages = Math.ceil(itemCount / pageSize);

  const startIndex = (activePage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const onPageChange = (event: React.MouseEvent<HTMLAnchorElement>, paginationProps: PaginationProps) => {
    const pageNumber = Math.max(1, paginationProps.activePage as number);
    setSearchFilter({ ...searchFilter, page: Math.min(pageNumber, totalPages) });
  };

  const getPage = (items: T[]): T[] => items.slice(startIndex, endIndex);

  return { paginationProps: { activePage, totalPages, onPageChange }, getPage };
}
