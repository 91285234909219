import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { IState } from 'reducers/reducers';

export default function QueryStateInterface() {
  const queryClient = useQueryClient();
  const invalidateQueriesFromRedux = useSelector((state: IState) => state.reactQuery.invalidateQueries);

  useEffect(() => {
    if (invalidateQueriesFromRedux) {
      const { queryKey } = invalidateQueriesFromRedux;
      queryClient.invalidateQueries(queryKey);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalidateQueriesFromRedux]);

  return <></>;
}
