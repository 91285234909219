import { Link } from 'react-router-dom';
import { DisabledTextInput } from './program-duplicator.styles';
import { ProgramMetadataEntries } from '../../../api/program-metadata.models';

const typeURLs = {
  mitforlob: '/collections/mitforlob',
  contactText: 'media/textEdit',
  trainingVideos: '/content/videoList',
  phamplets: '/content/document',
  programVideos: '/content/videoList',
  faq: '/content/faq',
  symptoms: '/content/symptom',
  notifications: '/content/notifications',
  instructionVideos: '/content/videoList',
};

export function InputForm({ metadata }: { metadata?: ProgramMetadataEntries }) {
  const linkToType = (type: string) => typeURLs[type] || `/${type}`;

  return (
    <>
      {metadata?.map((entry) => {
        const isImage = ['logo', 'banner'].includes(entry.type);

        return (
          <div key={entry.type}>
            <h4>{entry.label}</h4>
            <DisabledTextInput>
              {!isImage
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            && <Link to={`${linkToType(entry.type)}/${entry.id}`} style={{ textDecoration: 'underline' }} href="#" target="_blank">{entry.name}</Link>}
              {isImage
                            && entry.name}
            </DisabledTextInput>
          </div>
        );
      })}
    </>
  );
}
