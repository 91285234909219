import moment from 'moment';
import { CustomType } from '@visikon/core-models/content';
import { getTranslation } from '@visikon/core-models/typeUtils';
import { Content } from '@visikon/core-models';
import { ITypeDescription } from './CustomTypeInput';

export interface IPrintableCustomTypeInstance {
  id: string;
  [key: string]: string;
}
export interface IPrintableCustomTypeFields {
  [key: string]: string;
}

export function getFields(typeDescription: ITypeDescription, max?: number): IPrintableCustomTypeFields {
  const result: IPrintableCustomTypeFields = {};
  let count = 0;
  const { fields } = typeDescription;
  const fieldKeys = Object.keys(fields).filter(field => !['menuLabel', 'pageTitle'].includes(field));

  for (const k of fieldKeys) {
    if (count >= (max || 1000)) {
      break;
    }

    const curr = fields[k];
    if (curr.type === 'string' || curr.type === 'long-string' || curr.type === 'list') {
      count++;
      result[k] = curr.desc;
    }
  }
  result.date = 'Created at';
  return result;
}

export function getSearchableFieldNames(typeName: string) {
  switch (typeName) {
    case 'mitforlobPack': return ['Name', 'Department', 'Organization'];
    case 'symptom': return ['Title', 'Description'];
    case 'survey': return ['Name', 'Title', 'Description'];
    default: return [];
  }
}

export function getValues(val: CustomType, language: Content.LanguageCode, typeDescription: ITypeDescription, max: number): IPrintableCustomTypeInstance {
  const trans = getTranslation(val, language);
  const value = trans!.data;
  const { fields } = typeDescription;
  const row: IPrintableCustomTypeInstance = { id: val._id, namespace: val.namespace, typeDescriptor: val.typeDescriptor };

  let count = 0;
  for (const k of Object.keys(fields)) {
    if (value === undefined) {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (count >= max) {
      break;
    }

    const curr = fields[k];
    if (curr.type === 'string' || curr.type === 'long-string') {
      count++;
      row[k] = value[k];
    }
    if (curr.type === 'list') {
      count++;
      const c = k in value ? value[k].length : 0;
      row[k] = `${c} item${c > 1 ? 's' : ''}`;
    }
  }
  row.date = moment(val.createdAt).format('YYYY-MM-DD - HH:mm');
  return row;
}
