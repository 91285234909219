import * as React from 'react';
import { BlockCreation } from '@visikon/core-models/content';
import { createBlock, updateBlock } from 'actions/BlockActions';
import { useBlock } from 'api/blocksApi';
import { Spinner } from 'components/spinner';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { languageState } from 'redux-recoil-sync.state';
import { Dimmer } from 'semantic-ui-react';
import { BlockEditModal } from '../block/BlockEditModal';

export default function BlockEditor() {
  const [language] = useRecoilState(languageState);
  const { id } = useParams<{ id: string }>();
  const isCreatingNew = id === 'new';
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useBlock(id);

  // eslint-disable-next-line no-debugger
  if (!id) debugger;

  if (query.isLoading) {
    return (
      <Dimmer active inverted>
        <Spinner visible />
      </Dimmer>
    );
  }

  const handleSave = (block: BlockCreation & { readyToSave?: boolean }) => {
    const actionToBeDispatched = isCreatingNew ? createBlock : updateBlock;
    dispatch(actionToBeDispatched(block));
  };

  return <BlockEditModal open initialBlock={query.data} lang={language} onDismiss={() => history.replace('/block/overview')} onSave={handleSave} />;
}
