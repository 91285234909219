import { Action } from 'typescript-fsa';
import * as LangActions from 'actions/LanguageActions';
import { put, takeEvery } from 'redux-saga/effects';

import { LOCAL_STORAGE_LANGUAGE_KEY } from 'config';
import { Content } from '@visikon/core-models';
import { logger } from '../commons/logger';

function* loadLanguageFromLocalStorage() {
  try {
    const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY);
    if (language) {
      yield put(LangActions.changeLanguage(language as Content.LanguageCode));
    }
  } catch (e) {
    logger.warn("Couldn't load kanguage from localstorage", e);
  }
}

// eslint-disable-next-line require-yield
function* watchForLanguageChange(action: Action<Content.LanguageCode>): IterableIterator<void> {
  try {
    if (action.payload) {
      localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, action.payload);
    }
  } catch (e) {
    logger.warn("Couldn't write language to localstorage", e);
  }
}

export function* languageSaga() {
  yield takeEvery(LangActions.changeLanguageFromLocalStorage, loadLanguageFromLocalStorage);
  yield takeEvery(LangActions.changeLanguage, watchForLanguageChange);
}
