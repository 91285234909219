import { Button } from 'semantic-ui-react';
import { ProgressBar } from './progress-bar';
import * as Styles from './video-player-controls.styles';
import { formatDuration, getNumbers } from './video-player.helpers';
import { VideoClip } from './video-player.models';

interface IProps {
  clips: VideoClip[];
  currentPos: number;
  currentIndex: number;
  isPlaying: boolean;
  onPlayPauseClick(): void;
  onRewindClick(): void;
  onSeek(seekToSeconds: number): void;
}

export const VideoPlayerControls = (props: IProps) => {
  const {currentPos, currentIndex, isPlaying, onPlayPauseClick, onRewindClick, onSeek, clips} = props;
  const { durations, durationInTotal, currentTimeTotal, percentage } = getNumbers(clips, currentIndex, currentPos);

  const handleOnSeek = (seekToPercentage: number) => {
    onSeek(seekToPercentage * durationInTotal);
  };

  const isDisabled = clips.length === 0;
  const btnIcon = isPlaying ? 'pause' : 'play';

  return (
    <Styles.Wrapper>
      <Button size="tiny" icon={btnIcon} disabled={isDisabled} onClick={onPlayPauseClick} />
      <Button size="tiny" icon="fast backward" disabled={isDisabled} onClick={onRewindClick} />
      <Styles.Time>{formatDuration(currentTimeTotal)}</Styles.Time>
      <Styles.ProgressWrapper>
        <ProgressBar progress={percentage} markers={durations} onSeek={handleOnSeek} disabled={isDisabled} />
      </Styles.ProgressWrapper>
      <Styles.Time>{formatDuration(durationInTotal)}</Styles.Time>
    </Styles.Wrapper>
  );
};

