import { useState } from 'react';

/**
 * PLEASE ONLY USE THIS FUNCTION AS AN WORKAROUND!
 * EXCESSIVELY USAGE OR INCORRECT USAGE WILL CREATE PERFORMANCE ISSUES!
 *
 * @returns a function which when called forces the component to rerender.
 */
export function useForceRender() {
  const [count, setCounter] = useState<number>(0);

  return () => setCounter(count + 1);
}
