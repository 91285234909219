export function SearchSummary({ filteredRows, unfilteredRows }: { filteredRows: any[], unfilteredRows: any[] }) {
  return (
    <>
      {filteredRows.length === unfilteredRows.length
        && `Showing all ${filteredRows.length} entries.`}

      {filteredRows.length !== unfilteredRows.length
        && `Found ${filteredRows.length} entries.`}
    </>
  );
}
