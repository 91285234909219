import { Route, RouteComponentProps } from 'react-router-dom';
import { CollectionPage } from './CollectionPage';

interface IProps extends RouteComponentProps<void> {

}

export const CollectionsRoot = (props: IProps) => {
  const { match } = props;

  const renderCollectionsRoot = () => (
    <h1>Select collection</h1>
  );

  return (
    <>
      <Route path={match.url} exact render={renderCollectionsRoot} />
      <Route path="/collections/:name/:id?" component={CollectionPage} />
    </>
  );
};
