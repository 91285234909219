import { createLogger, format, transports } from 'winston';
import { API } from '../config';
import { getEnv } from './env';

const base = new URL(API.baseURL);
const httpTransportOptions = {
  host: base.hostname,
  port: parseInt(base.port, 10),
  path: `${base.pathname}log`,
  ssl: base.protocol.indexOf('s') !== -1,
};

const ApiLogger = {
  create: () => {
    const logger = createLogger({
      level: 'debug',
      exitOnError: false,
      format: format.json(),
      transports: [
        new transports.Http(httpTransportOptions),
        new transports.Console(),
      ],
    });
    // eslint-disable-next-line
    logger.on('warn', (e) => console.log(`warning! ${e}`));
    return logger;
  },
};

/* eslint-disable no-console */
const innerLogger = ApiLogger.create();

function sendLog(logLevel: string, message: any, args: any[]) {
  const logBody = {
    level: logLevel,
    message,
    service: `client:core-frontend`,
    ddtags: `env:${getEnv()},client:core-frontend`,
    arguments: args.map(a => a instanceof Error ? JSON.stringify(a, Object.getOwnPropertyNames(a)) : a),
  };
  innerLogger[logLevel](logBody);
}

export const logger = {
  debug: (message: any, ...args: any[]) => sendLog('debug', message, args),
  info: (message: any, ...args: any[]) => sendLog('info', message, args),
  warn: (message: any, ...args: any[]) => sendLog('warn', message, args),
  error: (message: any, ...args: any[]) => sendLog('error', message, args),
};
