import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;

  padding: 0.3rem 2rem 0.3rem 0.5rem;

  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);

  width: fit-content;
  height: fit-content;

  color: white;
  font-size: 14;
`;

type WarningOverlayProps = {
  children: string;
};

export function WarningOverlay(props: WarningOverlayProps) {
  return (
    <Overlay>
      <Icon name="warning sign" style={{ marginRight: '0.5rem' }} />
      {props.children}
    </Overlay>
  );
}
