import { useState } from 'react';
import * as Semantic from 'semantic-ui-react';
import { LanguageCode, BlockCreation } from '@visikon/core-models/content';
import { BlockOverview } from 'containers/composer/blocks/BlockOverview';
import { BlockEditModal } from 'containers/composer/block/BlockEditModal';
import * as Styles from './BlockPicker.modal.styles';

interface IProps {
  isOpen: boolean;
  excludeBlocks: string[];
  lang: LanguageCode;
  onDismiss(): void;
  onPick(blockId: string): void;
  onAddNew(state: BlockCreation): void;
}

export const BlockPickerDialog = ({
  isOpen, lang, onDismiss, onPick, onAddNew,
}: IProps) => {
  const [mode, setMode] = useState<'init' | 'new' | 'existing'>('init');
  // const [ block, updateBlock ] = useState<BlockCreation | undefined>(undefined);

  const dismissInternal = () => {
    setMode('init');
    onDismiss();
  };

  const onPickInternal = (id: string) => {
    setMode('init');
    onPick(id);
  };

  function renderInit() {
    return (
      <Semantic.Modal style={{ overflow: 'auto' }} open={isOpen} onClose={dismissInternal} closeOnEscape>
        <Semantic.Modal.Header>Add block</Semantic.Modal.Header>
        <Semantic.Modal.Content>
          <Styles.GridContainer>
            <Semantic.Grid columns={2} relaxed="very">
              <Semantic.Grid.Column>
                <Styles.ButtonContainer>
                  <Semantic.Button size="massive" onClick={() => setMode('new')}>Add new</Semantic.Button>
                </Styles.ButtonContainer>
              </Semantic.Grid.Column>
              <Semantic.Grid.Column>
                <Styles.ButtonContainer>
                  <Semantic.Button size="massive" onClick={() => setMode('existing')}>Add existing</Semantic.Button>
                </Styles.ButtonContainer>
              </Semantic.Grid.Column>
            </Semantic.Grid>
            <Semantic.Divider vertical>OR</Semantic.Divider>
          </Styles.GridContainer>
        </Semantic.Modal.Content>
      </Semantic.Modal>
    );
  }

  function renderExisting() {
    return (
      <Semantic.Modal size="fullscreen" style={{ overflow: 'auto' }} open={isOpen} onClose={dismissInternal} closeOnEscape>
        <Semantic.Modal.Header>Add exiting block</Semantic.Modal.Header>
        <Semantic.Modal.Content>
          <BlockOverview useAsPicker onClick={onPickInternal} />
        </Semantic.Modal.Content>
      </Semantic.Modal>
    );
  }

  function renderNew() {
    return (
      <BlockEditModal
        open={isOpen}
        lang={lang}
        onSave={onAddNew}
        onDismiss={dismissInternal}
      />
    );
  }

  // eslint-disable-next-line consistent-return
  function renderChoice() {
    // eslint-disable-next-line default-case
    switch (mode) {
      case 'init': return renderInit();
      case 'new': return renderNew();
      case 'existing': return renderExisting();
    }
  }

  return (renderChoice());
};
