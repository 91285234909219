import { APIUtils } from 'commons/utils';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { tokenState } from 'redux-recoil-sync.state';
import { Content } from '@visikon/core-models';
import { URLS } from './urls';
import { QueryConfig } from './react-query-utils';

interface VideoLibaryPaginationResult {
  count: number;
  page: number;
  pages: number;
  entries: Content.Video[];
}

export function useListVideos(page: number, limit: number = 15, name?: string, tags?: string, archived?: boolean) {
  const [token] = useRecoilState(tokenState);

  return useQuery<VideoLibaryPaginationResult>(
    ['listVideos', page, limit, name, tags, archived],
    async () => {
      const url = URLS.videos.list(page, limit, name, tags, archived);
      const data = await axios.get<any>(url, APIUtils.tokenHeader(token!));
      return data.data;
    },
    { ...QueryConfig, enabled: !!token, keepPreviousData: true },
  );
}

export function useVideo(id: string, enabled: boolean) {
  const [token] = useRecoilState(tokenState);

  return useQuery<Content.Video>(
    ['video', id],
    async () => {
      const url = URLS.videos.get(id);
      const result = await axios.get<Content.Video>(url, APIUtils.tokenHeader(token!));

      return result.data;
    },
    { ...QueryConfig, staleTime: 300 /** 5 minutes */, enabled: !!token && enabled },
  );
}
