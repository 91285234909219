import {
  listBlocksSuccessful,
  setLoadingStatus,
  addNewBlockToComposerSuccessful,
  addNewBlockToComposerClear,
  addNewBlockToComposerError,
  updateBlockInState,
  generateThumbnailSuccess,
  createBlockSuccessful,
  updateBlockSuccessful,
  setBlocksFetchState,
} from 'actions/BlockActions';
import { Block } from '@visikon/core-models/content';
import { Reducer } from 'redux';
import { isType } from 'typescript-fsa';
import { ArrayUtils } from 'commons/utils';
import { logger } from 'commons/logger';

export enum FetchState {
  FAILED = -1,
  INITIAL,
  FETCHING,
  SUCCEEDED,
}

export interface IState {
  isUploading: boolean;
  all: Block[];
  newlyCreatedBlockId?: string;
  newlyCreatedBlockError: boolean;
  thumbnailSrc: any;
  createBlockSuccess?: Date;
  updateBlockSuccess?: Date;
  fetchState: FetchState;
}

const initialState: IState = {
  isUploading: false,
  all: [],
  newlyCreatedBlockError: false,
  thumbnailSrc: '',
  createBlockSuccess: undefined,
  updateBlockSuccess: undefined,
  fetchState: FetchState.INITIAL,
};

export const blocksReducer: Reducer<IState> = (state = initialState, action) => {
  if (isType(action, setBlocksFetchState)) {
    return {
      ...state,
      fetchState: action.payload,
    };
  }

  if (isType(action, listBlocksSuccessful)) {
    return {
      ...state,
      all: action.payload,
    };
  }

  if (isType(action, updateBlockInState)) {
    const idx = state.all.findIndex((b) => b._id === action.payload._id);
    let all;

    if (idx === -1) {
      all = [action.payload, ...state.all];
    } else {
      all = ArrayUtils.replaceAtIndex(state.all, idx, action.payload);
    }

    return {
      ...state,
      all,
    };
  }

  if (isType(action, setLoadingStatus)) {
    return {
      ...state,
      isUploading: action.payload,
    };
  }

  if (isType(action, addNewBlockToComposerSuccessful)) {
    return {
      ...state,
      newlyCreatedBlockId: action.payload,
    };
  }
  if (isType(action, addNewBlockToComposerError)) {
    return {
      ...state,
      newlyCreatedBlockError: true,
    };
  }
  if (isType(action, addNewBlockToComposerClear)) {
    return {
      ...state,
      newlyCreatedBlockId: undefined,
      newlyCreatedBlockError: false,
    };
  }

  if (isType(action, generateThumbnailSuccess)) {
    return { ...state, thumbnailSrc: action.payload };
  }

  if (isType(action, createBlockSuccessful)) {
    return { ...state, createBlockSuccess: new Date() };
  }

  if (isType(action, updateBlockSuccessful)) {
    logger.debug('update reducer');
    return { ...state, updateBlockSuccess: new Date() };
  }

  return state;
};
