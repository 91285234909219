import { fiftyShadesOfGrey, uuidv4, ArrayUtils } from 'commons/utils';
import React from 'react';
import {
  Button, Divider, Icon, List,
} from 'semantic-ui-react';
import styled from 'styled-components';
import {
  CustomTypeInput, CustomTypeValue, ICommonBaseProps, ITypeField,
} from '../CustomTypeInput';
import { DraggableList } from '../../dragNdrop/DraggableList';

const CollapsedListItem = styled.div`
    display: flex;
    flex: 1;
    padding: 5px;
    border-top: 1px solid lightgrey;

    &:first-of-type {
        border-top: 0;
    }
`;

export interface IParametersForList {
  type: 'list';
  nameOfListElement: string;
  desc: string;
  fields: ITypeField;
}

export interface IComponentListInputProps extends ICommonBaseProps, IParametersForList {
  depth: number;
  value?: CustomTypeValue[];
  onChange(key: string, val: CustomTypeValue[]): void;
}

interface IComponentListState {
  collapsed?: boolean;
  rows: string[];
  val: {
    [key: string]: CustomTypeValue;
  };
}
export class ListTypeInput extends React.Component<IComponentListInputProps, IComponentListState> {
  styles: { [key: string]: React.CSSProperties } = {
    marginStyle: {
      marginLeft: 20,
    },
    removeButtonStyle: {
      flex: '0 0 auto',
      alignSelf: 'center',
      marginLeft: 10,
    },
    itemStyle: {
      flex: '1 0 auto',
      paddingRight: 10,
    },
  };

  constructor(props: IComponentListInputProps) {
    super(props);

    this.state = { rows: [uuidv4()], val: {} };
    if (props.value && props.value.length > 0) {
      this.state = this.populateValuesToState(props.value);
    }

    this.renderList = this.renderList.bind(this);
    this.renderCollapsedList = this.renderCollapsedList.bind(this);

    this.renderItem = this.renderItem.bind(this);
    this.renderItemCollapsed = this.renderItemCollapsed.bind(this);

    this.renderAddButton = this.renderAddButton.bind(this);
    this.renderRemoveButton = this.renderRemoveButton.bind(this);
    this.renderCollapseButton = this.renderCollapseButton.bind(this);

    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.reorderRows = this.reorderRows.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleCollapsed = this.toggleCollapsed.bind(this);
  }

  componentDidUpdate(oldProps: IComponentListInputProps) {
    if (this.props.lang !== oldProps.lang) {
      const hasValue = this.props.value !== undefined && this.props.value !== null && this.props.value.length > 0;

      // If we have a value, just use that man!
      if (hasValue) {
        const s = this.populateValuesToState(this.props.value!);
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ ...s });
        return;
      }

      // TODO: Check this translation stuff...
      // No value and translation - just abort - use that old content.
      if (this.props.isTranslation) {
        return;
      }

      // If no value, and not translation, set empty content.
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ rows: [uuidv4()], val: {} });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  populateValuesToState(value: any[]): IComponentListState {
    const val = {};
    value.forEach((v) => { val[uuidv4()] = v; });
    return { rows: Object.keys(val), val };
  }

  // eslint-disable-next-line class-methods-use-this
  collectResult(input: { [key: string]: CustomTypeValue }): CustomTypeValue[] {
    const result: CustomTypeValue[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const item in input) {
      // eslint-disable-next-line no-prototype-builtins
      if (input.hasOwnProperty(item)) {
        result.push(input[item]);
      }
    }
    return result;
  }

  handleChange(key: string, val: CustomTypeValue) {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newVal = { ...this.state.val, [key]: val };
    this.setState({ val: newVal });

    const result = this.collectResult(newVal);
    this.props.onChange(this.props.pKey, result);
  }

  renderRemoveButton(key: string) {
    if (this.props.isReadOnly || this.props.isTranslation) {
      return null;
    }

    // No empty lists
    if (this.state.rows.length === 1) {
      return null;
    }

    return (
      <Button
        negative
        icon="minus circle"
        onClick={() => this.removeRow(key)}
      />
    );
  }

  renderAddButton() {
    if (this.state.collapsed || this.props.isReadOnly || this.props.isTranslation) {
      return null;
    }

    return (
      <Button style={this.styles.marginStyle} content={`Add ${this.props.nameOfListElement}`} icon="add" onClick={this.addRow} />
    );
  }

  renderCollapseButton() {
    if (this.props.isReadOnly || this.props.isTranslation) {
      return null;
    }

    const collapseExpandString = this.state.collapsed ? 'Expand list' : 'Collapse list';
    const collapseExpandIcon = this.state.collapsed ? 'angle down' : 'angle up';
    return (
      <Button size="mini" style={{ marginLeft: 30 }} onClick={this.toggleCollapsed}>
        <Icon name={collapseExpandIcon} />
        {' '}
        {collapseExpandString}
      </Button>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  convertFieldValueToString(thing: any) {
    switch (typeof thing) {
      case 'object':
        if (Array.isArray(thing)) {
          return '[...]';
        }
        return '{}';
      default:
        return String(thing);
    }
  }

  renderItemCollapsed(itemKey: string) {
    const { fields } = this.props;
    const value = this.state.val[itemKey];

    const key = Object.keys(fields)[0];
    const fieldValue = value ? this.convertFieldValueToString(value[key]) : '';
    return (
      <CollapsedListItem key={`${key}-key-${fieldValue}`}>
        <Icon name="bars" />
        {fieldValue}
      </CollapsedListItem>
    );
  }

  renderItem(key: string, depth: number) {
    return (
      <React.Fragment key={key}>
        <List.Item style={{
          display: 'flex', flexRirection: 'row', background: fiftyShadesOfGrey(depth), padding: 10,
        }}
        >
          <div style={this.styles.itemStyle}>
            <CustomTypeInput
              pKey={key}
              fields={this.props.fields}
              onChange={this.handleChange}
              value={this.state.val[key]}
              depth={depth + 1}
              isReadOnly={this.props.isReadOnly}
              lang={this.props.lang}
              namespace={this.props.namespace}
              isTranslation={this.props.isTranslation}
            />
          </div>
          <div style={this.styles.removeButtonStyle}>{this.renderRemoveButton(key)}</div>
        </List.Item>
        <Divider />
      </React.Fragment>
    );
  }

  addRow() {
    this.setState((prevState) => ({
      rows: [...prevState.rows, uuidv4()],
    }));
  }

  removeRow(key: string) {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const rows = this.state.rows.filter((r) => (r !== key));
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newVals = { ...this.state.val };
    if (key in newVals) {
      delete newVals[key];
    }

    // Call parent and update state
    const dataChange = this.collectResult(newVals);
    this.props.onChange(this.props.pKey, dataChange);
    this.setState({ rows, val: newVals });
  }

  reorderRows(from: number, to: number) {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const rows = ArrayUtils.arrayMove(this.state.rows, from, to) as string[];
    const { val } = this.state;
    const newVal = {};
    // sort according to rows
    for (const key of rows) {
      newVal[key] = val[key];
    }

    const dataChange = this.collectResult(newVal);
    this.setState({ rows, val: newVal });
    this.props.onChange(this.props.pKey, dataChange);
  }

  toggleCollapsed() {
    this.setState((s) => ({ collapsed: !s.collapsed }));
  }

  renderCollapsedList() {
    const items = this.state.rows.map((key) => ({ id: key, element: this.renderItemCollapsed(key) }));
    return (
      <DraggableList
        onReorder={this.reorderRows}
        items={items}
      />
    );
  }

  renderList() {
    if (this.state.collapsed) {
      return (
        this.renderCollapsedList()
      );
    }

    return (
      <List style={this.styles.marginStyle} relaxed>
        {this.state.rows.map((key) => (this.renderItem(key, this.props.depth)))}
      </List>
    );
  }

  render() {
    return (
      <>
        <div style={{ marginTop: 30 }}>
          <h4 style={{ display: 'inline-block' }}>{this.props.desc}</h4>
          {this.renderCollapseButton()}
        </div>
        {this.renderList()}
        {this.renderAddButton()}
      </>
    );
  }
}

export interface IComponentListRenderProps extends ICommonBaseProps, IParametersForList {
  value: CustomTypeValue[];
}
export function ListTypeCompact({ desc, value }: IComponentListRenderProps) {
  return (
    <>
      <h4 className="ui header" style={{ margin: 0 }}>
        {value.length}
        {' '}
        items in list
      </h4>
      <p>
        <i className="ui icon list" />
        {' '}
        {desc}
      </p>
    </>
  );
}
