import { useRecoilState } from 'recoil';
import { LanguageCode } from '@visikon/core-models/content';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetProgramMetadata, usePostProgramMetadata } from '../../../api/program-metadata';
import { Spinner } from '../../../components/spinner';
import { languageState } from '../../../redux-recoil-sync.state';
import * as s from './program-duplicator.styles';
import { InputForm } from './input-form';
import { OutputForm } from './output-form';
import { getMetadataForLanguage } from '../../../api/program-metadata.helpers';
import { ProgramMetadata, ProgramMetadataEntries } from '../../../api/program-metadata.models';
import { FormWrapper } from './form-wrapper';
import { Header } from './header';

const useApis = (name: string, id: string) => {
  const query = useGetProgramMetadata(name, id);
  const mutation = usePostProgramMetadata(name, id);

  return { query, mutation };
};

const useLanguages = () => {
  const [language] = useRecoilState(languageState);
  const inputLanguage = useState(language);
  const outputLanguage = useState(language);

  return { inputLanguage, outputLanguage };
};

const useMetaDataState = (inputLanguage: [LanguageCode, (l: LanguageCode) => void], data?: ProgramMetadata) => {
  const inputState = getMetadataForLanguage(data, inputLanguage[0]);
  const [outputState, setOutputState] = useState<ProgramMetadataEntries>();

  return { inputState, outputState, setOutputState };
};

const useRedirect = (responseData?: ProgramMetadata) => {
  const [language] = useRecoilState(languageState);
  const inputLanguage = useState(language);
  const history = useHistory();

  useEffect(() => {
    if (responseData) {
      const path = location.pathname.substring(0, location.pathname.lastIndexOf('/') + 1);
      const metadata = getMetadataForLanguage(responseData, inputLanguage[0]);
      history.push(path + metadata[0].id);
    }
  }, [responseData, history, inputLanguage]);
};

export function ProgramDuplicator({ name, id }: { name: string, id: string }) {
  const { query, mutation } = useApis(name, id);
  const { inputLanguage, outputLanguage } = useLanguages();
  const { inputState, outputState, setOutputState } = useMetaDataState(inputLanguage, query.data);
  useRedirect(mutation.data);

  const isLoading = query.isLoading || mutation.isLoading;
  const canSave = outputState && outputState.length > 0 && JSON.stringify(inputState) !== JSON.stringify(outputState);

  const handleSave = () => {
    if (canSave) mutation.mutate({ da: outputState });
  };

  return (
    <div>
      <Spinner visible={isLoading} text={mutation.isLoading ? 'Saving' : 'Loading'} />

      {query.data && (
      <>
        <div className="ui form">
          <Header onSave={handleSave} error={query.error || mutation.error} isSaveDisabled={isLoading || !canSave} />

          <s.Form>
            <FormWrapper state={inputLanguage} data={query.data} isInput>
              <InputForm metadata={inputState} />
            </FormWrapper>

            <VerticalDivider />

            <FormWrapper state={outputLanguage}>
              <OutputForm metadata={inputState} onChange={setOutputState} />
            </FormWrapper>
          </s.Form>
        </div>
      </>
      )}
    </div>
  );
}

export function VerticalDivider() {
  return (
    <>
      <div style={{ minWidth: 7 }} />
      <div style={{ minWidth: 8, borderLeft: '1px solid #aaa' }} />
    </>
  );
}
