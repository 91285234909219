import { LanguageCode } from '@visikon/core-models/languageTypes';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { staticVoiceData } from './StaticVoiceData';

interface VoiceOption {
  key: string;
  value: string;
  text: string;
}

interface VoiceDropdownProps {
  activeLanguage: LanguageCode;
  onChange: (event: React.ChangeEvent<HTMLDivElement>, data: DropdownProps) => void;
}

const getDropdownOptions = (activeLanguage: LanguageCode): VoiceOption[] => {
  const activeLanguageData = staticVoiceData.find((lang) => lang.value === activeLanguage);
  const VOICE_GENDERS = activeLanguageData ? Object.keys(activeLanguageData.preferredVoice) : ['Female', 'Male'];

  if (!activeLanguageData) return [];

  let allVoices: VoiceOption[] = [];

  VOICE_GENDERS.forEach((gender) => {
    const genderVoices = activeLanguageData.listOfVoices[gender];
    const voiceOptions = genderVoices.map((voice: string) => ({
      key: voice,
      value: `${voice}-${gender}`,
      text: `${voice} (${gender})`,
    }));

    allVoices = allVoices.concat(voiceOptions);
  });

  return allVoices;
};

export const VoiceDropdown = ({ activeLanguage, onChange }: VoiceDropdownProps) => {
  const options = getDropdownOptions(activeLanguage);
  const hideDropdown = options.length === 1;

  if (hideDropdown) {
    return null;
  }

  return <StyledDropdown placeholder="Select Voice" selection options={options} onChange={onChange} />;
};

const StyledDropdown = styled(Dropdown)`
  margin: 0 0.5em;
  min-width: 11em;

  & i {
    margin: -0.78571429em !important;
  }
`;
