import React from 'react';
import { InputOnChangeData, Form } from 'semantic-ui-react';
import { ICommonBaseProps } from '../CustomTypeInput';

export interface IParametersForString {
  type: 'string';
  maxLength?: number;
  desc: string;
}

interface IComponentStringInputProps extends ICommonBaseProps, IParametersForString {
  value?: string;
  onChange(key: string, val: string): void;
}

export class StringTypeInput extends React.Component<IComponentStringInputProps> {
  constructor(props: IComponentStringInputProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) {
    this.props.onChange(this.props.pKey, data.value);
  }

  render() {
    if (this.props.isTranslation && this.props.value === null) {
      return null;
    }

    if (this.props.isReadOnly) {
      return (
        <label>
          <h4>{this.props.desc}</h4>
          <input disabled value={this.props.value} style={{ background: '#ddd', margin: '0 0 1em' }} />
        </label>
      );
    }

    const className = this.props.lang ? `trans-lang-${this.props.namespace}-${this.props.lang}` : '';

    return (
      <Form.Input
        maxLength={this.props.maxLength || -1}
        value={this.props.value || ''}
        onChange={this.handleChange}
        label={<h4>{this.props.desc}</h4>}
        className={className}
      />
    );
  }
}

interface IComponentStringRenderProps extends ICommonBaseProps, IParametersForString {
  value: string;
}
export function StringTypeCompact({ desc, value }: IComponentStringRenderProps) {
  return (
    <>
      <h4 className="ui header" style={{ margin: 0 }}>{value}</h4>
      <p>
        <i className="ui icon pencil alternate" />
        {' '}
        {desc}
      </p>
    </>
  );
}
