import { listContentByType } from 'actions/ContentActions';
import { LanguageCode } from '@visikon/core-models/content';
import { getTranslation } from '@visikon/core-models/typeUtils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'reducers/reducers';
import { Checkbox, Flag, Table } from 'semantic-ui-react';
import { GenderIcon } from '../../components/icons/GenderIcon';
import { langFromCode } from '../../commons/languages';
import { LanguageFilter } from '../../components/LanguageFilter';

interface IProgramRow {
  id: string;
  name: string;
  department: string;
  organization: string;
  subName: string;
  anatomy?: string;
  languages: LanguageCode[];
}

interface IProps {
  checked: string[];

  onClick(id: string): void;
}

function sortRows(programs: IProgramRow[]): IProgramRow[] {
  return [...programs].sort((a, b) => {
    const orgCompare = (a.organization || '').localeCompare(b.organization || '');
    if (orgCompare !== 0) {
      return orgCompare;
    }
    return (a.department || '').localeCompare(b.department || '');
  });
}

export const ProgramMultiPicker = ({ checked, onClick }: IProps) => {
  const [filterLanguageCode, setFilterLanguageCode] = useState<LanguageCode | undefined>(undefined);
  const content = useSelector((state: IState) => state.content.data.mitforlobPack) || [];
  const activeLanguage = useSelector((state: IState) => state.language.activeLanguage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (content.length === 0) {
      dispatch(listContentByType('mitforlobPack'));
    }
  },[content.length, dispatch]);

  const active = content.filter(item => !item.archived);
  const chosenLanguage = active.map((customType) => {
    const translation = getTranslation(customType, activeLanguage);
    const languageCodes = customType.translations.map((t) => t.language);
    return { ...translation, languages: languageCodes };
  });
  const rows = chosenLanguage.map((d) => ({ ...d!.data, id: d!._id, languages: d!.languages })) as IProgramRow[];
  const languageFiltered = filterLanguageCode ? rows.filter((r) => r.languages.indexOf(filterLanguageCode) !== -1) : rows;
  const sorted = sortRows(languageFiltered);

  const handleRowClick = (id: string) => {
    onClick(id);
  };

  const renderRow = (program: IProgramRow) => {
    const isChecked = checked.indexOf(program.id) !== -1;

    return (
      <Table.Row key={program.id} style={{ cursor: 'pointer' }} onClick={() => handleRowClick(program.id)}>
        <Table.Cell positive={isChecked}>
          <Checkbox checked={isChecked} />
        </Table.Cell>
        <Table.Cell positive={isChecked}>
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <div>{program.name}</div>
            <div><GenderIcon anatomy={program.anatomy} /></div>
          </div>
        </Table.Cell>
        <Table.Cell positive={isChecked}>
          {program.subName}
        </Table.Cell>
        <Table.Cell positive={isChecked}>
          {program.organization}
        </Table.Cell>
        <Table.Cell positive={isChecked}>
          {program.department}
        </Table.Cell>
        <Table.Cell positive={isChecked}>
          {program.languages?.map((l) => (<Flag name={langFromCode(l).flag as any} key={l as string} />))}
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <div>
      <LanguageFilter filterLanguageCode={filterLanguageCode} setFilterLanguageCode={setFilterLanguageCode} />
      <Table celled striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Name <Flag name={langFromCode(activeLanguage).flag as any}
                                         key={activeLanguage as string} /> </Table.HeaderCell>
            <Table.HeaderCell>Sub program name</Table.HeaderCell>
            <Table.HeaderCell>Organization</Table.HeaderCell>
            <Table.HeaderCell>Department</Table.HeaderCell>
            <Table.HeaderCell>Languages</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {sorted.map(renderRow)}
        </Table.Body>
      </Table>
    </div>);
};


// const mapStateToProps: MapStateToProps<IPropsFromState, IProps, IState> = (state) => {
//   const all = state.content.data.mitforlobPack ? state.content.data.mitforlobPack : [];
//   const active = all.filter(item => !item.archived);
//   const chosenLanguage = active.map((d) => {
//     const translation = getTranslation(d, state.language.activeLanguage);
//     const languageCodes = d.translations.map((t) => t.language);
//     return { ...translation, languages: languageCodes };
//   });
//   const rows = chosenLanguage.map((d) => ({  ...d!.data, id: d!._id, languages: d!.languages })) as IProgramRow[];
//   const sorted = sortRows(rows);
//   return {
//     data: sorted,
//     activeLanguage: state.language.activeLanguage,
//   };
// };
// const mapDispatchToProps: MapDispatchToProps<IPropsFromDispatch, {}> = (dispatch) => ({
//   fetchData: () => {
//     dispatch(listContentByType('mitforlobPack'));
//   },
// });
// export const ProgramMultiPicker = connect(mapStateToProps, mapDispatchToProps)(CProgramMultiPicker);
