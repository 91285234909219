import { BlockList } from "@visikon/core-models/content";
import { useToggle } from "commons/toggle";
import * as Semantic from 'semantic-ui-react';

type Props = {
    composition: BlockList | undefined,
    onToggle: (val: boolean) => void,
};

export function ArchiveMenuItem({ composition, onToggle }: Props) {
    const [modalVisible, toggleModal] = useToggle();
    const archived = composition?.archived;

    return (
        <>
            <Semantic.Confirm
                open={modalVisible}
                content={`Are you sure you want to ${archived ? 're-activate' : 'archive'} this composition?`}
                confirmButton="Yes"
                onCancel={() => toggleModal()}
                onConfirm={() => {
                    onToggle(!archived);
                    toggleModal();
                }}
            />
            <Semantic.Dropdown.Item
                icon={archived ? 'share square outline' : 'trash alternate outline'}
                text={`${archived ? 'Un-archive' : 'Archive'} composition`}
                onClick={toggleModal}
            />
        </>
    );
}
