import React from 'react';
import {
  Popup, Icon, DropdownItemProps, Flag,
} from 'semantic-ui-react';
import { Content } from '@visikon/core-models';
import { langFromCode } from 'commons/languages';
import { CustomTypeValue, IParameters, ITypeField } from './CustomTypeInput';
import { ImageTypeCompact } from './TypeComponents/ImageType';
import { ListTypeCompact } from './TypeComponents/ListType';
import { LongStringTypeCompact } from './TypeComponents/LongStringType';
import { StringTypeCompact } from './TypeComponents/StringType';
import { BoolTypeCompact } from './TypeComponents/BoolType';

interface ICustomTypeProps {
  fields: ITypeField;
  depth?: number;
  value: CustomTypeValue;
  languages?: Content.LanguageCode[];
  button?: {
    icon?: string;
    text: string;
    handler(): void;
  };
  isCopy?: boolean;
  onCopyCallback?(): void;
}
export class CustomTypeCompact extends React.Component<ICustomTypeProps> {
  // eslint-disable-next-line class-methods-use-this
  findValue(key: string, value: CustomTypeValue) {
    if (!value) {
      return null;
    }
    if (typeof value === 'object' && key in value) {
      return value[key];
    }
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  renderParam(key: string, parameters: IParameters, value: CustomTypeValue): React.ReactNode {
    switch (parameters.type) {
      case 'string': return (<StringTypeCompact key={key} pKey={key} {...parameters} value={value} />);
      case 'long-string': return (<LongStringTypeCompact key={key} pKey={key} {...parameters} value={value} />);
      case 'list': return (<ListTypeCompact key={key} pKey={key} {...parameters} value={value} />);
      case 'image': return (<ImageTypeCompact key={key} pKey={key} {...parameters} value={value} />);
      case 'boolean': return (<BoolTypeCompact key={key} pKey={key} {...parameters} value={value} />);
      default: return null;
    }
  }

  renderCopyIcon() {
    if (!this.props.onCopyCallback) {
      return null;
    }

    return (
      <Popup
        trigger={(
          <Icon
            style={{ cursor: 'pointer' }}
            onClick={() => { this.props.onCopyCallback!(); }}
            name="copy outline"
            className="right floated"
          />
)}
        inverted
        content="Copy"
      />
    );
  }

  renderFlags() {
    const { languages } = this.props;
    if (!languages) {
      return null;
    }
    const langs = languages.map((t) => langFromCode(t));
    const langComparator = (l1: DropdownItemProps, l2: DropdownItemProps) => (l1.value as string || '').localeCompare(l2.value as string || '');
    return langs.sort(langComparator).map((l) => <Flag key={l.flag as string} name={l.flag as any} />);
  }

  render() {
    const { fields } = this.props;
    const result = [];
    let count = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const key in fields) {
      // eslint-disable-next-line no-prototype-builtins
      if (fields.hasOwnProperty(key)) {
        if (count > 2) {
          break;
        }
        const val = this.findValue(key, this.props.value);
        if (val) {
          result.push(this.renderParam(key, fields[key], val));
          result.push(<div key={`spacing_between_${key}`}><br /></div>);
          count++;
        }
      }
    }

    const cardStyle = this.props.isCopy ? { background: 'repeating-linear-gradient( 135deg, #fff, #fff 5px, #d7d7d7 5px, #d7d7d7 10px )' } : {};

    return (
      <>
        <div
          className="content"
          style={{
            ...cardStyle, position: 'relative', display: 'flex', flexDirection: 'column',
          }}
        >
          <div className="description">
            {this.renderCopyIcon()}
            {result}
          </div>
          <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'end', flexGrow: 1, paddingTop: 7,
          }}
          >
            <div>
              {this.renderFlags()}
            </div>
          </div>
        </div>
        {this.props.button
                    && (
                    <div className="ui bottom attached button" onClick={this.props.button.handler}>
                      <i className={`${this.props.button.icon || 'add'} icon`} />
                      {this.props.button.text}
                    </div>
                    )}
      </>
    );
  }
}
