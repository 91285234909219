import { Content } from "@visikon/core-models";
import { useGetIdByTypeAndName } from "api/contentApi";
import { useHistory } from "react-router-dom";
import * as Styles from './MediaLibraryLink.styles';

export function MediaLibraryLink({ composition }: { composition: Content.BlockList | undefined }) {
    const { data } = useGetIdByTypeAndName('Video', composition?.name);
    const history = useHistory();

    return (
        <Styles.Container>
            {data?.found &&
                <Styles.Button
                    basic
                    icon="linkify"
                    title="Go to video"
                    onClick={() => {
                        history.push(`/media/videos/${data?.id}`);
                    }}
                />
            }
        </Styles.Container>
    );
}
