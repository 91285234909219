import { Content } from '@visikon/core-models';
import { saveComposition } from 'actions/BlockActions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Semantic from 'semantic-ui-react';
import { ArchiveMenuItem } from './modals/Archive.button';
import { CopyMenuItem } from './modals/Copy.button';
import * as Styles from './ComposerMenu.styles';
import { CompositionBlock } from '@visikon/core-models/models/composer/blocks';

type Props = {
  compositionId: string | undefined;
  name: string;
  composition: Content.BlockList | undefined;
  compositionBlocks: CompositionBlock[];
  thumbnail?: string;
  audioDelay: number;
  tags: string[];
  hasChanges: boolean;
  hash: string | undefined;
  thumbnailRefId?: string;
};

function removeIds(compositionBlocks: CompositionBlock[]) {
  return compositionBlocks.map((b) => ({ blockId: b.blockId, speakVariation: b.speakVariation, videoVariation: b.videoVariation }));
}

export function ComposerMenu(props: Props) {
  const {
    compositionId: _id,
    name,
    composition,
    compositionBlocks,
    thumbnail: thumbnailSrc,
    thumbnailRefId,
    audioDelay,
    tags,
    hasChanges,
    hash,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const blocks = removeIds(compositionBlocks);

  const handleSave = () => {
    dispatch(saveComposition({ _id, name, tags, blocks, thumbnailSrc, thumbnailRefId, audioDelay, hash }));
  };

  const handleArchiveToggle = (val: boolean) => {
    dispatch(saveComposition({ _id, blocks, archived: val }));
  };

  const handleOnCopySuccess = (compositionId: string) => {
    history.push(`/block/composer/${compositionId}`);
  };

  return (
    <Semantic.Menu style={{ marginTop: 0 }}>
      <Styles.SaveMenuItem title="Save" color="green" disabled={!hasChanges} onClick={handleSave}>
        Save
      </Styles.SaveMenuItem>
      <Semantic.Menu.Menu position="left">
        <Semantic.Dropdown item icon="ellipsis horizontal">
          <Semantic.Dropdown.Menu>
            <CopyMenuItem compositionId={_id} compositionName={composition?.name} disabled={hasChanges} onSuccess={handleOnCopySuccess} />

            <ArchiveMenuItem composition={composition} onToggle={handleArchiveToggle} />
          </Semantic.Dropdown.Menu>
        </Semantic.Dropdown>
      </Semantic.Menu.Menu>
    </Semantic.Menu>
  );
}
