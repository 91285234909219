import React from 'react';
import { InputOnChangeData, Dropdown } from 'semantic-ui-react';
import { ICommonBaseProps } from '../CustomTypeInput';

interface ComplexChoiceType {
  text: string;
  value: string;
  icon?: string;
}
export type ChoiceType = string[] | ComplexChoiceType[];
export interface IParametersForChoice {
  type: 'choice';
  choices: ChoiceType;
  desc: string;
}

interface IComponentChoiceInputProps extends ICommonBaseProps, IParametersForChoice {
  value?: string;
  onChange(key: string, val: string): void;
}

export class ChoiceTypeInput extends React.Component<IComponentChoiceInputProps> {
  constructor(props: IComponentChoiceInputProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) {
    this.props.onChange(this.props.pKey, data.value);
  }

  // eslint-disable-next-line class-methods-use-this
  areChoicesStrings(choices: ChoiceType): choices is string[] {
    if (choices === undefined || choices.length === 0) {
      return true;
    }

    return (typeof choices[0] === 'string');
  }

  render() {
    if (this.props.isTranslation) {
      return null;
    }

    let choices: ComplexChoiceType[];
    if (this.areChoicesStrings(this.props.choices)) {
      choices = this.props.choices.map((c) => ({ text: c, value: c }));
    } else {
      choices = this.props.choices;
    }

    return (
      <div style={{ width: 'auto' }}>
        <h4>{this.props.desc}</h4>
        <Dropdown
          selection
          onChange={this.handleChange}
          value={this.props.value}
          options={choices}
        />
      </div>
    );
  }
}
