import { UpdateStatus } from 'reducers/compositionsReducer';
import styled from 'styled-components';

type Props = {
  status: UpdateStatus | undefined;
};

export function Status({ status }: Props) {
  return (
    <>
      {status?.status === 'STARTED' && <div style={{ fontWeight: 'bold' }}>Saving...</div>}
      {status?.status === 'SUCCEEDED' && <SucceededStatus>{`Saved: ${status?.lastModified}`}</SucceededStatus>}
      {status?.status === 'FAILED' && <div style={{ color: 'red' }}>{`Error: ${status?.error}`}</div>}
    </>
  );
}

const SucceededStatus = styled.div`
  color: grey;
  font-style: italic;
  margin-bottom: -13px;
  font-size: 12px;
`;
