import { langFromCode, languages } from 'commons/languages';
import { Content } from '@visikon/core-models';
import React from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

interface IProps {
  activeLang: Content.LanguageCode;
  onLanguageChanged: (langCode: Content.LanguageCode) => void;
  languageChoices?: DropdownItemProps[];
  readOnly?: boolean;
}

interface IState {
  isSearching: boolean;
}

export class LanguageSelector extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = { isSearching: false };
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  handleLanguageChange(
    _: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps,
  ) {
    this.setState({ isSearching: false });
    this.props.onLanguageChanged(data.value as Content.LanguageCode);
  }

  // eslint-disable-next-line class-methods-use-this
  renderReadOnly(lang: DropdownItemProps) {
    return (
      <button type="button" className="ui mini icon left button" disabled>
        <i className={`${lang.flag} flag`} />
        {lang.text}
      </button>
    );
  }

  render() {
    const activeLang = langFromCode(this.props.activeLang);
    const options = (this.props.languageChoices || languages).map((l) => ({
      ...l,
      active: l.value === this.props.activeLang,
      selected: l.value === this.props.activeLang,
    }));
    if (this.props.readOnly) {
      return this.renderReadOnly(activeLang);
    }

    return (
      <>
        {this.state.isSearching && (
          <div style={{ minWidth: 150 }}>
            <Dropdown
              className="mini icon"
              floating
              fluid
              labeled
              options={options}
              search
              searchInput={{ autoFocus: true }}
              onChange={this.handleLanguageChange}
              onBlur={() => {
                this.setState({ isSearching: false });
              }}
              selectOnBlur={false}
              selection
              text="Search language"
            />
          </div>
        )}
        {!this.state.isSearching && (
          <button
            type="button"
            className="ui mini icon left button"
            onClick={() => {
              this.setState({ isSearching: true });
            }}
          >
            <i className={`${activeLang.flag} flag`} />
            {activeLang.text}
          </button>
        )}
      </>
    );
  }
}
