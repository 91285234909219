import axios from 'axios';
import { useRecoilState } from 'recoil';
import { azureTokenState, tokenState } from '../../redux-recoil-sync.state';
import { useCallback } from 'react';
import { URLS } from 'api/urls';
import { APIUtils } from 'commons/utils';

export const useAzureToken = () => {
  const [azureTokenData, setAzureTokenData] = useRecoilState(azureTokenState);
  const [token] = useRecoilState(tokenState);

  return useCallback(async () => {
    const now = Date.now();
    if (azureTokenData.token && azureTokenData.fetchedAt && now - azureTokenData.fetchedAt < 5 * 60 * 1000) {
      return azureTokenData.token;
    }
    try {
      const res = await axios.get(URLS.text.azureToken.get(), APIUtils.tokenHeader(token!));
      setAzureTokenData({ token: res.data, fetchedAt: now });

      return res.data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching Azure token:', error);
      throw error;
    }
  }, [azureTokenData, setAzureTokenData, token]);
};
