import { authenticateFromLocalStorage } from 'actions/AuthActions';
import { changeLanguageFromLocalStorage } from 'actions/LanguageActions';
import { Environment } from 'commons/env';
import { appEnv, initDatadog } from 'config';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { RecoilRoot } from 'recoil';
import { reducer } from 'reducers/reducers';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { unregister } from 'registerServiceWorker';
import sagas from 'sagas/sagas';
import 'semantic/dist/semantic.min.css';
import { App } from './containers/App';
import { ReduxRecoilSync } from './redux-recoil-sync';
import { SocketHandler } from 'SocketHandler';
import axios from 'axios';
import { logger } from './commons/logger';

initDatadog();

// Setup sagas
const sagaMiddleware = createSagaMiddleware();

// Add Redux Devtools in dev
const composeEnhancers = (() => {
  if (appEnv === Environment.Local) {
    return (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }
  return compose;
})();

const store = createStore(reducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(sagas);

// Load stuff from localStorage
store.dispatch(authenticateFromLocalStorage());
store.dispatch(changeLanguageFromLocalStorage());

// Generic response error handler
axios.interceptors.response.use(response => response, (error) => {
  logger.error(error);
  return Promise.reject(error);
});

ReactDOM.render(
  <Provider store={store}>
    <RecoilRoot>
      <SocketHandler />
      <ReduxRecoilSync />
      <App />
    </RecoilRoot>
  </Provider>,
  document.getElementById('root') as HTMLElement,
);

unregister();
