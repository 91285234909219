import { loadContentEntry } from 'actions/ContentActions';
import { StringUtils } from 'commons/utils';
import { getTypeDescription, typeDescriptionDisplayName } from 'components/contentTypes/ContentDescriptors';
import { CustomType } from '@visikon/core-models/content';
import { getTranslation } from '@visikon/core-models/typeUtils';
import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { IState } from 'reducers/reducers';
import { List } from 'semantic-ui-react';
import styled from 'styled-components';

function truncate(s: string) {
  if (s.length > 100) {
    return `${s.substring(0, 97)}...`;
  }
  return s;
}

const keysPriority = ['name', 'documentName', 'title', 'header', 'text'].reverse();
function compareKeys(k1: string, k2: string) {
  return keysPriority.indexOf(k2) - keysPriority.indexOf(k1);
}

const uninterestingKeys = ['imagePos'];
function interestingKeys(data: any) {
  return (key: string) => uninterestingKeys.indexOf(key) === -1 && typeof data[key] === 'string';
}

interface IOwnProps {
  id: string;
  type: string;
}

interface IPropsFromState {
  entry: CustomType | undefined;
}

interface IPropsFromDispatch {
  loadEntry(id: string, type: string): void;
}

type IProps = IOwnProps & IPropsFromState & IPropsFromDispatch;

const Container = styled.div`
    padding-left: 30px;
    flex: 1;

    h1 {
        font-size: 10pt;
        color: grey;
    }

    div.ui.list {
        padding: 0;
    }
    div.header {
        color: grey;
    }
`;

class CCustomTypeAnyListItem extends React.Component<IProps> {
  componentDidMount() {
    if (this.props.entry === undefined) {
      this.props.loadEntry(this.props.id, this.props.type);
    }
  }

  render() {
    const { entry, id } = this.props;

    const contentDescriptor = getTypeDescription(this.props.type);
    const typeString = contentDescriptor ? typeDescriptionDisplayName(contentDescriptor) : this.props.type;

    if (entry === undefined) {
      return (
        <Container>
          <h1>{typeString}</h1>
          Loading data. Id:
          {' '}
          {id}
          .
        </Container>
      );
    }

    if (contentDescriptor === undefined) {
      return (
        <Container>
          <h1>{typeString}</h1>
          No content. Id:
          {' '}
          {entry._id}
          .
        </Container>
      );
    }

    const trans = getTranslation(entry, 'da');
    if (trans === undefined) {
      return (
        <Container>
          <h1>{typeString}</h1>
          No content..
        </Container>
      );
    }
    // Keys to use. Sort, filter on string values and max 3.
    const keys = Object.keys(trans.data).sort(compareKeys).filter(interestingKeys(trans.data)).slice(0, 2);
    const values = keys.map((k) => (
      <List.Item key={k}>
        <List.Header>{StringUtils.capitalizeFirstLetter(k)}</List.Header>
        {truncate(trans.data[k])}
      </List.Item>
    ));

    return (
      <Container>
        <h1>{typeString}</h1>
        <List>{values}</List>
      </Container>
    );
  }
}

const mapStateToProps: MapStateToProps<IPropsFromState, IOwnProps, IState> = (state, ownProps) => {
  const { type, id } = ownProps;
  let result;

  if (state.content.data && type in state.content.data) {
    result = state.content.data[type].find((c) => c._id === id);
  }
  return {
    entry: result,
  };
};

const mapDispatchToProps: MapDispatchToProps<IPropsFromDispatch, IOwnProps> = (dispatch) => ({
  loadEntry: (id, type) => {
    dispatch(loadContentEntry({ id, type }));
  },
});

export const CustomTypeAnyListItem = connect(mapStateToProps, mapDispatchToProps)(CCustomTypeAnyListItem);
