import { Content } from '@visikon/core-models';
import { Button, Checkbox, Grid, Header, Pagination } from 'semantic-ui-react';
import { BlockListCard } from './components/Card';
import { SearchField } from '../../../components/search/search-field';
import { PaginationProps, usePaginationState } from '../../../components/semantic/PaginationState';
import { SearchSummary } from '../../../components/search/search-summary';
import { useSearchState } from '../../../components/search/search-state';
import { FilterCategory, filterListBy, NEW_RESOURCE_ID } from '../../../commons/utils';
import * as Styles from './CompositionListRenderer.styles';

export function BlockLists({
  items,
  languageCode,
  onClick,
  useAsPicker,
  showArchived,
  setShowArchived,
}: {
  items: Content.CompositionListItem[];
  languageCode: Content.LanguageCode;
  onClick: (id: string, composition?: Content.BlockList) => void;
  useAsPicker?: boolean;
  showArchived: boolean;
  setShowArchived: (bool: boolean) => void;
}) {
  const { searchFilter } = useSearchState();
  const filteredArchivedItems = items.filter((item) => (showArchived ? item.archived : !item.archived));
  const rows = filterListBy(searchFilter.category?.toLocaleLowerCase() as FilterCategory, filteredArchivedItems, searchFilter.text);

  return (
    <div>
      <BlockListHeader
        filteredRows={rows}
        unfilteredRows={items}
        onClick={onClick}
        useAsPicker={useAsPicker}
        showArchived={showArchived}
        setShowArchived={setShowArchived}
      />
      <PaginatedList items={rows} language={languageCode} onClick={onClick} />
    </div>
  );
}

function BlockListHeader({
  filteredRows,
  unfilteredRows,
  onClick,
  useAsPicker,
  showArchived,
  setShowArchived,
}: {
  filteredRows: Content.CompositionListItem[];
  unfilteredRows: Content.CompositionListItem[];
  onClick: (id: string) => void;
  useAsPicker?: boolean;
  showArchived: boolean;
  setShowArchived: (bool: boolean) => void;
}) {
  return (
    <>
      {!useAsPicker && <Header as="h1">Compositions</Header>}
      <Grid>
        <Grid.Row columns={4}>
          <Grid.Column>
            {!useAsPicker && (
              <Button color="green" onClick={() => onClick(NEW_RESOURCE_ID)}>
                Create composition
              </Button>
            )}
          </Grid.Column>
          <Grid.Column width={8} textAlign="center">
            <div style={{ textAlign: 'left' }}>
              <SearchField categories={['Name', 'Tags']} />
            </div>
            <SearchSummary filteredRows={filteredRows} unfilteredRows={unfilteredRows} />
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Checkbox label="Show archive" checked={showArchived} onChange={() => setShowArchived(!showArchived)} toggle />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

// eslint-disable-next-line react/no-unused-prop-types
function PaginatedList({ items, onClick }: { items: Content.BlockList[]; language: Content.LanguageCode; onClick: (id: string) => void }) {
  const paginationState = usePaginationState(items);

  return (
    <>
      <Paginator paginationProps={paginationState.paginationProps} />
      <div className="ui cards">
        {paginationState.getPage(items).map((item) => (
          <BlockListCard key={item._id} composition={item} showCopy onClick={() => onClick(item._id)} />
        ))}
      </div>

      <Paginator paginationProps={paginationState.paginationProps} />
    </>
  );
}

function Paginator({ paginationProps }: { paginationProps: PaginationProps }) {
  return (
    <Styles.PaginationContainer>
      <Pagination {...paginationProps} />
    </Styles.PaginationContainer>
  );
}
