import * as React from 'react';
import { Icon, Step } from 'semantic-ui-react';
import styled from 'styled-components';

export function PreviewReadyToast({ toastText, success }: { toastText: string; success: boolean }) {
  const [toast, setToast] = React.useState<string>('');
  const [visible, setVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    let hideToastTimeout: NodeJS.Timeout;
    let showToastTimeout: NodeJS.Timeout;

    if (success) {
      setToast(toastText);

      // Show toast after 10ms to account for the animation
      showToastTimeout = setTimeout(() => {
        setVisible(true);
      }, 10);

      // Hide toast after 2 seconds
      hideToastTimeout = setTimeout(() => {
        setVisible(false);
      }, 2000);
    }

    return () => {
      clearTimeout(showToastTimeout);
      clearTimeout(hideToastTimeout);
    };
  }, [toastText, success]);

  return (
    <>
      {toast && (
        <AnimatedContent visible={visible}>
          <Step.Group>
            <Step completed style={{ padding: '2px 4px' }}>
              <Step.Content style={{ padding: '0px 2px' }}>
                <Step.Description>{toast}</Step.Description>
              </Step.Content>
              <Icon name="checkmark" style={{ fontSize: '16px', margin: 0 }} />
            </Step>
          </Step.Group>
        </AnimatedContent>
      )}
    </>
  );
}

const AnimatedContent = styled.div<{ visible: boolean }>`
  padding: 6px 12px;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  transition: opacity 0.4s;
  height: 100%;
  pointer-events: none;
  z-index: 1000;
`;
