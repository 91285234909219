import { Route, RouteComponentProps } from 'react-router-dom';
import { CustomTypeListContainerRoute } from 'components/contentTypes/CustomTypeListRoute';
import { CustomTypeEditorRoute } from 'components/contentTypes/CustomTypeEditorRoute';

interface IProps extends RouteComponentProps<void> {

}

export const ContentRoot = (props: IProps) => {
  const { match } = props;

  const renderContentRoot = () => (
    <h1>Content root</h1>
  );

  return (
    <>
      <Route path="/namespaces" exact component={CustomTypeListContainerRoute} />
      <Route path={match.url} exact render={renderContentRoot} />
      <Route path="/content/:name" exact component={CustomTypeListContainerRoute} />
      <Route path="/content/:name/:id" exact component={CustomTypeEditorRoute} />
    </>
  );
};
