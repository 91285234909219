const LINE_FEED = '\n';
const TAB = '\t';
const CARRIAGE_RETURN = '\r';

const DelimiterExpr = new RegExp(`${TAB}|${LINE_FEED}|${CARRIAGE_RETURN}`, 'g');

export function removeDelimitingCharacters(input: string) {
  return input && input.replace(DelimiterExpr, '');
}

export function trimAll(input: Array<string>) {
  return input.map(str => str && str.trim());
}

export function normalize(inputString: string) {
  return (inputString || '')
    .toLowerCase()
    .replace('/', '-')
    .replace('\\', '-')
    .replace('&', '-')
    .replace('+', '-')
    .replace('=', '-')
    .replace('*', '-')
    .replace('?', '-')
    .replace(/[-]+/g, '-') // Make sure we only have one consecutive dash
    .trim();
}
