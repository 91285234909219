import { useEffect } from 'react';
import { connect } from 'react-redux';
import { LanguageCode } from '@visikon/core-models/content';
import { useSetRecoilState } from 'recoil';
import { Users } from '@visikon/core-models';
import { languageState, tokenState, userTypeState } from './redux-recoil-sync.state';
import { IState } from './reducers/reducers';

type Props = {
  token: string,
  activeLanguage: LanguageCode,
  userType: Users.AnyUserKinds
};

function CReduxRecoilSync(props: Props) {
  return <ReduxRecoilBinding {...props} />;
}

function ReduxRecoilBinding({ token, activeLanguage, userType }: Props) {
  const setToken = useSetRecoilState(tokenState);
  const setLanguage = useSetRecoilState(languageState);
  const setUserType = useSetRecoilState(userTypeState);

  useEffect(() => {
    setToken(token);
  }, [token, setToken]);

  useEffect(() => {
    setLanguage(activeLanguage);
  }, [activeLanguage, setLanguage]);

  useEffect(() => {
    setUserType(userType);
  }, [userType, setUserType]);

  return <></>;
}

export const ReduxRecoilSync = connect((state: IState) => ({
  token: state.auth.token,
  activeLanguage: state.language.activeLanguage,
  userType: state.auth.userType,
}))(CReduxRecoilSync);
