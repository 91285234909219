import { LanguageSelector } from './LanguageSelector';
import { LanguageCode } from '@visikon/core-models/content';

type Props = {
  filterLanguageCode: LanguageCode | undefined;
  setFilterLanguageCode: (languageCode: LanguageCode | undefined) => void;
}
export const LanguageFilter = ( {filterLanguageCode, setFilterLanguageCode } : Props) => (
    <>
      <div style={{ display: 'flex', alignItems: 'right', flexDirection: 'row', flexWrap: 'wrap', height: '30px', gap: '2px' }}>
        <div style={{ marginTop: '4px' }} onClick={() => setFilterLanguageCode( filterLanguageCode ? undefined : 'da' )}>
          <input
            style={{ width: '16px', height: '16px', cursor: 'pointer' }}
            type='checkbox'
            checked={!!filterLanguageCode}
            onChange={()=>{ /* noop */ } }
          />
          <label> Filter language  </label>
        </div>
        {filterLanguageCode && <div>
          <LanguageSelector activeLang={filterLanguageCode}
                            onLanguageChanged={(langCode) => setFilterLanguageCode(langCode)} />
        </div>}
      </div>
      </> );

