import { Content } from '@visikon/core-models';
import { ImageLibraryContainer } from '../../../../mediaLibrary/ImageLibraryContainer';

export function MediaLibraryView({ onPick }: { onPick: (src?: string) => void }) {
  const handlePick = (image: Content.Image) => onPick(image._id);

  return (
    <div style={{ maxHeight: 'calc(90vh - 200px)' }}>
      <ImageLibraryContainer onlyPicker onImagePick={handlePick} />
    </div>
  );
}
