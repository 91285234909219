import React, { useEffect, useState } from 'react';
import * as s from './program-duplicator.styles';
import { ProgramMetadataEntries } from '../../../api/program-metadata.models';

export function OutputForm({ metadata, onChange }: { metadata?: ProgramMetadataEntries, onChange: (outputState: ProgramMetadataEntries) => void }) {
  const indexByType = (type: string, entries: ProgramMetadataEntries) => entries?.findIndex((entry) => entry.type === type);

  const [state, setState] = useState([] as ProgramMetadataEntries);

  useEffect(() => {
    if (metadata) setState(metadata);
  }, [metadata]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldIndex = indexByType(e.target.name, state);

    state[fieldIndex] = {
      name: e.target.value,
      type: e.target.name,
      id: state[fieldIndex].id ? state[fieldIndex].id : '',
      label: state[fieldIndex].label ? state[fieldIndex].label : '',
    };
    setState([...state]);
    onChange([...state]);
  };

  return (
    <>
      {state && state.map((entry) => (
        <div key={entry.type} style={{ marginBottom: 16 }}>
          <h4>{entry.label}</h4>
          <s.TextInput name={entry.type} type="text" onChange={handleChange} value={entry.name} />
        </div>
      ))}
    </>
  );
}
