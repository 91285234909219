import { LanguageCode } from '@visikon/core-models/content';
import { DropdownItemProps, Popup } from 'semantic-ui-react';
import React from 'react';
import * as s from './program-duplicator.styles';
import { ProgramMetadata } from '../../../api/program-metadata.models';
import { LanguageSelector } from './language-selector';

export function FormWrapper({
  state, isInput, children, data,
}: { state: [LanguageCode, (l: LanguageCode) => void], isInput?: boolean, children: React.ReactNode, data?: ProgramMetadata }) {
  const filter = (selectableLanguage: DropdownItemProps) => !!(data && Object.keys(data).find((programLanguage) => programLanguage === selectableLanguage.value as LanguageCode));

  return (
    <s.FormColumn>
      <s.FormHeading>
        {isInput && 'Source program'}
        {!isInput && 'New program'}
        <Popup
          trigger={<i className="ui icon question circle" />}
          content={(
            <div>
              {isInput && 'All content from the selected language will be copied to a new program.'}
              {!isInput && 'Content of the new program will be placed under the selected language using the names below.'}
            </div>
)}
          hideOnScroll
        />
      </s.FormHeading>

      <LanguageSelector state={state} filter={isInput ? filter : undefined} />

      <HorizontalDivider />

      {children}
    </s.FormColumn>
  );
}

export function HorizontalDivider() {
  return (<div style={{ marginBottom: 32 }} />);
}
