import { LanguageCode } from '@visikon/core-models/content';
import { API, appEnv } from '../config';
import { Environment } from './env';

export const LocalStorage = {
  load: <T>(key: string, defaultValue?: T) => {
    const o = localStorage.getItem(key);
    return o
      ? JSON.parse(o) as T
      : defaultValue;
  },

  save: <T>(key: string, o: T) => localStorage.setItem(key, JSON.stringify(o)),
};

const dev = appEnv === Environment.Local;

export const BlobUrls = {
  thumbnailUrl: (parentId?: string, translation?: {language: LanguageCode, thumbnailSrc?: string}) => {
    if(!parentId || !translation) { return undefined; }
    return dev
      ? `${API.baseURL}media/videoThumbnail/${parentId}/${translation?.language}`
      : `${API.baseBlobUrl}${translation?.thumbnailSrc}`;
  },

  videoUrl: (parentId?: string, translation?: {language: LanguageCode, src: string}) => {
    if(!parentId || !translation) { return undefined; }
    return dev
      ? `${API.baseURL}media/video/file/${parentId}/${translation?.language}`
      : `${API.baseBlobUrl}${translation?.src}`;
  },

  encodedResourceUrl: (path?: string) => {
    if(!path) { return undefined; }
    return dev
      ? `${API.baseURL}encodings/${path}`
      : `${API.baseBlobUrl}${path}`;
  },

  genericUrl: (path?: string) => {
    if(!path) { return undefined; }
      return `${API.baseBlobUrl}${path}`;
  },
}
