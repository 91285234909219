import React from 'react';
import { RouteComponentProps } from 'react-router';
import { getTypeDescription } from 'components/contentTypes/ContentDescriptors';
import { CustomTypeListContainer } from '../content/CustomTypeListContainer';
import { CustomTypeEditor } from '../content/CustomTypeEditor';
import { ProgramDuplicator } from '../content/program-duplicator/program-duplicator';

interface Projects {
  [key: string]: {
    contentTypeName: string;
    displayName: string;
  };
}
const projects: Projects = {
  mitforlob: {
    contentTypeName: 'mitforlobPack',
    displayName: 'Mit Forløb',
  },
  diabetes: {
    contentTypeName: 'diabetesGuide',
    displayName: 'Diabetes Guide',
  },
};

type IProps = RouteComponentProps<{ name: string; id?: string; onSave?: string }>;
class CCollectionPage extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.handleOnClickNewButton = this.handleOnClickNewButton.bind(this);
    this.handleOnClickEditButton = this.handleOnClickEditButton.bind(this);
  }

  handleOnClickNewButton() {
    const loc = this.props.location;
    this.props.history.push(`${loc.pathname}/new`);
  }

  handleOnClickEditButton(id: string) {
    const loc = this.props.location;
    this.props.history.push(`${loc.pathname}/${id}`);
  }

  // eslint-disable-next-line class-methods-use-this
  renderNotFound() {
    return <div>Not found!</div>;
  }

  render() {
    const { match } = this.props;
    const projectName = match.params.name;
    const project = projects[projectName];
    if (project === undefined) {
      return this.renderNotFound();
    }

    if (match.params.id) {
      return match.params.id.startsWith('clone:') ? (
        <ProgramDuplicator {...this.props} id={match.params.id.substr(6)} name={project.contentTypeName} />
      ) : (
        <CustomTypeEditor {...this.props} id={match.params.id} name={project.contentTypeName} />
      );
    }

    const typeDescription = getTypeDescription(project.contentTypeName);
    if (typeDescription === undefined) {
      return this.renderNotFound();
    }

    return (
      <>
        <h1 className="ui header">{project.displayName}</h1>
        <CustomTypeListContainer
          name={project.contentTypeName}
          handleOnClickNewButton={this.handleOnClickNewButton}
          handleOnEditClick={this.handleOnClickEditButton}
        />
      </>
    );
  }
}

export const CollectionPage = CCollectionPage;
