import { createContent, loadContentEntry, PublishState, updateContent } from 'actions/ContentActions';
import { NEW_RESOURCE_ID, replaceTranslation, usePrevious } from 'commons/utils';
import { doesTypeExist, getTypeDescription } from 'components/contentTypes/ContentDescriptors';
import { CustomTypeForm } from 'components/contentTypes/CustomTypeForm';
import { CustomTypeValue } from 'components/contentTypes/CustomTypeInput';
import { CustomTypeTranslateForm } from 'components/contentTypes/CustomTypeTranslateForm';
import { Content, UploadTypes, Users } from '@visikon/core-models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { IState } from 'reducers/reducers';
import { permissionLevel, PermissionLevels } from '@visikon/core-models/typeUtils';
import { languages } from 'commons/languages';
import { clearCreateSuccess as clearCreateSuccessAction, getALlPublishedVersionById } from 'actions/publisherAction';
import { RouteComponentProps } from 'react-router-dom';
import { ObjectId } from '@visikon/core-models/base';
import { CustomTypeEditorHeader } from './CustomTypeEditorHeader';
import { PublishedRevisions } from '@visikon/core-models/content';
import { LanguageCode } from '@visikon/core-models/languageTypes';
import { Confirm } from 'semantic-ui-react';

type CustomTypeMapData = {
  [type: string]: Content.CustomType[];
};

export interface CustomTypeWithPublishState extends Content.CustomType {
  publishState?: PublishState;
  published?: PublishedRevisions;
}

interface IPropsFromState {
  entry?: CustomTypeWithPublishState;
  userType: Users.AnyUserKinds;
  activeLanguage: Content.LanguageCode;
  languagesConfig?: Users.CMSUser['languagesConfig'];
  namespace?: string;
  mediaVariation: string;
  toggleTranslationPermission: boolean;
  data?: CustomTypeMapData;
}

interface IPropsFromDispatch {
  fetchContentEntry(type: string, id: ObjectId): void;

  getAllPublishedVersion(orginalId: string): void;

  clearCreateSuccess(): void;

  saveContent(
    language: Content.LanguageCode | null,
    val: Content.CustomType | UploadTypes.CustomType,
    mediaVariation: string,
    existingValue?: Content.CustomType,
  ): void;
}

interface IOwnProps {
  name: string;
  id: string;
}

type IProps = IOwnProps & IPropsFromState & IPropsFromDispatch & RouteComponentProps<any>;

const CCustomTypeEditor = (props: IProps) => {
  const {
    getAllPublishedVersion,
    clearCreateSuccess,
    fetchContentEntry,
    id,
    name,
    data,
    entry,
    toggleTranslationPermission,
    activeLanguage,
    saveContent,
  } = props;

  const getValue = useCallback(
    (language: Content.LanguageCode) => {
      if (!entry) {
        return undefined;
      }
      const trans = entry.translations.find((t) => t.language === language);
      if (trans) {
        return trans.data;
      }
      return undefined;
    },
    [entry],
  );

  const lastSavedId = retrieveLastSavedEntryId();
  const prevLastSavedId = usePrevious(lastSavedId);

  const [typeNotFoundError, setTypeNotFoundError] = useState(false);
  const typeDescription = useMemo(() => getTypeDescription(name), [name]);
  const [entryLocal, setEntryLocal] = useState<CustomTypeWithPublishState | undefined>(undefined);
  const [showTranslation, setShowTranslation] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [val, setVal] = useState<CustomTypeValue>(getValue(activeLanguage));
  const [translateFormVal, setTranslateFormVal] = useState(undefined);
  const [localActiveLanguage, setLocalActiveLanguage] = useState<LanguageCode>(activeLanguage);
  const [confirmChangeTo, setConfirmChangeTo] = useState<Content.LanguageCode | undefined>(undefined);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    if (!showTranslation) {
      setLocalActiveLanguage(activeLanguage);
    }
    setVal(getValue(activeLanguage));
  }, [activeLanguage, getValue, showTranslation]);

  useEffect(() => {
    if (entryLocal && !entryLocal.translations.some((t) => t.language === localActiveLanguage)) {
      setLocalActiveLanguage(entryLocal.translations[0].language);
      setVal(entryLocal.translations[0].data);
    }
  // eslint-disable-next-line
  }, [entryLocal]);

  useEffect(() => {
    // Check if type exists
    const typeName = name;
    if (!doesTypeExist(typeName)) {
      setTypeNotFoundError(true);
      return;
    }

    if (id !== NEW_RESOURCE_ID) {
      if (!entry) {
        fetchContentEntry(typeName, id);
      }
      setEntryLocal(entry);
    }

    getAllPublishedVersion(id);
    clearCreateSuccess();
  }, [clearCreateSuccess, fetchContentEntry, getAllPublishedVersion, id, entry, name]);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent && entry !== entryLocal) {
      setEntryLocal(entry);
    }

    return () => {
      isCurrent = false;
    };
  }, [entryLocal, entry]);

  useEffect(() => {
    let isCurrent = true;

    const handleSlugChange = (ID: string | undefined) => {
      const loc = props.location;
      const baseUrl = loc.pathname.slice(0, loc.pathname.lastIndexOf('/'));
      props.history.push(`${baseUrl}/${ID}`);
    };

    if (prevLastSavedId !== lastSavedId) {
      if (id === NEW_RESOURCE_ID && hasSaved && isCurrent) {
        handleSlugChange(lastSavedId);
      }
    }

    return () => {
      isCurrent = false;
    };
  }, [hasSaved, id, lastSavedId, prevLastSavedId, props]);

  const handleChange = (language: Content.LanguageCode, value: CustomTypeValue) => {
    saveContent(language, value, props.mediaVariation, entryLocal);
    setHasSaved(true);
  };

  const handleLocalFormChange = (key: string, value: CustomTypeValue) => {
    setHasChanges(true);
    setVal(value);
  };

  const renderContentTypeError = () => (
    <>
      <h1 className='header'>Woops</h1>
      <p>Content type not found</p>
    </>
  );

  const handleSave = () => {
    const formValue = showTranslation ? translateFormVal : val;
    handleChange(localActiveLanguage, formValue);
    setHasChanges(false);
  };

  const confirmLanguageChange = (lang?: Content.LanguageCode) => {
    const language = lang || confirmChangeTo;
    if (language === undefined) {
      return;
    }
    setVal(getValue(language));
    setLocalActiveLanguage(language);
    setConfirmChangeTo(undefined);
    setHasChanges(false);
  };

  const handleLanguageChange = (lang: Content.LanguageCode) => {
    if (hasChanges) {
      setConfirmChangeTo(lang);
    } else {
      setLocalActiveLanguage(lang);
      confirmLanguageChange(lang);
    }
  };

  function retrieveLastSavedEntryId(): string | undefined {
    const dataList = data?.[name];
    if (dataList) {
      const lastEntry = dataList[dataList.length - 1];
      return lastEntry?._id;
    }

    return undefined;
  }

  function deleteLanguage() {
    if (entry) {
      const newEntry = { ...entry, ...entryLocal };
      newEntry.translations = newEntry.translations.filter((t) => t.language !== localActiveLanguage);
      saveContent(null, val, props.mediaVariation, newEntry);
      setConfirmDelete(false);
      setHasChanges(false);
    }
  }

  function canDelete() {
    return props.entry?.translations.length !== 1 && !hasChanges &&
      props.entry?.translations.some((l) => l.language === localActiveLanguage) || false;
  }

  const renderForm = () => {
    const { userType, activeLanguage: primaryLanguage, languagesConfig } = props;

    if (userType === 'Translator' && entryLocal === undefined) {
      return null;
    }

    if (userType === 'Translator' || showTranslation) {
      // Translators cannot see empty editor
      const primaryContent = entryLocal!.translations.find((t) => t.language === primaryLanguage)!;
      return (
        <CustomTypeTranslateForm
          entry={entryLocal!}
          referenceTranslation={primaryContent}
          languagesConfig={languagesConfig}
          namespace={props.namespace || ''}
          typeDescription={typeDescription!}
          setHasChanges={setHasChanges}
          activeLanguage={localActiveLanguage}
          globalActiveLanguage={primaryLanguage}
          handleLanguageChange={setLocalActiveLanguage}
          translateFormVal={translateFormVal}
          setTranslateFormVal={setTranslateFormVal}
        />
      );
    }

    const confirmDeleteMessage = (<div style={{ margin: '20px' }}>
      <h1><i className='warning icon' style={{ color: 'red' }} />Warning</h1>
      <div>{`You are about to delete the language '${languages.find(l => l.value === localActiveLanguage)?.text}' and all its content.`} </div>
      <br />
      <div> Are you sure?</div>
    </div>);

    // In normal mode we can begin with `undefined` - aka. creating a new
    return (
      <>
        <CustomTypeForm
          activeLanguage={localActiveLanguage}
          typeDescription={typeDescription!}
          handleLanguageChange={handleLanguageChange}
          confirmLanguageChange={confirmLanguageChange}
          setConfirmChangeTo={setConfirmChangeTo}
          confirmChangeTo={confirmChangeTo}
          val={val}
          handleFormChange={handleLocalFormChange}
          canDelete={canDelete()}
          deleteLanguage={() => setConfirmDelete(true)}
        />
        <Confirm
          content={confirmDeleteMessage}
          open={confirmDelete}
          onCancel={() => setConfirmDelete(false)}
          onConfirm={() => deleteLanguage()}
        />
      </>
    );
  };

  if (typeNotFoundError) {
    return renderContentTypeError();
  }

  if (!typeDescription) {
    return null;
  }

  if (props.id !== NEW_RESOURCE_ID && entryLocal === undefined) {
    return <div>Loading..</div>;
  }

  return (
    <>
      <CustomTypeEditorHeader
        id={props.id}
        type={name}
        typeDescription={typeDescription}
        toggleTranslationPermission={toggleTranslationPermission}
        showTranslation={showTranslation}
        setShowTranslation={setShowTranslation}
        activeLanguage={activeLanguage}
        languages={entry?.translations?.map((t) => t.language) as Content.LanguageCode[]}
        hasChanges={hasChanges}
        handleSave={handleSave}
        handleLanguageChange={handleLanguageChange}
      />
      {renderForm()}
    </>
  );
};

const mapStateToProps: MapStateToProps<IPropsFromState, IProps, IState> = (state, props) => {
  const contentType = props.name;
  const { id } = props;
  const data = contentType in state.content.data ? state.content.data[contentType] : [];
  const userType = state.auth.userType || 'NoUser';

  const toggleTranslationPermission = permissionLevel(userType) >= PermissionLevels.EDITOR_PERMISSION_LEVEL;
  let primaryLanguage = state.auth.languagesConfig ? state.auth.languagesConfig.referenceLanguage : state.language.activeLanguage;
  if (toggleTranslationPermission) {
    primaryLanguage = state.language.activeLanguage;
  }

  // Language config. If admin, just allow all
  let { languagesConfig } = state.auth;
  if (permissionLevel(userType) >= PermissionLevels.TRANSLATOR_PERMISSION_LEVEL) {
    languagesConfig = {
      referenceLanguage: primaryLanguage,
      destinationLanguages: languages.map((l) => l.value) as any[],
    };
  }

  return {
    entry: data.find((d) => d._id === id),
    userType,
    toggleTranslationPermission,
    activeLanguage: primaryLanguage,
    languagesConfig,
    namespace: state.auth.namespace,
    mediaVariation: state.language.activeMediaVariation,
    data: state.content.data,
  };
};

const mapDispatchToProps: MapDispatchToProps<IPropsFromDispatch, IOwnProps> = (dispatch, ownProps) => ({
  fetchContentEntry: (type: string, id: ObjectId) => {
    dispatch(loadContentEntry({ type, id }));
  },
  getAllPublishedVersion: (orginalId: string) => {
    dispatch(getALlPublishedVersionById(orginalId));
  },
  clearCreateSuccess() {
    dispatch(clearCreateSuccessAction());
  },
  saveContent: (language: Content.LanguageCode, val: CustomTypeValue, mediaVariation: string, entry?: CustomTypeWithPublishState) => {
    if (entry && language === null) { // we have deleted a language
      dispatch(updateContent({ ...entry }));
    } else if (entry) {
      const existingTranslations = entry.translations.find((t) => t.language === language);
      if (existingTranslations) {
        const updatedTranslation = { ...existingTranslations, data: val, lastModified: Date.now() };
        const update = replaceTranslation(entry, updatedTranslation, language);
        dispatch(updateContent(update));
      } else {
        const newTranslation = {
          mediaVariation,
          data: val,
          language,
          lastModified: Date.now(),
        };
        const translations = [...entry.translations, newTranslation];
        const updated = { ...entry, translations };
        dispatch(updateContent(updated));
      }
    } else {
      const typeDescriptor = ownProps.name;
      dispatch(
        createContent({
          typeDescriptor,
          publishState: 'Unpublished',
          translation: {
            language,
            data: val,
          },
        }),
      );
    }
  },
});
export const CustomTypeEditor = connect(mapStateToProps, mapDispatchToProps)(CCustomTypeEditor);
