import { Content } from '@visikon/core-models';
import { ObjectId } from '@visikon/core-models/base';
import { updateImage, uploadImageFile } from 'actions/MediaActions';
import { useImage } from 'api/imageLibraryApi';
import { ImageDetails } from 'components/mediaLibrary/images/ImageDetails';
import { Spinner } from 'components/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { IState } from 'reducers/reducers';
import { languageState } from 'redux-recoil-sync.state';

export function ImageDetailsContainer() {
  const [language] = useRecoilState(languageState);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useImage(id);

  const mediaVariants: Content.MediaVariation[] = useSelector((state: IState) => state.media.mediaVariations);

  if (query.isIdle) return <Spinner visible text="Awaiting authentication token..." />;
  if (query.isError) return <>Error :\</>;
  if (query.isLoading || !query.data) return <Spinner visible />;

  return (
    <ImageDetails
      image={query.data!}
      language={language}
      mediaVariations={mediaVariants}
      onDismiss={() => {
        history.goBack();
      }}
      onUploadTranslation={(languageCode: Content.LanguageCode, variation: ObjectId, file: File) =>
        dispatch(uploadImageFile({ image: query.data!, language: languageCode, variation, file }))
      }
      toggleArchive={(toggledImage) => {
        dispatch(updateImage(toggledImage));
      }}
    />
  );
}
