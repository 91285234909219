import { Block, BlockCreation, LanguageCode } from '@visikon/core-models/content';
import { logger } from 'commons/logger';
import { useToggle } from 'commons/toggle';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'reducers/reducers';
import { BlockPickerDialog } from './BlockPicker.modal';
import * as Styles from './BlockPicker.styles';
import { getTranslationFromArray } from '@visikon/core-models/typeUtils';

type Props = {
    language: LanguageCode,
    blocks: Block[]
    onBlockPick: (block: {
        blockId: string,
        videoVariation: string | undefined,
        speakVariation: string | undefined,
    } | undefined) => void,
    onBlockCreate(blockCreation: BlockCreation): void
};

const reduxSelector = (state: IState) => ({
    newlyCreatedBlockId: state.blocks.newlyCreatedBlockId,
});

export function BlockPickerButton({ language, blocks, onBlockPick, onBlockCreate }: Props) {
    const [modalVisible, toggleModal, setModalVisible] = useToggle();
    const { newlyCreatedBlockId } = useSelector(reduxSelector);

    useEffect(() => {
        if (modalVisible && !!newlyCreatedBlockId) {
            setModalVisible(false);
        }
    }, [newlyCreatedBlockId, modalVisible, setModalVisible]);

    const handleBlockPicked = (blockId: string) => {
        const block = getBlockFromId(blockId, language, blocks);

        onBlockPick(block);
        toggleModal();
    }

    return (
        <>
            {modalVisible && (
                <BlockPickerDialog
                    isOpen
                    lang={language}
                    onPick={handleBlockPicked}
                    onAddNew={onBlockCreate}
                    onDismiss={toggleModal}
                    excludeBlocks={[]}
                />
            )}
            <Styles.AddButtonWrapper>
                <Styles.AddButton onClick={toggleModal}>+</Styles.AddButton>
            </Styles.AddButtonWrapper>
        </>
    );
}

export function getBlockFromId(blockId: string, language: LanguageCode, blocks: Block[]) {
    const blockPicked = blocks.find((b) => b._id === blockId);

    if (!blockPicked) {
        logger.warn("Couldn't find picked block....");
        return undefined;
    }

    const newBlockVideo = getTranslationFromArray(blockPicked.videos, language);
    const newBlockSpeak = getTranslationFromArray(blockPicked.speaks, language);

    return {
        blockId,
        videoVariation: newBlockVideo ? newBlockVideo.variations[0] : undefined,
        speakVariation: newBlockSpeak ? newBlockSpeak.variations[0] : undefined,
    };
}
