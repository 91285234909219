import { ITypeDescription } from 'components/contentTypes/CustomTypeInput';
import { CustomType, LanguageCode } from '@visikon/core-models/content';
import { SearchField } from 'components/search/search-field';
import { FilterCategory, filterListBy } from 'commons/utils';
import { SortableTable } from 'components/SortableTable';
import { Flags } from 'components/Flags';
import { PublishStatus } from 'components/PublishStatus';
import { IPublishedProgramType } from 'actions/ContentActions';
import { ObjectId } from '@visikon/core-models/base';
import {
  getFields,
  getSearchableFieldNames,
  getValues,
  IPrintableCustomTypeFields,
  IPrintableCustomTypeInstance,
} from './CustomTypeHelpers';
import { SearchFilter, useSearchState } from '../search/search-state';
import { SearchSummary } from '../search/search-summary';
import { useState } from 'react';
import { LanguageFilter } from '../LanguageFilter';

type Props = {
  typeDescription: ITypeDescription;
  list: CustomType[];
  onCopy?(id: ObjectId): void;
  onClickEdit?(id: string): void;
  onPick?(id: string): void;
};

const isMitforlob = () => {
  const locationPath = window.location.pathname.split('/');
  return locationPath.includes('mitforlob');
};

const explicitHeaderNames = () => {
  const appliedHeaders = [{ label: 'Languages' }];
  // Only show publish status header on Mit Forløb programs
  if (isMitforlob()) {
    appliedHeaders.push({ label: 'Publish status' });
  }

  return appliedHeaders;
};

const explicitRowContent = (list: (CustomType & IPublishedProgramType)[], row: IPrintableCustomTypeInstance) => {
  const match = list.find((c) => c._id === row.id);


  const appliedRowContent = [<Flags languageCodes={match?.translations} />];
  // Only show publish status column on Mit Forløb programs
  if (isMitforlob()) {
    appliedRowContent.push(
      <div style={{ textAlign: 'center' }}>
        <PublishStatus revisions={match?.published} />
      </div>,
    );
  }
  return appliedRowContent;
};

const mapHeader = (keys: string[], header: IPrintableCustomTypeFields) => [
  ...keys.map((key) => ({
    key,
    label: header[key],
  })),
  ...explicitHeaderNames(),
];

const mapRows = (keys: string[], rows: IPrintableCustomTypeInstance[], list: (CustomType & IPublishedProgramType)[]) =>
  rows.map((row) => ({
    id: row.id,
    cells: [...keys.map((key) => row[key]), ...explicitRowContent(list, row)],
  }));

const languageFilter = (list: CustomType[], filterLanguageCode: LanguageCode) => list.filter((item) => item.translations.some(t => t.language === filterLanguageCode));

const filterRows = (typeDescription: ITypeDescription, unfilteredRows: CustomType[], filter: SearchFilter) => {
  const { text, category } = filter;

  const rows = unfilteredRows.map((item) => getValues(item, 'da', typeDescription, 4)).sort((a: any, b: any) => b.createdAt - a.createdAt);

  return filterListBy(category?.toLowerCase() as FilterCategory, rows, text?.trim());
};

export const CustomTypeList = ({ typeDescription, onPick, onClickEdit, onCopy, list }: Props) => {
  const { searchFilter } = useSearchState();
  const [filterLanguageCode, setFilterLanguageCode] = useState<LanguageCode | undefined>(undefined);

  const rows = filterRows(typeDescription, filterLanguageCode ? languageFilter(list, filterLanguageCode) : list, searchFilter);
  const header = getFields(typeDescription, 4);
  const keys = Object.keys(header);
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: 500 }}>
          <SearchField categories={getSearchableFieldNames(typeDescription.meta.name)} />
          <SearchSummary filteredRows={rows} unfilteredRows={list} />
        </div>
      </div>

      <LanguageFilter filterLanguageCode={filterLanguageCode} setFilterLanguageCode={setFilterLanguageCode} />

      <SortableTable
        typeName={typeDescription.meta.name}
        headers={mapHeader(keys, header)}
        rows={mapRows(keys, rows, list)}
        onCopy={(id: string) => onCopy && onCopy(id)}
        onRowClick={(id: string) => {
          if (onPick) onPick(id);
          else if (onClickEdit) onClickEdit(id);
        }}
      />
    </>
  );
};
