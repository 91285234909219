import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    width: 600px;
    margin-top: 5px;
`;

export const ProgressWrapper = styled.div`
    flex: 1;
    align-self: center;

    div.ui.progress {
        margin: 0;
    }
`;

export const Time = styled.div`
    margin-left: 5px;
    margin-right: 5px;
    align-self: center;
    font-size: 1.2em;
`;
