import { TextLibraryContainer } from 'containers/mediaLibrary/TextLibraryContainer';
import { Content } from '@visikon/core-models';
import React from 'react';
import { Button, Divider, Input, InputOnChangeData, Modal, Segment } from 'semantic-ui-react';
import { CustomTypeValue, ICommonBaseProps } from '../CustomTypeInput';

export interface IParametersForText {
  type: 'text';
  desc: string;
}

interface ITextType {
  type: 'text';
  _idRef: string;
}
interface ILinkType {
  type: 'webLink';
  webLink: string;
}

type ValueType = ITextType | ILinkType;

function isTextValue(value: ValueType | undefined): value is ITextType {
  return value !== null && value !== undefined && value.type === 'text';
}

function isUrlValue(value: ValueType | undefined): value is ILinkType {
  return value !== null && value !== undefined && value.type === 'webLink';
}

export interface IComponentTextInputProps extends ICommonBaseProps, IParametersForText {
  value?: ValueType;
  onChange(key: string, val: CustomTypeValue | undefined): void;
}

interface IComponentState {
  isPickerOpen: boolean;
}
export class TextTypeInput extends React.Component<IComponentTextInputProps, IComponentState> {
  constructor(props: IComponentTextInputProps) {
    super(props);

    this.state = { isPickerOpen: false };

    this.renderModal = this.renderModal.bind(this);
    this.renderTextPicker = this.renderTextPicker.bind(this);
    this.renderUrlForm = this.renderUrlForm.bind(this);

    this.dismissPicker = this.dismissPicker.bind(this);
    this.onTextPicked = this.onTextPicked.bind(this);
    this.onTextRemoved = this.onTextRemoved.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
  }

  dismissPicker() {
    this.setState({ isPickerOpen: false });
  }

  onTextPicked(img: Content.Text) {
    this.props.onChange(this.props.pKey, { _idRef: img._id, type: 'text' });
    this.setState({ isPickerOpen: false });
  }

  onUrlChange(_: any, data: InputOnChangeData) {
    this.props.onChange(this.props.pKey, { webLink: data.value, type: 'webLink' });
    this.setState({ isPickerOpen: false });
  }

  onTextRemoved() {
    this.props.onChange(this.props.pKey, undefined);
  }

  renderModal() {
    return (
      <Modal open={this.state.isPickerOpen} onClose={this.dismissPicker} size="fullscreen">
        <Modal.Content>
          <TextLibraryContainer onlyPicker onTextPick={this.onTextPicked} />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <>
        <h4>{this.props.desc}</h4>
        <Segment>

          {this.renderTextPicker()}
          <Divider horizontal>Or</Divider>
          {this.renderUrlForm()}

        </Segment>
      </>
    );
  }

  renderUrlForm() {
    const disabled = isTextValue(this.props.value);

    let value = '';
    if (isUrlValue(this.props.value)) {
      value = this.props.value.webLink;
    }

    return (
      <Input label="Enter URL" placeholder="https://..." disabled={disabled} style={{ width: '80%' }} value={value} onChange={this.onUrlChange} />
    );
  }

  renderTextPicker() {
    if (this.props.isTranslation) {
      return null;
    }

    if (!isTextValue(this.props.value)) {
      return (
        <>
          <div>No text chosen</div>
          <br />
          <Button onClick={() => { this.setState((prevState) => ({ isPickerOpen: !prevState.isPickerOpen })); }}>Pick text</Button>
          {this.renderModal()}
        </>
      );
    }

    return (
      <>
        <div>
          Has value:
          <a href={`/media/textEdit/${this.props.value._idRef}`}>{this.props.value._idRef}</a>
        </div>
        <br />
        <Button onClick={() => { this.setState((prevState) => ({ isPickerOpen: !prevState.isPickerOpen })); }}>Change text</Button>
        <Button color="red" onClick={this.onTextRemoved}>Remove chosen</Button>
        {this.renderModal()}
      </>
    );
  }
}
