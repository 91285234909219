import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Menu, Image, Sidebar } from 'semantic-ui-react';
import { PermissionLevels, permissionLevel } from '@visikon/core-models/typeUtils';
import logo from 'images/menu_logo.png';
import { Users } from '@visikon/core-models';
import { VersionIndicator } from '../VersionIndicator';
import { UtilitiesSubMenu } from './UtilitiesSubMenu';

interface ItemData {
  url: string;
  permissionLevel: PermissionLevels;
}
const menuItemsPerNamespace: { [namespace: string]: ItemData[] } = {
  novo: [
    // Content
    { url: '/collections/diabetes', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/content/videoList', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/content/document', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/content/wikiContent', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/content/wikiEntry', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/content/wikiLink', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/content/stringSequence', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/content/drugList', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    // Media
    { url: '/media/texts', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/media/videos', permissionLevel: PermissionLevels.EDITOR_PERMISSION_LEVEL },
    { url: '/media/images', permissionLevel: PermissionLevels.EDITOR_PERMISSION_LEVEL },
    // Other
    { url: '/users', permissionLevel: PermissionLevels.ADMIN_PERMISSION_LEVEL },
    { url: '/logout', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
  ],
  safedelivery: [
    // Content
    { url: '/content/videoList', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/content/document', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/content/drugList', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/content/notifications', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    // Media
    { url: '/media/texts', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/media/videos', permissionLevel: PermissionLevels.EDITOR_PERMISSION_LEVEL },
    { url: '/media/images', permissionLevel: PermissionLevels.EDITOR_PERMISSION_LEVEL },
    // Blocks
    { url: '/block/composer', permissionLevel: PermissionLevels.EDITOR_PERMISSION_LEVEL },
    { url: '/block/overview', permissionLevel: PermissionLevels.EDITOR_PERMISSION_LEVEL },
    // Other
    { url: '/logout', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
  ],
  mitforlob: [
    // Content
    { url: '/collections/mitforlob', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/content/videoList', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/content/document', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/content/faq', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/content/symptom', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/content/notifications', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/content/survey', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/content/contentContainer', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },

    // Media
    { url: '/media/texts', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
    { url: '/media/videos', permissionLevel: PermissionLevels.EDITOR_PERMISSION_LEVEL },
    { url: '/media/images', permissionLevel: PermissionLevels.EDITOR_PERMISSION_LEVEL },
    // Blocks
    { url: '/block/composer', permissionLevel: PermissionLevels.EDITOR_PERMISSION_LEVEL },
    { url: '/block/overview', permissionLevel: PermissionLevels.EDITOR_PERMISSION_LEVEL },
    { url: '/block/speakManuscripts', permissionLevel: PermissionLevels.EDITOR_PERMISSION_LEVEL },
    // Other
    { url: '/namespaces', permissionLevel: PermissionLevels.SUPERADMIN_PERMISSION_LEVEL },
    { url: '/startcodes', permissionLevel: PermissionLevels.SUPERADMIN_PERMISSION_LEVEL },
    { url: '/users', permissionLevel: PermissionLevels.ADMIN_PERMISSION_LEVEL },
    { url: '/logout', permissionLevel: PermissionLevels.TRANSLATOR_PERMISSION_LEVEL },
  ],
};

interface IProps extends RouteComponentProps<{}> {
  isLoggedIn: boolean;
  namespace: string;
  userType: Users.AnyUserKinds;
}

const CMenuItem = (props: { name: string; path: string; url: string; active?: boolean; namespace?: string; userType: Users.AnyUserKinds }) => {
  const { name, url, path, active, namespace, userType } = props;

  const urlsToShow = menuItemsPerNamespace[namespace || 'mitforlob'];
  if (!urlsToShow) {
    return null;
  }

  const itemData = urlsToShow.find((entry) => entry.url === url);
  if (!itemData) {
    return null;
  }

  const currentLevel = permissionLevel(userType);
  if (currentLevel < itemData.permissionLevel) {
    return null;
  }
  return (
    <Menu.Item name={name} as={Link} to={url} active={active || path.startsWith(url)}>
      {name}
    </Menu.Item>
  );
};

export class MainMenu extends React.Component<IProps, {}> {
  // TODO: Refactor so the menu below is rendered from the data structure `menuItemsPerNamespace`.
  renderLoggedIn() {
    const path = this.props.location.pathname;
    const { namespace } = this.props;
    const { userType } = this.props;
    const otherProps = { path, namespace, userType };

    return (
      <Sidebar as={Menu} visible vertical>
        <Image src={logo} as={Link} to="/" />
        <br />
        <Menu.Menu>
          <Menu.Header>
            <br />
            Collections
          </Menu.Header>
          <CMenuItem name="Mit Forløb" url="/collections/mitforlob" {...otherProps} />
          <CMenuItem name="Diabetes Guide" url="/collections/diabetes" {...otherProps} />
        </Menu.Menu>
        <Menu.Menu>
          <Menu.Header>
            <br />
            Content Library
          </Menu.Header>
          <CMenuItem name="Video Playlists" url="/content/videoList" {...otherProps} />
          <CMenuItem name="Document Lists" url="/content/document" {...otherProps} />
          <CMenuItem name="FAQ" url="/content/faq" {...otherProps} />
          <CMenuItem name="Symptom Guides" url="/content/symptom" {...otherProps} />
          <CMenuItem name="Notifications" url="/content/notifications" {...otherProps} />
          <CMenuItem name="Survey" url="/content/survey" {...otherProps} />
          <CMenuItem name="Content Containers" url="/content/contentContainer" {...otherProps} />

          <CMenuItem name="Wiki Content" url="/content/wikiContent" {...otherProps} />
          <CMenuItem name="Wiki Entry" url="/content/wikiEntry" {...otherProps} />
          <CMenuItem name="Wiki Link" url="/content/wikiLink" {...otherProps} />
          <CMenuItem name="String Sequence" url="/content/stringSequence" {...otherProps} />
          <CMenuItem name="Drug List" url="/content/drugList" {...otherProps} />

          {/* <CMenuItem name="Training Guides" url="/content/training"      {...otherProps} /> */}
          {/* <CMenuItem name="Notes"           url="/content/notes"         {...otherProps} /> */}
        </Menu.Menu>
        <Menu.Menu>
          <Menu.Header>
            <br />
            Composer
          </Menu.Header>
          <CMenuItem name="Compositions" url="/block/composer" {...otherProps} />
          <CMenuItem name="Blocks" url="/block/overview" {...otherProps} />
          <UtilitiesSubMenu path={path} />
        </Menu.Menu>
        <Menu.Menu>
          <Menu.Header>
            <br />
            Media Library
          </Menu.Header>
          {/* <CMenuItem name="Variations" url="/media/variations"  path={path} /> */}
          <CMenuItem name="Videos" url="/media/videos" {...otherProps} />
          <CMenuItem name="Images" url="/media/images" {...otherProps} />
          <CMenuItem name="Texts" url="/media/texts" active={path.includes('/texts') || path.includes('/textEdit')} {...otherProps} />
        </Menu.Menu>
        <Menu.Menu>
          <Menu.Header>
            <br />
            Administration
          </Menu.Header>
          {/* <CMenuItem name="Projects" url="/projects" {...otherProps} /> */}
          <CMenuItem name="Namespaces" url="/namespaces" {...otherProps} />
          <CMenuItem name="Start codes" url="/startcodes" {...otherProps} />
          <CMenuItem name="Users" url="/users" {...otherProps} />
          <CMenuItem name="Logout" url="/logout" {...otherProps} />
        </Menu.Menu>
        <VersionIndicator
          style={{
            fontSize: '9pt',
            color: 'rgba(0,0,0,0.5)',
          }}
        />
      </Sidebar>
    );
  }

  renderLoggedOut() {
    const path = this.props.location.pathname;
    return (
      <Menu pointing vertical fixed="left">
        <Image src={logo} />
        <br />
        <Menu.Menu>
          <Menu.Item name="Home" as={Link} to="/" active={path === '/'} />
          <Menu.Item name="About" as={Link} to="/about" active={path.startsWith('/about')}>
            About
          </Menu.Item>
          <Menu.Item name="Login" as={Link} to="/login" active={path.startsWith('/login')}>
            Login
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }

  render() {
    if (this.props.isLoggedIn) {
      return this.renderLoggedIn();
    }
    return this.renderLoggedOut();
  }
}
