import { all } from 'redux-saga/effects';
import { authSaga } from './authSaga';
import { foldersSaga } from './foldersSaga';
import { contentSaga } from './contentSaga';
import { mediaLibrarySaga } from './mediaLibrarySaga';
import { languageSaga } from './languageSaga';
import { usersSaga } from './usersSaga';
import { blocksSaga } from './blocksSaga';
import { compositionsSaga } from './compositionsSaga';
import { publisherSaga } from './publisherSaga';

export default function* rootSaga() {
  yield all([
    contentSaga(),
    mediaLibrarySaga(),
    blocksSaga(),
    compositionsSaga(),
    foldersSaga(),
    authSaga(),
    languageSaga(),
    usersSaga(),
    publisherSaga(),
  ]);
}
