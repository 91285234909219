import actionCreatorFactory from 'typescript-fsa';
import { BlockCreation, Block, BlockList } from '@visikon/core-models/content';
import { FetchState } from 'reducers/blocksReducer';

const actionCreator = actionCreatorFactory('MediaLibrary');

export const setLoadingStatus = actionCreator<boolean>('SET_LOADING_STATUS');

export const createBlock = actionCreator<BlockCreation>('CREATE_BLOCK');
export const createBlockSuccessful = actionCreator<Block>('CREATE_BLOCK_SUCCESS');

export const updateBlock = actionCreator<BlockCreation>('UPDATE_BLOCK');
export const updateBlockSuccessful = actionCreator<Block>('UPDATE_BLOCK_SUCCESSFUL');

export const listBlocks = actionCreator('LIST_BLOCKS');
export const listBlocksSuccessful = actionCreator<Block[]>('LIST_BLOCKS_SUCCESSFUL');
export const setBlocksFetchState = actionCreator<FetchState>('SET_BLOCKS_FETCH_STATE');

// Add new/create block through composer
export const addNewBlockToComposer = actionCreator<BlockCreation>('ADD_NEW_BLOCK_TO_COMPOSER');
export const addNewBlockToComposerSuccessful = actionCreator<string>('ADD_NEW_BLOCK_TO_COMPOSER_SUCCESSFUL');
export const addNewBlockToComposerError = actionCreator('ADD_NEW_BLOCK_TO_COMPOSER_ERROR');
export const addNewBlockToComposerClear = actionCreator('ADD_NEW_BLOCK_TO_COMPOSER_CLEAR');

// Actions to update block state in reducer
export const updateBlockInState = actionCreator<Block>('UPDATE_BLOCK_IN_STATE');

export const saveComposition = actionCreator<Partial<BlockList>>('SAVE_COMPOSITION');
export const saveCompositionStarted = actionCreator('SAVE_COMPOSITION_STARTED');
export const saveCompositionSuccessful = actionCreator<BlockList>('SAVE_COMPOSITION_SUCCESSFUL');
export const saveCompositionFailed = actionCreator<Error>('SAVE_COMPOSITION_FAILED');
export const saveCompositionClear = actionCreator('SAVE_COMPOSITION_CLEAR');

export const generateThumbnail = actionCreator<{ id: string; thumbnailSrc: string }>('GENERATE_THUMBNAIL');
export const generateThumbnailSuccess = actionCreator('GENERATE_THUMBNAIL_SUCCESS');
export const thumbnailApiError = actionCreator<{ msg: string }>('THUMBNAIL_API_ERROR');
