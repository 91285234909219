import axios from 'axios';
import { UploadTypes, Content } from '@visikon/core-models';
import { API } from 'config';
import { APIUtils } from 'commons/utils';
import { ObjectId } from '@visikon/core-models/base';

/*
 * Images
 */
export const createImage = (token: string, image: UploadTypes.Image) => {
  const url = new URL('./media/image', API.baseURL);
  return axios.post(url.toString(), image, APIUtils.tokenHeader(token));
};

export const uploadImageFile = (token: string, id: ObjectId, lang: Content.LanguageCode, variation: ObjectId, file: File) => {
  const url = new URL(`./media/imageFile/${id}/${lang}/${variation}`, API.baseURL);
  const headers = {
    'Content-Type': 'multipart/form-data',
    'upload-mime': file.type,
    'upload-name': file.name,
    'upload-size': file.size,
  };
  return axios.post(url.toString(), file, APIUtils.tokenHeader(token, headers));
};

export const loadImage = (token: string, imageId: string) => {
  const url = new URL(`./media/image/${imageId}`, API.baseURL);
  return axios.get(url.toString(), APIUtils.tokenHeader(token));
};

export const listImagesInFolder = (token: string, folderId: string) => {
  const url = new URL(`./media/image/list/${folderId}`, API.baseURL);
  return axios.get(url.toString(), APIUtils.tokenHeader(token));
};

export const updateImage = (token: string, image: Content.Image) => {
  const url = new URL(`./media/image/${image._id}`, API.baseURL);
  return axios.post(url.toString(), image, APIUtils.tokenHeader(token));
};

/*
 * Videos
 */
export const createVideo = (token: string, video: UploadTypes.Video) => {
  const url = new URL('./media/video', API.baseURL);
  return axios.post(url.toString(), video, APIUtils.tokenHeader(token));
};

export const uploadVideoFile = (token: string, id: ObjectId, lang: Content.LanguageCode, variation: ObjectId, file: File, duration: number) => {
  const url = new URL(`./media/videoFile/${id}/${lang}/${variation}`, API.baseURL);
  const headers = {
    'Content-Type': 'multipart/form-data',
    'upload-mime': file.type,
    'upload-name': file.name,
    'upload-size': file.size,
    'upload-length': duration,
  };
  return axios.post(url.toString(), file, APIUtils.tokenHeader(token, headers));
};

export const uploadVideoThumbnail = (token: string, id: ObjectId, lang: Content.LanguageCode, variation: ObjectId, file: File) => {
  const url = new URL(`./media/videoThumbnail/${id}/${lang}/${variation}`, API.baseURL);
  const headers = {
    'Content-Type': 'multipart/form-data',
    'upload-mime': file.type,
    'upload-name': file.name,
    'upload-size': file.size,
  };
  return axios.post(url.toString(), file, APIUtils.tokenHeader(token, headers));
};

export const uploadVideoSubtitles = (token: string, id: ObjectId, lang: Content.LanguageCode, variation: ObjectId, file: File) => {
  const url = new URL(`./media/subtitles/${id}/${lang}/${variation}`, API.baseURL);
  const headers = {
    'Content-Type': 'multipart/form-data',
    'upload-mime': file.type,
    'upload-name': file.name,
    'upload-size': file.size,
  };
  return axios.post(url.toString(), file, APIUtils.tokenHeader(token, headers));
};

export const uploadBlockListThumbnail = (token: string, id: ObjectId, lang: Content.LanguageCode, file: File) => {
  const url = new URL(`./blockListThumbnail/${id}/${lang}`, API.baseURL);
  const headers = {
    'Content-Type': 'multipart/form-data',
    'upload-mime': file.type,
    'upload-name': file.name,
    'upload-size': file.size,
  };
  return axios.post(url.toString(), file, APIUtils.tokenHeader(token, headers));
};

export const uploadThumbnail = (token: string, name: string, file: File) => {
  const url = new URL(`./media/thumbnail`, API.baseURL);
  const headers = {
    'Content-Type': 'multipart/form-data',
    'upload-name': name,
  };
  return axios.post(url.toString(), file, APIUtils.tokenHeader(token, headers));
};

export const loadVideo = (token: string, videoId: string) => {
  const url = new URL(`./media/video/${videoId}`, API.baseURL);
  return axios.get(url.toString(), APIUtils.tokenHeader(token));
};

export const listVideosInFolder = (token: string, folderId: string) => {
  const url = new URL(`./media/video/list/${folderId}`, API.baseURL);
  return axios.get(url.toString(), APIUtils.tokenHeader(token));
};

export const updateVideo = (token: string, video: Content.Video) => {
  const url = new URL(`./media/video/${video._id}`, API.baseURL);
  return axios.post(url.toString(), video, APIUtils.tokenHeader(token));
};

/*
 * Media variations
 */
export const listMediaVariations = (token: string) => {
  const url = new URL('./media/variation', API.baseURL);
  return axios.get(url.toString(), APIUtils.tokenHeader(token));
};

export const createMediaVariation = (token: string, mediaVariation: UploadTypes.MediaVariation) => {
  const url = new URL('./media/variation', API.baseURL);
  return axios.post(url.toString(), mediaVariation, APIUtils.tokenHeader(token));
};

/*
 * Texts
 */
export const saveText = (token: string, text: UploadTypes.Text) => {
  const url = new URL('./media/text', API.baseURL);
  return axios.post(url.toString(), text, APIUtils.tokenHeader(token));
};
export const updateText = (token: string, text: Content.Text) => {
  const url = new URL(`./media/text/${text._id}`, API.baseURL);
  return axios.post(url.toString(), text, APIUtils.tokenHeader(token));
};

export const listTexts = (token: string) => {
  const url = new URL('./media/text', API.baseURL);
  return axios.get(url.toString(), APIUtils.tokenHeader(token));
};

export const loadText = (token: string, id: ObjectId) => {
  const url = new URL(`./media/text/${id}`, API.baseURL);
  return axios.get(url.toString(), APIUtils.tokenHeader(token));
};
