import { Archiveable } from "@visikon/core-models/content";
import { CSSProperties, useState } from 'react';
import { Button, Confirm } from "semantic-ui-react";

export function ArchiveButton({ entry, onToggle, dontShowModal, style }: { entry: Archiveable, onToggle: (entry: boolean) => void, dontShowModal?: boolean, style?: CSSProperties }) {
  const [modalVisible, setModalVisible] = useState(false);
  // eslint-disable-next-line no-param-reassign
  const archived = entry?.archived;

  const toggleEntry = () => {
    onToggle(!archived);
    setModalVisible(false);
  }

  return (
    <>
      <Confirm
        open={modalVisible}
        content={`Are you sure you want to ${archived ? 're-activate' : 'archive'} this item?`}
        confirmButton="Yes"
        onCancel={() => setModalVisible(false)}
        onConfirm={toggleEntry}
      />
      <Button
        icon
        positive={archived}
        negative={!archived}
        title={`${archived ? 'Re-activate' : 'Archive'} item`}
        style={{ margin: '16px 16px 0 0', position: 'absolute', right: '4px', ...style }}
        onClick={() => {
          if (dontShowModal) {
            toggleEntry();
          } else {
            setModalVisible(true);
          }
        }
        }
      >
        <i className={`${archived ? 'check' : 'trash alternate outline'} icon`} />
      </Button>
    </>
  );
}
