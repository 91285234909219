import { useState, useEffect } from 'react';
import { Accordion, Icon, Form, TextArea } from 'semantic-ui-react';

interface IProps {
  title: string;
  placeholder?: string;
  content?: string;
  shouldExpand: boolean;
  style?: React.CSSProperties;
  sendWrittenNotes(notes: string): void;
}

export const VariationComments = ({ content, title, shouldExpand, style, placeholder, sendWrittenNotes }: IProps) => {
  const [open, setOpen] = useState(false);
  const [fieldText, setFieldText] = useState(content);
  const [typing, setTyping] = useState(false);

  useEffect(() => {
    if (content) setFieldText(content);
    if (shouldExpand && content) setOpen(true);
  }, [shouldExpand, content]);

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      if (fieldText !== undefined && typing) sendWrittenNotes(fieldText);
      setTyping(false);
    }, 1000);

    return () => clearTimeout(typingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldText]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Accordion styled style={style}>
      <Accordion.Title active={open} index={0} onClick={handleClick}>
        <Icon name="dropdown" />
        {title}
      </Accordion.Title>
      <Accordion.Content active={open}>
        <Form>
          <TextArea
            rows={2}
            placeholder={placeholder}
            value={fieldText}
            onChange={(e) => {
              setTyping(true);
              setFieldText(e.target.value as string);
            }}
          />
        </Form>
      </Accordion.Content>
    </Accordion>
  );
};
