import { Block, LanguageCode } from '@visikon/core-models/content';
import { useToggle } from 'commons/toggle';
import NewWindow from 'react-new-window';
import * as Semantic from 'semantic-ui-react';
import { ManusExportButton } from './ManusExport.button';
import { IManusPart, IManusPrint, ManusPrint as ManusPrintModal } from './ManusPrint.modal';
import { matchLanguageOrFallback } from '@visikon/core-models/helpers/language-fallback';
import { CompositionBlock } from '@visikon/core-models/models/composer/blocks';

type Props = {
  name: string;
  selectedBlocks: CompositionBlock[];
  allBlocks: Block[];
  language: LanguageCode;
};

const modalSize = {
  width: Math.min(window.innerWidth - 200, 1200),
  height: Math.min(window.innerHeight - 150, 800),
};

export function ManusPrintButton({ name, selectedBlocks, allBlocks, language }: Props) {
  const [modalVisible, toggleModal] = useToggle();

  const openPrintWindow = (win: Window) => {
    setTimeout(() => {
      win.print();
    }, 200);
  };

  const formatPrintData = (): IManusPrint => {
    const manus = selectedBlocks
      .map((b) => {
        const block = allBlocks.find((bl) => bl._id === b.blockId);
        if (block === undefined) {
          return undefined;
        }
        const speak = matchLanguageOrFallback(block.speaks, language, b.speakVariation);
        if (speak === undefined) {
          return undefined;
        }
        const video = matchLanguageOrFallback(block.videos, language, b.videoVariation);
        let duration = 0;
        if (video && video.videoFile) {
          duration = video.videoFile.duration;
        }
        return {
          text: speak.text,
          status: speak.status,
          variation: b.speakVariation,
          name: block.name,
          duration,
        };
      })
      .filter((m) => m !== undefined) as IManusPart[]; // No undefined here...

    return {
      name,
      language,
      manus,
    };
  };

  return (
    <>
      {modalVisible && (
        <NewWindow onOpen={openPrintWindow} onUnload={toggleModal} features={modalSize} center="screen" title={name}>
          <ManusPrintModal data={formatPrintData()} />
        </NewWindow>
      )}
      <div style={{ position: 'absolute', top: -8, right: -14 }}>
        <ManusExportButton {...{ name, selectedBlocks, allBlocks, language }} />
        <Semantic.Button basic size="mini" onClick={toggleModal}>
          <Semantic.Icon name="print" />
          Print
        </Semantic.Button>
      </div>
    </>
  );
}
