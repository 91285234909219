import { Action } from 'typescript-fsa';
import { AxiosResponse } from 'axios';
import * as FolderActions from 'actions/FolderActions';
import * as FolderAPI from 'api/folderApi';
import {
  call, put, takeEvery, select,
} from 'redux-saga/effects';
import { getToken, handleAuthorizationError } from './saga-helpers';

function* listFolders(action: Action<{ id?: string }>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const folderId = action.payload.id || 'root';
    const folders: AxiosResponse = yield call(FolderAPI.getFolderTree, token, folderId);
    yield put(FolderActions.listFoldersSuccess(folders.data));
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(FolderActions.listFoldersError({ msg: 'Could not load folders' }));
  }
}

export function* foldersSaga() {
  yield takeEvery(FolderActions.listFolders, listFolders);
}
