import React from 'react';
import { Tab } from 'semantic-ui-react';
import { CustomTypeListContainer } from 'containers/content/CustomTypeListContainer';
import { MapStateToProps, connect } from 'react-redux';
import { IState } from 'reducers/reducers';
import { ContentContainerKey } from '@visikon/core-models/models/content/content-containers';

interface PaneAccess {
  desc: string;
  name: string;
}
const namespaceToPanes: { [key: string]: PaneAccess[] } = {
  mitforlob: [
    { desc: 'Video Playlist', name: 'videoList' },
    { desc: 'Document', name: 'document' },
    { desc: 'FAQ', name: 'faq' },
    { desc: 'Symptom Guide', name: 'symptom' },
    { desc: 'Notifications', name: 'notifications' },
    { desc: 'Survey', name: 'survey' },
    { desc: 'Content Containers', name: ContentContainerKey },
  ],
  novo: [
    { desc: 'Video Playlist', name: 'videoList' },
    { desc: 'Document', name: 'document' },
    { desc: 'Wiki Content', name: 'wikiContent' },
    { desc: 'Wiki Entry', name: 'wikiEntry' },
    { desc: 'Wiki Link', name: 'wikiLink' },
    { desc: 'String sequence', name: 'stringSequence' },
    { desc: 'Drug list', name: 'drugList' },
  ],
  safedelivery: [
    { desc: 'Video Playlist', name: 'videoList' },
    { desc: 'Document', name: 'document' },
    { desc: 'Drug list', name: 'drugList' },
    { desc: 'Notifications', name: 'notifications' },
  ],
};

interface IPropsFromState {
  namespace?: string;
}

interface IOwnProps {
  onPick(id: string, type: string): void;
}

type IProps = IPropsFromState & IOwnProps;

class CCustomTypePicker extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.handleOnPick = this.handleOnPick.bind(this);
  }

  handleOnPick(type: string) {
    return (id: string) => {
      this.props.onPick(id, type);
    };
  }

  renderPane(customTypeName: string) {
    // eslint-disable-next-line react/no-unstable-nested-components
    return () => (
      <Tab.Pane>
        <CustomTypeListContainer name={customTypeName} onPick={this.handleOnPick(customTypeName)} />
      </Tab.Pane>
    );
  }

  render() {
    if (this.props.namespace === undefined) {
      return null;
    }

    const panes = namespaceToPanes[this.props.namespace] || [];
    const panesRendered = panes.map((p) => ({ menuItem: p.desc, render: this.renderPane(p.name) }));
    return <Tab panes={panesRendered} />;
  }
}

const mapStateToProps: MapStateToProps<IPropsFromState, IOwnProps, IState> = (state) => ({
  namespace: state.auth.namespace || 'N/A',
});

export const CustomTypePicker = connect(mapStateToProps)(CCustomTypePicker);
