import { LanguageCode } from '@visikon/core-models/content';
import { DropdownItemProps, Flag } from 'semantic-ui-react';
import { langFromCode } from '../commons/languages';

export const Flags = ({ languageCodes }: { languageCodes: { language: LanguageCode }[] | undefined }) => {
  if (languageCodes === undefined) {
    return <></>;
  }

  const flagName = (flag: any) => (flag === 'N/A' ? '' : flag);

  // eslint-disable-next-line react/prop-types
  const stringValue = (props: DropdownItemProps) => (props.value as string) || '';

  const flags = languageCodes
    .map((t) => t.language)
    .map((t) => langFromCode(t))
    .sort((l1, l2) => stringValue(l1).localeCompare(stringValue(l2)))
    .map((l) => <Flag key={l.flag as string} name={flagName(l.flag)} />);

  return <>{flags}</>;
};
