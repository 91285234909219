import { Action } from 'typescript-fsa';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as PublisherActions from 'actions/publisherAction';
import * as PublishedApi from 'api/publisherApi';
import * as ContentActions from 'actions/ContentActions';
import * as ContentApi from 'api/contentApi';
import { AxiosResponse } from 'axios';
import { getToken, handleAuthorizationError } from './saga-helpers';

type PublisherApiOperation = typeof PublishedApi.publishContent | typeof PublishedApi.toggleDisabled | typeof PublishedApi.toggleApproved;
type PublisherSuccessAction = typeof PublisherActions.publishContentSuccessful | typeof PublisherActions.toggleDisableSuccess | typeof PublisherActions.toggleApprovedSuccess;

function* publishProgram(action: Action<{ id: string }>) {
  yield applyAction(PublishedApi.publishContent, PublisherActions.publishContentSuccessful, action.payload.id);
}

function* toggleDisabled(action: Action<{ id: string; originalId: string }>) {
  yield applyAction(PublishedApi.toggleDisabled, PublisherActions.toggleDisableSuccess, action.payload.originalId, action.payload.id);
}

function* toggleApproved(action: Action<{ id: string; originalId: string }>) {
  yield applyAction(PublishedApi.toggleApproved, PublisherActions.toggleApprovedSuccess, action.payload.originalId, action.payload.id);
}

function* applyAction(apiOperation: PublisherApiOperation, successAction: PublisherSuccessAction, programId: string, revisionId?: string) {
  try {
    // @ts-ignore
    const token = yield select(getToken);

    const response: AxiosResponse = revisionId
      ? yield call(apiOperation, token, revisionId)
      : yield call(apiOperation, token, programId);

    if (response.status === 200) {
      yield put(successAction());

      const getAll: AxiosResponse = yield call(PublishedApi.getAllPublishedVersion, token, programId);
      yield put(PublisherActions.getALlPublishedVersioSuccess(getAll.data));

      const getEntry: AxiosResponse = yield call(ContentApi.loadEntry, token, 'mitforlobPack', programId);
      yield put(ContentActions.loadContentEntrySuccess(getEntry.data));

      yield put(PublisherActions.clearCreateSuccess());
    } else {
      yield put(PublisherActions.publishedApiError({ msg: response.data }));
    }
  } catch (error) {
    yield handleAuthorizationError(error.response);

    if (error.response.data?.error && error.response.data?.payload) {
      yield put(PublisherActions.publishedApiError({ msg: error.response.data.error, payload: error.response.data.payload }));
    } else {
      yield put(PublisherActions.publishedApiError({ msg: error.message }));
    }
  }
}

function* getAllRevisions(action: Action<string>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const id = action.payload;
    const response: AxiosResponse = yield call(PublishedApi.getAllPublishedVersion, token, id);

    if (response.status === 200) {
      const result: PublisherActions.IPublishedContent[] = response.data;
      yield put(PublisherActions.getALlPublishedVersioSuccess(result));
    } else {
      yield put(PublisherActions.publishedApiError({ msg: response.data }));
    }
  } catch (error) {
    yield handleAuthorizationError(error.response);
    yield put(PublisherActions.publishedApiError({ msg: error.message }));
  }
}

export function* publisherSaga() {
  yield takeEvery(PublisherActions.getALlPublishedVersionById, getAllRevisions);
  yield takeEvery(PublisherActions.publishContent, publishProgram);
  yield takeEvery(PublisherActions.toggleDisable, toggleDisabled);
  yield takeEvery(PublisherActions.toggleApproved, toggleApproved);
}
