import { Blueprint } from "@visikon/core-models/encoding";
import { useToggle } from "commons/toggle";
import * as Semantic from 'semantic-ui-react';
import { EncodeNewDialog } from "./EncodeNew.modal";

type Props = {
    blueprint: Blueprint,
    disabled: boolean,
};

export function EncodeNewMenuItem({ blueprint, disabled }: Props) {
    const [modalVisible, toggleModal] = useToggle();

    return (
        <>
            {modalVisible && (
                <EncodeNewDialog
                    open
                    onDismiss={toggleModal}
                    blueprint={blueprint}
                />
            )}
            <Semantic.Dropdown.Item
                icon="cogs"
                text="Encode"
                disabled={disabled}
                onClick={toggleModal}
            />
        </>
    );
}
