import { PLACEHOLDER_VIDEO } from 'commons/utils';
import { TagList } from 'components/tags/TagList';
import { Content } from '@visikon/core-models';
import { Block, BlockSpeak } from '@visikon/core-models/content';
import { Card, Icon, Image, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import { getBlockVideoUrl } from '../../blockResourceSelectors';
import { getTranslationFromArray } from '@visikon/core-models/typeUtils';

interface IProps {
  block: Block;
  lang: Content.LanguageCode;
  onClick(id: string): void;
}

const VideoPreview = styled.video`
    width: 290px;
    height: auto;
`;

const AudioWarning = styled.div`
    position: absolute;
    div {
        span {
            font-weight: 900;
        }
        i.icon {
            margin: 0 0 0 .5em !important;
        }
    }
`;

const placeholderStyle = {
  width: 290,
  height: 163,
  objectFit: 'cover',
};

const MissingMediaWarning = ({ block }: { block: Block }) => {
  const warnMissingAudio = block.speaks.filter((s) => s.audioFile === undefined).length > 0;
  const warnMissingVideo = block.videos.filter((s) => s.videoFile === undefined).length > 0;
  if (!warnMissingAudio && !warnMissingVideo) {
    return null;
  }

  return (
    <AudioWarning>
      <Label color="yellow">
        <span>Warning:</span>
        {warnMissingAudio && <Icon name="microphone" />}
        {warnMissingVideo && <Icon name="film" />}
      </Label>
    </AudioWarning>
  );
};

export const BlockCard = ({ block, lang, onClick }: IProps) => {
  const speak = getTranslationFromArray(block.speaks, lang);
  const video = getTranslationFromArray(block.videos, lang, (v) => !!v.videoFile, true);

  const previewUrl = (video && video.videoFile) ? getBlockVideoUrl(block, video.language, video.variations[0]) : undefined;
  const placeholderImgExists = (video && video.videoFile) && video.videoFile.contentType.split('/').includes('image');

  const showPlaceholder = () => {
    if (!previewUrl) return <Image src={PLACEHOLDER_VIDEO} style={placeholderStyle} />;
    if (!placeholderImgExists) return <VideoPreview src={previewUrl} />;
    return <Image src={previewUrl} style={placeholderStyle} />;
  };

  function renderSpeak(s: BlockSpeak | undefined) {
    if (!s) {
      return '';
    }
    if ((s.text || '').length > 150) {
      const textBeginning = (s.text || '').substr(0, 147);
      return `${textBeginning}...`;
    }

    return s.text;
  }

  return (
    <Card onClick={() => onClick(block._id)} style={{ position: 'relative' }}>
      {showPlaceholder()}
      <MissingMediaWarning block={block} />
      <Card.Content>
        <Card.Header>{block.name}</Card.Header>
        <Card.Meta>
          <span className="date">{new Date(block.createdAt).toISOString().slice(0, 10)}</span>
        </Card.Meta>
        <Card.Description>{renderSpeak(speak)}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <TagList tagList={block.tags} readOnly />
      </Card.Content>
    </Card>
  );
};
