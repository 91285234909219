import { Content } from '@visikon/core-models';
import { ObjectId } from '@visikon/core-models/base';
import { LanguageCode, Video } from '@visikon/core-models/content';
import { createVideo, updateVideo, uploadVideoFile, uploadVideoThumbnail } from 'actions/MediaActions';
import { useListVideos, useVideo } from 'api/videoLibraryApi';
import { PAGE_SIZE, SearchCategories } from 'components/mediaLibrary/constants';
import { VideoCreateDialog } from 'components/mediaLibrary/videos/VideoCreateDialog';
import { VideoDetails } from 'components/mediaLibrary/videos/VideoDetails';
import { SearchPagination } from 'components/search/search-pagination';
import { Spinner } from 'components/spinner';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
// import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { IState } from 'reducers/reducers';
import { languageState } from 'redux-recoil-sync.state';
import { Checkbox } from 'semantic-ui-react';
import { VideoContainer } from './VideoContainer';

const FOLDER_ID = 'root';

interface IProps {
  onlyPicker?: boolean;
  onVideoPick?: (video: Video) => void;
}

export function VideoLibraryContainer({ onVideoPick, onlyPicker = false }: IProps) {
  const { videoId } = useParams<{ videoId?: string }>();
  const [page, setPage] = useState(1); // start on page 1
  const [nameSearch, setNameSearch] = useState(true); // use tags search
  const [searchTerm, setSearchTerm] = useState<any>();
  const [showArchived, setShowArchived] = useState(false);

  const isUploading: boolean = useSelector((state: IState) => state.media.isUploading);
  const mediaVariants: Content.MediaVariation[] = useSelector((state: IState) => state.media.mediaVariations);
  const updateVideoSuccess = useSelector((state: IState) => state.media.updateVideoSuccess);
  const [language] = useRecoilState(languageState);

  // parameters to useQuery
  const nameParam = nameSearch ? searchTerm : null;
  const tagsParam = !nameSearch ? searchTerm : null;
  const { data, ...useQuery } = useListVideos(page, PAGE_SIZE, nameParam, tagsParam, showArchived);
  const detailedVideo = useVideo(videoId ?? 'placeholder', videoId !== undefined);

  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (updateVideoSuccess !== undefined) {
      queryClient.invalidateQueries(['listVideos']);
      queryClient.invalidateQueries(['video', videoId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateVideoSuccess]);

  const videos = data
    ? data.entries.map((vid: Video) => (
        <VideoContainer
          readOnly={onlyPicker}
          onClick={() => {
            if (!onlyPicker) {
              history.push(`/media/videos/${vid._id}`);
            } else if (onVideoPick) {
              onVideoPick(vid);
            }
          }}
          key={vid._id}
          video={vid}
        />
      ))
    : [];

  const videoContainer =
    useQuery.isFetching || detailedVideo.isLoading ? (
      <Spinner visible />
    ) : (
      <div className="ui cards" style={{ maxWidth: 1400 }}>
        {videos}
      </div>
    );

  return (
    <div>
      <h1>Video Library</h1>

      <div style={{ display: 'flex' }}>
        <div style={{ minWidth: 200 }}>
          <VideoCreateDialog
            isUploading={isUploading}
            onCreate={(name, tags, file, duration) => {
              dispatch(createVideo({ name, tags, file, duration, folder: FOLDER_ID }));
            }}
          />
        </div>
        <div style={{ flexGrow: 1, marginLeft: 10 }}>
          <Checkbox
            label="Show archive"
            checked={showArchived}
            onChange={() => setShowArchived(showArchived ? !showArchived : true)}
            style={{ marginBottom: 10 }}
            toggle
          />
          <div style={{ display: 'flex' }}>
            <div style={{ marginBottom: 30, width: 900 }}>
              <SearchPagination
                categories={[SearchCategories.Names, SearchCategories.Tags]}
                onCategoryChange={(newCategory) => {
                  setPage(1);
                  setNameSearch(newCategory === SearchCategories.Names);
                }}
                onSearch={(newSearchTerm) => {
                  setPage(1);
                  setSearchTerm(newSearchTerm);
                }}
                activePage={page}
                totalPages={data ? data.pages : 0}
                onPageChange={(pageNumber) => {
                  setPage(pageNumber);
                }}
              />
              {!useQuery.isFetching ? <div>Found {`${data?.count} videos - Showing ${data?.entries.length} `}</div> : null}
            </div>
          </div>

          {videoContainer}
        </div>
      </div>
      {detailedVideo.data && (
        <VideoDetails
          video={detailedVideo.data}
          language={language}
          mediaVariations={mediaVariants}
          onDismiss={() => history.push(`/media/videos`)}
          onUploadTranslation={(languageCode: Content.LanguageCode, variation: ObjectId, file: File, duration: number) =>
            dispatch(uploadVideoFile({ video: detailedVideo.data, language: languageCode, variation, file, duration }))
          }
          onUploadThumbnail={(languageCode: LanguageCode, variation: string, file: any): void => {
            dispatch(uploadVideoThumbnail({ video: detailedVideo.data, language: languageCode, variation, file }));
          }}
          isUploading={isUploading}
          toggleArchiveVideo={(toggleState: boolean, activeVideo: Content.Video) => {
            const copy = JSON.parse(JSON.stringify(activeVideo));
            copy.archived = toggleState;
            dispatch(updateVideo(copy));
          }}
        />
      )}
    </div>
  );
}
