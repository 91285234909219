import { ArrayUtils } from 'commons/utils';
import { DraggableList } from 'components/dragNdrop/DraggableList';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, List, Modal } from 'semantic-ui-react';
import { CustomTypeAnyListItem } from '../CustomTypeAnyListItem';
import { CustomTypeValue, ICommonBaseProps } from '../CustomTypeInput';
import { CustomTypePicker } from '../CustomTypePicker';

export interface IParametersForAnyList {
  type: 'anyList';
  desc: string;
}

export interface IComponentAnyListInputProps extends ICommonBaseProps, IParametersForAnyList {
  value: CustomTypeValue[];
  onChange(key: string, val: CustomTypeValue[]): void;
}

interface IState {
  isPickerOpen: boolean;
  fieldName: string;
}
export class AnyListTypeInput extends React.Component<IComponentAnyListInputProps, IState> {
  styles: { [key: string]: React.CSSProperties } = {
    marginStyle: {
      marginLeft: 20,
      marginTop: 20,
    },
    removeButtonStyle: {
      flex: '0 0 auto',
      alignSelf: 'center',
      marginLeft: 10,
    },
    itemStyle: {
      flex: '1 0 auto',
      paddingRight: 10,
    },
  };

  constructor(props: IComponentAnyListInputProps) {
    super(props);

    // eslint-disable-next-line react/no-unused-state
    this.state = { isPickerOpen: false, fieldName: '' };

    this.renderItem = this.renderItem.bind(this);
    this.renderRemoveButton = this.renderRemoveButton.bind(this);
    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.reorderRows = this.reorderRows.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    this.dismissPicker = this.dismissPicker.bind(this);
  }

  renderRemoveButton(id: string) {
    return <Button negative icon="minus circle" onClick={() => this.removeRow(id)} />;
  }

  renderItem(item: CustomTypeValue) {
    return {
      id: item._idRef,
      element: (
        <React.Fragment key={item._idRef}>
          <List.Item style={{ display: 'flex', padding: 10, alignItems: 'center' }}>
            <Button as={Link} to={`/content/${item.type}/${item._idRef}`} icon="arrow right" content="Go to resource" labelPosition="right" />
            <CustomTypeAnyListItem id={item._idRef} type={item.type} />
            <div style={this.styles.removeButtonStyle}>{this.renderRemoveButton(item._idRef)}</div>
          </List.Item>
        </React.Fragment>
      ),
    };
  }

  reorderRows(from: number, to: number) {
    const result = ArrayUtils.arrayMove(this.props.value, from, to) as string[];
    this.props.onChange(this.props.pKey, result);
  }

  addRow(id: string, type: string) {
    const newEntry = { _idRef: id, type };
    const valueCopy = [...(this.props.value || []), newEntry];
    this.props.onChange(this.props.pKey, valueCopy);
    this.setState({ isPickerOpen: false });
  }

  removeRow(id: string) {
    const valueCopy = this.props.value.filter((v) => v._idRef !== id);
    this.props.onChange(this.props.pKey, valueCopy);
  }

  dismissPicker() {
    this.setState({ isPickerOpen: false });
  }

  render() {
    if (this.props.isTranslation) {
      return null;
    }

    const items = (this.props.value || []).map((key) => this.renderItem(key));
    return (
      <>
        <h4>{this.props.desc}</h4>
        {/* <List style={this.styles.marginStyle} relaxed={true}>
                    {this.props.value && this.props.value.map(this.renderItem)}
                </List> */}
        <DraggableList onReorder={this.reorderRows} items={items} />
        <Button
          style={this.styles.marginStyle}
          content="Add item"
          icon="add"
          onClick={() => {
            this.setState({ isPickerOpen: true });
          }}
        />

        <Modal open={this.state.isPickerOpen} onClose={this.dismissPicker} size="fullscreen">
          <Modal.Content>
            <CustomTypePicker onPick={this.addRow} />
          </Modal.Content>
        </Modal>
      </>
    );
  }
}
