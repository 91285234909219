import { Content } from '@visikon/core-models';
import React from 'react';
import {
  Button, Input, Modal, Popup, Image,
} from 'semantic-ui-react';
import { TagList } from '../../tags/TagList';
import { FilePicker } from '../../FilePicker';

interface IProps {
  isUploading: boolean;
  onCreate: (name: string, tags: Content.Tag[], file: File) => void;
}

interface IState {
  modalShown: boolean;
  name: string;
  tags: Content.Tag[];
  fileChosen?: File;
  imageData?: string;
}

const initialState: IState = {
  modalShown: false,
  name: '',
  tags: [],
  fileChosen: undefined,
  imageData: undefined,
};

export class ImageCreateDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = { ...initialState };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onFileChoosen = this.onFileChoosen.bind(this);
    this.onTagAdded = this.onTagAdded.bind(this);
    this.onTagRemoved = this.onTagRemoved.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onOpen = this.onOpen.bind(this);

    this.renderImageArea = this.renderImageArea.bind(this);
    this.renderEnabledSaveButton = this.renderEnabledSaveButton.bind(this);
    this.renderDisabledSaveButton = this.renderDisabledSaveButton.bind(this);
  }

  componentDidUpdate(prevProps: IProps) {
    if (!prevProps.isUploading && this.props.isUploading) {
      this.onClose();
    }
  }

  handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ name: event.target.value });
  }

  onTagAdded(tag: Content.Tag) {
    this.setState((prevState) => ({
      tags: [...prevState.tags, tag],
    }));
  }

  onTagRemoved(tag: Content.Tag) {
    this.setState((prevState) => ({
      tags: prevState.tags.filter((t) => t !== tag),
    }));
  }

  onFileChoosen(files: File[]) {
    const file = files[0];
    const reader = new FileReader();

    // If no name was chosen yet, use file name
    if (this.state.name.trim() === '') {
      this.setState({ name: file.name });
    }

    reader.onload = (e: any) => {
      const data = e.target.result;
      this.setState({ fileChosen: file, imageData: data });
    };

    reader.readAsDataURL(file);
  }

  onClose() {
    this.setState({ ...initialState });
  }

  onOpen() {
    this.setState({ modalShown: true });
  }

  onSave() {
    const { name, tags, fileChosen } = this.state;
    const isNameEmpty = name.trim() === '';
    const isTagListEmpty = tags.length === 0;
    const isFileEmpty = fileChosen === undefined;
    if (isNameEmpty || isTagListEmpty || isFileEmpty) {
      return;
    }

    this.props.onCreate(name, tags, fileChosen!);
  }

  // eslint-disable-next-line class-methods-use-this
  getDisabledExplanation(isFileEmpty: boolean, isNameEmpty: boolean, isTagListEmpty: boolean) {
    if (isFileEmpty) {
      return 'You need an image';
    }
    if (isNameEmpty && isTagListEmpty) {
      return 'Please add both a name and at least one tag';
    }
    if (isNameEmpty) {
      return 'Please enter a name';
    }
    return 'Please add at least one tag';
  }

  renderEnabledSaveButton() {
    return (
      <div style={{ display: 'inline-block' }}>
        <Button
          icon="save"
          content="Save Image"
          positive
          onClick={this.onSave}
        />
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderDisabledSaveButton(explanation: string) {
    return (
      <Popup
        content={explanation}
        wide
        position="top center"
        trigger={(
          <div style={{ display: 'inline-block' }}>
            <Button
              icon="save"
              content="Save Image"
              positive
              disabled
            />
          </div>
                  )}
      />
    );
  }

  renderImageArea() {
    const { fileChosen, imageData } = this.state;
    if (fileChosen) {
      return (<Image wrapped size="medium" src={imageData} />);
    }

    return (
      <div style={{ width: 300 }}>
        <FilePicker
          fileTypeText="image"
          accept="image/png,image/jpeg,image/gif"
          onFilePick={this.onFileChoosen}
          multiple={false}
        />
      </div>
    );
  }

  render() {
    const { name, tags, fileChosen } = this.state;
    const isNameEmpty = name.trim() === '';
    const isTagListEmpty = tags.length === 0;
    const isFileEmpty = fileChosen === undefined;
    const isSaveDisabled = isNameEmpty || isTagListEmpty || isFileEmpty;
    const explanation = this.getDisabledExplanation(isFileEmpty, isNameEmpty, isTagListEmpty);
    return (
      <Modal
        open={this.state.modalShown}
        onOpen={this.onOpen}
        onClose={this.onClose}
        trigger={<Button icon="add" content="New Image" positive />}
      >
        <Modal.Header>Create new image</Modal.Header>
        <Modal.Content image>
          {this.renderImageArea()}
          <Modal.Description>
            <div style={{ width: 400 }}>
              <Input size="large" fluid autoFocus label="Name" value={name} onChange={this.handleNameChange} />
            </div>
            <br />
            <TagList
              readOnly={false}
              onTagAdded={this.onTagAdded}
              onTagRemoved={this.onTagRemoved}
              tagList={this.state.tags}
              header="Tags"
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" onClick={this.onClose} />
          {!isSaveDisabled && this.renderEnabledSaveButton()}
          {isSaveDisabled && this.renderDisabledSaveButton(explanation)}
        </Modal.Actions>
      </Modal>
    );
  }
}
