import { useToggle } from 'commons/toggle';
import * as Semantic from 'semantic-ui-react';
import { CompositionCopyModal } from './Copy.modal';
import { NEW_RESOURCE_ID } from 'commons/utils';

type Props = {
  compositionId?: string;
  compositionName?: string;
  disabled: boolean;
  onSuccess?(blockListId: string): void;
};

export function CopyMenuItem({ compositionId, compositionName, disabled, onSuccess }: Props) {
  const [modalVisible, toggleModal] = useToggle();
  const validId = !!compositionId && compositionId !== NEW_RESOURCE_ID;

  return (
    <>
      {modalVisible && (
        <CompositionCopyModal compositionName={compositionName} compositionId={compositionId!} onClose={toggleModal} onSuccess={onSuccess} />
      )}
      <Semantic.Dropdown.Item icon="copy" text="Copy composition" disabled={disabled || !validId} onClick={toggleModal} />
    </>
  );
}
