import { API } from 'config';
import axios from 'axios';
import { APIUtils } from 'commons/utils';
import { useRecoilState } from 'recoil';
import { useQuery } from 'react-query';
import { ValidatedProgram } from '@visikon/core-models/publisher';
import { tokenState } from '../redux-recoil-sync.state';
import { URLS } from './urls';
import { QueryConfig } from './react-query-utils';

export const publishContent = (token: string, id: string) => {
  const url = new URL('./publish/Publishcontent', API.baseURL);
  return axios.post(url.toString(), { id }, APIUtils.tokenHeader(token));
};

export const getAllPublishedVersion = (token: string, id: string) => {
  const url = new URL(`./publish/listAllVersion/${id}`, API.baseURL);
  return axios.get(url.toString(), APIUtils.tokenHeader(token));
};

export const toggleDisabled = (token: string, id: string) => {
  const url = new URL('./publish/toggleDisable', API.baseURL);
  return axios.post(url.toString(), { id }, APIUtils.tokenHeader(token));
};

export const toggleApproved = (token: string, id: string) => {
  const url = new URL('./toggleApproved', API.baseURL);
  return axios.post(url.toString(), { id }, APIUtils.tokenHeader(token));
};

export function useGetVideosPreview(id: string | undefined) {
  const [token] = useRecoilState(tokenState);

  return useQuery<ValidatedProgram, Error>(['publisher/preview/videos', { id }], async () => {
    if(!id) { return {} as ValidatedProgram; }

    try {
      const response = await axios.get<ValidatedProgram>(URLS.publisher.validate(id), APIUtils.tokenHeader(token!))
      return response.data;
    } catch (e) {
      throw axios.isAxiosError(e) && e.response?.data.message
        ? new Error(e.response?.data.message)
        : e;
    }
  }, {
    ...QueryConfig,
    enabled: !!token && !!id,
    staleTime: 5000,
  });
}
