import { BlockPlaceholderChange, BlockVideoChange } from '@visikon/core-models/content';
import { useForceRender } from 'commons/useForceRender';
import useRefState from 'commons/useRefState';
import { useRef } from 'react';
import { Dropdown, Icon, Menu, Popup, Table } from 'semantic-ui-react';
import { VariationProps } from '../SharedVarationParts';
import { StatusPicker } from '../StatusPicker';
import { VariationComments } from '../VariationComments';
import { VariationDropDown } from '../VariationDropDown';
import { WorkFileList } from '../WorkFileList';
import * as Styles from './VideoVariation.styles';
import VideoVariationPreview from './VideoVariationPreview';
import { FileUploadButton } from '../FileUpload';
import { downloadFile } from 'commons/download-file';

type VideoVariationProps = VariationProps<BlockVideoChange & BlockPlaceholderChange, BlockVideoChange & BlockPlaceholderChange>;
export const VideoVariation = ({ variation, blockVariations, isActiveVariation, onChange, onRemove }: VideoVariationProps) => {
  const forceRender = useForceRender();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [video, setVideo, getVideo] = useRefState<VideoVariationProps['variation']>(variation);

  const handleFilePicked = async (files: File[]) => {
    const file = files[0];
    const videoTag = videoRef.current;

    if (file) {
      const fileType = file.type.split('/');
      if (videoTag && fileType.includes('video')) {
        videoTag.src = URL.createObjectURL(file);
        videoTag.onloadedmetadata = () => {
          handleVariationChanged({
            videoFile: {
              contentType: file.type,
              preview: (file as any).preview,
              size: file.size,
              file,
              duration: videoTag.duration,
            },
          });

          forceRender();
        };
      } else if (fileType.includes('image')) {
        handleVariationChanged({
          imageFile: {
            contentType: file.type,
            preview: (file as any).preview,
            size: file.size,
            file,
          },
        });
      }
    }
  };

  const handleVariationChanged = (videoChange: Partial<VideoVariationProps['variation']>) => {
    const newVideo = { ...getVideo(), ...videoChange };

    setVideo(newVideo);
    onChange(newVideo);
  };

  const handleRemoveVideo = () => {
    const modifiedVideo = { ...getVideo() };
    onRemove?.(modifiedVideo.key);
  };

  const hasVideo = video.existingSource || video.videoFile;

  return (
    <Styles.Container style={{ border: isActiveVariation ? '4px solid #9fcb34' : '' }}>
      <video width={0} height={0} ref={videoRef} />
      <Styles.Content>
        <Table compact basic="very">
          <Table.Body>
            <Table.Row>
              <Table.Cell style={{ width: 44 }}>
                <StatusPicker onChange={(status) => handleVariationChanged({ status })} initialValue={video.status} />
              </Table.Cell>
              <Table.Cell>
                <VariationDropDown
                  defaultValue={video.variations}
                  blockVariations={blockVariations}
                  onChange={(variations) => handleVariationChanged({ variations })}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row style={{ border: 0 }}>
              <Table.Cell colSpan={2}>
                <Menu>
                  <Dropdown item icon="bars" style={hasVideo ? {} : { color: 'red' }}>
                    <Dropdown.Menu>
                      <FileUploadButton text="Upload video" onFilePick={handleFilePicked} />
                      <DownloadButton video={video} />
                      <DeleteButton hasVideo={!!hasVideo} handleRemoveVideo={handleRemoveVideo} />
                    </Dropdown.Menu>
                  </Dropdown>

                  <WorkfilesButton fileNameForShow={video.fileNameForShow} video={video} />
                </Menu>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan={2}>
                <VideoVariationPreview video={video} />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Styles.Content>

      <VariationComments
        title="Comment"
        content={video.notes}
        sendWrittenNotes={(notes) => handleVariationChanged({ notes })}
        placeholder="Write here..."
        shouldExpand={isActiveVariation}
        style={{ margin: '10px', width: 'unset' }}
      />
    </Styles.Container>
  );
};

function WorkfilesButton({ fileNameForShow, video }: { fileNameForShow?: string; video: BlockVideoChange }) {
  return (
    <>
      {fileNameForShow && (
        <Popup
          trigger={
            <Menu.Item position="right">
              <Icon name="file" />
            </Menu.Item>
          }
          content={<WorkFileList video={video} />}
          header="Original filename"
          on="click"
          size="small"
          position="bottom right"
        />
      )}
    </>
  );
}

function DeleteButton({ hasVideo, handleRemoveVideo }: { hasVideo: boolean; handleRemoveVideo: () => void }) {
  const featureToggle = { disabled: false };

  return (
    <>
      {!featureToggle.disabled && hasVideo && (
        <>
          <Dropdown.Divider />
          <Dropdown.Item onClick={handleRemoveVideo}>
            <Icon name="trash" />
            <span className="text">Remove video</span>
          </Dropdown.Item>
        </>
      )}
    </>
  );
}

function DownloadButton({ video }: { video?: BlockVideoChange }) {
  const name = video?.fileNameForShow;
  const src = video && (video.videoFile ? video.videoFile.preview : video.existingSource);

  return (
    <>
      {src && name && (
        <>
          <Dropdown.Item onClick={() => downloadFile(src, name)}>
            <Icon name="download" />
            <span className="text">Download video</span>
          </Dropdown.Item>
        </>
      )}
    </>
  );
}
