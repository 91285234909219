import { Route, RouteComponentProps } from 'react-router-dom';
import { ImageLibraryContainer } from './ImageLibraryContainer';
import { TextLibraryContainer } from './TextLibraryContainer';
import { MediaVariations } from './MediaVariations';
import { TextEditorContainer } from './TextEditorContainer';
import { VideoLibraryContainer } from './VideoLibraryContainer';
import { ImageDetailsContainer } from './ImageDetailsContainer';

export const MediaRoot = (props: RouteComponentProps) => {
  const { match } = props;

  const renderMediaRoot = () => <h1>Media root</h1>;

  return (
    <>
      <Route path={match.url} exact render={renderMediaRoot} />
      <Route path="/media/videos/:videoId?" component={VideoLibraryContainer} />
      <Route path="/media/images" component={ImageLibraryContainer} />
      <Route path="/media/images/:id" component={ImageDetailsContainer} />
      <Route path="/media/texts" component={TextLibraryContainer} />
      <Route path="/media/textEdit/:id" component={TextEditorContainer} />
      <Route path="/media/variations" component={MediaVariations} />
    </>
  );
};
