import { Button, Form, InputOnChangeData, List, Modal, Table, Label, Placeholder, Icon, Popup } from 'semantic-ui-react';
import { useCreateProgramCode, useListProgramCodes } from 'api/programCodes';
import { ObjectId } from '@visikon/core-models/base';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { StartCode } from './StartCode';
import { CopyOnClick } from 'components/CopyOnClick';

interface IdProp {
  id: ObjectId;
}

function CreateProgramCodeForm({ id }: IdProp) {
  const [code, setCode] = useState<string>('');
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const mutator = useCreateProgramCode(id);
  const errors: string[] = mutator.error?.response?.data.errors ?? [`Something unexpected went wrong: ${mutator.error?.message}`];

  useEffect(() => {
    if (mutator.isSuccess) {
      setShowSuccess(true);
      const timeout = setTimeout(() => setShowSuccess(false), 4000);

      return () => {
        clearTimeout(timeout);

        // Also reset state or else it will get stuck.
        setShowSuccess(false);
      };
    }

    return () => null;
  }, [mutator.isSuccess]);

  const onCodeChange = (_: never, data: InputOnChangeData) => {
    setCode(data.value.toLowerCase());
  };

  const submit = () => {
    mutator.mutate(code);
  };

  return (
    <Form>
      <Form.Group>
        <Form.Input value={code} onChange={onCodeChange} action>
          <input />
          <Button onClick={submit} loading={mutator.isLoading}>
            Add code
          </Button>
        </Form.Input>
        {showSuccess && <SuccessLabel color="green">Success</SuccessLabel>}
      </Form.Group>
      <List>{mutator.isError && errors.map((err: string, index: number) => <ErrorMsg key={index}>{err}</ErrorMsg>)}</List>
    </Form>
  );
}

function PlaceHolderTableCell() {
  return (
    <Table.Cell>
      <Placeholder>
        <Placeholder.Line />
      </Placeholder>
    </Table.Cell>
  );
}

function PlaceHolderRows({ cells = 1 }: { cells?: number }) {
  const tableRows: JSX.Element[] = [];

  for (let index = 0; index < cells; index++) {
    tableRows.push(
      <Table.Row key={index}>
        <PlaceHolderTableCell />
        <PlaceHolderTableCell />
        <PlaceHolderTableCell />
        <PlaceHolderTableCell />
      </Table.Row>,
    );
  }

  return <>{tableRows}</>;
}

function ProgramCodeTableContent({ id }: IdProp) {
  const codes = useListProgramCodes(id);

  if (codes.isLoading) return <PlaceHolderRows cells={3} />;

  return (
    <>
      {codes.data?.map((code) => {
        const uppercaseCode = code.code.toUpperCase();

        const onCopy = (ev: React.MouseEvent) => {
          if (ev.shiftKey) {
            const codeArray = uppercaseCode.split('');
            return codeArray.join(' ');
          }

          return uppercaseCode;
        };

        return (
          <Table.Row key={code._id}>
            <Table.Cell>
              <CopyOnClick text={onCopy}>
                <span>
                  {uppercaseCode}
                  <Icon name="copy outline" />
                </span>
              </CopyOnClick>
            </Table.Cell>
            <Popup
              trigger={<Table.Cell>{moment().add(code.user_ttl, 'days').fromNow(true)}</Table.Cell>}
              content={`${code.user_ttl} days`}
              position="left center"
            />
            <Table.Cell>{moment(code.createdAt).format('D-M-YYYY - HH:mm')}</Table.Cell>
            <Table.Cell>{code.createdBy}</Table.Cell>
            <Table.Cell>{code.timesUsed}</Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
}

export default function ProgramCodeListModal({ id }: IdProp) {
  return (
    <Modal closeIcon trigger={<Button>Codes</Button>}>
      <Modal.Header>Program Codes</Modal.Header>
      <Modal.Content>
        <ActionsContainer>
          <div>
            <h3 style={{ textAlign: 'center' }}>New standard code</h3>
            <CreateProgramCodeForm id={id} />
          </div>
          <div>
            <h3 style={{ textAlign: 'center' }}>Start code</h3>
            <StartCode programId={id} />
          </div>
        </ActionsContainer>
        <i>TIP: Hold shift while pressing copy on a code to copy a space seperated code ex. A B C 1 2 3 4</i>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Code</Table.HeaderCell>
              <Table.HeaderCell>
                User TTL
                <Popup
                  content="Defines the time to live (TTL) for newly generated users using a Standard Code. Users will expire after the specificed TTL"
                  trigger={<Icon name="question circle" />}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>Created at</Table.HeaderCell>
              <Table.HeaderCell>Created by</Table.HeaderCell>
              <Table.HeaderCell>Times used</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <ProgramCodeTableContent id={id} />
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  );
}

const ErrorMsg = styled(List.Item)`
  color: #ff0000;
`;

const SuccessLabel = styled(Label)`
  align-self: center;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
