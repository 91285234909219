/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import {
  Form, Input, Button, Modal, Checkbox, Image, Accordion, Icon, Grid, Label, Confirm,
} from 'semantic-ui-react';
import { Users } from '@visikon/core-models';
import { TFAInput } from './TFAInput';

type EditProfileData = Users.EditProfileData;
interface IProps {
  user: EditProfileData;
  image: any;
  createSuccess: boolean;
  onSave(user: EditProfileData): void;
  onPasswordSave(user: EditProfileData): void;
  onCreate(qrCode: string): void;
  onActivate(tfaToken: string): void;
}

interface IState {
  user: EditProfileData;
  usernameError?: boolean;
  emailError?: boolean;
  passwordError: any;
  qrCodeError: boolean;
  qrCode: string;
  password: string;
  passwordConfirm: string;
  tfaToken: string;
  tfaTokenError: boolean;
  activeIndex: any;
  open: boolean;
  openConfirmDialog: boolean;
}

export class ProfileSetting extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      user: props.user,
      usernameError: false,
      emailError: false,
      passwordError: false,
      qrCode: '',
      qrCodeError: false,
      password: '',
      passwordConfirm: '',
      tfaToken: '',
      tfaTokenError: false,
      activeIndex: 0,
      open: false,
      openConfirmDialog: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleOnSave = this.handleOnSave.bind(this);
    this.handleQr = this.handleQr.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUserNameChange = this.handleUserNameChange.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handlePasswordConfirm = this.handlePasswordConfirm.bind(this);
    this.handleVerifyTwoFactor = this.handleVerifyTwoFactor.bind(this);
    this.handleOnActivate = this.handleOnActivate.bind(this);
    this.handlePasswordOnSave = this.handlePasswordOnSave.bind(this);
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.user !== this.props.user) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ user: this.props.user });
    }
  }

  handleOnSave() {
    const { user, usernameError, emailError } = this.state;
    if (usernameError || emailError) {
      return;
    }
    this.props.onSave(user);
  }

  handlePasswordOnSave() {
    const {
      user, passwordError, password, passwordConfirm,
    } = this.state;
    if (passwordError) {
      return;
    }

    if (password !== passwordConfirm) {
      alert('Passwords do not match!'); // eslint-disable-line
    } else if (password === '' || passwordConfirm === '') {
      alert('Password cannot be empty!'); // eslint-disable-line
    } else {
      this.props.onPasswordSave(user);
      this.setState({ password: '', passwordConfirm: '' });
    }
  }

  handleQr() {
    const { qrCode, qrCodeError } = this.state;
    if (qrCode === undefined || qrCodeError) {
      return;
    }
    this.props.onCreate(qrCode);
  }

  handleOnActivate() {
    const { tfaToken, tfaTokenError } = this.state;
    if (tfaToken === undefined || tfaTokenError) {
      return;
    }
    this.props.onActivate(tfaToken);
  }

  handleChange() {
    const { user } = this.state;
    if (user.TFActivate === false) {
      this.handleQr();
      this.setState({ open: true });
    } else {
      this.setState({ open: false, openConfirmDialog: true });
    }
  }

  handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    const email = event.target.value;
    const emailError = (email || '').trim() === '';
    if (emailError) {
      this.setState({ emailError });
    } else {
      const { user } = this.state;
      user.email = email;
      this.setState({ user });
    }
  }

  handleUserNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    const username = event.target.value;
    const usernameError = (username || '').trim() === '';
    if (usernameError) {
      this.setState({ usernameError });
    } else {
      const { user } = this.state;
      user.username = username;
      this.setState({ user });
    }
  }

  handlePassword(event: React.ChangeEvent<HTMLInputElement>) {
    const password = event.target.value;
    const passwordError = (password || '').trim() === '';
    const { user } = this.state;
    user.password = password;
    this.setState({ user, password, passwordError });
  }

  handlePasswordConfirm(event: React.ChangeEvent<HTMLInputElement>) {
    const passwordConfirm = event.target.value;

    const { user } = this.state;
    user.passwordConfirm = passwordConfirm;
    this.setState({ user, passwordConfirm });
  }

  handleVerifyTwoFactor(tfaToken: any) {
    this.setState({ tfaToken });
  }

  close = () => this.setState({ open: false });

  closeConfirmDialog = () => this.setState({ openConfirmDialog: false });

  handleClick = (e: any, titleProps: any) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const {
      user, emailError, usernameError, tfaToken, passwordConfirm, password,
    } = this.state;

    return (
      <>
        <h1>Account settings</h1>

        <Accordion fluid styled>
          <Accordion.Title active={this.state.activeIndex === 0} index={0} onClick={this.handleClick}>
            <Icon name="dropdown" />
            {' '}
            Profile
          </Accordion.Title>
          <Accordion.Content active={this.state.activeIndex === 0}>
            <Grid reversed="mobile vertically" centered columns={2}>
              <Grid.Row centered columns={2}>
                <Grid.Column>
                  <Form>
                    <Form.Field width="10">
                      <label>Username</label>
                      <Input value={user.username} error={usernameError} onChange={this.handleUserNameChange} />
                    </Form.Field>
                    <Form.Field width="10">
                      <label>Email</label>
                      <Input value={user.email} error={emailError} onChange={this.handleEmailChange} />
                    </Form.Field>

                    <Modal trigger={<Button>Change password</Button>} size="mini">
                      <Modal.Header>Change Password</Modal.Header>
                      <Modal.Content style={{ minHeight: 200 }}>
                        <Form>
                          <Form.Field required>
                            <label>New Password</label>
                            <Input type="password" value={password} onChange={this.handlePassword} error />
                          </Form.Field>
                          <Form.Field required>
                            <label>Confirm Password</label>
                            <Form.Input
                              type="password"
                              value={passwordConfirm}
                              onChange={this.handlePasswordConfirm}
                            />
                          </Form.Field>
                        </Form>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button color="blue" onClick={this.handlePasswordOnSave}>
                          Save
                        </Button>
                        {this.props.createSuccess && <Label color="green">Success</Label>}
                      </Modal.Actions>
                    </Modal>
                  </Form>
                </Grid.Column>
              </Grid.Row>
              <Grid.Column>
                <Checkbox
                  label="Turn on two factor Authentication"
                  toggle
                  checked={user.TFActivate}
                  onChange={this.handleChange}
                />

                <Confirm
                  open={this.state.openConfirmDialog}
                  content="Are you sure you want to disable two-step verification"
                  onCancel={this.closeConfirmDialog}
                  onConfirm={() => {
                    user.TFActivate = false;
                    this.handleOnSave();
                    this.closeConfirmDialog();
                  }}
                />
                <Modal open={this.state.open} onClose={this.close} size="tiny">
                  <Modal.Header>Activate two-step verification</Modal.Header>
                  <Modal.Content>
                    <label>
                      <b>1. Use your Authenticator application and scan the following QR code:</b>
                    </label>
                    <Image src={this.props.image} centered />
                    <label>
                      <b>2. Enter the 6 digit code generated by your mobile application to activate two-step verification:</b>
                    </label>
                    <TFAInput onChange={this.handleVerifyTwoFactor} value={tfaToken} />
                  </Modal.Content>
                  <Modal.Actions>
                    <Button color="blue" onClick={this.handleOnActivate}>
                      Activate
                    </Button>
                  </Modal.Actions>
                </Modal>
              </Grid.Column>
            </Grid>
            <Grid columns={2}>
              <Grid.Column>
                <Button primary onClick={this.handleOnSave}>
                  SAVE
                </Button>
                {this.props.createSuccess && <Label color="green">Success</Label>}
              </Grid.Column>
            </Grid>
          </Accordion.Content>
        </Accordion>
      </>
    );
  }
}
