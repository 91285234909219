import { Environment } from 'commons/env';
import { appEnv } from 'config';
import React from 'react';

interface Props {
  style?: React.CSSProperties;
}
export const VersionIndicator = (props: Props) => {
  if (appEnv === Environment.Local) {
    return (
      <div style={props.style || {}}>dev</div>
    );
  }

  return (
    <div style={props.style || {}}>
      <b>{appEnv} {process.env.REACT_APP_VERSION}</b> <em>{process.env.REACT_APP_GIT_BRANCH}/{process.env.REACT_APP_GIT_HASH}</em>
    </div>
  );
};
