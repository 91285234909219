import { Button, Divider, Icon } from 'semantic-ui-react';
import { FixedAtTop } from '../../../components/FixedAtTop';
import { ErrorMessage } from '../../../components/error-message';

export function Header({ onSave, isSaveDisabled, error }: { onSave: () => void, isSaveDisabled: boolean, error?: Error | null }) {
  return (
    <>
      <div style={{ minHeight: 36, display: 'inline-block' }}>
        <FixedAtTop fixOffset={-100} showOverlay style={{ display: 'flex', background: 'white' }}>
          <Button positive onClick={onSave} disabled={isSaveDisabled}>
            <Icon name="save" />
            Save copy
          </Button>
          <ErrorMessage error={error} />
        </FixedAtTop>
      </div>
      <Divider />
    </>
  );
}
