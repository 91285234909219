import { BlockSummary } from './EncodeNewBlueprintTable';
import * as Styles from './EncodeNewDurations.styles'

export type DurationProps = {
  width: number,
  opacity?: number,
}

export function BlockDurations({ data }: { data: BlockSummary }) {
  const onePct = 3.6;

  return (
    <>
      <BlockDuration duration={data.metadata.video.durationPct} onePct={onePct} />
      <BlockDuration duration={data.metadata.speak.durationPct} onePct={onePct} delay={data.audioDelayPct} />
    </>
  );
}

function BlockDuration({ duration, onePct, delay }: { duration: number, onePct: number, delay?: number }) {
  const delayPct = delay && delay * onePct;
  const durationPct = duration * onePct;

  const renderDelay = !!delayPct && !!durationPct;

  return (<div style={{display: 'flex'}}>
    {renderDelay &&
      <Styles.DelayDuration width={delayPct} opacity={0.1} />
    }
    {!!durationPct &&
      <Styles.Duration width={durationPct} />
    }
    {!durationPct &&
      <Styles.MissingMedia>
        Missing media file!
      </Styles.MissingMedia>
    }
  </div>);
}
