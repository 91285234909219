import { BlockList } from '@visikon/core-models/content';
import * as Actions from 'actions/BlockActions';
import axios from 'axios';
import { APIUtils } from 'commons/utils';
import { API } from 'config';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { logger } from '../commons/logger';
import { getToken } from './saga-helpers';
import { invalidateQueries } from 'actions/ReactQueryActions';
import { COMPOSITOON_CACHE_KEY } from 'api/compositionApi';

function* saveComposition(action: Action<Partial<BlockList>>) {
  try {
    yield put(Actions.saveCompositionStarted());
    // Set saving action
    // @ts-ignore
    const token = yield select(getToken);

    const url = new URL('./blocklist', API.baseURL);
    // @ts-ignore
    const result = yield call(axios.post, url.toString(), action.payload, APIUtils.tokenHeader(token));
    const update = result.data as BlockList;
    yield put(Actions.saveCompositionSuccessful(update));
  } catch (e) {
    logger.error("Couldn't save BlockList!", e);
    yield put(Actions.saveCompositionFailed(e));
  }
}

function* saveCompositionSuccessful({ payload }: Action<BlockList>) {
  yield put(invalidateQueries([COMPOSITOON_CACHE_KEY, 'single', payload._id]));
  yield put(invalidateQueries([COMPOSITOON_CACHE_KEY, 'list']));
}

export function* compositionsSaga() {
  yield takeEvery(Actions.saveComposition, saveComposition);
  yield takeEvery(Actions.saveCompositionSuccessful, saveCompositionSuccessful);
}
