// Ugly way to do it, but CORS prevents using the download attribute of <a> tags
import { logger } from './logger';

export const downloadFile = (url: string, name: string) => {
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((e) => logger.error(e));
};
