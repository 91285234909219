import { CMSUserKinds } from '@visikon/core-models/user';

export interface UserTypeSelect {
  text: string;
  value: CMSUserKinds;
  key: CMSUserKinds;
}

export const userTypeOptions: UserTypeSelect[] = [
  {
    text: 'Embed User',
    value: 'EmbedIntegration',
    key: 'EmbedIntegration',
  },
  {
    text: 'Translator',
    value: 'Translator',
    key: 'Translator',
  },
  {
    text: 'Viewer License',
    value: 'ViewerLicense',
    key: 'ViewerLicense',
  },
  {
    text: 'Editor',
    value: 'Editor',
    key: 'Editor',
  },
  {
    text: 'Administrator',
    value: 'Admin',
    key: 'Admin',
  },
  {
    text: 'Super Administrator',
    value: 'SuperAdmin',
    key: 'SuperAdmin',
  },
];
