import { Content } from '@visikon/core-models';
import { LanguageCode } from '@visikon/core-models/content';
import { getTranslation } from '@visikon/core-models/typeUtils';

type UserBatchExport = {
  codeNoFormat: string;
  code: string;
  userLink: string;
  qr: string;
  programName: string;
  departmentName: string;
  organizationName: string;
  subName: string;
};
type ProgramAndDepartment = { programName: string; departmentName: string; organizationName: string; subName: string; programId: string };

const UserBatchExportHeaders = [
  'user-code-plain',
  'user-code',
  'user-link',
  '"#QR"',
  'program-name',
  'department-name',
  'organization-name',
  'program-sub-name',
];

function appendHeaders(rows: UserBatchExport[]) {
  return [
    UserBatchExportHeaders,
    ...rows.map((row) => [
      row.codeNoFormat,
      row.code,
      row.userLink || '-',
      row.qr || '-',
      row.programName || '-',
      row.departmentName || '-',
      row.organizationName || '-',
      row.subName || '-',
    ]),
  ];
}

export function getUserBatchCsvData(codes: string[], selectedProgramData: ProgramAndDepartment[], domain?: string) {
  const { programName, departmentName, organizationName = '-', subName = '-' } = selectedProgramData[0];
  const preparedData = codes.map((code) => ({
    codeNoFormat: code,
    code: format(code),
    userLink: `https://${domain}/auth/${code}`,
    qr: `URL:https://${domain}/auth/${code}`,
    programName,
    departmentName,
    organizationName,
    subName,
  }));

  const data = appendHeaders(preparedData);
  const fileName = `${programName}_${subName}_${codes.length}`;

  return { data, fileName };
}

export function extractProgramNames(programList: Content.CustomType[], programIds: string[], language: LanguageCode = 'da') {
  // Perhaps base Language on selected domain?
  const selectedProgramData = programList.reduce(
    (resArr: ProgramAndDepartment[], program) => {
      if (programIds?.includes(program._id)) {
        const translation = getTranslation(program, language);
        const prep = {
          programId: program._id,
          programName: translation?.data.name as string,
          subName: translation?.data.subName as string,
          departmentName: translation?.data.department as string,
          organizationName: translation?.data.organization as string,
        };
        resArr.push(prep);
      }
      return resArr;
    },

    [],
  );
  return selectedProgramData;
}

function format(code: string) {
  // all caps /.toUpperCase()
  // space after each character except last
  const formatting = code
    .split('')
    .map((c, index) => {
      let sequence: string | string[] = [''];
      sequence = `${c} `;
      if (index === code.length - 1) sequence = c;
      return sequence;
    })
    .join('')
    .toUpperCase();

  return `"${formatting}"`;
}
