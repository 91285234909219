import { Button, Icon, Input } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { IState } from 'reducers/reducers';
import { MitForlobProgram } from '@visikon/core-models/content';
import useCodeGenerator from './UseCodeGenerator';
import { CopyOnClick } from 'components/CopyOnClick';

interface StartCodeProps {
  programId: string;
}

function CodeGenerator({ submit }: { submit: (code: string) => void }) {
  const [code, genereateCode, clearCode] = useCodeGenerator();

  if (!code) {
    return (
      <Button icon labelPosition="right" style={{ marginLeft: 0 }} onClick={genereateCode} disabled>
        Add start-code
        <Icon color="green" name="plus" />
      </Button>
    );
  }

  return (
    <>
      <Input value={code} readOnly action>
        <input />
        <Button icon="refresh" onClick={genereateCode} />
        <Button icon negative onClick={clearCode}>
          Cancel
        </Button>
        <Button icon labelPosition="right" positive onClick={() => submit(code)}>
          Save
          <Icon name="save" />
        </Button>
      </Input>
    </>
  );
}

export function StartCode({ programId }: StartCodeProps) {
  const program = useSelector<IState, MitForlobProgram>(({ content }) => {
    const programs = content.data.mitforlobPack ? content.data.mitforlobPack : [];

    return programs.find((e) => e._id === programId) as MitForlobProgram;
  });

  if (program.startCode === undefined) {
    return <CodeGenerator submit={() => {}} />;
  }

  const buttonStyle = { background: 'white', border: '1px solid rgba(34,36,38,.15)' };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      <div style={{ display: 'flex' }}>
        <CopyOnClick text={program.startCode} popupPosition="left center">
          <Button icon labelPosition="left" style={buttonStyle}>
            <Icon name="copy outline" />
            {program.startCode}
          </Button>
        </CopyOnClick>
      </div>
    </div>
  );
}
