import { useState } from 'react';
import pseudoword from 'commons/pseudowords';

export default function useCodeGenerator(): [string | undefined, () => string, () => void] {
  const [code, setCode] = useState<string | undefined>();

  function generateCode() {
    const newcode = pseudoword(7);
    const num = Math.floor(Math.random() * 99);
    setCode(`${newcode}${num}`);

    return newcode;
  }

  function clearCode() {
    setCode(undefined);
  }

  return [code, generateCode, clearCode];
}
