import { Breadcrumb } from 'components/Breadcrumb';
import { MainContent } from 'components/MainContent';
import { MainMenu } from 'components/main-menu/MainMenu';
import { Users } from '@visikon/core-models';
import React, { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import { IState } from 'reducers/reducers';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthRoute } from './auth/AuthRoute';
import { LoginContainer } from './auth/LoginContainer';
import { LogoutContainer } from './auth/LogoutContainer';
import { BlockRoot } from './composer/BlockRoot';
import { CollectionsRoot } from './collections/CollectionsRoot';
import { ContentRoot } from './content/ContentRoot';
import { ForbiddenError } from './ForbiddenError';
import { FrontPageContainer } from './FrontPageContainer';
import { CMSLanguageSelectContainer } from './LanguageSelectorContainer';
import { MediaRoot } from './mediaLibrary/MediaRoot';
import { UsersRoot } from './users/UsersRoot';
import { EmbedFront } from './embed/EmbedFront';
import { EmbedVideos } from './embed/EmbedVideos';
import { EmbedImages } from './embed/EmbedImages';
import { NamespaceRoot } from './namespace/NamespaceRoot';
import { ProfileRoot } from './profilesettings/ProfileRoot';
import { ProfileSelectContainer } from './ProfileSelectorContainer';
import { StartCodeOverviewContainer } from './content/StartCodeOverviewContainer';
import QueryStateInterface from './composer/QueryStateInterface';

interface IProps {
  isLoggedIn: boolean;
  namespace: string;
  userType: Users.AnyUserKinds;
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

class CApp extends React.Component<IProps, {}> {
  // eslint-disable-next-line class-methods-use-this
  renderEmbedGUI() {
    return (
      <Router>
        <>
          <AuthRoute path="/" exact component={EmbedFront} />
          <AuthRoute path="/images" component={EmbedImages} />
          <AuthRoute path="/videos" component={EmbedVideos} />

          <Route path="/login" component={LoginContainer} />
          <Route path="/logout" component={LogoutContainer} />
        </>
      </Router>
    );
  }

  renderFullApp(userType: Users.AnyUserKinds, isLoggedIn: boolean, namespace: string) {
    return (
      <DndProvider backend={HTML5Backend}>
        <Router>
          <ScrollToTop />
          <>
            <ForbiddenError />
            <Route component={QueryStateInterface} />
            <Route render={(props) => <MainMenu {...props} isLoggedIn={isLoggedIn} namespace={namespace} userType={userType} />} />
            <Route render={(props) => <Breadcrumb {...props} isLoggedIn={isLoggedIn} />} />
            {this.props.isLoggedIn && <ProfileSelectContainer />}
            {this.props.isLoggedIn && <CMSLanguageSelectContainer />}

            <MainContent>
              <Route path="/" component={FrontPageContainer} exact />
              <Route path="/login" component={LoginContainer} />
              <Route path="/logout" component={LogoutContainer} />

              <AuthRoute path="/profilesetting" component={ProfileRoot} />
              <AuthRoute path="/media" component={MediaRoot} />
              <AuthRoute path="/block" component={BlockRoot} />
              <AuthRoute path="/content" component={ContentRoot} />
              <AuthRoute path="/collections" component={CollectionsRoot} />
              <AuthRoute path="/users" component={UsersRoot} />
              <AuthRoute path="/namespaces" component={NamespaceRoot} />
              <AuthRoute path="/startcodes" component={StartCodeOverviewContainer} />
            </MainContent>
          </>
        </Router>
      </DndProvider>
    );
  }

  render() {
    const { userType, isLoggedIn, namespace } = this.props;
    if (isLoggedIn && userType === 'EmbedIntegration') {
      return this.renderEmbedGUI();
    }

    return this.renderFullApp(userType, isLoggedIn, namespace);
  }
}

const mapStateToProps = (state: IState) => ({
  isLoggedIn: state.auth.isLoggedIn,
  namespace: state.auth.namespace,
  userType: state.auth.userType,
});

const MainApp = connect(mapStateToProps)(CApp);

const queryClient = new QueryClient();
const isDev = !process.env.REACT_APP_ENV;

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MainApp />
      {isDev && <ReactQueryDevtools position="bottom-right" />}
    </QueryClientProvider>
  );
}
