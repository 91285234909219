import { LOCAL_STORAGE_LIST_CARD_VIEW_KEY } from 'config';

export function prettyMediaFilenameForShow(filename: string | undefined) {
  if (filename === undefined) {
    return filename;
  }

  const reg = /.+[0-9]+_[0-9]+_(.+)/;
  const found = filename.match(reg);
  if (found !== null && found.length === 2) {
    return found[1];
  }
  return filename;
}

/*
  Blocks
*/

// List or card view - localstorage
export enum View {
  CARD = 'card',
  LIST = 'list',
}

export function getView(): View {
  const storageValue: string | null = localStorage.getItem(LOCAL_STORAGE_LIST_CARD_VIEW_KEY);

  return storageValue ? View[storageValue.toUpperCase()] : View.LIST;
}

export function setView(newView: View) {
  localStorage.setItem(LOCAL_STORAGE_LIST_CARD_VIEW_KEY, newView);
}

export function toggleView() {
  const currentView = getView();
  setView(currentView === View.LIST ? View.CARD : View.LIST);
}
