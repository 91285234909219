import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { APIUtils } from '../commons/utils';
import { tokenState } from '../redux-recoil-sync.state';
import { URLS } from './urls';
import { appendTypeLabels } from './program-metadata.helpers';
import { EndpointMetadata, ProgramMetadata } from './program-metadata.models';
import { QueryConfig } from './react-query-utils';

export function useGetProgramMetadata(type: string, id: string) {
  const [token] = useRecoilState(tokenState);

  return useQuery<ProgramMetadata, Error>(['program-metadata', type, id], async () => {
    const result = await axios.get<EndpointMetadata>(URLS.metadata.get(type, id), APIUtils.tokenHeader(token!)).then((res) => res.data);

    return appendTypeLabels(result);
  }, {
    ...QueryConfig,
    enabled: !!token,
  });
}

export function usePostProgramMetadata(type: string, id: string) {
  const [token] = useRecoilState(tokenState);
  const queryClient = useQueryClient();

  return useMutation<ProgramMetadata, Error, ProgramMetadata>(
    async (payload: ProgramMetadata) => {
      const result = await axios.post(URLS.metadata.get(type, id), payload, APIUtils.tokenHeader(token!));
      return result.data;
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['program-metadata', type, id]); // Re-fetch program-metadata from server
      },
    },
  );
}
