import { combineReducers } from 'redux';
import { authReducer, IState as IAuthState } from './authReducer';
import { blocksReducer, IState as IBlocksState } from './blocksReducer';
import { compositionsReducer, IState as ICompositionState } from './compositionsReducer';
import { contentReducer, IState as IContentState } from './contentReducer';
import { foldersReducer, IState as IFoldersState } from './foldersReducer';
import { langReducer, IState as ILangState } from './langReducer';
import { mediaReducer, IState as IMediaState } from './mediaReducer';
import { userReducer, IState as IUserState } from './userReducer';
import { publisherReducer, IState as IPublishState } from './publisherReducer';
import { reactQueryReducer, IReactQueryState } from './ReactQueryReducer';

export interface IState {
  auth: IAuthState;
  blocks: IBlocksState;
  blockLists: ICompositionState;
  content: IContentState;
  folders: IFoldersState;
  language: ILangState;
  media: IMediaState;
  users: IUserState;
  publishContent: IPublishState;
  reactQuery: IReactQueryState;
}

export const reducer = combineReducers({
  auth: authReducer,
  blocks: blocksReducer,
  blockLists: compositionsReducer,
  content: contentReducer,
  folders: foldersReducer,
  language: langReducer,
  media: mediaReducer,
  users: userReducer,
  publishContent: publisherReducer,
  reactQuery: reactQueryReducer,
});
