import { Misc } from '@visikon/core-models';
import { useCallback, useEffect } from 'react';
import { QueryKey, useQueryClient } from 'react-query';
import { socket } from 'SocketHandler';

interface IoListenderItem {
  eventName: string;
  listener: (eventName: string, ...args: any[]) => void;
}

function useSocketListener(events: string[], cb: (event: string, ...args: any[]) => void) {
  useEffect(() => {
    const listeners: IoListenderItem[] = [];

    events.forEach((eventName) => {
      const listener = (...args: any[]) => cb(eventName, ...args);
      listeners.push({ eventName, listener });

      socket.on(eventName, listener);
      // eslint-disable-next-line no-console
      console.debug(`Created IO listener for event ${eventName}`);
    });

    return () => {
      listeners.forEach(({ eventName, listener }) => {
        socket.removeListener(eventName, listener);
        // eslint-disable-next-line no-console
        console.debug(`Removed IO listener for event ${eventName}`);
      });
    };

    // We ignore the eslint rule below because we want to ensure the events array hasn't changed
    // We check if the array have is equal by converting it to an simple string
    // React will only compare the array object itself which will almost always be a different object
    // Even when containing the exact same items and re-render will accour because the array object itself changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cb, events.join('|')]);
}

/**
 * Subscribes to updates for a specificed document via Socket.io and
 *
 * @param documentId document id
 * @param queryKey query key used to invalide a query
 * @param eventPrefix
 */
export function useSocketQueryInvalidation(
  documentId: string | undefined,
  queryKey: QueryKey,
  eventPrefix: string = Misc.SocketSubscriptionConfig.documentUpdatedEventPrefix,
) {
  const events = documentId ? [eventPrefix + documentId] : [];
  const queryClient = useQueryClient();

  // Some consumers of this hook may be badly optimized so cache the callback
  const callback = useCallback(() => {
    queryClient.invalidateQueries(queryKey);

    // We ignore the eslint rule below because id shouldt change without queryKey also changening
    // We can't use queryKey here because the object may be a different object with the same content
    // and we don't want to use JSON.stringify to check for equal content for every rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  // Document subscription
  useEffect(() => {
    socket.emit(Misc.SocketSubscriptionConfig.subscribeEvent, [documentId]);

    return () => {
      socket.emit(Misc.SocketSubscriptionConfig.unsubscribeEvent, [documentId]);
    };
  }, [documentId]);

  useSocketListener(events, callback);
}
