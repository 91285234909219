import { BlockPlaceholderChange, BlockVideoChange } from '@visikon/core-models/content';
import { destroyVideoPlayer, PLACEHOLDER_VIDEO } from 'commons/utils';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

interface VideoVariationPreviewProps {
  video: BlockVideoChange & BlockPlaceholderChange;
}

export default function VideoVariationPreview({ video }: VideoVariationPreviewProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  const { contentType } = video;
  const isVideoType = contentType && contentType.split('/').includes('video');
  const isImageType = contentType && contentType.split('/').includes('image');

  useEffect(() => {
    if (videoRef.current) {
      destroyVideoPlayer(videoRef.current);
    }
  }, [video]);

  if (video.videoFile) {
    return (
      <VideoPreview ref={videoRef} controls>
        <source src={video.videoFile.preview} type={contentType} />
      </VideoPreview>
    );
  }
  if (video.imageFile) return <ImgPlaceholder src={video.imageFile.preview} />;
  if (video.existingSource) {
    if (isVideoType) {
      return (
        <VideoPreview ref={videoRef} controls>
          <source src={video.existingSource} type={contentType} />
        </VideoPreview>
      );
    }
    if (isImageType) return <ImgPlaceholder src={video.existingSource} />;
  }

  return <ImgPlaceholder src={PLACEHOLDER_VIDEO} />;
}

const VideoPreview = styled.video`
  width: 313px;
  height: auto;
`;

const ImgPlaceholder = styled.img`
  width: 313px;
  height: 176px;
  object-fit: cover;
`;
