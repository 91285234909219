import { Accordion, Icon } from 'semantic-ui-react'
import React, { useState } from 'react';

export type AccordionItem = {
  title: string | JSX.Element,
  content: JSX.Element
};

export function SimpleAccordion({items, initialIndex}: {items: Array<AccordionItem>, initialIndex?: number}) {
  const [active, setActive] = useState(initialIndex || 0);

  const handleClick = (index: number) => {
    setActive(index !== active ? index : -1);
  }
  return (<Accordion styled fluid>
    {items.map((item, i) => (<React.Fragment key={i}>
      <Accordion.Title index={i} active={active === i} onClick={() => handleClick(i)}>
        <Icon name='dropdown' />
        {item.title}
      </Accordion.Title>
      <Accordion.Content active={active === i}>
        {item.content}
      </Accordion.Content>
    </React.Fragment>))}
  </Accordion>);
}
