import React from 'react';

interface IState {
  isFixed: boolean;
  lastKnownScrollPosition: number;
  dynamicPosition: number;
  ticking: boolean;
  placeholderStyle?: React.CSSProperties;
}

interface IProps {
  fixOffset: number;
  showOverlay?: boolean;
  style?: React.CSSProperties;
}

export class FixedAtTop extends React.Component<IProps, IState> {
  div: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);

    this.div = React.createRef();

    this.state = {
      isFixed: false,
      lastKnownScrollPosition: 0,
      dynamicPosition: 0,
      ticking: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    this.setState({ lastKnownScrollPosition: window.scrollY });
    const { ticking } = this.state;
    const { fixOffset } = this.props;
    if (!ticking) {
      window.requestAnimationFrame(() => {
        const { lastKnownScrollPosition, dynamicPosition, isFixed } = this.state;
        if (this.div.current) {
          const div = this.div.current;
          if (lastKnownScrollPosition >= (div.offsetTop - fixOffset) && !isFixed) {
            this.setState({ isFixed: true, dynamicPosition: div.offsetTop });
          }
          if (lastKnownScrollPosition < (dynamicPosition - fixOffset) && isFixed) {
            this.setState({ isFixed: false });
          }
        }
        this.setState({ ticking: false });
      });
      this.setState({ ticking: true });
    }
  }

  render() {
    const style = this.state.isFixed ? {
      ...(this.props.style || {}),
      position: 'fixed',
      paddingTop: this.props.fixOffset,
      top: 0,
      zIndex: 10,
      boxShadow: this.props.showOverlay ? '0px 0px 20px 20px #FFF' : 'inherit',
    } : {
      ...(this.props.style || {}),
    };

    return (
      <div style={style as any} ref={this.div}>
        {this.props.children}
      </div>
    );
  }
}
