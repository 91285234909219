import React from 'react';

import { RouteComponentProps } from 'react-router';
import { CustomTypeListContainer } from 'containers/content/CustomTypeListContainer';

type IProps = RouteComponentProps<{ name: string }>;

export class CustomTypeListContainerRoute extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.handleOnClickNewButton = this.handleOnClickNewButton.bind(this);
    this.handleOnEditClick = this.handleOnEditClick.bind(this);
  }

  handleOnClickNewButton() {
    const loc = this.props.location;
    this.props.history.push(`${loc.pathname}/new`);
  }

  handleOnEditClick(id: string) {
    const loc = this.props.location;
    this.props.history.push(`${loc.pathname}/${id}`);
  }

  render() {
    const { name } = this.props.match.params;
    return (
      <CustomTypeListContainer
        name={name}
        handleOnClickNewButton={this.handleOnClickNewButton}
        handleOnEditClick={this.handleOnEditClick}
      />
    );
  }
}
