import axios from 'axios';
import { API } from 'config';
import { APIUtils } from 'commons/utils';
import { Content, UploadTypes } from '@visikon/core-models';
import { useRecoilState } from 'recoil';
import { useQuery } from 'react-query';
import { ObjectId } from '@visikon/core-models/base';
import { tokenState } from '../redux-recoil-sync.state';
import { URLS } from './urls';
import { QueryConfig } from './react-query-utils';

export const createEntry = (token: string, content: UploadTypes.CustomType) => {
  const url = new URL(`./content/${content.typeDescriptor}`, API.baseURL);
  return axios.post(url.toString(), content, APIUtils.tokenHeader(token));
};

export const copyEntry = (token: string, id: string) => {
  const url = new URL('./content/copy', API.baseURL);
  return axios.post(url.toString(), { id }, APIUtils.tokenHeader(token));
};

export const updateEntry = (token: string, content: Content.CustomType) => {
  const url = new URL(`./content/${content.typeDescriptor}/${content._id}`, API.baseURL);
  return axios.post(url.toString(), content, APIUtils.tokenHeader(token));
};

export const listEntries = (token: string, type: string) => {
  const url = new URL(`./content/${type}`, API.baseURL);
  return axios.get(url.toString(), APIUtils.tokenHeader(token));
};
export const programActions = (token: string) => {
  const url = new URL(`./programActions`, API.baseURL);
  return axios.get(url.toString(), APIUtils.tokenHeader(token));
};

export const loadEntry = (token: string, type: string, id: ObjectId) => {
  const url = new URL(`./content/${type}/${id}`, API.baseURL);
  return axios.get(url.toString(), APIUtils.tokenHeader(token));
};

export const thumbnailBlock = (token: string, id: string, thumbnailSrc: any) => {
  const url = new URL('./thumbs/thumbnail', API.baseURL);
  return axios.post(url.toString(), { id, thumbnailSrc }, APIUtils.tokenHeader(token));
};

type ReturnType = {
  found: boolean,
  id: string
};

export function useGetIdByTypeAndName(type: string, name: string | undefined) {
  const [token] = useRecoilState(tokenState);

  const query = async () => axios.post<ReturnType>(
    URLS.idByTypeAndName.post(type),
    name,
    APIUtils.tokenHeader(token!, { 'Content-Type': 'text/plain' })
  ).then((res) => res.data);

  return useQuery<ReturnType, Error>(['GetIdByTypeAndName', type, name], query, {
    ...QueryConfig,
    enabled: !!token && !!name,
  });
}
