import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faVenus, faMars, faVenusMars } from '@fortawesome/free-solid-svg-icons';

library.add(faMars, faVenus, faVenusMars);

export const GenderIcon = ({ anatomy }: { anatomy: string | undefined }) => {
  if (!anatomy) return null;

  return (
    <span style={{ marginLeft: 8 }}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {anatomy === 'male' ? (
        <FontAwesomeIcon icon="mars" size="lg" />
      ) : anatomy === 'female' ? (
        <FontAwesomeIcon icon="venus" size="lg" />
      ) : (
        <FontAwesomeIcon icon="venus-mars" size="lg" />
      )}
    </span>
  );
};
