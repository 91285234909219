import { APIUtils } from 'commons/utils';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { tokenState } from 'redux-recoil-sync.state';
import BlockListResponse from 'containers/composer/blocks/BlockList';
import { Block, BlockList } from '@visikon/core-models/content';
import { URLS } from './urls';
import { QueryConfig } from './react-query-utils';

export const BLOCK_CACHE_KEY = 'blocks';

export function useBlock(id: string) {
  const [token] = useRecoilState(tokenState);

  return useQuery<Block>(
    [BLOCK_CACHE_KEY, 'single', id],
    async () => {
      const result = await axios.get<Block>(URLS.blocks.get(id!), APIUtils.tokenHeader(token!));

      return result.data;
    },
    { ...QueryConfig, enabled: !!token && id !== 'new' },
  );
}

type BlockUsageReponse = {
  count: number;
  compositions: BlockList[];
};

export function useBlockUsage(id: string) {
  const [token] = useRecoilState(tokenState);

  return useQuery<BlockUsageReponse>(
    [BLOCK_CACHE_KEY, 'usage', id],
    async () => {
      const result = await axios.get<BlockUsageReponse>(URLS.blocks.usage(id), APIUtils.tokenHeader(token!));

      return result.data;
    },
    { ...QueryConfig, enabled: !!token, retry: false },
  );
}

export function useListBlocks(page: number, limit: number = 15, name?: string, manuscript?: string, tags?: string, archived?: boolean) {
  const [token] = useRecoilState(tokenState);

  return useQuery(
    [BLOCK_CACHE_KEY, 'list', page, limit, name, manuscript, tags, archived],
    async () => {
      const data = await axios.get<BlockListResponse>(URLS.blocks.list(page, limit, name, manuscript, tags, archived), APIUtils.tokenHeader(token!));
      return data.data;
    },
    { ...QueryConfig, enabled: !!token, keepPreviousData: true },
  );
}
