import { LanguageCode } from '@visikon/core-models/content';
import { EndpointMetadata, ProgramMetadata, ProgramMetadataEntries } from './program-metadata.models';

export const appendTypeLabels = (result: EndpointMetadata) => {
  const typeLabels = {
    mitforlob: 'Program',
    logo: 'Logo',
    banner: 'Banner',
    contactText: 'Contact text',
    trainingVideos: 'Training videos',
    phamplets: 'phamplets',
    programVideos: 'Program videos',
    faq: 'FAQ',
    symptoms: 'Symptoms',
    notifications: 'Notifications',
    instructionVideos: 'Instruction videos',
  };

  const output = {} as ProgramMetadata;

  Object.keys(result).forEach((language) => {
    output[language] = [] as ProgramMetadataEntries;

    result[language].forEach((content) => {
      output[language].push({
        ...content,
        label: typeLabels[content.type] || content.type,
      });
    });
  });

  return output;
};

export const getMetadataForLanguage = (data: ProgramMetadata | undefined, selectableLanguage: LanguageCode) => {
  const hasLanguage = data && Object.keys(data).find((programLanguage) => programLanguage === selectableLanguage);

  return hasLanguage
    ? data[selectableLanguage]
    : {} as ProgramMetadataEntries;
};
