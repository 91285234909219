import styled from 'styled-components';

export const VideoWrapper = styled.div`
    position: relative;
`;

export const VideoPlayer = styled.video`
    width: 600px;
    height: 338px;
    border: 1px solid lightgray;
    cursor: pointer;
`;

export const PauseOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
`;
