import {
  clearCreateSuccess, publishContentSuccessful, getALlPublishedVersioSuccess, IPublishedContent, publishedApiError, publishContent, toggleApprovedSuccess,
} from 'actions/publisherAction';
import { Reducer } from 'redux';
import { isType } from 'typescript-fsa';

export interface IState {
  getAll?: IPublishedContent[];
  creationSuccess: boolean;
  creationError: string | undefined;
  creationErrorPayload: any,
  isWorking: boolean;

}
const initialState: IState = {
  creationSuccess: false,
  isWorking: false,
  creationError: undefined,
  creationErrorPayload: undefined,
};

export const publisherReducer: Reducer<IState> = (state = initialState, action) => {
  if (isType(action, publishContent)) {
    return {
      ...state,
      isWorking: true,
    };
  }

  if (isType(action, publishContentSuccessful)) {
    return {
      ...state,
      creationSuccess: true,
    };
  }

  if (isType(action, clearCreateSuccess)) {
    return {
      ...state, creationSuccess: false, creationError: undefined, isWorking: false,
    };
  }

  if (isType(action, publishedApiError)) {
    return { ...state, creationError: action.payload.msg, creationErrorPayload: action.payload.payload, isWorking: false };
  }

  if (isType(action, getALlPublishedVersioSuccess)) {
    return { ...state, getAll: action.payload };
  }

  if (isType(action, toggleApprovedSuccess)) {
    return { ...state, data: action.payload };
  }

  return state;
};
