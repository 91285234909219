import styled from "styled-components";

export const AddButtonWrapper = styled.div`
    padding-left: 23px;
    width: 150px;
`;

export const AddButton = styled.button`
    width: 100px;
    height: 100px;
    background-color: #21ba45;
    color: white;
    font-size: 6em;
    border: none;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        background-color: #16ab39;
    }

    &:active {
        background-color: #198f35;
    }
`;
