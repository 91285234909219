import { Environment } from 'commons/env';
import { appEnv } from 'config';
import { RouteComponentProps } from 'react-router-dom';
import { Breadcrumb as BC } from 'semantic-ui-react';

let baseTitle = 'visikon Core';
if (appEnv !== Environment.Prod) {
  baseTitle = `${baseTitle} (${appEnv})`;
}

interface IProps extends RouteComponentProps<{}> {
  isLoggedIn: boolean;
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function* intersperse(a: any[], delim: any) { // tslint:disable-line:no-any
  let first = true;
  for (const x of a) {
    if (!first) {
      yield delim;
    }
    first = false;
    yield x;
  }
}

const urlPartToName = (part: string) => {
  switch (part) {
    case '': return 'Home';
    default: return capitalizeFirstLetter(part);
  }
};

export const Breadcrumb = (props: IProps) => {
  if (!props.isLoggedIn) {
    return null;
  }

  const parts = props.location.pathname.split('/').map(urlPartToName).filter((p) => (p !== null));
  const documentTitle = parts.filter((p) => p !== 'Home').join(' -> ');
  document.title = `${baseTitle} - ${documentTitle}`;

  const guiParts = Array.from(
    intersperse(
      parts.map((p) => (<BC.Section link={false}>{p}</BC.Section>)),
      <BC.Divider icon="right angle" />,
    ),
  );
  return (
    <div className="breadcrumbComponent">
      <BC>
        {guiParts.map((p, i) => (<span key={i}>{p}</span>))}
      </BC>
    </div>
  );
};
