import styled from "styled-components";

export const ManusArea = styled.div`
    position: relative;
    display: flex;
    width: 350px;
    border: 3px groove #aaa;
    margin-bottom: 20px;
`;

export const ManusEdit = styled.div`
    flex: 1;
    overflow-y: scroll;

    &&&&&&& {
        margin-top: 2.5rem !important;
    }
`;
