import { languages } from 'commons/languages';
import { Content, Users } from '@visikon/core-models';
import { useEffect, useState } from 'react';
import { Divider, Icon, DropdownItemProps } from 'semantic-ui-react';
import { SemanticCOLORS, SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { LanguageSelector } from 'components/LanguageSelector';
import { usePrevious } from 'commons/utils';
import { CustomTypeInput, CustomTypeValue, ITypeDescription } from './CustomTypeInput';
import { logger } from '../../commons/logger';
import { ArrayElement } from './utils';

interface IProps {
  entry: Content.CustomType;
  referenceTranslation: ArrayElement<Content.CustomType['translations']>;
  languagesConfig: Users.CMSUser['languagesConfig'];
  namespace: string;
  typeDescription: ITypeDescription;
  activeLanguage?: Content.LanguageCode;
  globalActiveLanguage?: Content.LanguageCode;
  translateFormVal?: CustomTypeValue;
  setHasChanges: (val: boolean) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  handleLanguageChange: (lang: Content.LanguageCode) => void;
  setTranslateFormVal: (val: CustomTypeValue) => void;
}

export const CustomTypeTranslateForm = (props: IProps) => {
  const [availableSecondaryLanguages, setAvailableSecondaryLanguages] = useState<DropdownItemProps[] | undefined>(undefined);
  const [firstTimeRunning, setFirstTimeRunning] = useState(true);

  // List available translation languages
  const availableSecLanguages = justTheseLanguages(...props.languagesConfig!.destinationLanguages);
  const prevAvailableSecLanguages = usePrevious(availableSecLanguages);

  useEffect(() => {
    if (firstTimeRunning) {
      updateSecondaryContent(props);
      setFirstTimeRunning(false);
    }
    return () => {
      setFirstTimeRunning(false);
    };
  }, [props, firstTimeRunning]);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      if (prevAvailableSecLanguages !== availableSecondaryLanguages) {
        setAvailableSecondaryLanguages(availableSecLanguages);
      }
    }

    return () => {
      isCurrent = false;
    };
  }, [availableSecLanguages, availableSecondaryLanguages, prevAvailableSecLanguages]);

  const handleChange = (key: string, val: CustomTypeValue) => {
    props.setTranslateFormVal(val);
    props.setHasChanges(true);
  };

  function justTheseLanguages(...include: Content.LanguageCode[]) {
    const translations = props.entry.translations.map((v) => v.language);
    return languages
      .map((l) => {
        const hasTranslation = translations.indexOf(l.value as Content.LanguageCode) !== -1;
        const icon: SemanticICONS = hasTranslation ? 'check circle outline' : 'circle outline';
        const color: SemanticCOLORS = hasTranslation ? 'green' : 'grey';
        return { ...l, icon: <Icon name={icon} color={color} /> };
      })
      .filter((l) => include.indexOf(l.value as Content.LanguageCode) !== -1);
  }

  return (
    <div className="ui form">
      <Divider />
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <LanguageSelector
            readOnly
            activeLang={props.referenceTranslation?.language || props.globalActiveLanguage}
            onLanguageChanged={() => {
              /* not! */
            }}
            languageChoices={[]}
          />
          <br />
          <br />
          <CustomTypeInput
            pKey="primary_root"
            isReadOnly
            isTranslation
            namespace={props.namespace}
            value={props.referenceTranslation?.data || ''}
            fields={props.typeDescription.fields}
            onChange={() => {
              logger.info('Change in primary!');
            }}
          />
        </div>

        {/* Splitter */}
        <div style={{ minWidth: 7 }} />
        <div style={{ minWidth: 8, borderLeft: '1px solid #aaa' }} />

        {/* Actual translation area */}
        {props.activeLanguage && (
          <div style={{ flex: 1 }}>
            <LanguageSelector
              activeLang={props.activeLanguage}
              onLanguageChanged={(lang) => updateSecondaryContent(props, lang)}
              languageChoices={availableSecondaryLanguages}
            />
            <br />
            <br />
            <CustomTypeInput
              pKey="secondary_root"
              isTranslation
              lang={props.activeLanguage}
              namespace={props.namespace}
              value={props.translateFormVal}
              fields={props.typeDescription.fields}
              onChange={handleChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

function chooseSecondaryLanguage(languagesConfig: Users.CMSUser['languagesConfig'], entry: Content.CustomType) {
  const firstAvailable = languagesConfig!.destinationLanguages.find((l) => l !== languagesConfig!.referenceLanguage);
  const lastResort = () => allLanguagesBut(entry, languagesConfig!.referenceLanguage)[0].value as Content.LanguageCode;

  return firstAvailable || lastResort();
}

function allLanguagesBut(entry: Content.CustomType, ...except: Content.LanguageCode[]) {
  const translations = entry.translations.map((v) => v.language);
  return languages
    .map((l) => {
      const hasTranslation = translations.indexOf(l.value as Content.LanguageCode) !== -1;
      const icon: SemanticICONS = hasTranslation ? 'check circle outline' : 'circle outline';
      const color: SemanticCOLORS = hasTranslation ? 'green' : 'grey';
      return { ...l, icon: <Icon name={icon} color={color} /> };
    })
    .filter((l) => except.indexOf(l.value as Content.LanguageCode) === -1);
}

function updateSecondaryContent(props: IProps, lang?: Content.LanguageCode) {
  const { languagesConfig, entry, referenceTranslation, setTranslateFormVal, handleLanguageChange } = props;
  const secondaryLanguage = lang || chooseSecondaryLanguage(languagesConfig, entry);
  const secondaryTranslation = entry.translations.find((t) => t.language === secondaryLanguage);
  const value = secondaryTranslation ? { ...secondaryTranslation.data } : { ...referenceTranslation?.data };
  setTranslateFormVal(value);
  handleLanguageChange(secondaryLanguage);
}
