import { Button, Confirm, Icon, Modal, Table } from 'semantic-ui-react';
import { IPublishedContent } from '../../../actions/publisherAction';
import moment from 'moment/moment';
import { useState } from 'react';

type Props = {
  children: JSX.Element,
  allRevisions?: IPublishedContent[],
  onApprove: (id: string, originalId: string) => void,
  onDisable: (id: string, originalId: string) => void,
};
export function PublisherVersionsModal(props: Props) {
  const {children, allRevisions, onApprove, onDisable} = props;

  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [showApproveButton, setShowApproveButton] = useState(true);
  const [showApprovePopup, setShowApprovePopup] = useState(false);

  const sortedRevisions = allRevisions ? allRevisions.sort((a, b) => b.version - a.version) : [];
  const liveIndex = sortedRevisions.findIndex((revision) => revision.approved && !revision.disabled);
  const previewIndex = sortedRevisions.findIndex((revision) => !revision.disabled);

  const handleApprove = (id: string, originalId: string) => {
    onApprove(id, originalId);
  };

  const handleDisable = (id: string, originalId: string) => {
    onDisable(id, originalId);
  };

  return (
    <Modal trigger={children} size="large">
      <Modal.Header> Version history</Modal.Header>
      <Modal.Content>
        <Table sortable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Version</Table.HeaderCell>
              <Table.HeaderCell>Published at</Table.HeaderCell>
              <Table.HeaderCell>Published by</Table.HeaderCell>
              <Table.HeaderCell>Disable</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {sortedRevisions.map((e, index) => {
              const showRollback = previewIndex === index;
              const isPublic = liveIndex === index;
              // eslint-disable-next-line no-nested-ternary
              const backgroundColor = isPublic ? '#9adfaa' : e.disabled ? '#eee' : 'white';

              return (
                <Table.Row key={e._id}>
                  <Table.Cell style={{ backgroundColor }}>{e.version}</Table.Cell>
                  <Table.Cell style={{ backgroundColor }}>{moment(e.publishedAt).format('D-M-YYYY - HH:mm')}</Table.Cell>
                  <Table.Cell style={{ backgroundColor }}>{e.publishedBy}</Table.Cell>
                  <Table.Cell style={{ backgroundColor }} collapsing textAlign="center">
                    {e.disabled && <div>Rolled back</div>}
                    {isPublic && <div>Public</div>}
                    {!e.disabled && e.approved && <Icon name="check circle outline" size="large" />}
                    {showRollback && (
                      <>
                        <Button
                          disabled={previewIndex === sortedRevisions.length - 1 ? true : undefined}
                          negative
                          onClick={() => setShowDisablePopup(true)}
                        >
                          Roll back
                        </Button>
                        {!e.approved && showApproveButton && (
                          <Button disabled={showApprovePopup} primary onClick={() => setShowApprovePopup(true)}>
                            Approve
                          </Button>
                        )}
                      </>
                    )}
                    {showRollback && (
                      <>
                        <Confirm
                          open={showApprovePopup}
                          content="Are you sure you want to Approve this version?"
                          confirmButton="OK"
                          onCancel={() => {
                            setShowApprovePopup(false);
                            setShowApproveButton(false);
                          }}
                          onConfirm={() => {
                            handleApprove(e._id, e.originalId);
                            setShowApprovePopup(false);
                            setShowApproveButton(true);
                          }}
                        />
                        <Confirm
                          open={showDisablePopup}
                          content="Are you sure you want to disable this version?"
                          confirmButton="OK"
                          onCancel={() => setShowDisablePopup(false)}
                          onConfirm={() => {
                            handleDisable(e._id, e.originalId);
                            setShowDisablePopup(false);
                          }}
                        />
                      </>
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  )
}