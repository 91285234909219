import { Label, SemanticCOLORS } from 'semantic-ui-react';
import moment from 'moment';
import { PublishedRevisions } from '@visikon/core-models/content';

export function ToolTip({ children, text, isLive, attr }: { children: any, text: string, isLive: boolean, attr?: any }) {
  const toolTip = text ? { 'data-tooltip': text, 'data-inverted': !isLive || undefined, ...attr } : { ...attr };

  return (
    <div {...toolTip} style={{ display: 'inline-block'}}>
      {children}
    </div>
  );
}

export const PublishStatus = ({ revisions }: { revisions?: PublishedRevisions }) => {
  if(!revisions) return null;

  const { publishedAt, color, statusText, isLive } = getPublishStatus(revisions);

  return (
    <ToolTip text={statusText} isLive={isLive}>
      {publishedAt &&
        <Label color={color} key={color}>
          {moment(publishedAt).format('DD.MM.YYYY')}
        </Label>
      }
    </ToolTip>
  )
}

export function getPublishStatus(revisions?: PublishedRevisions) {
  const publishedAt = revisions?.preview?.publishedAt || revisions?.live?.publishedAt;

  if(!publishedAt) {
    return {
      publishedAt,
      isLive: false,
      color: undefined as SemanticCOLORS | undefined,
      statusText: '',
    }
  }

  const isLive = moment(revisions?.live?.publishedAt || 0).isAfter(revisions?.preview?.publishedAt || 0);
  const color: SemanticCOLORS = isLive && 'green' || 'yellow';
  const statusText = isLive ? 'Live' : 'Preview';

  return {publishedAt, isLive, color, statusText: `${statusText} (${moment(publishedAt).format('DD.MM.YYYY HH:mm')})`};
}
