import { APIUtils } from 'commons/utils';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { tokenState } from 'redux-recoil-sync.state';
import { URLS } from './urls';
import { QueryConfig } from './react-query-utils';
import { SearchCategories } from 'components/mediaLibrary/constants';
import { Image } from '@visikon/core-models/content';

export const CACHE_KEY_IMAGE = 'media/images';
export const CACHE_KEY_IMAGE_SEARCH = 'search';

export function useImage(id: string) {
  const [token] = useRecoilState(tokenState);

  return useQuery([CACHE_KEY_IMAGE, 'single', id], {
    queryFn: async () => {
      const result = await axios.get<Image>(URLS.images.getById(id), APIUtils.tokenHeader(token!));

      return result.data;
    },
    ...QueryConfig,
    enabled: !!token,
  });
}

export function useListImages(page: number, limit: number = 15, search: string, searchTerm: string, archived?: boolean) {
  const [token] = useRecoilState(tokenState);

  const getSearchTerm = (value: string, selected: SearchCategories): string | null =>
    value === SearchCategories.All || value === selected ? searchTerm! : null;

  return useQuery(
    [CACHE_KEY_IMAGE, CACHE_KEY_IMAGE_SEARCH, page, limit, search, searchTerm, archived],
    async () => {
      const data = await axios.get<any>(
        URLS.images.get(page, limit, getSearchTerm(search, SearchCategories.Names)!, getSearchTerm(search, SearchCategories.Tags)!, archived),
        APIUtils.tokenHeader(token!),
      );
      return data.data;
    },
    { ...QueryConfig, enabled: !!token, keepPreviousData: true },
  );
}

export function useGetImageTranslationsById(id?: string) {
  const [token] = useRecoilState(tokenState);

  return useQuery(
    [CACHE_KEY_IMAGE, 'single-translation', id],
    async () => {
      const imageData = id && (await axios.get<Image>(URLS.images.getById(id), APIUtils.tokenHeader(token!)));

      return imageData ? imageData.data.translations : undefined;
    },
    { ...QueryConfig, enabled: !!token, keepPreviousData: true },
  );
}
