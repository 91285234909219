import * as Semantic from 'semantic-ui-react';
import styled from 'styled-components';
import { BlobUrls } from '../../../../../commons/local-storage';
import { ThumbnailModalStates, ThumbnailViewLabels } from './Thumbnail.modal';
import { ContentContainer, ContentActionsContainer } from './Thumbnail.styles';

const Image = styled.img`
  width: 600px;
  height: 338px;
  border: 1px dashed black;
`;

export function ThumbnailView({thumbnailSrc, onChange}: {thumbnailSrc: string, onChange: (newState: ThumbnailModalStates) => void}) {
  return (<ContentContainer>
    <div>
      <Image
        alt=""
        id="img_from_canvas"
        src={thumbnailSrc?.startsWith('data:image') ? thumbnailSrc : BlobUrls.genericUrl(thumbnailSrc)}
      />
      <ContentActionsContainer>
        <Semantic.Button onClick={() => onChange(ThumbnailModalStates.Grab)}>{ThumbnailViewLabels.grab}</Semantic.Button>
        <Semantic.Button onClick={() => onChange(ThumbnailModalStates.Pick)}>{ThumbnailViewLabels.pick}</Semantic.Button>
      </ContentActionsContainer>
    </div>

  </ContentContainer>)
}