import { languages } from 'commons/languages';
import { emptySlateDocument } from 'commons/utils';
import { FixedAtTop } from 'components/FixedAtTop';
import { ImageLibraryContainer } from 'containers/mediaLibrary/ImageLibraryContainer';
import { isEqual } from 'lodash-es';
import { Content, Users } from '@visikon/core-models';
import React from 'react';
import { Button, Icon, Modal, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import { Block, Change, Schema, Value } from 'slate';
import EditList from 'slate-edit-list';
import { Editor } from './Editor';
import { EditorPageContainer, EditorTranslateWrapper, ToolbarContainer } from './EditorContainers';
import { translationSchema } from './EditorUtils';
import { LinkModal } from './LinkModal';

const DEFAULT_NODE = 'paragraph';
type ListType = 'ul_list' | 'ol_list';

export interface EditorValue {
  language: Content.LanguageCode;
  content: Value;
  docSchema?: Schema;
  lastModified: number;
}

export const toTranslations = (editorValues: EditorValue[]) => {
  const translations = editorValues.map((ev) => ({
    language: ev.language,
    content: ev.content,
    lastModified: ev.lastModified,
  }));
  return translations;
};

export const toEditorValues = (translations: { language: Content.LanguageCode; content: any; lastModified: number }[]) => {
  const editorValues = translations.map((t) => ({
    language: t.language,
    content: Value.fromJSON(t.content),
    lastModified: t.lastModified,
  }));
  return editorValues;
};

const langSorter = (v1: EditorValue, v2: EditorValue) => v1.language.localeCompare(v2.language);

export enum EditMode {
  Default,
  Split,
  Translate,
}

export enum EditorFocus {
  Primary,
  Secondary,
}

interface IProps {
  editMode: EditMode;
  values: EditorValue[];
  userType: Users.AnyUserKinds;
  primaryLanguage: Content.LanguageCode;
  secondaryLanguages?: Content.LanguageCode[];
  namespace?: string;
  onValueChange(change: Value | null, language: Content.LanguageCode, schema?: Schema): void;
}

interface IState {
  showImagePicker: boolean;
  showVideoPicker: boolean;
  showLinkModal: boolean;

  focus: EditorFocus;
  primaryValue: EditorValue;
  secondaryValue?: EditorValue;
}

const ToolbarButtonText = ({ text }: { text: string }) => (
  <div
    style={{
      marginTop: 10,
      height: 28,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div>{text}</div>
  </div>
);

const ToolbarButtonStyle: React.CSSProperties = {
  width: 94,
  height: 76,
};

export class EditorPage extends React.Component<IProps, IState> {
  listPlugin: any;

  // eslint-disable-next-line react/no-unused-class-component-methods
  plugins: any[];

  constructor(props: IProps) {
    super(props);

    this.listPlugin = EditList();
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.plugins = [this.listPlugin];

    const value = this.props.values.find((v) => v.language === this.props.primaryLanguage) || this.props.values[0];
    this.state = {
      primaryValue: { ...value, docSchema: translationSchema(value.content) },
      focus: EditorFocus.Primary,
      showImagePicker: false,
      // eslint-disable-next-line react/no-unused-state
      showVideoPicker: false,
      showLinkModal: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeByFocus = this.handleChangeByFocus.bind(this);

    this.loadLanguage = this.loadLanguage.bind(this);
    this.loadSecondaryLanguageContent = this.loadSecondaryLanguageContent.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.isInList = this.isInList.bind(this);
    this.onPickedImage = this.onPickedImage.bind(this);
    this.renderMediaModals = this.renderMediaModals.bind(this);
    this.renderLinkModal = this.renderLinkModal.bind(this);
    this.renderTranslatorToolbar = this.renderTranslatorToolbar.bind(this);

    this.getActiveContent = this.getActiveContent.bind(this);
  }

  componentDidMount() {
    if (this.props.editMode !== EditMode.Default) {
      this.updateLanguagesBecauseEditmode();
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.editMode === EditMode.Default && this.props.editMode !== EditMode.Default) {
      this.updateLanguagesBecauseEditmode();
    }
  }

  updateLanguagesBecauseEditmode() {
    this.loadSecondaryLanguageContent();

    if (this.props.editMode === EditMode.Translate) {
      this.setState({ focus: EditorFocus.Secondary });
    }
  }

  loadSecondaryLanguageContent() {
    const explicitChoice = (this.props.secondaryLanguages || []).find((l) => l !== this.state.primaryValue.language);
    const firstAvailable = () => {
      const first = this.props.values.sort(langSorter).find((v) => v.language !== this.state.primaryValue.language);
      return first ? first.language : undefined;
    };
    const lastResort = () => this.allLanguagesBut(this.state.primaryValue.language)[0].value as Content.LanguageCode;

    const lang = explicitChoice || firstAvailable() || lastResort();
    // We need to load another language here:
    this.loadLanguage(lang as Content.LanguageCode, EditorFocus.Secondary);
  }

  getActiveContent(): Value {
    if (this.state.focus === EditorFocus.Secondary && this.state.secondaryValue) {
      return this.state.secondaryValue.content;
    }
    return this.state.primaryValue.content;
  }

  loadLanguage(language: Content.LanguageCode, editor: EditorFocus) {
    const translation = this.props.values.find((v) => v.language === language);
    if (editor === EditorFocus.Primary) {
      // When choosing a new language in the primary editor we just load the content and set the schema
      let newValue: EditorValue;
      if (translation) {
        // If translation exists we just load that, no schema
        newValue = {
          language,
          content: translation.content,
          docSchema: translationSchema(translation.content),
          lastModified: translation.lastModified,
        };
      } else {
        newValue = { language, content: Value.fromJS(emptySlateDocument(language)), lastModified: new Date().getTime() };
      }
      this.setState({ primaryValue: newValue });
    } else if (editor === EditorFocus.Secondary) {
      const { primaryValue } = this.state;
      let newValue: EditorValue;
      if (translation) {
        // If translation exists we just load that
        newValue = { language, content: translation.content, docSchema: translation.docSchema, lastModified: translation.lastModified };
      } else {
        // If no translation is found we load a copy from the primary content
        // newValue = { language, content: Value.fromJSON(primaryValue.content.toJSON()), docSchema: primaryValue.docSchema };
        newValue = {
          language,
          content: Value.fromJS(emptySlateDocument(language)),
          docSchema: primaryValue.docSchema,
          lastModified: new Date().getTime(),
        };
      }
      this.setState({ secondaryValue: newValue });
    }
  }

  handleChangeByFocus(change: any) {
    const { focus } = this.state;
    this.handleChange(change, focus);
  }

  handleChange(change: any, editor: EditorFocus) {
    if (change.value === null) {
      // delete
      const { language } = this.state.primaryValue;
      // Find the new language to show
      const availableContents = this.props.values.filter((content) => content.language !== language);
      const newLanguage = availableContents.length > 0 ? availableContents[0].language : this.props.primaryLanguage;
      this.loadLanguage(newLanguage, EditorFocus.Primary); // we only allow delete in primary editor mode

      const newValue = availableContents.length === 0 ? Value.fromJSON(emptySlateDocument(this.props.primaryLanguage)) : null;
      // Delete by propagating the null value for target language
      this.props.onValueChange(newValue, language);
      return;
    }

    const incomingContent = change.value.document.nodes;
    if (editor === EditorFocus.Primary) {
      const oldContent = this.state.primaryValue.content.document.nodes;
      const { language } = this.state.primaryValue;
      // eslint-disable-next-line react/no-access-state-in-setstate
      const newValue: EditorValue = { ...this.state.primaryValue, content: change.value };

      this.setState({ primaryValue: newValue });
      if (!isEqual(incomingContent, oldContent)) {
        this.props.onValueChange(change.value, language);
      }
    } else if (editor === EditorFocus.Secondary && this.state.secondaryValue) {
      const oldContent = this.state.secondaryValue.content.document.nodes;
      const { language, docSchema } = this.state.secondaryValue;

      // eslint-disable-next-line react/no-access-state-in-setstate
      const newValue: EditorValue = { ...this.state.secondaryValue, content: change.value };

      this.setState({ secondaryValue: newValue });

      if (!isEqual(incomingContent, oldContent)) {
        this.props.onValueChange(change.value, language, docSchema);
      }
    }
  }

  /* ************************************* *
   * Mark functionality - bold, italic etc *
   * ************************************* */

  onClickMark = (event: any, type: string) => {
    event.preventDefault();
    const change = this.getActiveContent().change().toggleMark(type);
    this.handleChangeByFocus(change);
  };

  hasMark = (type: string) => this.getActiveContent().activeMarks.some((mark: any) => mark !== undefined && mark.type === type);

  onClickBlock = (event: any, type: string) => {
    event.preventDefault();
    const change = this.getActiveContent().change();

    if (type !== 'ul_list' && type !== 'ol_list') {
      const isActive = this.hasBlock(type);
      change.setBlocks(isActive ? DEFAULT_NODE : type);
    }

    this.handleChangeByFocus(change);
  };

  hasBlock(type: string) {
    return this.getActiveContent().blocks.some((node) => node !== undefined && node.type === type);
  }

  hasSelection = () => this.getActiveContent().selection.isExpanded;

  /* ************************ *
   * Hyperlink functionality  *
   * ************************ */

  hasLinks = () => this.getActiveContent().inlines.some((inline) => inline !== undefined && inline.type === 'externalLink');

  wrapLink = (href: string) => (change: any) => {
    change.wrapInline({
      type: 'externalLink',
      data: { href },
    });
    return change.collapseToEnd();
  };

  unwrapLink = (change: Change) => change.unwrapInline('externalLink');

  onClickLink = () => {
    // Show link modal
    this.setState({ showLinkModal: true });
  };

  onInsertLink = (linkText: string, url: string) => {
    // Hide link modal
    this.setState({ showLinkModal: false });

    const editorValue = this.getActiveContent();

    if (this.hasSelection()) {
      const change = editorValue.change().call(this.wrapLink(url));
      this.handleChangeByFocus(change);
    } else {
      const change = editorValue.change().insertText(linkText).extend(-linkText.length).call(this.wrapLink(url));
      this.handleChangeByFocus(change);
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  onRemoveLink = () => {
    const hasLink = this.hasLinks();
    if (hasLink) {
      const change = this.getActiveContent().change().call(this.unwrapLink);
      this.handleChangeByFocus(change);
    }
  };

  /* *************************** *
   * List specific functionality *
   * *************************** */

  isInList(type: ListType) {
    const currentList = this.listPlugin.utils.getCurrentList(this.getActiveContent());
    return currentList && currentList.type === type;
  }

  toggleList(type: ListType) {
    const { wrapInList, unwrapList } = this.listPlugin.changes;
    const changeEffect = this.isInList(type) ? unwrapList : wrapInList;

    // We add type to the call
    const addParamType = (change: any, ...args: any[]) => changeEffect(change, type, ...args);

    // Update state
    const customChange = this.getActiveContent().change().call(addParamType);
    this.handleChangeByFocus(customChange);
  }

  /* ******************* *
   * Media functionality *
   * ******************* */
  onPickedImage(image: Content.Image) {
    // dismiss modal
    this.setState({ showImagePicker: false });
    // Add image block
    const imageBlock: Block = Block.create({ type: 'image', data: { idRef: image._id } });
    const change = this.getActiveContent().change().insertBlock(imageBlock);
    this.handleChangeByFocus(change);
  }

  /* *************************** *
   *          Rendering          *
   * *************************** */

  renderToolbar() {
    if (this.props.userType === 'Translator') {
      return this.renderTranslatorToolbar();
    }

    return (
      <ToolbarContainer>
        <FixedAtTop fixOffset={50} showOverlay style={{ display: 'flex', backgroundColor: 'white' }}>
          <Button.Group icon basic>
            <Button style={ToolbarButtonStyle} active={this.hasMark('bold')} onClick={(event) => this.onClickMark(event, 'bold')}>
              <Icon size="large" name="bold" />
              <ToolbarButtonText text="Bold" />
            </Button>
            <Button style={ToolbarButtonStyle} active={this.hasMark('italic')} onClick={(event) => this.onClickMark(event, 'italic')}>
              <Icon size="large" name="italic" />
              <ToolbarButtonText text="Italic" />
            </Button>
          </Button.Group>

          <Button.Group icon basic>
            <Button style={ToolbarButtonStyle} active={this.hasBlock('title')} onClick={(event) => this.onClickBlock(event, 'title')}>
              <Icon size="large" name="header" />
              <ToolbarButtonText text="Header 1" />
            </Button>
            <Button style={ToolbarButtonStyle} active={this.hasBlock('header')} onClick={(event) => this.onClickBlock(event, 'header')}>
              <Icon size="large" name="header" />
              <ToolbarButtonText text="Header 2" />
            </Button>
          </Button.Group>

          <Button.Group icon basic>
            <Button style={ToolbarButtonStyle} active={this.isInList('ul_list')} onClick={() => this.toggleList('ul_list')}>
              <Icon size="large" name="unordered list" />
              <ToolbarButtonText text="Bulleted list" />
            </Button>
            <Button style={ToolbarButtonStyle} active={this.isInList('ol_list')} onClick={() => this.toggleList('ol_list')}>
              <Icon size="large" name="ordered list" />
              <ToolbarButtonText text="Ordered list" />
            </Button>
          </Button.Group>

          <Button.Group icon basic>
            <Button style={ToolbarButtonStyle} active={this.hasBlock('center')} onClick={(event) => this.onClickBlock(event, 'center')}>
              <Icon size="large" name="align center" />
              <ToolbarButtonText text="Center text" />
            </Button>
          </Button.Group>

          <Button.Group icon basic>
            <Button
              style={ToolbarButtonStyle}
              onClick={() => {
                this.setState({ showImagePicker: true });
              }}
            >
              <Icon size="large" name="image" />
              <ToolbarButtonText text="Insert image" />
            </Button>
            <Button style={ToolbarButtonStyle} active={this.hasLinks()} onClick={this.onClickLink}>
              <Icon size="large" name="linkify" />
              <ToolbarButtonText text={this.hasSelection() ? 'Create link' : 'Insert link'} />
            </Button>
          </Button.Group>

          <Button.Group icon basic>
            <Button style={ToolbarButtonStyle}>
              <Icon size="large" name="undo" />
              <ToolbarButtonText text="Undo" />
            </Button>
            <Button style={ToolbarButtonStyle}>
              <i className="horizontally flipped undo large icon" />
              <ToolbarButtonText text="Redo" />
            </Button>
          </Button.Group>
        </FixedAtTop>
      </ToolbarContainer>
    );
  }

  renderTranslatorToolbar() {
    return (
      <ToolbarContainer>
        <FixedAtTop fixOffset={50} showOverlay style={{ display: 'flex', backgroundColor: 'white' }}>
          <Button.Group icon basic>
            <Button style={ToolbarButtonStyle} active={this.hasMark('bold')} onClick={(event) => this.onClickMark(event, 'bold')}>
              <Icon size="large" name="bold" />
              <ToolbarButtonText text="Bold" />
            </Button>
            <Button style={ToolbarButtonStyle} active={this.hasMark('italic')} onClick={(event) => this.onClickMark(event, 'italic')}>
              <Icon size="large" name="italic" />
              <ToolbarButtonText text="Italic" />
            </Button>
          </Button.Group>
        </FixedAtTop>
      </ToolbarContainer>
    );
  }

  renderMediaModals() {
    return (
      <Modal
        size="fullscreen"
        open={this.state.showImagePicker}
        onClose={() => {
          this.setState({ showImagePicker: false });
        }}
      >
        <Modal.Content style={{ maxHeight: '90vh', overflow: 'auto' }}>
          <ImageLibraryContainer onlyPicker onImagePick={this.onPickedImage} />
        </Modal.Content>
      </Modal>
    );
  }

  renderLinkModal() {
    const linkText = this.getActiveContent().change().value.fragment.text;
    const link = this.getActiveContent().inlines.find((inline) => inline !== undefined && inline.type === 'externalLink');
    const url = link !== undefined ? link.data.get('href', '') : '';
    return (
      <LinkModal
        url={url}
        onLinkInsert={this.onInsertLink}
        onDismiss={() => {
          this.setState({ showLinkModal: false });
        }}
        linkText={linkText}
        hasSelection={this.hasSelection()}
        visible={this.state.showLinkModal}
      />
    );
  }

  allLanguagesBut(...except: Content.LanguageCode[]) {
    const translations = this.props.values.map((v) => v.language);
    return languages
      .map((l) => {
        const hasTranslation = translations.indexOf(l.value as Content.LanguageCode) !== -1;
        const icon: SemanticICONS = hasTranslation ? 'check circle outline' : 'circle outline';
        const color: SemanticCOLORS = hasTranslation ? 'green' : 'grey';
        return { ...l, icon: <Icon name={icon} color={color} /> };
      })
      .filter((l) => except.indexOf(l.value as Content.LanguageCode) === -1);
  }

  justTheseLanguages(...include: Content.LanguageCode[]) {
    const translations = this.props.values.map((v) => v.language);
    return languages
      .map((l) => {
        const hasTranslation = translations.indexOf(l.value as Content.LanguageCode) !== -1;
        const icon: SemanticICONS = hasTranslation ? 'check circle outline' : 'circle outline';
        const color: SemanticCOLORS = hasTranslation ? 'green' : 'grey';
        return { ...l, icon: <Icon name={icon} color={color} /> };
      })
      .filter((l) => include.indexOf(l.value as Content.LanguageCode) !== -1);
  }

  render() {
    const defaultMode = this.props.editMode === EditMode.Default;
    const translateMode = this.props.editMode === EditMode.Translate;
    const splitMode = this.props.editMode === EditMode.Split;

    // Filter available language choices with whatever is showing in the other edtior
    const langChoicesPrimary =
      !defaultMode && this.state.secondaryValue ? this.allLanguagesBut(this.state.secondaryValue.language) : this.allLanguagesBut();
    const langChoicesSecondary = this.props.secondaryLanguages
      ? this.justTheseLanguages(...this.props.secondaryLanguages)
      : this.allLanguagesBut(this.state.primaryValue.language);

    return (
      <EditorPageContainer>
        {this.renderToolbar()}
        <EditorTranslateWrapper singleMode={defaultMode}>
          <Editor
            value={this.state.primaryValue.content}
            language={this.state.primaryValue.language}
            languageChoices={langChoicesPrimary}
            namespace={this.props.namespace}
            readOnly={translateMode}
            onLanguageChange={(lang) => this.loadLanguage(lang, EditorFocus.Primary)}
            onValueChange={(c) => this.handleChange(c, EditorFocus.Primary)}
            showArchive={defaultMode}
          />
          {translateMode && this.state.secondaryValue && (
            <Editor
              value={this.state.secondaryValue.content}
              translateMode
              language={this.state.secondaryValue.language}
              languageChoices={langChoicesSecondary}
              namespace={this.props.namespace}
              schema={this.state.secondaryValue.docSchema}
              onLanguageChange={(lang) => this.loadLanguage(lang, EditorFocus.Secondary)}
              onValueChange={(c) => this.handleChange(c, EditorFocus.Secondary)}
            />
          )}
          {splitMode && this.state.secondaryValue && (
            <Editor
              value={this.state.secondaryValue.content}
              language={this.state.secondaryValue.language}
              languageChoices={langChoicesSecondary}
              namespace={this.props.namespace}
              onLanguageChange={(lang) => this.loadLanguage(lang, EditorFocus.Secondary)}
              onValueChange={(c) => this.handleChange(c, EditorFocus.Secondary)}
            />
          )}
        </EditorTranslateWrapper>
        {this.renderMediaModals()}
        {this.renderLinkModal()}
      </EditorPageContainer>
    );
  }
}
