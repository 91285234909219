import { MutableRefObject, useRef } from 'react';

type InitialValueType<T> = T extends any ? T | (() => T) : never;
type RefStateSetter<T> = (value: T) => void;
type RefStateReturn<T> = [T, RefStateSetter<T>, () => T, MutableRefObject<T | null>];

export default function useRefState<T = undefined>(initialValue?: InitialValueType<T>): RefStateReturn<T> {
  const ref = useRef<T | null>(null);

  const get = () => {
    if (ref.current === null && initialValue !== undefined) {
      if (typeof initialValue === 'function') {
        ref.current = initialValue();
      } else {
        ref.current = initialValue as T;
      }
    }

    return ref.current as T;
  };

  const set = (value: T) => {
    ref.current = value;
  };

  return [get(), set, get, ref];
}
