import { ArrayUtils } from 'commons/utils';
import React from 'react';
import { Button, List, Modal, Segment, Popup, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { CustomTypeValue, ICommonBaseProps } from '../CustomTypeInput';
import { CustomTypePicker } from '../CustomTypePicker';
import { CustomTypeAnyListItem } from '../CustomTypeAnyListItem';

function reorderObjectByIndex(obj: object, from: number, to: number) {
  const keys = Object.keys(obj);
  const newKeys = ArrayUtils.arrayMove(keys, from, to) as string[];
  const result: any = {};
  for (const k of newKeys) {
    result[k] = obj[k];
  }
  return result;
}

export interface FieldKeyOption {
  key: string;
  displayName: string;
}

export interface IParametersForMap {
  type: 'map';
  fieldKeys?: FieldKeyOption[];
  desc: string;
}

export interface IComponentMapInputProps extends ICommonBaseProps, IParametersForMap {
  value: {
    [key: string]: CustomTypeValue;
  };
  onChange(key: string, val?: CustomTypeValue): void;
}

interface IState {
  isPickerOpen: boolean;
  fieldName: string;
  popupIsOpen: boolean;
}
export class MapTypeInput extends React.Component<IComponentMapInputProps, IState> {
  styles: { [key: string]: React.CSSProperties } = {
    marginStyle: {
      marginLeft: 20,
    },
    removeButtonStyle: {
      flex: '0 0 auto',
      alignSelf: 'center',
      marginLeft: 10,
    },
    itemStyle: {
      flex: '1 0 auto',
      paddingRight: 10,
    },
    popUpStyle: {
      background: '#fff',
      border: '1px solid #e0b4b4',
      color: '#9f3a38',
      display: 'block',
    },
  };

  constructor(props: IComponentMapInputProps) {
    super(props);

    this.state = { isPickerOpen: false, fieldName: '', popupIsOpen: false };

    this.renderItem = this.renderItem.bind(this);
    this.renderRemoveButton = this.renderRemoveButton.bind(this);
    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.reorderRows = this.reorderRows.bind(this);
    this.dismissPicker = this.dismissPicker.bind(this);
  }

  renderRemoveButton(key: string) {
    return <Button negative icon="minus circle" onClick={() => this.removeRow(key)} />;
  }

  renderItem(key: string) {
    const val = this.props.value[key];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { type, _idRef } = val;

    return (
      <React.Fragment key={key}>
        <List.Item style={{ display: 'flex', padding: 10, flexDirection: 'column' }}>
          <div style={{ marginBottom: 4 }}>
            <b>Field key:</b> {key}
          </div>

          {key === 'notifications' ? (
            <Segment>
              <div
                style={{
                  display: 'flex',
                  padding: 10,
                  alignItems: 'center',
                  marginTop: 0,
                }}
              >
                <Button as={Link} to={`/content/${type}/${_idRef}`} icon="arrow right" content="Go to resource" labelPosition="right" />

                <CustomTypeAnyListItem id={_idRef} type={type} />

                <div style={this.styles.removeButtonStyle}>{this.renderRemoveButton(key)}</div>
              </div>
              {/* Disable Adapative Notifications */}
              {/* <Divider />
              <CustomNotificationList
                id={_idRef}
                type={type}
                onChange={this.props.onChange}
                value={this.props.value}
              /> */}
            </Segment>
          ) : (
            <Segment
              style={{
                display: 'flex',
                padding: 10,
                alignItems: 'center',
                marginTop: 0,
              }}
            >
              <Button as={Link} to={`/content/${type}/${_idRef}`} icon="arrow right" content="Go to resource" labelPosition="right" />

              <CustomTypeAnyListItem id={_idRef} type={type} />

              <div style={this.styles.removeButtonStyle}>{this.renderRemoveButton(key)}</div>
            </Segment>
          )}
        </List.Item>
      </React.Fragment>
    );
  }

  addRow(id: string, type: string) {
    const valueCopy = { ...(this.props.value || {}) };
    const { fieldName } = this.state;
    valueCopy[fieldName] = { _idRef: id, type };

    if (this.state.fieldName === '') {
      this.setState({ popupIsOpen: true });
      setTimeout(() => {
        this.setState({ popupIsOpen: false });
      }, 3500);
    } else {
      this.props.onChange(this.props.pKey, valueCopy);
      this.setState({ isPickerOpen: false, fieldName: '' });
    }
  }

  removeRow(key: string) {
    const valueCopy = { ...(this.props.value || {}) };
    delete valueCopy[key];
    this.props.onChange(this.props.pKey, valueCopy);
  }

  reorderRows(from: number, to: number) {
    const newValue = reorderObjectByIndex(this.props.value, from, to);
    this.props.onChange(this.props.pKey, newValue);
  }

  dismissPicker() {
    this.setState({ isPickerOpen: false });
  }

  render() {
    if (this.props.isTranslation) {
      return null;
    }

    const items = Object.keys(this.props.value || {}).map((key) => this.renderItem(key));

    return (
      <>
        <h4>{this.props.desc}</h4>
        <List style={this.styles.marginStyle} relaxed>
          {items}
        </List>
        <Button
          style={this.styles.marginStyle}
          content="Add item"
          icon="add"
          onClick={() => {
            this.setState({ isPickerOpen: true });
          }}
        />

        <Modal open={this.state.isPickerOpen} onClose={this.dismissPicker} size="fullscreen">
          <Modal.Header style={{ fontSize: 16 }}>
            <Popup
              style={this.styles.popUpStyle}
              open={this.state.popupIsOpen}
              content="Write a field key!"
              position="bottom center"
              trigger={
                <Input
                  label="Field key"
                  value={this.state.fieldName}
                  datalis
                  list="field-key-autocompletion"
                  onChange={(event, data) => {
                    this.setState({ fieldName: data.value });
                  }}
                  placeholder={this.props.fieldKeys?.[0].key ?? 'programVideos'}
                  error={this.state.popupIsOpen}
                />
              }
            />
            <datalist id="field-key-autocompletion">
              {this.props.fieldKeys?.map((option) => (
                <option key={option.key} value={option.key}>
                  {option.displayName}
                </option>
              ))}
            </datalist>
          </Modal.Header>
          <Modal.Content style={{ maxHeight: 'calc(620px)' }} scrolling>
            <br />
            <CustomTypePicker onPick={this.addRow} />
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

export interface IComponentMapRenderProps extends ICommonBaseProps, IParametersForMap {
  value: {
    [key: string]: CustomTypeValue;
  };
}
export function MapTypeCompact({ desc, value }: IComponentMapRenderProps) {
  return (
    <>
      <h4 className="ui header" style={{ margin: 0 }}>
        {value.length} items in list
      </h4>
      <p>
        <i className="ui icon list" /> {desc}
      </p>
    </>
  );
}
