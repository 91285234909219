import { saveCompositionSuccessful, saveCompositionClear, saveCompositionFailed, saveCompositionStarted } from 'actions/BlockActions';
import { Reducer } from 'redux';
import { isType } from 'typescript-fsa';
import moment from 'moment';
import { logger } from '../commons/logger';

export interface UpdateStatus {
  status: string;
  error?: string;
  lastModified?: string;
}
export interface IState {
  newlySavedCompositionId?: string;
  status?: UpdateStatus;
}

const initialState: IState = {};

export const compositionsReducer: Reducer<IState> = (state = initialState, action) => {
  if (isType(action, saveCompositionStarted)) {
    logger.info('SAVE_BLOCK_LIST_STARTED');
    return {
      ...state,
      status: { status: 'STARTED' },
    };
  }
  if (isType(action, saveCompositionSuccessful)) {
    logger.info('SAVE_BLOCK_LIST_SUCCESSFUL', action.payload);

    return {
      ...state,
      newlySavedCompositionId: action.payload._id,
      status: {
        status: 'SUCCEEDED',
        lastModified: moment(action.payload.lastModified).format('DD-MM-YYYY h:mm:ss'),
      },
    };
  }
  if (isType(action, saveCompositionFailed)) {
    logger.error('SAVE_BLOCK_LIST_FAILED', action.payload);
    return {
      ...state,
      status: {
        status: 'FAILED',
        error: action.payload.message,
      },
    };
  }
  if (isType(action, saveCompositionClear)) {
    logger.info('SAVE_BLOCK_LIST_CLEAR');
    return {
      ...state,
      newlySavedCompositionId: undefined,
    };
  }

  return state;
};
