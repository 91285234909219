import { Popup, StrictPopupProps } from 'semantic-ui-react';
import { useRef, useState } from 'react';
import { copyToClipboard } from 'commons/utils';

type TextGetters = ((event: React.MouseEvent) => string | Promise<string>) | string;
interface CopyOnClickProps<ChildrenProps extends { onClick: () => void } = { onClick: () => void }> {
  text: TextGetters;
  closeAfter?: number;
  children: React.ReactElement<ChildrenProps>;
  popupPosition?: StrictPopupProps['position'];
}

interface PopupState {
  open: boolean;
  message: string;
  error: boolean;
}

const DEFAULT_TIMEOUT = 3000;

function ResolveText(text: TextGetters, event: React.MouseEvent): Promise<string> {
  const textResult = typeof text === 'string' ? text : text(event);
  if (typeof textResult === 'string') return Promise.resolve(textResult);

  return textResult;
}

export function CopyOnClick({ text, children, closeAfter, popupPosition }: CopyOnClickProps) {
  const timeoutIdRef = useRef<number>();
  const [popup, setPopup] = useState<PopupState>({ open: false, message: '', error: false });

  const onClick = async (ev: React.MouseEvent) => {
    const timeoutId = timeoutIdRef.current;
    const closePopup = () => {
      if (timeoutId) clearTimeout(timeoutId);
      setPopup({ open: false, message: '', error: false });
    };

    try {
      const textToToBeCopied = await ResolveText(text, ev);
      await copyToClipboard(textToToBeCopied);
      setPopup({ open: true, message: `Copied!`, error: false });
    } catch (error) {
      const unknownError = 'Unknown error';
      const message = error.message ?? unknownError;
      setPopup({ open: true, message: `Failed to copy to clipboard: ${message}`, error: true });
    } finally {
      setTimeout(closePopup, closeAfter ?? DEFAULT_TIMEOUT);
    }
  };

  return (
    <Popup
      className={`${popup.error ? 'negative' : 'positive'}`}
      trigger={<children.type {...children.props} onClick={onClick} />}
      position={popupPosition}
      content={popup.message}
      open={popup.open}
    />
  );
}
