import axios, { AxiosError } from 'axios';
import { APIUtils } from 'commons/utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { tokenState } from 'redux-recoil-sync.state';
import { ProgramCode } from '@visikon/core-models/v2/programs';
import { URLS } from './urls';
import { QueryConfig } from './react-query-utils';

export function useListProgramCodes(programId: string) {
  const [token] = useRecoilState(tokenState);

  return useQuery<ProgramCode[]>(
    ['programCodes', programId],
    async () => {
      const res = await axios.get(URLS.programs(programId).codes.get(), APIUtils.tokenHeader(token!));

      return res.data;
    },
    {
      ...QueryConfig,
      keepPreviousData: true,
    },
  );
}

interface CoreValidationError {
  errors: string[];
}

export function useCreateProgramCode(programId: string) {
  const [token] = useRecoilState(tokenState);
  const queryClient = useQueryClient();

  return useMutation<unknown, AxiosError<CoreValidationError>, string, unknown>(
    async (code: string) => {
      const res = await axios.post(URLS.programs(programId).codes.post(), { code }, APIUtils.tokenHeader(token!));

      return res.data;
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['programCodes', programId]);
      },
    },
  );
}
