import { logOut } from 'actions/AuthActions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

export const LogoutContainer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logOut());
  });

  return (
    <Redirect to={{ pathname: '/' }} />
  );
};
