import { forbiddenErrorClear } from 'actions/AuthActions';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'reducers/reducers';
import { Message } from 'semantic-ui-react';
import styled from 'styled-components';

const MessageContainer = styled.div`
  position: absolute;
  display: flex;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 10px;
  justify-content: center;
  z-index: 10;
`;

export function ForbiddenError() {
  const dispatch = useDispatch();
  const visible = useSelector((state: IState) => state.auth.forbiddenError === true);

  if (!visible) {
    return null;
  }

  return (
    <MessageContainer>
      <Message negative onDismiss={() => dispatch(forbiddenErrorClear())} header="Forbidden" content="You cannot perform this action." />
    </MessageContainer>
  );
}
