import { Route, RouteComponentProps } from 'react-router-dom';
import { BlockComposer } from './compositions/Composer';
import { BlockListOverview } from './compositions/CompositionList';
import { SpeakManuscripts } from './utilities/speak-manuscripts/SpeakManuscripts';
import { BlockOverview } from './blocks/BlockOverview';
import BlockEditor from './blocks/BlockEditor';

type IProps = RouteComponentProps<void>;

export const BlockRoot = (props: IProps) => {
  const { match } = props;

  const renderBlockRoot = () => <h1>Block root</h1>;

  return (
    <>
      <Route path={match.url} exact render={renderBlockRoot} />
      <Route path="/block/composer" exact component={BlockListOverview} />
      <Route path="/block/composer/:id" component={BlockComposer} />
      <Route path="/block/composer/:id/:blockId" component={BlockComposer} />
      <Route path="/block/overview/:id" component={BlockEditor} />
      <Route path="/block/overview" component={BlockOverview} />
      <Route path="/block/speakManuscripts" exact component={SpeakManuscripts} />
    </>
  );
};
