import { Content } from '@visikon/core-models';
import React from 'react';
import { Button, Grid, Input, Transition, Card, Label, Icon, Dropdown, DropdownProps } from 'semantic-ui-react';
import { CustomTypeCompact } from 'components/contentTypes/CustomTypeCompact';
import { getTypeDescription } from 'components/contentTypes/ContentDescriptors';
import { CustomTypeListContainer } from '../content/CustomTypeListContainer';
import { connect, MapStateToProps } from 'react-redux';
import { IState } from 'reducers/reducers';
import { getTranslation } from '@visikon/core-models/typeUtils';
import { CREATE_BATCH_ROUTE, CREATE_NAMED_USER_ROUTE } from './UsersRoot';
import { BATCH_DOMAINS } from './constants';

interface IOwnProps {
  contentName: string;
  resources: Content.CustomType[];
  createSuccess: boolean;
  onCreate(nameOrCount: number | string, expiration: number, resourceId: string[], selectedDomain: string): void;
}

interface IPropsFromState {
  activeLanguage?: Content.LanguageCode;
}

type IProps = IOwnProps & IPropsFromState;

function CCreateForm(props: IProps) {
  const isNamedUser = location.pathname.includes(CREATE_NAMED_USER_ROUTE);
  const isBatch = location.pathname.includes(CREATE_BATCH_ROUTE);

  const [isRunning, setIsRunning] = React.useState(false);
  const [pickResource, setPickResource] = React.useState(false);
  const [nameValue, setNameValue] = React.useState('');
  const [nameValueError, setNameValueError] = React.useState(true);
  const [expirationValue, setExpirationValue] = React.useState(isNamedUser ? '1095' : '365');
  const [expirationValueError, setExpirationValueError] = React.useState(false);
  const [countValue, setCountValue] = React.useState('100');
  const [domainValue, setDomainValue] = React.useState('');
  const [countValueError, setCountValueError] = React.useState(false);
  const [resources, setResources] = React.useState<Content.CustomType[]>([]);

  const addressDefinitions = BATCH_DOMAINS.map((domain) => ({ text: domain, value: domain }));

  const domainOptions = addressDefinitions.map((domain, index) => ({
    key: index,
    text: domain.text,
    value: domain.value,
  }));

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameValueError((nameValue || '').trim() === '');
    setNameValue(event.target.value);
  };

  const handleCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCountValueError(isNaN(Number(countValue)));
    setCountValue(event.target.value);
  };
  const handleDomainChange = (_: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    setDomainValue(data.value as string);
  };

  const handleExpirationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExpirationValueError(isNaN(Number(expirationValue)));
    setExpirationValue(event.target.value);
  };

  const handleResourcePicked = (id: string) => {
    const resource = props.resources.find((r) => r._id === id);
    if (resource === undefined) {
      return;
    }

    const oldResources = resources.filter((r) => r._id !== resource._id);
    const mergedResources = [...oldResources, resource];

    setResources(mergedResources);
    setPickResource(false);
  };

  const handleResourceRemoved = (id: string) => {
    setResources((prevres) => prevres.filter((r) => r._id !== id));
  };

  const handleOnCreate = (nameOrCount: string | number, selectedDomain: string) => {
    const errorType = typeof nameOrCount === 'string' ? nameValueError : countValueError;
    if (resources.length === 0 || expirationValueError || errorType) {
      return;
    }

    setIsRunning(true);
    props.onCreate(
      nameOrCount,
      Number(expirationValue),
      resources.map((r) => r._id),
      selectedDomain,
    );
  };

  const renderResources = () => {
    if (resources.length === 0) {
      return null;
    }

    const typeDescriptor = getTypeDescription(props.contentName);
    if (typeDescriptor === undefined) {
      return null;
    }

    return (
      <Grid.Column>
        <h4>
          {isNamedUser ? 'User will have access to' : null}
          {isBatch ? 'Batch will have access to' : null}
        </h4>
        <Transition.Group>
          <div
            style={{
              maxHeight: 600,
              overflowY: 'scroll',
              paddingLeft: 5,
              marginRight: 20,
              height: '100%',
            }}
          >
            {resources.map((r) => (
              <Card key={r._id} style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    padding: 5,
                    top: 10,
                    right: 7,
                    cursor: 'pointer',
                    zIndex: 1,
                  }}
                  onClick={() => handleResourceRemoved(r._id)}
                >
                  <Icon name="trash alternate" />
                </div>
                <CustomTypeCompact fields={typeDescriptor.fields} value={getTranslation(r, props.activeLanguage!)?.data} />
              </Card>
            ))}
          </div>
        </Transition.Group>
      </Grid.Column>
    );
  };

  const createButtonDisabled =
    isRunning ||
    props.createSuccess ||
    resources.length === 0 ||
    expirationValueError ||
    countValueError ||
    (isBatch && !domainValue) ||
    (isNamedUser && nameValueError);
  const pickerButtonText = resources.length === 0 ? 'Pick resources' : 'Add resources';
  const pickerButtonPositive = resources.length === 0;

  return (
    <Transition.Group animation="horizontal flip" duration={500}>
      {!pickResource && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            paddingBottom: 100,
          }}
        >
          <Grid columns={2} style={{ height: '100%' }}>
            <Grid.Column>
              <label>
                {isNamedUser ? 'Name of user:' : null}
                {isBatch ? 'Number of users in this batch:' : null}
                <br />
                {isBatch ? (
                  <Input value={countValue} error={countValueError} onChange={(e) => handleCountChange(e)} label="users" labelPosition="right" />
                ) : null}
                {isNamedUser ? <Input value={nameValue} error={nameValueError} onChange={handleNameChange} /> : null}
              </label>
              <br />
              <br />
              <label>
                Expiration in days after activation:
                <br />
                <Input value={expirationValue} error={expirationValueError} onChange={handleExpirationChange} label="days" labelPosition="right" />
              </label>
              <br />
              <br />
              {isBatch ? (
                <>
                  <label>
                    Select domain:
                    <br />
                    <Dropdown value={domainValue} placeholder="Domain" selection options={domainOptions} onChange={handleDomainChange} />
                  </label>
                  <br />
                  <br />
                </>
              ) : null}

              <Button
                positive={pickerButtonPositive}
                onClick={() => {
                  setPickResource(true);
                }}
              >
                {pickerButtonText}
              </Button>

              <br />
              <br />
              <br />

              <div style={{ position: 'absolute', bottom: 0 }}>
                <Button
                  primary
                  disabled={createButtonDisabled}
                  loading={isRunning && !props.createSuccess}
                  onClick={() => handleOnCreate(isNamedUser ? nameValue : Number(countValue), domainValue)}
                >
                  {`CREATE ${isNamedUser ? 'USER' : 'BATCH'}`}
                </Button>

                {props.createSuccess && <Label color="green">Success</Label>}
              </div>
            </Grid.Column>
            {renderResources()}
          </Grid>
        </div>
      )}
      {pickResource && (
        <div style={{ position: 'absolute' }}>
          <h3>Pick resource:</h3>
          <div
            style={{
              height: '600px',
              overflowY: 'scroll',
              overflowX: 'hidden',
              padding: 10,
            }}
          >
            <CustomTypeListContainer name={props.contentName} onPick={handleResourcePicked} />
          </div>
        </div>
      )}
    </Transition.Group>
  );
}

const mapStateToProps: MapStateToProps<IPropsFromState, IProps, IState> = (state) => ({
  activeLanguage: state.language.activeLanguage,
});

export const CreateForm = connect(mapStateToProps)(CCreateForm);
