import * as Semantic from 'semantic-ui-react';
import styled from 'styled-components';

export const LanguageContainer = styled.div`
  width: 16px;
  line-height: 0.5;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const Flag = styled(Semantic.Flag)`
  height: 14px !important;
  padding-top: 1px;
`;