import { useToggle } from "commons/toggle";
import * as Semantic from 'semantic-ui-react';
import { AudioDelayInputModal } from "./AudioDelay.modal";

type Props = {
    value: number,
    onChange: (value: number) => void,
};

export function AudioDelayMenuItem({ value, onChange }: Props) {
    const [modalVisible, toggleModal] = useToggle();

    return (
        <>
            {modalVisible && (
                <AudioDelayInputModal
                    isOpen
                    audioDelay={value}
                    onSet={onChange}
                    onDismiss={toggleModal}
                />
            )}
            <Semantic.Dropdown.Item
                icon="clock outline"
                text="Audio delay"
                onClick={toggleModal}
            />
        </>
    );
}
