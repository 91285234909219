import { Block, BlockSpeak, BlockVideo, LanguageCode } from '@visikon/core-models/content';
import { API, appEnv } from '../../config';
import { Environment } from '../../commons/env';
import { ClipResource, ClipResourceType, VideoClip } from '../../components/player/video-player.models';
import { matchLanguageOrFallback } from '@visikon/core-models/helpers/language-fallback';

export function getBlockItems(block: Block, lang: LanguageCode, videoVariation?: string, speakVariation?: string) {
  const blockVideo = matchLanguageOrFallback(block.videos, lang, videoVariation);
  const blockSpeak = matchLanguageOrFallback(block.speaks, lang, speakVariation);

  return {
    video: asBlockItem(
      blockVideo,
      block,
    ),
    audio: asBlockItem(
      blockSpeak,
      block,
    )
  } as VideoClip
}

export function getBlockVideoUrl(block: Block, lang: LanguageCode, variation?: string): string | undefined {
  const blockVideo = matchLanguageOrFallback(block.videos, lang, variation);
  return getBlockResourceUrl(blockVideo, block);
}

export function getBlockAudioUrl(block: Block, lang: LanguageCode, variation?: string): string | undefined {
  const blockSpeak = matchLanguageOrFallback(block.speaks, lang, variation);
  return getBlockResourceUrl(blockSpeak, block);
}

export function getBlockResourceUrl(blockItem: BlockVideo | BlockSpeak | undefined, block: Block) {
  if (!blockItem || !block) {
    return undefined;
  }
  return asBlockItem(blockItem, block)?.src;
}

export function asBlockItem(blockItem: BlockVideo | BlockSpeak | undefined, block: Block): ClipResource | undefined {
  if (!blockItem || !block) {
    return undefined;
  }

  return {
    type: getBlockType(blockItem),
    src: getBlockItemResourceUrl(blockItem, block),
    duration: isVideo(blockItem)
      ? (blockItem as BlockVideo).videoFile?.duration
      : (blockItem as BlockSpeak).audioFile?.duration,
  };
}

function getBlockItemResourceUrl(blockItem: BlockVideo | BlockSpeak, block: Block) {
  const type = getBlockType(blockItem);
  const src = isVideo(blockItem)
    ? (blockItem as BlockVideo).videoFile?.src
    : (blockItem as BlockSpeak).audioFile?.src;

  if(!src) {
    return undefined;
  }
  if (appEnv === Environment.Local) {
    return `${API.baseURL}blocks/media/${block._id}/${type}/${blockItem?.language}/${blockItem.variations[0]}?lastModified=${block?.lastModified}`;
  }
  return `${API.baseBlobUrl}${src}`;
}

function getBlockType(blockItem: BlockVideo | BlockSpeak) {
  return isVideo(blockItem)
    ? ClipResourceType.Video
    : ClipResourceType.Audio;
}

function isVideo(blockItem: BlockVideo | BlockSpeak) {
  // eslint-disable-next-line no-prototype-builtins
  return blockItem.hasOwnProperty('videoFile');
}
