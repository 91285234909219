import styled from 'styled-components';

export const Form = styled.div`
  display: flex;
`;

export const FormColumn = styled.div`
  flex: 1
`;

export const FormHeading = styled.div`
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
`;

export const TextInput = styled.input`
`;

export const DisabledTextInput = styled.div`
       border: 1px solid rgba(34,36,38,.15);
       color: rgba(0,0,0,.87);
       border-radius: .28571429rem;
       box-shadow: 0 0 0 0 transparent inset;
       background: #dddddd;
       padding: .67857143em 1em;
       line-height: 1.21428571em;
       margin-bottom: 16px;
`;

// {
//     font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
//     margin: 0;
//     outline: 0;
//     -webkit-appearance: none;
//     -webkit-tap-highlight-color: rgba(255,255,255,0);
//     line-height: 1.21428571em;
//     padding: .67857143em 1em;
//     font-size: 1em;
//     background: #fff;
//     border: 1px solid rgba(34,36,38,.15);
//     color: rgba(0,0,0,.87);
//     border-radius: .28571429rem;
//     box-shadow: 0 0 0 0 transparent inset;
//     transition: color .1s ease,border-color .1s ease;
// }
// .ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url], .ui.form textarea {
//     width: 100%;
//     vertical-align: top;
// }
// <style>
//     .lepErC:disabled {
//     background: #dddddd !important;
// }
