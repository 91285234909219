import { useState } from 'react';
import { Button, Icon, Label } from 'semantic-ui-react';
import { getPublishStatus } from 'components/PublishStatus';
import { connect, MapDispatchToProps, MapStateToProps, useSelector } from 'react-redux';
import {
  clearCreateSuccess as clearCreateSuccessAction,
  IPublishedContent,
  publishContent,
  toggleApproved,
  toggleDisable,
} from 'actions/publisherAction';
import { toggleArchived } from 'actions/ContentActions';
import { IState } from 'reducers/reducers';
import { CustomTypeValue, ITypeHeaderProps } from 'components/contentTypes/CustomTypeInput';
import { Content } from '@visikon/core-models';
import { CustomTypeWithPublishState } from '../CustomTypeEditor';
import ProgramCodeListModal from '../ProgramCodeListModal';
import { PublisherModal } from '../publisher/Publisher.modal';
import { HeaderWrapper } from './ListHeaderWrapper';
import { ArchiveButton } from './ArchiveButton';
import { PublisherVersionsModal } from '../publisher/PublisherVersions.modal';
import { API } from 'config';
import { langFromCode } from 'commons/languages';

interface IPropsFromState {
  entry?: CustomTypeWithPublishState;
  allRevisions?: IPublishedContent[];
  isPublisherWorking: boolean;
  createSuccess: boolean;
  createError: string | undefined;
}

interface IPropsFromDispatch {
  clearCreateSuccess(): void;
  toggleArchived(val: Content.CustomType): void;
  publishProgram(orginalId: string): void;
  onApprove(id: string, originalId: string): void;
  onDisable(id: string, originalId: string): void;
}

type IProps = ITypeHeaderProps & IPropsFromState & IPropsFromDispatch;

function getModulePreviewUrl(id: string, language: Content.LanguageCode, { live, preview }: Content.PublishedRevisions) {
  const search = new URLSearchParams({ reviewer: 'true' });
  if (live?.path) search.set('live_path', live?.path);
  if (preview?.path) search.set('preview_path', preview?.path);

  return `${API.baseModulePreviewUrl}/${id}/${language}?${search.toString()}`;
}

interface ModulePreviewButtonProps {
  id: string;
  revisions?: Content.PublishedRevisions;
}

function ModulePreviewButton({ id, revisions }: ModulePreviewButtonProps) {
  const languageCode = useSelector((state: IState) => state.language.activeLanguage);
  const language_info = langFromCode(languageCode);
  const url = getModulePreviewUrl(id, languageCode, revisions || {});

  return (
    <Button as="a" target="_blank" href={url} disabled={!revisions}>
      <i className={`${language_info.flag} flag`} />
      Preview
    </Button>
  );
}

const CProgramHeader = (props: IProps) => {
  const [confirmPublish, setConfirmPublish] = useState(false);
  const { clearCreateSuccess, id, entry, typeDescription, isPublisherWorking } = props;

  const handlePublishedContent = () => {
    props.publishProgram(id);
  };

  const renderProgramPanel = () => {
    const { isLive, color, statusText } = getPublishStatus(entry?.published);
    const toolTip = statusText ? { 'data-tooltip': statusText, 'data-inverted': !isLive || undefined } : {};

    return (
      <HeaderWrapper>
        {entry?._id && confirmPublish && (
          <PublisherModal
            id={entry?._id}
            open={confirmPublish}
            onCancel={() => setConfirmPublish(false)}
            onConfirm={() => {
              handlePublishedContent();
              setConfirmPublish(false);
            }}
          />
        )}
        <Button
          loading={isPublisherWorking}
          disabled={isPublisherWorking}
          onClick={() => {
            setConfirmPublish(true);
          }}
        >
          Publish
        </Button>
        {props.createSuccess && <Label color="green">Success</Label>}
        {props.createError && (
          <Label color="red">
            Error: {props.createError}
            <Icon name="delete" onClick={() => clearCreateSuccess()} />
          </Label>
        )}

        <PublisherVersionsModal {...props}>
          <Button color={color} {...toolTip}>
            Version history
          </Button>
        </PublisherVersionsModal>

        <ModulePreviewButton id={id} revisions={entry?.published} />

        <ProgramCodeListModal id={id} />

        {entry && <ArchiveButton entry={entry} onToggle={props.toggleArchived} />}
      </HeaderWrapper>
    );
  };

  if (!typeDescription) {
    return null;
  }

  return <>{renderProgramPanel()}</>;
};

const mapStateToProps: MapStateToProps<IPropsFromState, IProps, IState> = (state, props) => {
  const contentType = props.type;
  const { id } = props;
  const data = contentType in state.content.data ? state.content.data[contentType] : [];

  return {
    entry: data.find((d) => d._id === id),
    allRevisions: state.publishContent.getAll,
    isPublisherWorking: state.publishContent.isWorking,
    createSuccess: state.publishContent.creationSuccess,
    createError: state.publishContent.creationError,
  };
};

const mapDispatchToProps: MapDispatchToProps<IPropsFromDispatch, ITypeHeaderProps> = (dispatch) => ({
  clearCreateSuccess() {
    dispatch(clearCreateSuccessAction());
  },
  toggleArchived: (val: CustomTypeValue) => {
    dispatch(toggleArchived(val));
  },
  publishProgram: (orginalId: string) => {
    dispatch(publishContent({ id: orginalId }));
  },
  onApprove: (id: string, originalId: string) => {
    dispatch(toggleApproved({ id, originalId }));
  },
  onDisable: (id: string, originalId: string) => {
    dispatch(toggleDisable({ id, originalId }));
  },
});

export const ProgramHeader = connect(mapStateToProps, mapDispatchToProps)(CProgramHeader);
