import React from 'react';
import { Image } from '@visikon/core-models/content';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { ImageLibraryContainer } from 'containers/mediaLibrary/ImageLibraryContainer';
import { PLACEHOLDER_IMAGE } from 'commons/utils';
import { ImageFileWrapper } from 'containers/mediaLibrary/ImageFileWrapper';
import { ICommonBaseProps, CustomTypeValue } from '../CustomTypeInput';
import { Link } from 'react-router-dom';

export interface IParametersForImage {
  type: 'image';
  desc: string;
  size?: 'normal' | 'small';
}

export interface IComponentImageInputProps extends ICommonBaseProps, IParametersForImage {
  value?: {
    type: 'image';
    _idRef: string;
  };
  onChange(key: string, val: CustomTypeValue): void;
}

interface IComponentState {
  isPickerOpen: boolean;
}
export class ImageTypeInput extends React.Component<IComponentImageInputProps, IComponentState> {
  constructor(props: IComponentImageInputProps) {
    super(props);

    this.state = { isPickerOpen: false };

    this.dismissPicker = this.dismissPicker.bind(this);
    this.onImagePicked = this.onImagePicked.bind(this);
    this.onImageClear = this.onImageClear.bind(this);
  }

  dismissPicker() {
    this.setState({ isPickerOpen: false });
  }

  onImagePicked(img: Image) {
    this.props.onChange(this.props.pKey, { _idRef: img._id, type: 'image' });
    this.setState({ isPickerOpen: false });
  }

  onImageClear() {
    this.props.onChange(this.props.pKey, undefined);
  }

  render() {
    if (this.props.isTranslation) {
      return null;
    }

    const hasImage = this.props.value;
    const buttonText = hasImage ? 'Change image' : 'Pick image';
    const imageSize = this.props.size === 'small' ? 'small' : 'large';
    const classes = `ui ${imageSize} bordered image`;
    return (
      <>
        <h4>{this.props.desc}</h4>
        {hasImage && <ImageFileWrapper className={classes} imageId={this.props.value!._idRef} />}
        {!hasImage && <img alt="" className={classes} src={PLACEHOLDER_IMAGE} />}
        <br />
        <Button
          onClick={() => {
            this.setState((prevState) => ({ isPickerOpen: !prevState.isPickerOpen }));
          }}
        >
          {buttonText}
        </Button>
        {hasImage && (
          <>
            <Button onClick={() => this.onImageClear()} color="yellow">
              Remove image
            </Button>
            <Button as={Link} to={`/media/images/${this.props.value!._idRef}`}>
              <Icon name="file image outline" />
              Go to Image
            </Button>
          </>
        )}
        <Modal open={this.state.isPickerOpen} onClose={() => this.dismissPicker()} size="fullscreen">
          <Modal.Content style={{ maxHeight: '90vh', overflow: 'auto' }}>
            <ImageLibraryContainer onlyPicker onImagePick={(i) => this.onImagePicked(i)} />
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

export interface IComponentImageRenderProps extends ICommonBaseProps, IParametersForImage {
  value: Image;
}
export function ImageTypeCompact({ desc }: IComponentImageRenderProps) {
  return (
    <>
      <p>
        <i className="ui icon image" />
        {desc}
      </p>
    </>
  );
}
