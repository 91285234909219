import { ImageLibraryContainer } from 'containers/mediaLibrary/ImageLibraryContainer';
import { Content } from '@visikon/core-models';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { EmbedImageDialog } from './EmbedImageDialog';
import { EmbedMainWrapper } from './EmbedShared';
// import { EmbedImageDialog } from "./EmbedImagesDialog";

const ImageLibraryWrapper = styled.div`
    &>div {
        margin-top: 20px;
    }

    &>div>h1 {
        display: none;
    }
`;

type IProps = RouteComponentProps<{}>;

interface IState {
  activeImage: Content.Image | undefined;
}
export class EmbedImages extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = { activeImage: undefined };

    this.onImagePick = this.onImagePick.bind(this);
  }

  onImagePick(video: Content.Image) {
    this.setState({ activeImage: video });
  }

  render() {
    return (
      <EmbedMainWrapper>
        <div>
          <Link to="/" style={{ marginLeft: 20 }}>
            <Button basic color="blue" icon labelPosition="left">
              <Icon name="arrow left" />
              Back
            </Button>
          </Link>

          <ImageLibraryWrapper>
            <ImageLibraryContainer onlyPicker onImagePick={this.onImagePick} />
          </ImageLibraryWrapper>

          {this.state.activeImage
                        && (
                        <EmbedImageDialog
                          image={this.state.activeImage}
                          onDismiss={() => this.setState({ activeImage: undefined })}
                        />
                        )}
        </div>
      </EmbedMainWrapper>
    );
  }
}
