import { Block } from '@visikon/core-models/content';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { BlockListCard } from '../compositions/components/Card';
import { useBlockUsage } from 'api/blocksApi';
import { Loader } from 'semantic-ui-react';
import { PlaceHolderSegment } from 'components/PlaceholderSegment';

const Wrapper = styled.div<{ height: number }>`
  min-height: ${(p) => p.height}px;
  margin: 30px;
`;

interface IOwnProps {
  // eslint-disable-next-line react/no-unused-prop-types
  block: Block;
  height?: number;
}

export function BlockEntryUsage({ height = 600, block }: IOwnProps) {
  const history = useHistory();
  const blockUsageQuery = useBlockUsage(block._id);

  if (blockUsageQuery.isLoading) {
    return (
      <PlaceHolderSegment>
        <Loader active inline="centered">
          Loading block usage
        </Loader>
      </PlaceHolderSegment>
    );
  }

  if (blockUsageQuery.isError) {
    const { error } = blockUsageQuery;
    const isErrorObject = typeof error === 'object' && error instanceof Error;
    const message = isErrorObject ? error.message : 'Unknown error';

    return (
      <PlaceHolderSegment iconName="exclamation triangle" header="Error happend trying to load block usage">
        <pre>{message}</pre>
      </PlaceHolderSegment>
    );
  }

  // Handle no usage
  if (blockUsageQuery.data?.count === 0) {
    return <PlaceHolderSegment iconName="search minus">No usage found for this block</PlaceHolderSegment>;
  }

  return (
    <Wrapper height={height || 600}>
      <div className="ui cards">
        {blockUsageQuery.data?.compositions.map((bl) => (
          <BlockListCard
            key={bl._id}
            composition={bl}
            onClick={(id) => {
              history.push(`/block/composer/${id}`);
            }}
          />
        ))}
      </div>
    </Wrapper>
  );
}
