import styled from 'styled-components';

export const Container = styled.div`
    margin: 20px;
    @media print {
        margin: 0;
    }
`;

export const Item = styled.div`
  white-space: nowrap;
  font-weight: bold;
  padding-left: 10px;
`;