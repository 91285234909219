import styled from 'styled-components';

const Button = styled.button`
  border: 0;
  background: initial;
  color: #4183c4;
  
  &:hover {
    color: #1e70bf;
    text-decoration: underline;
  }
`;

export function LinkButton({children, onClick}: {children: string, onClick: () => void}) {
  return (
    <Button type="button" onClick={onClick}>{children}</Button>
  );
}