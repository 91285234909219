import { Content } from '@visikon/core-models';
import actionCreatorFactory from 'typescript-fsa';
import { ObjectId } from '@visikon/core-models/base';

const actionCreator = actionCreatorFactory('Language');

export const changeLanguageFromLocalStorage = actionCreator('CHANGE_LANGUAGE_LOCAL_STORAGE');
export const changeLanguage = actionCreator<Content.LanguageCode>('CHANGE_LANGUAGE');

// export const changeMediaVariationFromLocalStorage = actionCreator("CHANGE_MEDIA_VARIATION_LOCAL_STORAGE");
export const changeMediaVariation = actionCreator<ObjectId>('CHANGE_MEDIA_VARIATION');
