import actionCreatorFactory from 'typescript-fsa';
import { Content, UploadTypes } from '@visikon/core-models';
import { ObjectId } from '@visikon/core-models/base';
import { PublishedRevisions } from '@visikon/core-models/content';
// import { IAuthRequest, IAuthToken, IAuthError } from "reducers/authReducer";

const actionCreator = actionCreatorFactory('CustomContent');

export const contentApiError = actionCreator<{ msg: string }>('CONTENT_API_ERROR');
export const clearApiError = actionCreator('CLEAR_CONTENT_API_ERROR');

export interface IContentListResult {
  type: string;
  result: Content.CustomType[];
}
export const listContentByType = actionCreator<string>('LIST_CONTENT_BY_TYPE');
export const listContentByTypeSuccess = actionCreator<IContentListResult>('LIST_CONTENT_BY_TYPE_SUCCESS');
export const listProgramCodes = actionCreator('LIST_PROGRAM_CODES');
export const listProgramCodesSuccess = actionCreator<any>('LIST_PROGRAM_CODES_SUCCESS');

export interface IContentLoadType {
  type: string;
  id: ObjectId;
}
export const loadContentEntry = actionCreator<IContentLoadType>('LOAD_CONTENT_ENTRY');
export const loadContentEntrySuccess = actionCreator<Content.CustomType>('LOAD_CONTENT_ENTRY_SUCCESS');

// export const saveContent = actionCreator<UploadTypes.CustomType | Content.CustomType>("SAVE_CONTENT_ENTRY");
// export const saveContentSuccess = actionCreator<Content.CustomType>("SAVE_CONTENT_ENTRY_SUCCESS");

export interface ICreateContentType extends UploadTypes.CustomType {
  copy?: boolean;
}

export type PublishState = 'Published' | 'Not Approved' | 'Unpublished';
export interface IPublishedProgramType {
  publishState?: PublishState;
  published?: PublishedRevisions;
  withLabel?: boolean;
}

export const createContent = actionCreator<ICreateContentType & IPublishedProgramType>('CREATE_CONTENT');
export const createContentSuccess = actionCreator<Content.CustomType & IPublishedProgramType>('CREATE_CONTENT_SUCCESS');

export const updateContent = actionCreator<Content.CustomType & IPublishedProgramType>('UPDATE_CONTENT');
export const updateContentSuccess = actionCreator<Content.CustomType & IPublishedProgramType>('UPDATE_CONTENT_SUCCESS');

export const copyContent = actionCreator<{ contentId: string }>('COPY_CONTENT');
// copyContentSuccess is the same as createContentSuccess

export const toggleArchived = actionCreator<Content.CustomType>('TOGGLE_ARCHIVED');
export const toggleArchivedSuccess = actionCreator<Content.CustomType>('TOGGLE_ARCHIVED_SUCCESS');
