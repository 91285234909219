import { Content, UploadTypes } from '@visikon/core-models';
import { ObjectId } from '@visikon/core-models/base';
import * as AuthActions from 'actions/AuthActions';
import { generateThumbnailSuccess } from 'actions/BlockActions';
import * as LanguageActions from 'actions/LanguageActions';
import * as MediaActions from 'actions/MediaActions';
import * as MediaAPI from 'api/mediaApi';
import { AxiosResponse } from 'axios';
import { IState } from 'reducers/reducers';
import {
  call, delay, put, select, takeEvery
} from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import {
  getLanguage, getLoadedImages, getLoadedVideos, getMediaVariation, getToken, handleAuthorizationError
} from './saga-helpers';
import { invalidateQueries } from 'actions/ReactQueryActions';
import { CACHE_KEY_IMAGE, CACHE_KEY_IMAGE_SEARCH } from 'api/imageLibraryApi';

interface WithIds {
  _id: string;
}
interface Folders<T> {
  [folder: string]: T[];
}

function searchInFolders<A extends WithIds>(folders: Folders<A>, id: ObjectId): A | undefined {
  for (const k of Object.keys(folders)) {
    const find = folders[k].find((entry) => entry._id === id);
    if (find) {
      return find;
    }
  }
  return undefined;
}

// ******** Images ******** //

// function* listImagesInFolder(action: Action<string>) {
//   try {
//     // @ts-ignore
//     const token = yield select(getToken);
//     const folderId = action.payload;
//     const response: AxiosResponse = yield call(MediaAPI.listImagesInFolder, token, folderId);

//     if (response.status === 200) {
//       const result: Content.Image[] = response.data;
//       yield put(MediaActions.listImagesInFolderSuccess({ folderId, result }));
//     } else {
//       yield put(MediaActions.mediaLibraryError({ msg: response.data }));
//     }
//   } catch (e) {
//     yield handleAuthorizationError(e.response);
//     yield put(MediaActions.mediaLibraryError({ msg: e.message }));
//   }
// }

function* loadImageById(action: Action<string>) {
  try {
    const imageId = action.payload;
    const loadedImages: Folders<Content.Image> = yield select(getLoadedImages) || {};

    // Search for already loaded image
    const existing = searchInFolders(loadedImages, imageId);
    if (existing !== undefined) {
      return;
    }

    // @ts-ignore
    const token = yield select(getToken);
    const response: AxiosResponse = yield call(MediaAPI.loadImage, token, imageId);

    if (response.status === 200) {
      const result: Content.Image = response.data;
      yield put(MediaActions.loadImageSuccess(result));
    } else {
      yield put(MediaActions.mediaLibraryError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(MediaActions.mediaLibraryError({ msg: e.message }));
  }
}

function* createImage(action: Action<UploadTypes.Image>) {
  try {
    yield put(MediaActions.isUploading(true));
    // @ts-ignore
    const token = yield select(getToken);
    // @ts-ignore
    const language = yield select(getLanguage);
    // @ts-ignore
    const variation = yield select(getMediaVariation);
    const imageUpload = action.payload;
    const response: AxiosResponse = yield call(MediaAPI.createImage, token, imageUpload);

    if (response.status === 200) {
      const image: Content.Image = response.data;
      const { file } = imageUpload;
      yield put(MediaActions.uploadImageFile({
        image, language, variation, file,
      }));
      yield put(MediaActions.isUploading(false));
      yield put(invalidateQueries([CACHE_KEY_IMAGE, CACHE_KEY_IMAGE_SEARCH]))
      yield put(MediaActions.setNewlyCreatedId(image._id));
    } else {
      yield put(MediaActions.mediaLibraryError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(MediaActions.mediaLibraryError({ msg: e.message }));
  }
}

function* updateImage(action: Action<Content.Image>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const image = action.payload;
    const response: AxiosResponse = yield call(MediaAPI.updateImage, token, image);

    if (response.status === 200) {
      // const newImage: Content.Image = response.data;
      // TODO: Set React Query data using QueryClient.setQueryData()
      yield put(invalidateQueries([CACHE_KEY_IMAGE]))
    } else {
      yield put(MediaActions.mediaLibraryError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(MediaActions.mediaLibraryError({ msg: e.message }));
  }
}

function* uploadImageFile(action: Action<MediaActions.UploadImageFile>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);

    const {
      image, language, variation, file,
    } = action.payload;
    // const image: UploadTypes.ImageFile = yield call(convertFileToUploadImage, action.payload.image, action.payload.language, action.payload.file);
    const response: AxiosResponse = yield call(MediaAPI.uploadImageFile, token, image._id, language, variation, file);

    if (response.status === 200) {
      // const res: Content.Image = response.data;
      // listen for this action to invalidate cached images
      // TODO: Set React Query data using QueryClient.setQueryData()
      yield put(invalidateQueries([CACHE_KEY_IMAGE]));
      // Update list of images in current folder
      // yield put(MediaActions.listImagesInFolder(image.folder!));
    } else {
      yield put(MediaActions.mediaLibraryError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(MediaActions.mediaLibraryError({ msg: e.message }));
  }
}

// ******** Videos ******** //

function* listVideosInFolder(action: Action<string>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const folderId = action.payload;
    const response: AxiosResponse = yield call(MediaAPI.listVideosInFolder, token, folderId);

    if (response.status === 200) {
      const result: Content.Video[] = response.data;
      yield put(MediaActions.listVideosInFolderSuccess({ folderId, result }));
    } else {
      yield put(MediaActions.mediaLibraryError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(MediaActions.mediaLibraryError({ msg: e.message }));
  }
}

function* loadVideoById(action: Action<string>) {
  try {
    const videoId = action.payload;
    const loadedVideos: Folders<Content.Video> = yield select(getLoadedVideos) || {};

    // Search for already loaded image
    const existing = searchInFolders(loadedVideos, videoId);
    if (existing !== undefined) {
      return;
    }

    // @ts-ignore
    const token = yield select(getToken);
    const response: AxiosResponse = yield call(MediaAPI.loadVideo, token, videoId);

    if (response.status === 200) {
      const result: Content.Video = response.data;
      yield put(MediaActions.loadVideoSuccess(result));
    } else {
      yield put(MediaActions.mediaLibraryError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(MediaActions.mediaLibraryError({ msg: e.message }));
  }
}

function* createVideo(action: Action<UploadTypes.Video>) {
  try {
    yield put(MediaActions.isUploading(true));
    // @ts-ignore
    const token = yield select(getToken);
    // @ts-ignore
    const language = yield select(getLanguage);
    // @ts-ignore
    const variation = yield select(getMediaVariation);
    const videoUpload = action.payload;
    const response: AxiosResponse = yield call(MediaAPI.createVideo, token, videoUpload);

    if (response.status === 200) {
      const video: Content.Video = response.data;
      const { file } = videoUpload;
      const { duration } = videoUpload;
      yield put(MediaActions.uploadVideoFile({
        video, language, variation, file, duration,
      }));
      yield put(MediaActions.createVideoSuccess());
      // // Update list of images in current folder
      // yield put(MediaActions.listImagesInFolder(imageUpload.folder));
      yield put(MediaActions.isUploading(false));
      yield put(MediaActions.setNewlyCreatedId(video._id));
    } else {
      yield put(MediaActions.mediaLibraryError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(MediaActions.mediaLibraryError({ msg: e.message }));
  }
}

function* updateVideo(action: Action<Content.Video>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const video = action.payload;
    const response: AxiosResponse = yield call(MediaAPI.updateVideo, token, video);

    if (response.status === 200) {
      const newVideo: Content.Video = response.data;
      yield put(MediaActions.updateVideoSuccess(newVideo));
    } else {
      yield put(MediaActions.mediaLibraryError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(MediaActions.mediaLibraryError({ msg: e.message }));
  }
}

function* uploadVideoFile(action: Action<MediaActions.UploadVideoFile>) {
  yield put(MediaActions.isUploading(true));
  try {
    // @ts-ignore
    const token = yield select(getToken);

    const {
      video, language, variation, file, duration,
    } = action.payload;
    const response: AxiosResponse = yield call(MediaAPI.uploadVideoFile, token, video._id, language, variation, file, duration);

    if (response.status === 200) {
      const res: Content.Video = response.data;
      yield delay(750);
      yield put(MediaActions.updateVideoSuccess(res));
    } else {
      yield put(MediaActions.mediaLibraryError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(MediaActions.mediaLibraryError({ msg: e.message }));
  } finally {
    yield put(MediaActions.isUploading(false));
  }
}

function* uploadVideoThumbnail(action: Action<MediaActions.UploadVideoThumbnail>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);

    const {
      video, language, variation, file,
    } = action.payload;
    const response: AxiosResponse = yield call(MediaAPI.uploadVideoThumbnail, token, video._id, language, variation, file);

    if (response.status === 200) {
      const res: Content.Video = response.data;
      yield put(MediaActions.updateVideoSuccess(res));
    } else {
      yield put(MediaActions.mediaLibraryError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(MediaActions.mediaLibraryError({ msg: e.message }));
  }
}

function* uploadVideoSubtitles(action: Action<MediaActions.UploadVideoThumbnail>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);

    const {
      video, language, variation, file,
    } = action.payload;
    const response: AxiosResponse = yield call(MediaAPI.uploadVideoSubtitles, token, video._id, language, variation, file);

    if (response.status === 200) {
      const res: Content.Video = response.data;
      yield put(MediaActions.updateVideoSuccess(res));
    } else {
      yield put(MediaActions.mediaLibraryError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(MediaActions.mediaLibraryError({ msg: e.message }));
  }
}

function* uploadBlockListThumbnail(action: Action<MediaActions.BlockListThumbnail>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);

    const { blockList, language, file } = action.payload;
    const response: AxiosResponse = yield call(MediaAPI.uploadBlockListThumbnail, token, blockList._id, language, file);

    if (response.status === 200) {
      const res: Content.BlockList = response.data;
      yield put(MediaActions.updateBlockSuccess(res));
      yield put(generateThumbnailSuccess());
    } else {
      yield put(MediaActions.mediaLibraryError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(MediaActions.mediaLibraryError({ msg: e.message }));
  }
}

function* uploadThumbnail(action: Action<MediaActions.UploadThumbnail>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);

    const { name, file } = action.payload;
    const response: AxiosResponse = yield call(MediaAPI.uploadThumbnail, token, name, file);

    if (response.status === 200) {
      yield put(MediaActions.uploadThumbnailSuccess(response.data.source));
    } else {
      yield put(MediaActions.mediaLibraryError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(MediaActions.mediaLibraryError({ msg: e.message }));
  }
}

// ******** Media Variations ******** //

function* listMediaVariations() {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const response: AxiosResponse = yield call(MediaAPI.listMediaVariations, token);

    if (response.status === 200) {
      const variations: Content.MediaVariation[] = response.data;
      yield put(MediaActions.listMediaVariationsSuccess(variations));

      // Check if we should set selected GUI media variation
      // @ts-ignore
      const currentMediaVariation = yield select(getMediaVariation);
      if (currentMediaVariation === '' && variations.length > 0) {
        const base = variations.find((v) => v.name.toLowerCase() === 'base');
        yield put(LanguageActions.changeMediaVariation((base || variations[0])._id));
      }
    } else {
      yield put(MediaActions.mediaLibraryError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(MediaActions.mediaLibraryError({ msg: e.message }));
  }
}

function* createMediaVariation(action: Action<UploadTypes.MediaVariation>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const mediaVariation = action.payload;
    // tslint:disable-next-line
    const response: AxiosResponse = yield call(MediaAPI.createMediaVariation, token, mediaVariation);

    if (response.status === 200) {
      const variation: Content.MediaVariation = response.data;
      yield put(MediaActions.createMediaVariationSuccess(variation));
    } else {
      yield put(MediaActions.mediaLibraryError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(MediaActions.mediaLibraryError({ msg: e.message }));
  }
}

// ******** Texts ******** //

function* saveText(action: Action<UploadTypes.Text | Content.Text>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const text = action.payload;

    const isCreatingNew = !('_id' in action.payload);

    let apiCall;
    if (isCreatingNew) {
      apiCall = call(MediaAPI.saveText, token, text as UploadTypes.Text);
    } else {
      apiCall = call(MediaAPI.updateText, token, text as Content.Text);
    }
    const response: AxiosResponse = yield apiCall;

    if (response.status === 200) {
      const savedText: Content.Text = response.data;
      yield put(MediaActions.saveTextSuccess(savedText));
      if (isCreatingNew) {
        yield put(MediaActions.setNewlyCreatedId(savedText._id));
      }
    } else {
      yield put(MediaActions.saveTextError('Could not save text'));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(MediaActions.saveTextError('Could not save text'));
  }
}

export const getTexts = (state: IState) => state.media.texts;
function* loadTextIntoEditor(action: Action<ObjectId>) {
  try {
    // Try to load it from existing state
    const texts: Content.Text[] = yield select(getTexts);
    const fromState = texts.find((t) => t._id === action.payload);
    if (fromState) {
      yield put(MediaActions.loadTextIntoEditorSuccess(fromState));
      return;
    }

    // Else load it from backend
    // @ts-ignore
    const token = yield select(getToken);
    const id = action.payload;
    const response: AxiosResponse = yield call(MediaAPI.loadText, token, id);

    if (response.status === 200) {
      const loadedText: Content.Text = response.data;
      yield put(MediaActions.loadTextIntoEditorSuccess(loadedText));
    } else {
      yield put(MediaActions.mediaLibraryError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(MediaActions.mediaLibraryError({ msg: e.message }));
  }
}

function* listTexts() {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const response: AxiosResponse = yield call(MediaAPI.listTexts, token);

    if (response.status === 200) {
      const texts: Content.Text[] = response.data;
      yield put(MediaActions.listTextsSuccess(texts));
    } else {
      yield put(MediaActions.mediaLibraryError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(MediaActions.mediaLibraryError({ msg: e.message }));
  }
}

export function* mediaLibrarySaga() {
  // Images
  // yield takeEvery(MediaActions.listImagesInFolder, listImagesInFolder);
  yield takeEvery(MediaActions.loadImage, loadImageById);
  yield takeEvery(MediaActions.createImage, createImage);
  yield takeEvery(MediaActions.updateImage, updateImage);
  yield takeEvery(MediaActions.uploadImageFile, uploadImageFile);
  // Videos
  yield takeEvery(MediaActions.listVideosInFolder, listVideosInFolder);
  yield takeEvery(MediaActions.loadVideo, loadVideoById);
  yield takeEvery(MediaActions.createVideo, createVideo);
  yield takeEvery(MediaActions.updateVideo, updateVideo);
  yield takeEvery(MediaActions.uploadVideoFile, uploadVideoFile);
  yield takeEvery(MediaActions.uploadThumbnail, uploadThumbnail);
  yield takeEvery(MediaActions.uploadVideoThumbnail, uploadVideoThumbnail);
  yield takeEvery(MediaActions.uploadVideoSubtitles, uploadVideoSubtitles);
  yield takeEvery(MediaActions.uploadBlockListThumbnail, uploadBlockListThumbnail);
  // Texts
  yield takeEvery(MediaActions.saveText, saveText);
  yield takeEvery(MediaActions.listTexts, listTexts);
  yield takeEvery(MediaActions.loadTextIntoEditor, loadTextIntoEditor);

  // Media variations
  yield takeEvery(MediaActions.listMediaVariations, listMediaVariations);
  yield takeEvery(AuthActions.authenticateSuccessful as any, listMediaVariations);
  yield takeEvery(MediaActions.createMediaVariation, createMediaVariation);
}
