import { Block, LanguageCode } from '@visikon/core-models/content';
import { useEffect, useRef } from 'react';
import { Icon, Label, SemanticCOLORS } from 'semantic-ui-react';
import * as Styles from './ManusPart.styles';
import { matchLanguageOrFallback } from '@visikon/core-models/helpers/language-fallback';

interface IProps {
  block: Block;
  blockKey: string;
  variation?: string;
  lang: LanguageCode;
  isHighlighted: boolean;
  onHover(id?: string): void;
  onClick: (block: Block) => void;
}

const BlockMissing = (isHighlighted: boolean) => (
  <Styles.ManusPartContainer highlighted={isHighlighted}>
    <div>
      <Icon name="warning sign" color="red" />
      {' '}
      Block missing
    </div>
  </Styles.ManusPartContainer>
);

export const ManusPart = ({
  block, blockKey, variation, lang, isHighlighted, onHover, onClick
}: IProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const elm = ref.current;
      elm.addEventListener('mouseenter', onMouseEnter);
      elm.addEventListener('mouseleave', onMouseLeave);
      return () => {
        elm.removeEventListener('mouseenter', onMouseEnter);
        elm.removeEventListener('mouseleave', onMouseLeave);
      };
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  if (block === undefined) {
    return BlockMissing(isHighlighted);
  }

  const speak = matchLanguageOrFallback(block.speaks, lang, variation);

  function onMouseEnter() {
    onHover(blockKey);
  }
  function onMouseLeave() {
    onHover(undefined);
  }

  const speakText = speak ? speak.text : (<Styles.NoSpeak>No speak</Styles.NoSpeak>);
  const statusColor = (speak && speak.status ? speak.status : 'GREEN').toLowerCase() as SemanticCOLORS;
  return (
    <Styles.ManusPartContainer ref={ref} highlighted={isHighlighted} onClick={() => onClick(block)}>
      <div style={{ display: 'flex' }}>
        <Styles.LabelContainer><Label circular empty color={statusColor} /></Styles.LabelContainer>
        {speakText}
      </div>
    </Styles.ManusPartContainer>
  );
};
