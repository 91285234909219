import { VideoFile } from 'components/mediaLibrary/videos/VideoFile';
import { API } from 'config';
import { Content } from '@visikon/core-models';
import { getTranslation } from '@visikon/core-models/typeUtils';
import { useRef } from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { CopyOnClick } from 'components/CopyOnClick';

const EmbedTextArea = styled.textarea`
  font-family: monospace, monospace;
  line-height: 1.4em;
  font-size: 1.1em;
  margin-top: 10px;
  height: calc(100% - 49px);
  width: 100%;
  resize: none;
  border: 1px solid lightgrey;
`;

const ContentDiv = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: 10px;
  }

  & > div:last-child {
    margin-left: 10px;
  }
`;

function getEmbedUrl(video: Content.Video) {
  return `${API.baseURL}embed/${video._id}?q=ZRRur12Jcaw2c4nm`; // TODO: Get this from namespace keys
}

export function getEmbedCode(video: Content.Video) {
  return `<iframe width="560" height="315" src="${getEmbedUrl(
    video,
  )}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
}

interface IProps {
  video: Content.Video;
  onDismiss(): void;
}
export const EmbedVideoDialog = ({ onDismiss, video }: IProps) => {
  const textarea = useRef<HTMLTextAreaElement>(null);

  const trans = getTranslation<Content.Video>(video, 'da');
  if (!trans) {
    return <div>no!</div>;
  }

  const selectAll = () => {
    if (textarea.current) {
      textarea.current.focus();
      textarea.current.select();
    }
  };

  return (
    <Modal open onClose={onDismiss} closeOnEscape>
      <Modal.Header>{video.name}</Modal.Header>
      <Modal.Content>
        <ContentDiv>
          <div style={{ flex: 3 }}>
            <Header size="medium">Video preview:</Header>
            <VideoFile showThumbnail={false} showControls width={532} video={video} language={trans.language} />
          </div>
          <div style={{ flex: 2 }}>
            <div style={{ display: 'flex' }}>
              <Header size="medium" style={{ flex: 1 }}>
                Embed code:
              </Header>
              <CopyOnClick text={() => getEmbedCode(video)} popupPosition="top center">
                <Button size="small">Copy to clipboard</Button>
              </CopyOnClick>
            </div>
            <EmbedTextArea ref={textarea} value={getEmbedCode(video)} readOnly onClick={selectAll} spellCheck={false} />
          </div>
        </ContentDiv>
      </Modal.Content>
    </Modal>
  );
};
