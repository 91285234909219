
// Current number of items on pages with pagination
export const PAGE_SIZE = 15;

// Collection of available search categories
export const enum SearchCategories {
    All = "All",
    Names = "Names",
    Tags = "Tags",
    Manuscript = "Manuscript",
  }