import { TagList } from 'components/tags/TagList';
import { Content } from '@visikon/core-models';
import { Block } from '@visikon/core-models/content';
import React from 'react';
import { Icon, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { getTranslationFromArray } from '@visikon/core-models/typeUtils';

const DISPLAY_TEXT_LENGTH = 200;

const MediaInfoContainer = styled.div`
    white-space: nowrap;
    font-size: 1.4em;

    span {
        margin-right: 8px;
    }
`;

const MediaInfo = ({ block }: { block: Block }) => {
  const videoCount = block.videos.reduce((acc, video) => acc + (video.videoFile !== undefined ? 1 : 0), 0);
  const audioCount = block.speaks.reduce((acc, speak) => acc + (speak.audioFile !== undefined ? 1 : 0), 0);

  return (
    <MediaInfoContainer>
      <span>{videoCount}</span>
      <Icon name="film" />
      <span style={{ marginLeft: 40 }}>{audioCount}</span>
      <Icon name="music" />
    </MediaInfoContainer>
  );
};

const displayText = (text: string) => {
  if (text === undefined) {
    return '';
  }
  return text.length > DISPLAY_TEXT_LENGTH ? `${text.substr(0, (DISPLAY_TEXT_LENGTH - 3))}...` : text;
};

const firstCellStyle: React.CSSProperties = {
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
  color: 'rgba(0,0,0,.87)',
  width: '175px',
};

export const BlocksTable = ({ items, language, onClick }: { items: Content.Block[], language: Content.LanguageCode, onClick: (id: string) => void }) => (
    <>
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Text</Table.HeaderCell>
            <Table.HeaderCell>Media</Table.HeaderCell>
            <Table.HeaderCell>Tags</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {items.map((item) => {
            const speak = getTranslationFromArray(item.speaks, language);

            return (
              <Table.Row key={item._id} style={{ cursor: 'pointer' }} onClick={() => onClick(item._id)}>
                <Table.Cell style={firstCellStyle}>{item.name}</Table.Cell>
                <Table.Cell>{speak && displayText(speak.text)}</Table.Cell>
                <Table.Cell style={{ width: 156 }}><MediaInfo block={item} /></Table.Cell>
                <Table.Cell style={{ width: 400 }}><TagList readOnly tagList={item.tags} /></Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
