import moment from 'moment';
import { Content } from '@visikon/core-models';
import { Flag, Popup } from 'semantic-ui-react';
import { langFromCode } from 'commons/languages';
import { TagList } from '../../tags/TagList';

interface IProps {
  onTagAdded: (tag: Content.Tag) => void;
  onTagRemoved: (tag: Content.Tag) => void;
  onClick?: () => void;
  onlyPicker?: boolean;
  text: any;
  languages: Content.LanguageCode[];
  hideTags?: boolean;
}

export function TextItemView({ onTagAdded, onTagRemoved, onClick, onlyPicker, text, languages, hideTags }: IProps) {
  const cursor = onClick ? 'pointer' : 'default';

  return (
    <div className="card">
      <div className="content" style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
        <div className="header" onClick={onClick} style={{ cursor }}>{text.name}</div>
        <div className="meta" onClick={onClick} style={{ cursor }}>
          {/* Show fromNow() time with actual timestamp in popup */}
          <Popup
            trigger={<span>{moment(text.createdAt).fromNow()}</span>}
            content={<div>{moment(text.createdAt).format('LLL')}</div>}
            position="top right"
            hideOnScroll
            mouseEnterDelay={500}
          />
        </div>
        <div className="description">
          {!hideTags
                            && (
                            <TagList
                              onTagAdded={onTagAdded}
                              onTagRemoved={onTagRemoved}
                              tagList={text.tags}
                              readOnly={onlyPicker}
                            />
                            )}
        </div>
        <div style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'end', flexGrow: 1, paddingTop: 7,
        }}
        >
          <div>
            {languages && languages
              .filter((l) => l !== null)
              .map( (l,index) => {
              const lang = langFromCode(l);
              return ( <Flag key={index} name={lang.flag as any} /> ) } )
            }
          </div>
        </div>
        <div style={{
          position: 'absolute', top: 0, right: 0, fontSize: '0.7em', lineHeight: '0.7em', color: '#0005',
        }}
        >
          NS:
          {' '}
          {text.namespace ? text.namespace : 'N/A'}
        </div>

      </div>
    </div>
  );
}
