import { Flag, Menu, Tab } from 'semantic-ui-react';
import { ValidatedProgram, ValidatedProgramTranslation } from '@visikon/core-models/publisher';
import { langFromCode } from '../../../commons/languages';
import { PublisherTab } from './PublisherTab';

export function PublisherPreview({data}: {data?: ValidatedProgram}) {
  if(!data) {
    return null;
  }

  const panes = data.translations.map((translation: ValidatedProgramTranslation) => ({
    menuItem: (
      <Menu.Item key={translation.language}>
        <Flag name={langFromCode(translation.language).flag as any} />

        {translation.validation.valid &&<>
          {langFromCode(translation.language).text}
        </>}

        {!translation.validation.valid &&
          <span style={{color: 'red', fontWeight: 600}}>{langFromCode(translation.language).text}</span>
        }
      </Menu.Item>
    ),
    render: () => (<PublisherTab translation={translation} />),
  }));

  return (<>
      <Error data={data} />
      <Tab panes={panes} />
  </>
  );
}

function Error({data}: {data?: ValidatedProgram}) {
  if(!data || data.valid) {
    return <></>;
  }

  return (<div>
    There may be <b style={{color: 'red'}}>errors</b> in the languages marked with <b style={{color: 'red'}}>red</b>!
  </div>);
}
