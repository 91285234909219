import actionCreatorFactory from 'typescript-fsa';
import { IAuthRequest, IAuthToken, IAuthError } from 'reducers/authReducer';

const actionCreator = actionCreatorFactory('Authentication');

export const authenticate = actionCreator<IAuthRequest>('AUTHENTICATE');
export const authenticateFromLocalStorage = actionCreator('AUTHENTICATE_LOCAL_STORAGE');
export const authenticateSuccessful = actionCreator<IAuthToken>('AUTHENTICATE_SUCCESS');
export const authenticateFailed = actionCreator<IAuthError>('AUTHENTICATE_ERROR');
export const forbiddenError = actionCreator('FORBIDDEN_ERROR');
export const forbiddenErrorClear = actionCreator('FORBIDDEN_ERROR_CLEAR');
export const logOut = actionCreator('LOG_OUT');
export const logOutSuccessful = actionCreator('LOG_OUT_SUCCESS');
