import { Action } from 'typescript-fsa';
import { AxiosResponse } from 'axios';
import * as AuthActions from 'actions/AuthActions';
import { authenticate } from 'api/authApi';
import { IAuthRequest } from 'reducers/authReducer';
import { call, put, takeEvery } from 'redux-saga/effects';
import { LOCAL_STORAGE_TOKEN_KEY } from 'config';

function* authenticateSaga(action: Action<IAuthRequest>) {
  try {
    const response: AxiosResponse = yield call(
      authenticate,
      action.payload.username,
      action.payload.password,
      action.payload.tfaToken,
    );

    const result = response.data as { token?: string, error?: string };
    if (result.error) {
      yield put(AuthActions.authenticateFailed({ msg: result.error }));
    } else if (result.token) {
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, result.token);
      yield put(AuthActions.authenticateSuccessful({ token: result.token }));
    }
  } catch (e) {
    const result = e.response.data;
    let msg = e.message;
    if (result && result.error) {
      msg = result.error;
    }
    yield put(AuthActions.authenticateFailed({ msg }));
  }
}

function* loadAuthticationFromLocalStorage() {
  try {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    if (token) {
      yield put(AuthActions.authenticateSuccessful({ token }));
    }
  } catch (e) {
    yield put(AuthActions.authenticateFailed({ msg: e.message }));
  }
}

function* logOutSaga() {
  try {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    yield put(AuthActions.logOutSuccessful());
  } catch (e) {
    yield put(AuthActions.logOutSuccessful());
  }
}

export function* authSaga() {
  yield takeEvery(AuthActions.authenticateFromLocalStorage, loadAuthticationFromLocalStorage);
  yield takeEvery(AuthActions.authenticate, authenticateSaga);
  yield takeEvery(AuthActions.logOut, logOutSaga);
}
