import { Blueprint, Encoding } from '@visikon/core-models/encoding';
import axios from 'axios';
import { API } from 'config';
import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { LanguageCode } from '@visikon/core-models/content';
import { tokenState } from '../../redux-recoil-sync.state';
import { URLS } from '../urls';
import { APIUtils } from '../../commons/utils';
import { QueryConfig } from '../react-query-utils';
import { useSocketQueryInvalidation } from 'commons/sockets';

const ENCODE_URL = API.baseEncoderUrl;
const API_HEADER = { 'x-api-key': 'gQzB7EwqGsrrPpWrxl9F1toCnNAOKW1vKyf3B1ZOXzY5HyTUV0', 'Content-Type': 'application/json' };

type ErrorResponse = {
  error: string;
  status: number;
};

type EncoderStatusResponse = {
  status: 'idle' | 'running';
  id?: string;
  language?: LanguageCode;
  timestamp: number;
};

export function useEncoderStatus() {
  return useQuery<EncoderStatusResponse, Error>(
    'encoder-status',
    async () => {
      const result = await axios.get<EncoderStatusResponse>(`${ENCODE_URL}status`, { headers: API_HEADER }).then((res) => res.data);
      return result;
    },
    { ...QueryConfig },
  );
}

export function useEncoderStatusPoll() {
  return useQuery<EncoderStatusResponse, Error>(
    'encoder-status',
    async () => {
      const result = await axios
        .get<EncoderStatusResponse>(`${ENCODE_URL}status`, {
          headers: {
            ...API_HEADER,
            'x-polling': 'true',
          },
        })
        .then((res) => res.data);
      return result;
    },
    { ...QueryConfig, refetchInterval: 1000 },
  );
}

type EncodingsResponse = {
  encodings: Array<Encoding>;
};

export function useEncodingsByCompositionId(id: string | undefined) {
  const queryKey: QueryKey = ['encodings', id];
  const [token] = useRecoilState(tokenState);

  useSocketQueryInvalidation(id, queryKey, 'encodings:');

  return useQuery<EncodingsResponse, Error>(
    queryKey,
    async () => {
      if (!id) {
        return { encodings: [] };
      }

      const response = await axios.get<EncodingsResponse>(URLS.encodings.get(id), APIUtils.tokenHeader(token!, { 'x-polling': true }));
      return response.data;
    },
    {
      enabled: !!token && !!id,
      staleTime: 2 * 60 * 60 * 1000,
      refetchOnWindowFocus: true,
    },
  );
}

export function useEncodeFromBlueprint() {
  const queryClient = useQueryClient();

  return useMutation<Blueprint | ErrorResponse, Error, Blueprint>(
    async (payload: Blueprint) => {
      const result = await axios.post(`${ENCODE_URL}encode2`, payload, { headers: API_HEADER });
      return result.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['encoder-status-metadata']); // Re-fetch from server
      },
    },
  );
}
