import { LinkButton } from './LinkButton';
import { useState } from 'react';

export function ClipboardLinkButton({children, cleanup}: {children: string, cleanup?: boolean}) {
  const [toast, setToast] = useState<string>();

  const handleCopy = () => {
    const text = cleanup
      ? children.replaceAll('_', ' ').substring(0, children.lastIndexOf('.'))
      : children;

    navigator.clipboard.writeText(text).then(() => {
      setToast(` copied to clipboard ...`);
      setTimeout(() => setToast(undefined), 2000);
    });
  }

  return (<>
    <LinkButton onClick={handleCopy}>{children}</LinkButton>
    {toast && <span>{toast}</span>}
  </>);
}