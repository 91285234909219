import { PropsWithChildren, ReactNode } from 'react';
import { Header, Icon, Segment, SemanticICONS } from 'semantic-ui-react';

interface ErrorSegmentProps {
  iconName?: SemanticICONS;
  header?: string | ReactNode;
}

export function PlaceHolderSegment({ iconName, header, children }: PropsWithChildren<ErrorSegmentProps>) {
  return (
    <Segment placeholder>
      <Header icon={iconName && true}>
        {iconName && <Icon name={iconName} />}
        {header}
      </Header>
      <Segment.Inline>{children}</Segment.Inline>
    </Segment>
  );
}
