import { changeLanguage, changeMediaVariation } from 'actions/LanguageActions';
import { Content } from '@visikon/core-models';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { ObjectId } from '@visikon/core-models/base';

export interface IState {
  readonly activeLanguage: Content.LanguageCode;
  readonly activeMediaVariation: ObjectId;
}

export function langReducer(state: IState = { activeLanguage: 'da', activeMediaVariation: '' }, action: Action): IState {
  if (isType(action, changeLanguage)) {
    if (action.payload !== state.activeLanguage) {
      return { ...state, activeLanguage: action.payload };
    }
  }
  if (isType(action, changeMediaVariation)) {
    if (action.payload !== state.activeLanguage) {
      return { ...state, activeMediaVariation: action.payload };
    }
  }

  return state;
}
