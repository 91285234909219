import * as ContentActions from 'actions/ContentActions';
import * as ContentAPI from 'api/contentApi';
import { AxiosResponse } from 'axios';
import { Content } from '@visikon/core-models';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
// import { IState } from "reducers/reducers";
import { getToken, handleAuthorizationError } from './saga-helpers';

function* listContentByType(action: Action<string>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const type = action.payload;
    const response: AxiosResponse = yield call(ContentAPI.listEntries, token, type);

    if (response.status === 200) {
      const result: Content.CustomType[] = response.data;
      yield put(ContentActions.listContentByTypeSuccess({ type, result }));
    } else {
      yield put(ContentActions.contentApiError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(ContentActions.contentApiError({ msg: e.message }));
  }
}

function* listProgramCodes() {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const mitforlobType = 'mitforlobPack';
    const entries: AxiosResponse = yield call(ContentAPI.listEntries, token, mitforlobType );
    const result: Content.CustomType[] = entries.data;
    yield put(ContentActions.listContentByTypeSuccess({ type: mitforlobType, result }));
    const programActions: AxiosResponse = yield call(ContentAPI.programActions, token);
    yield put(ContentActions.listProgramCodesSuccess(programActions.data));

    if ( programActions.status !== 200 || entries.status !== 200) {
      yield put(ContentActions.contentApiError({ msg: entries.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(ContentActions.contentApiError({ msg: e.message }));
  }
}

function* createContent(action: Action<ContentActions.ICreateContentType>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const content = action.payload;
    const response: AxiosResponse = yield call(ContentAPI.createEntry, token, content);

    if (response.status === 200) {
      const result: Content.CustomType = response.data;
      if (action.payload.copy) {
        // Copying is kind of a hack
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (result as any).isCopy = true;
      }
      yield put(ContentActions.createContentSuccess(result));
    } else {
      yield put(ContentActions.contentApiError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(ContentActions.contentApiError({ msg: e.message }));
  }
}
function* copyContent(action: Action<{ contentId: string }>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const { contentId } = action.payload;
    const response: AxiosResponse = yield call(ContentAPI.copyEntry, token, contentId);

    if (response.status === 200) {
      const result: Content.CustomType = response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (result as any).isCopy = true;
      yield put(ContentActions.createContentSuccess(result));
    } else {
      yield put(ContentActions.contentApiError({ msg: "Couldn't copy..." }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(ContentActions.contentApiError({ msg: e.message }));
  }
}

function* updateContent(action: Action<Content.CustomType>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const content = action.payload;

    const response: AxiosResponse = yield call(ContentAPI.updateEntry, token, content);

    if (response.status === 200) {
      const result: Content.CustomType = response.data;
      yield put(ContentActions.updateContentSuccess(result));
    } else {
      yield put(ContentActions.contentApiError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(ContentActions.contentApiError({ msg: e.message }));
  }
}

function* loadEntry(action: Action<ContentActions.IContentLoadType>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const { id } = action.payload;
    const { type } = action.payload;
    const response: AxiosResponse = yield call(ContentAPI.loadEntry, token, type, id);

    if (response.status === 200) {
      const result: Content.CustomType = response.data;
      yield put(ContentActions.loadContentEntrySuccess(result));
    } else {
      yield put(ContentActions.contentApiError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(ContentActions.contentApiError({ msg: e.message }));
  }
}

function* toggleArchived(action: Action<Content.CustomType>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const content = { ...action.payload, archived: !action.payload.archived };

    const response: AxiosResponse = yield call(ContentAPI.updateEntry, token, content);

    if (response.status === 200) {
      const result: Content.CustomType = response.data;
      yield put(ContentActions.updateContentSuccess(result));
    } else {
      yield put(ContentActions.contentApiError({ msg: response.data }));
    }
  } catch (e) {
    yield handleAuthorizationError(e.response);
    yield put(ContentActions.contentApiError({ msg: e.message }));
  }
}

export function* contentSaga() {
  yield takeEvery(ContentActions.listContentByType, listContentByType);
  yield takeEvery(ContentActions.listProgramCodes, listProgramCodes);
  yield takeEvery(ContentActions.createContent, createContent);
  yield takeEvery(ContentActions.copyContent, copyContent);
  yield takeEvery(ContentActions.updateContent, updateContent);
  yield takeEvery(ContentActions.loadContentEntry, loadEntry);
  yield takeEvery(ContentActions.toggleArchived, toggleArchived);
}
