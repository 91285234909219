import { datadogRum } from '@datadog/browser-rum';
import { Environment, getEnv } from 'commons/env';
import axios from 'axios';
import { uuidv4 } from './commons/utils';

export const VCORE_API_KEY = 'vCore.mW6SYrezonTV69bdfSf0kVc/WD4mg6YWmQtMlqWeRSh/aUJAbnft/VtwFHeVHMsR';

export const LOCAL_STORAGE_TOKEN_KEY = 'local-storage-token-key';
export const LOCAL_STORAGE_LANGUAGE_KEY = 'local-storage-language-key';
export const LOCAL_STORAGE_LIST_CARD_VIEW_KEY = 'local-storage-list-card-view-key';

export const appEnv = getEnv();

const blobs: Record<Environment, string> = {
  Local: 'https://visikoncorestorageqa.blob.core.windows.net/blobs-qa/',
  QA: 'https://visikoncorestorageqa.blob.core.windows.net/blobs-qa/',
  Prod: 'https://visikoncorestorage.blob.core.windows.net/blobs-production/',
};

const modulePreviewHost: Record<Environment, string> = {
  Local: 'http://localhost:3020/',
  QA: 'https://embed-qa.mitforlob.dk/',
  Prod: 'https://embed.mitforlob.dk/',
};

// We known our backend & encoder we always be reachable at /api & /encoder respectively
// In Production & QA Azure Front Door will do the routing and in dev
// React Scripts's proxy will route these request to those services
// For the dev enviroment the proxy routing setup can be found in src/setupProxy.js
function baseUrl(suffix: string) {
  const { origin } = window.location;
  // Unless api origon is explict set during build time we default to using the current origin
  const base = process.env.REACT_APP_API_ORIGIN ?? origin;

  return `${base}/${suffix}/`;
}

function baseBlobUrl() {
  return blobs[appEnv];
}

export const API = {
  baseURL: baseUrl('api/v1'),
  baseBlobUrl: baseBlobUrl(),
  baseEncoderUrl: baseUrl('encoder'),
  baseModulePreviewUrl: `${modulePreviewHost[appEnv]}embed`,
};

export const V2API = {
  baseURL: baseUrl('api/v2'),
  baseBlobUrl: baseBlobUrl(),
  baseEncoderUrl: baseUrl('encoder'),
};

export const templateImage = 'mitforlob/blocks/coming_soon-TEMPLATE.png';
export function variationDefaultIMGURL(isRelative?: 'isRelative') {
  if (isRelative === 'isRelative') return templateImage;
  return `${API.baseBlobUrl}${templateImage}`;
}

export const videoFileTemplateImage = {
  videoFile: {
    contentType: 'image/png',
    size: 8522,
    src: variationDefaultIMGURL('isRelative'),
  },
};

export function initDatadog() {
  if (appEnv === Environment.Local) return;

  datadogRum.init({
    applicationId: 'beea32e3-ffbc-4be6-aed4-a162383f18b3',
    clientToken: 'pubb32a5b256146d7f18e1c80dcafb26d03',
    site: 'us3.datadoghq.com',
    service: 'core',
    env: appEnv,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
  });
}

axios.interceptors.request.use((config) => {
  const uuid = uuidv4();
  // NB the header 'x-correlation-id' is also defined in core-backend
  // @ts-ignore
  // eslint-disable-next-line no-param-reassign
  config.headers.common['x-correlation-id'] = uuid;
  return config;
});
