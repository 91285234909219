import * as React from 'react';
import { staticVoiceData } from './StaticVoiceData';
import { LanguageCode } from '@visikon/core-models/languageTypes';

interface UsePreferredVoiceProps {
  selectedLanguage: LanguageCode;
  selectedVoiceName?: string;
  selectedVoiceGender?: string;
}

export const usePreferredVoice = ({ selectedLanguage, selectedVoiceName, selectedVoiceGender }: UsePreferredVoiceProps) => {
  const [voiceName, setVoiceName] = React.useState('');
  const [voiceGender, setVoiceGender] = React.useState('');

  React.useEffect(() => {
    const { preferredVoice } = staticVoiceData.find((lang) => lang.value === selectedLanguage)!;
    if (!selectedVoiceName) {
      const firstGenderInPreferredVoice = Object.keys(preferredVoice)[0];
      setVoiceName(preferredVoice[firstGenderInPreferredVoice]);
      setVoiceGender(firstGenderInPreferredVoice);
    }
  }, [selectedLanguage, selectedVoiceName, setVoiceName, setVoiceGender, selectedVoiceGender]);

  return { voiceName, voiceGender };
};
