import { useState } from "react";

type Togglable = [
    boolean,                // isOn
    () => void,             // toggle()
    (on: boolean) => void   // setOn
];

export function useToggle(initialValue: boolean = false): Togglable {
    const [value, setValue] = useState<boolean>(initialValue);
    return [
        value,
        () => setValue(!value),
        (on: boolean) => setValue(on)
    ];
}
