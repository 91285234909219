/* eslint-disable no-param-reassign */
import { Block, BlockList, LanguageCode } from '@visikon/core-models/content';
import { useEffect, useState } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { IState } from 'reducers/reducers';
import { getBlockItems } from '../../../blockResourceSelectors';
import { VideoPlayer } from '../../../../../components/player/video-player';
import { VideoClip } from '../../../../../components/player/video-player.models';
import { BlobUrls } from '../../../../../commons/local-storage';

interface IOwnProps {
  blockList: BlockList['blocks'];
  lang: LanguageCode;
  audioDelay: number;
  thumbnail?: string;
  notifyPlaying?(index: number): void;
  notifyStopping?(): void;
}

interface IPropsFromState {
  blocksFromState: Block[];
}

type IProps = IPropsFromState & IOwnProps;

function CBlockListPlayer ({blockList, blocksFromState, lang, notifyPlaying, notifyStopping, audioDelay, thumbnail}: IProps) {
  const [clips, setClips] = useState<VideoClip[]>([]);

  // Reset when block changes
  useEffect(() => {
    const blocks = blockList.map((b) => blocksFromState.find((bfs) => bfs._id === b.blockId)).filter((b) => b !== undefined) as Block[];
    const videoClips = blocks.map((b, i) => getBlockItems(b, lang, blockList[i].videoVariation, blockList[i].speakVariation));

    setClips(videoClips);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockList, lang]);

  const thumbnailSrc = thumbnail && BlobUrls.genericUrl(thumbnail);
  return <VideoPlayer clips={clips} audioDelay={audioDelay} thumbnail={thumbnailSrc} notifyPlaying={notifyPlaying} notifyStopping={notifyStopping} />
}

const mapStateToProps: MapStateToProps<IPropsFromState, IOwnProps, IState> = (state) => ({
  blocksFromState: state.blocks.all,
});

export const BlockListPlayer = connect(mapStateToProps)(CBlockListPlayer);

export const checkIfImageExists = (url: string | undefined, callback: (exists: boolean) => void) => {
  const img = new Image();
  img.src = url || '';

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
};
