import { RouteComponentProps } from 'react-router';
import { CustomTypeEditor } from 'containers/content/CustomTypeEditor';

interface IProps extends RouteComponentProps<{ name: string, id: string }> {
  onSave: (id: string) => void;

}
export function CustomTypeEditorRoute(props: IProps) {
  const { name, id } = props.match.params;
  return (
    <CustomTypeEditor
      {...props}
      name={name}
      id={id} 
    />
  );
}
