import { createMediaVariation, listMediaVariations } from 'actions/MediaActions';
import { Content } from '@visikon/core-models';
import React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { IState } from 'reducers/reducers';
import {
  Button, Form, Modal, ModalProps,
} from 'semantic-ui-react';

interface IPropsFromState {
  variations: Content.MediaVariation[];
  error?: string;
}
interface IPropsFromDispatch {
  listMediaVariations: () => void;
  createMediaVariation: (name: string, description: string) => void;
}
type IProps = IPropsFromState & IPropsFromDispatch;

interface ILocalState {
  isShowingCreateDialog: boolean;
  inputName: string;
  inputDesc: string;
  isLoading: boolean;
}
const defaultState: ILocalState = {
  isShowingCreateDialog: false, inputName: '', inputDesc: '', isLoading: false,
};

class CMediaVariations extends React.Component<IProps, ILocalState> {
  // eslint-disable-next-line react/no-unused-class-component-methods
  modal: React.Component<ModalProps> | null; // tslint:disable-line:no-any

  constructor(props: IProps) {
    super(props);

    this.state = defaultState;

    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleOnCreate = this.handleOnCreate.bind(this);
    this.renderVariation = this.renderVariation.bind(this);
  }

  componentDidMount() {
    this.props.listMediaVariations();
  }

  componentDidUpdate(oldProps: IProps) {
    if (oldProps.variations.length !== this.props.variations.length) {
      // We infer that a new one was created.
      this.setState(defaultState); // eslint-disable-line
    }
  }

  handleModalClose() {
    this.setState(defaultState);
  }

  handleOnCreate() {
    this.props.createMediaVariation(this.state.inputName, this.state.inputDesc);
    this.setState({ isLoading: true });
  }

  // eslint-disable-next-line class-methods-use-this
  renderVariation(variation: Content.MediaVariation) {
    return (
      <div key={variation._id} className="card">
        <div className="content">
          <div className="header">
            {variation.name}
          </div>
          <div className="meta">
            {variation.description}
          </div>
          <div className="description">
            <b>34</b>
            {' '}
            assets are using this variation.
          </div>
        </div>
      </div>
    );
  }

  renderHeaderAndCreateDialog() {
    return (
      <div className="ui grid" style={{ marginTop: 30 }}>
        <div className="eight wide column">
          <h3 className="ui header">
            Overview
          </h3>
        </div>
        <div className="eight wide column">
          <Button
            icon="plus"
            primary
            floated="right"
            labelPosition="left"
            content="Create new"
            onClick={() => { this.setState({ isShowingCreateDialog: true }); }}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <h1 className="ui header">Media Variations</h1>

        <div className="ui raised segment">
          <p>
            Media Variations (or graphics packs or assets packs) are variations to
            our media, e.g. images and videos. Media Variations are used to describe
            multiple versions of the same content. For instance a video showing...
            <br />
            <br />
            Explain more here
          </p>
        </div>

        {this.renderHeaderAndCreateDialog()}
        <div className="ui cards">
          {this.props.variations.map((v) => (this.renderVariation(v)))}
        </div>

        <Modal
          open={this.state.isShowingCreateDialog}
          size="mini"
          onClose={this.handleModalClose}
        >
          <Modal.Header>Create Media Variation</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form loading={this.state.isLoading}>
                <Form.Input
                  value={this.state.inputName}
                  autoFocus
                  label="Name"
                  onChange={(_, d) => { this.setState({ inputName: d.value ? String(d.value) : '' }); }}
                  placeholder="Name"
                />
                <Form.TextArea
                  value={this.state.inputDesc}
                  onChange={(_, d) => { this.setState({ inputDesc: d.value ? String(d.value) : '' }); }}
                  label="Description"
                  placeholder="Describe the purpose of this Media Variation."
                />
                <div>
                  <Button onClick={this.handleOnCreate} positive>Create</Button>
                  <Button onClick={this.handleModalClose}>Cancel</Button>
                </div>
              </Form>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

const mapStateToProps: (state: IState) => IPropsFromState = (state) => ({
  variations: state.media.mediaVariations,
});

const mapDispatchToProps: MapDispatchToProps<IPropsFromDispatch, {}> = (dispatch) => ({
  listMediaVariations: () => {
    dispatch(listMediaVariations());
  },
  createMediaVariation: (name, description) => {
    dispatch(createMediaVariation({ name, description }));
  },
});

export const MediaVariations = connect(mapStateToProps, mapDispatchToProps)(CMediaVariations);
