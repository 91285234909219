import React from 'react';
import Dropzone from 'react-dropzone';

interface IProps {
  onFilePick: (files: File[]) => void;
  fileTypeText?: string;
  accept?: string;
  multiple?: boolean;
  disabled?: boolean;
}

export class FilePicker extends React.Component<IProps, {}> {
  dropStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  };

  constructor(props: IProps) {
    super(props);

    // Bind listeners
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(files: File[]) {
    this.props.onFilePick(files);
  }

  // eslint-disable-next-line class-methods-use-this
  getIcon(fileType?: string) {
    if (!fileType) {
      return 'file';
    }

    switch (fileType) {
      case 'video':
      case 'image':
        return fileType;

      default: return 'file';
    }
  }

  render() {
    return (
      <Dropzone
        disabled={!!this.props.disabled}
        onDropAccepted={this.onDrop}
        accept={this.props.accept}
        multiple={this.props.multiple === undefined || this.props.multiple}
      >
        {(dropProps: any) => { // tslint:disable-line:no-any
          if (dropProps.isDragActive && !dropProps.isDragReject) {
            return (
              <div style={this.dropStyle}>
                <h5 className="header">Yes, gimme</h5>
                <div style={{ position: 'relative', height: 60 }}>
                  <i
                    style={{ position: 'absolute', top: 10, left: -33 }}
                    className="huge checkmark icon"
                  />
                </div>
              </div>
            );
          }
          if (dropProps.isDragReject) {
            let rejectedText = 'Unaccepted file type';
            if (!this.props.multiple && dropProps.draggedFiles.length > 1) {
              rejectedText = 'One file at a time';
            }
            return (
              <div style={this.dropStyle}>
                <h5 className="header">{rejectedText}</h5>
                <div style={{ position: 'relative', height: 60 }}>
                  <i
                    style={{ position: 'absolute', top: 10, left: -33 }}
                    className="huge warning sign icon"
                  />
                </div>
              </div>
            );
          }
          return (
            <div style={this.dropStyle}>
              <h5 className="header">
                Drop
                {this.props.fileTypeText || 'file'}
                {' '}
                here
              </h5>
              <div className="subheader">or click to browse</div>
              <div style={{ position: 'relative', height: 60 }}>
                <i style={{ position: 'absolute', top: 10, left: -33 }} className={`huge ${this.getIcon(this.props.fileTypeText)} icon`} />
                <i
                  style={{ position: 'absolute', top: 50, left: 15 }}
                  className="big mouse pointer icon"
                />
              </div>
            </div>
          );
        }}
      </Dropzone>
    );
  }
}
