import { Icon } from 'semantic-ui-react';
import styled, { keyframes } from 'styled-components';
import * as Semantic from 'semantic-ui-react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 526px;
  border-radius: 3px;
  border: 1px solid #cecece;
  margin: 7px;
  padding: 10px;
  background: #f7f7f7;
  justify-content: space-between;
`;

export const Content = styled.div`
  margin-top: 7px;

  input {
    width: calc(100px - 32px);
  }

  div {
    padding-right: 10px;
    &:last-child {
      padding-right: 0;
    }
  }

  div.mini.input {
    padding-right: 10px;
  }
`;

export const Editor = styled.textarea`
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  min-height: 100px;
  font-size: 11pt;
  line-height: 12pt;
  resize: vertical;
  border: 1px solid rgba(128, 128, 128, 0.15);
  border-radius: 3px;

  width: 100%;
  height: 100%;
`;

export const PhoneticsEditor = styled.textarea`
  font-family: monospace;
  resize: vertical;
  border: 1px solid rgba(128, 128, 128, 0.15);
  border-radius: 3px;

  width: 100%;
  height: 7.7rem;

  font-size: 3rem;
  padding: 2rem;
  text-align: center;
  line-height: normal;
`;

export const TestableTextEditor = styled.textarea`
  font-family: monospace;
  resize: vertical;
  border: 1px solid rgba(128, 128, 128, 0.15);
  border-radius: 3px;

  width: 100%;
  height: 7.7rem;

  font-size: 1.7rem;
  padding: 1rem;
  line-height: normal;
`;

export const PhoneticLetter = styled.span`
  background-color: #d4d4d4;
  word-wrap: break-word;
  border-radius: 3px;
  padding: 0.1em 0.2em;
  font-size: 1rem;
  font-family: monospace;
  margin-left: 4px;
`;

export const PhoneticInfo = styled.div`
  & p:first-child {
    margin: 0;
  }
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
  margin-bottom: 1em;
  color: #595959;
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const FadeIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 5px;

  animation: ${fadeOut} 2s linear forwards;
`;

export const TestModalTitle = styled.h2`
  color: #595959;
`;

export const PhoneticRow = styled(Semantic.Table.Row)<{ selected: boolean }>`
  background-color: ${(props) => (props.selected ? 'darkgrey' : 'inherit')};

  &:hover {
    background-color: ${(props) => !props.selected && 'rgba(0, 0, 0, 0.05)'};
    cursor: pointer;
  }
  user-select: none;
`;
export const DeleteBtn = styled(Semantic.Button)<{ selected: boolean }>`
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;

  padding: 0.5rem !important;
  font-size: x-small !important;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

export const TableWrapper = styled.div`
  overflow: auto;
  height: 33rem;
`;

export const AddEntryRow = styled(Semantic.Table.Row)`
  position: sticky;
  bottom: 0px;
  left: 0px;
  background: white;
`;

export const LanguageContainer = styled.div`
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  margin-top: 15px;
  padding-top: 15px;
`;
