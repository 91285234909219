import { Content } from '@visikon/core-models';
import { destroyVideoPlayer, ERROR_IMAGE, PLACEHOLDER_IMAGE } from 'commons/utils';
import { API } from 'config';
import React from 'react';
import { getTranslationToUse } from '../utils';
import { WarningOverlay } from '../WarningOverlay';
import { getUriForSubtitles, Subtitle } from './Subtitles';

interface IProps {
  width?: number;
  className?: string;
  video: Content.Video;
  language: Content.LanguageCode;
  showControls?: boolean;
  showThumbnail?: boolean;
  id?: string;
  subtitles?: Subtitle[];
  videoRef?: React.RefObject<HTMLVideoElement>;
}

export function VideoFile(props: IProps) {
  const [url, setUrl] = React.useState('');
  const [thumbUrl, setThumbUrl] = React.useState('');
  const [poster, setPoster] = React.useState('');
  const [showOverlay, setShowOverlay] = React.useState(false);

  const { video, language, videoRef } = props;
  const videoRefLocal = React.useRef<HTMLVideoElement>(videoRef?.current || null);

  React.useEffect(() => {
    const translation = getTranslationToUse(video, language) as Content.VideoTranslation;

    if (!translation) {
      setPoster(PLACEHOLDER_IMAGE);
      return;
    }

    setUrl(`${API.baseBlobUrl}${translation.src}`);
    setShowOverlay(language !== translation.language);
    if (translation.thumbnailSrc) {
      setThumbUrl(`${API.baseBlobUrl}${translation.thumbnailSrc}`);
    }
  }, [video._id, language, video]);

  React.useEffect(() => {
    const videREF = videoRefLocal?.current;
    return () => {
      if (videREF) {
        destroyVideoPlayer(videREF);
      }
    };
  }, [videoRef]);

  function renderVideo() {
    const { subtitles, showThumbnail, width, showControls, className, id } = props;

    if (thumbUrl && (showThumbnail || showThumbnail === undefined)) {
      return <img alt="" src={thumbUrl} width={width || '290'} />;
    }

    const isShowingControls = (showControls && !showOverlay) || false;

    return (
      <video
        id={id}
        crossOrigin="anonymous"
        ref={videoRef}
        controls={isShowingControls}
        className={className}
        src={url}
        width={width || '290'}
        poster={poster}
        onError={() => setPoster(ERROR_IMAGE)}
      >
        {subtitles &&
          subtitles.map((entry) => (
            <track key={entry.key} label={entry.label} kind="subtitles" srcLang={entry.key} src={getUriForSubtitles(video, language, entry.key)} />
          ))}
      </video>
    );
  }

  return (
    <div style={{ position: 'relative', display: 'flex' }}>
      {renderVideo()}

      {showOverlay ? <WarningOverlay>No video for selected language</WarningOverlay> : null}
    </div>
  );
}
