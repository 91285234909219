import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { EmbedMainWrapper } from './EmbedShared';

const Header = styled.div`
    font-family: 'Muli',serif;
    color: #0b6db4;

    h1 {
        text-align: center;
        font-family: 'Muli',serif;
        margin-bottom: 40px;
    }
`;

const MainButton = styled.div`
    border-radius: .6rem;
    margin: 20px;

    cursor: pointer;
    background: #e0e1e2;
    min-height: 200px;
    min-width: 200px;

    &:hover {
        background: #cacbcd;
    }

    a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // text-align: center;
        width: 100%;
        height: 100%;
        color: black;
    }
`;

export function EmbedFront() {
  return (
    <EmbedMainWrapper>
      <div style={{ height: 800, display: 'flex', flexDirection: 'column' }}>
        {/* Header */}
        <Header>
          <h1>visikon Core - media library</h1>
        </Header>

        {/* Buttons */}
        <div style={{ display: 'flex' }}>
          <MainButton>
            <Link to="./images">
              <Icon name="image" size="big" style={{ fontSize: '6em' }} />
              <h1>Image</h1>
            </Link>
          </MainButton>
          <MainButton>
            <Link to="./videos">
              <Icon name="video" size="huge" style={{ fontSize: '6em' }} />
              <h1>Video</h1>
            </Link>
          </MainButton>
        </div>
      </div>
    </EmbedMainWrapper>
  );
}
