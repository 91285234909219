import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IState } from 'reducers/reducers';
import { BlockLists } from './CompositionListRenderer';
import { useAllCompositions } from 'api/compositionApi';
import { Spinner } from 'components/spinner';
import { PlaceHolderSegment } from 'components/PlaceholderSegment';

interface IOwnProps {
  useAsPicker?: boolean;
  onCompositionPick?: (composition: string) => void;
}

export const BlockListOverview = ({ useAsPicker, onCompositionPick }: IOwnProps) => {
  const history = useHistory();
  const [showArchived, setShowArchived] = useState(false);
  const lang = useSelector((state: IState) => state.language.activeLanguage);
  const compositionsQuery = useAllCompositions();

  const handleOnClick = (id: string) => {
    if (!useAsPicker) history.push(`/block/composer/${id}`);

    if (onCompositionPick) {
      onCompositionPick(id);
    }
  };

  if (compositionsQuery.isIdle) {
    return <PlaceHolderSegment iconName="key" header="Awaiting authentication token..." />;
  }

  if (compositionsQuery.isLoading) {
    return <Spinner visible text="Loading compositions..." />;
  }

  if (compositionsQuery.isError) {
    return (
      <PlaceHolderSegment iconName="exclamation triangle" header="An Error happend while loading compositions :/">
        <pre>{compositionsQuery.error.message}</pre>
      </PlaceHolderSegment>
    );
  }

  return (
    <BlockLists
      items={compositionsQuery.data}
      languageCode={lang}
      onClick={handleOnClick}
      useAsPicker={useAsPicker}
      showArchived={showArchived}
      setShowArchived={setShowArchived}
    />
  );
};
