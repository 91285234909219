import * as Semantic from 'semantic-ui-react';
import * as React from 'react';
import * as Styles from '../variationParts/speak/SpeakVariation.styles';
import { useFetchSynthesizedAudio, useUpdatePhoneticsXML } from 'api/v2/text2speechApi';
import { LanguageCode } from '@visikon/core-models/languageTypes';
import { Spinner } from 'components/spinner';
import { accepted_consonants, accepted_support_characters, accepted_vowels, hasUnacceptedValues } from './phoneticsValidation';
import { copyToClipboard } from 'commons/utils';
import PhoneticsList from './PhoneticsList';
import { LanguageSelector } from 'components/LanguageSelector';
import { usePreferredVoice } from './UsePreferredVoice';

export default function PhoneticsTestModal({
  activeLanguage,
  selectedVoiceName,
  selectedVoiceGender,
  children,
}: {
  activeLanguage: LanguageCode;
  selectedVoiceName: string;
  selectedVoiceGender: string;
  children: JSX.Element;
}) {
  const [testableText, setTestableText] = React.useState('Panodil');
  const [phoneticsText, setPhoneticsText] = React.useState('panodiːiːl');
  const [lastGeneratedPhoneticTXT, setLastGeneratedPhoneticTXT] = React.useState('');
  const [isGenerating, setGenerating] = React.useState(false);
  const [isPlaying, setPlaying] = React.useState<boolean>(false);
  const [lang, setLang] = React.useState<LanguageCode>(activeLanguage);

  const [phoneticVoiceName, setPhoneticVoiceName] = React.useState(selectedVoiceName);
  const [phoneticVoiceGender, setPhoneticVoiceGender] = React.useState(selectedVoiceGender);

  const mutator = useUpdatePhoneticsXML();

  const formattedRequest = wrappedWithSSML(testableText, phoneticsText);

  const { voiceName, voiceGender } = usePreferredVoice({
    selectedLanguage: lang,
  });

  React.useEffect(() => {
    setPhoneticVoiceName(voiceName);
    setPhoneticVoiceGender(voiceGender);
  }, [phoneticVoiceName, phoneticVoiceGender, setPhoneticVoiceName, setPhoneticVoiceGender, voiceName, voiceGender]);

  const synthesizedTestSpeak = useFetchSynthesizedAudio(
    {
      text: formattedRequest || '',
      activeLanguage: lang,
      selectedVoiceName: phoneticVoiceName,
      selectedVoiceGender: phoneticVoiceGender,
      isPhoneticsTest: true,
    },
    isGenerating,
  );

  const mediaRef = React.useRef<HTMLAudioElement>(null);
  const phoneticsEditorRef = React.useRef<HTMLTextAreaElement>(null);
  const testableEditorRef = React.useRef<HTMLTextAreaElement>(null);

  const sanitizePhoneticsText = (text: string) => text.toLowerCase();

  function onEnded() {
    if (mediaRef.current === null) {
      return;
    }

    // @ts-ignore
    delete mediaRef.current.src;
    setPlaying(false);
  }

  React.useEffect(() => {
    const blob = synthesizedTestSpeak.data as unknown as Blob;

    if (blob?.size === 0) {
      return;
    }

    if (isGenerating && synthesizedTestSpeak.isSuccess) {
      handleSpeechAttached(blob);
      setGenerating(false);
      setLastGeneratedPhoneticTXT(phoneticsText);
    }
  }, [isGenerating, phoneticsText, synthesizedTestSpeak, synthesizedTestSpeak.isSuccess]);

  const handleSpeechAttached = (blob: Blob) => {
    const mediaTag = mediaRef.current;

    if (blob && mediaTag) {
      const customFileName = `Generated_Audio_Phonetics_test.wav`;
      const file = new File([blob], customFileName, { type: blob.type });
      const tempUrl = URL.createObjectURL(file);

      mediaTag.src = tempUrl;
    }
  };

  const handlePlayClicked = () => {
    if (mediaRef.current === null) {
      return;
    }

    if (isPlaying) {
      mediaRef.current.pause();
      setPlaying(false);
    } else {
      mediaRef.current.play();
      setPlaying(true);
    }
  };

  const sanitizeText = () => {
    const sanitizedText = sanitizePhoneticsText(phoneticsText);
    setPhoneticsText(sanitizedText);

    // change the value of the editor
    phoneticsEditorRef.current!.value = sanitizedText;
  };

  const handleGenerateClicked = () => {
    sanitizeText();
    setGenerating(true);
  };

  const handleTestableEditorChange = (textValue: string) => {
    // Remove trailing newline characters
    let newTextValue = textValue;
    while (typeof newTextValue === 'string' && newTextValue.endsWith('\n')) {
      newTextValue = newTextValue.slice(0, -1);
    }
    setTestableText(newTextValue);
  };
  const handlePhoneticsEditorChange = (textValue: string) => {
    setPhoneticsText(textValue);
  };

  const containsUnacceptedValues = hasUnacceptedValues(phoneticsText);

  const [showCopyIcon, setShowCopyIcon] = React.useState(false);

  const handleSelectToCopy = (event: React.SyntheticEvent<HTMLTextAreaElement, Event>) => {
    const target = event.target as HTMLTextAreaElement;
    const selectedText = target.value.substring(target.selectionStart, target.selectionEnd);

    // Check if all text is selected
    if (target.value && selectedText.length === target.value.length) {
      const copy = copyToClipboard(selectedText);

      copy.then(
        () => {
          // Show copy icon
          setShowCopyIcon(true);

          // Hide copy icon
          setTimeout(() => {
            setShowCopyIcon(false);
          }, 2000);
        },
        (err) => {
          // eslint-disable-next-line no-console
          console.error('Could not copy text: ', err);
        },
      );
    }
  };

  const handlePassToEditor = React.useCallback(
    (passedCharacter: string) => {
      const editor = phoneticsEditorRef.current!;
      const cursorPosition = editor.selectionStart || 0;

      // Insert passedCharacter at cursor position
      const updatedText = [phoneticsText.slice(0, cursorPosition), passedCharacter, phoneticsText.slice(cursorPosition)].join('');

      // Update the state
      setPhoneticsText(updatedText);

      // Change the value of the editor
      editor.value = updatedText;

      // Set the cursor position after the inserted character
      const newPosition = cursorPosition + passedCharacter.length;
      editor.setSelectionRange(newPosition, newPosition);
    },
    [phoneticsText],
  );

  const clickable_vowelList = accepted_vowels(handlePassToEditor);
  const clickable_consonantList = accepted_consonants(handlePassToEditor);
  const clickable_supportCharList = accepted_support_characters(handlePassToEditor);

  const handleUpdateEntry = () => {
    const wholeLexeme = wrappedAsLexeme(testableText, phoneticsText);
    const [firstGrapheme] = testableText.split('\n');

    const xmlUpdateRequest = {
      lexeme: wholeLexeme,
      grapheme: firstGrapheme,
      lang,
    };

    mutator.mutate(xmlUpdateRequest);
  };

  const handleUpdateEditor = (graphemeText: string, phonemeText?: string) => {
    const graphemeEditor = testableEditorRef.current!;
    const phonemeEditor = phoneticsEditorRef.current!;

    // Update the state
    setTestableText(graphemeText);
    if (phonemeText) {
      setPhoneticsText(phonemeText);
    }

    // Change the value of the editor
    graphemeEditor.value = graphemeText;
    if (phonemeText) {
      phonemeEditor.value = phonemeText;
    }
  };

  return (
    <>
      <Semantic.Modal trigger={children} style={{ height: '70%' }} dimmer="blurring" inverted="false" onClick={(e: Event) => e.stopPropagation()}>
        <>
          <Semantic.Modal.Content style={{ display: 'flex', gap: '1rem' }}>
            <Semantic.Container>
              <Styles.TestModalTitle>Testable text</Styles.TestModalTitle>
              <Styles.TestableTextEditor
                ref={testableEditorRef}
                onChange={({ target }) => handleTestableEditorChange(target.value)}
                defaultValue={testableText}
                spellCheck="false"
              />
              <Styles.TestModalTitle>
                Phonetics text{' '}
                <span style={{ fontSize: '0.8rem', display: 'inline-block' }}>
                  <a
                    href="https://learn.microsoft.com/en-us/azure/ai-services/speech-service/speech-ssml-phonetic-sets#da-dk"
                    target="_blank"
                    rel="noreferrer"
                  >
                    &lt;&lt; IPA alphabet Danish &gt;&gt;
                  </a>
                </span>
              </Styles.TestModalTitle>
              <div style={{ position: 'relative', width: '100%' }}>
                <Styles.PhoneticsEditor
                  ref={phoneticsEditorRef}
                  onChange={({ target }) => handlePhoneticsEditorChange(target.value)}
                  defaultValue={phoneticsText}
                  onSelect={handleSelectToCopy}
                  spellCheck="false"
                />
                {showCopyIcon && <Styles.FadeIcon name="copy" color="grey" />}
              </div>
              <Styles.PhoneticInfo>
                <p>Phonetic vowels: {clickable_vowelList}</p>
                <p>Phonetic consonants: {clickable_consonantList}</p>
                <p>Phonetic support characters: {clickable_supportCharList}</p>
              </Styles.PhoneticInfo>
              <PlayButton loaded={!!mediaRef.current?.src} isPlaying={isPlaying} handlePlayClicked={handlePlayClicked} />
              <GenerateButton
                loading={isGenerating}
                handleGenerateClicked={handleGenerateClicked}
                isDisabled={containsUnacceptedValues || lastGeneratedPhoneticTXT === phoneticsText}
              />
              <Semantic.Button
                name="UpdateEntry"
                color="green"
                floated="right"
                onClick={() => {
                  handleUpdateEntry();
                }}
              >
                Update/Add Entry
              </Semantic.Button>

              {containsUnacceptedValues && (
                <Semantic.Item>
                  <Semantic.Icon name="warning circle" color="red" />
                  Non-phonetic sign detected
                </Semantic.Item>
              )}
              <Styles.LanguageContainer>
                Language:
                <br />
                <LanguageSelector activeLang={lang} onLanguageChanged={setLang} />
              </Styles.LanguageContainer>
            </Semantic.Container>
            <Semantic.Container style={{ position: 'relative' }}>
              <Styles.TestModalTitle>Phonetics List</Styles.TestModalTitle>
              <PhoneticsList onUpdateEditor={handleUpdateEditor} serverChanges={mutator.isSuccess} lang={lang} />
            </Semantic.Container>
            <audio ref={mediaRef} style={{ display: 'none' }} onEnded={onEnded} preload="metadata" crossOrigin="anonymous" />
          </Semantic.Modal.Content>
        </>
      </Semantic.Modal>
    </>
  );
}

function PlayButton({ loaded, isPlaying, handlePlayClicked }: { loaded: boolean; isPlaying: boolean; handlePlayClicked: () => void }) {
  return (
    <Semantic.Button name="play" onClick={handlePlayClicked} disabled={!loaded}>
      <Semantic.Icon name={isPlaying ? 'pause circle' : 'play circle'} />
    </Semantic.Button>
  );
}
function GenerateButton({
  loading,
  isDisabled,
  handleGenerateClicked,
}: {
  loading: boolean;
  isDisabled: boolean;
  handleGenerateClicked: () => void;
}) {
  return (
    <Semantic.Button name="generate" onClick={handleGenerateClicked} disabled={isDisabled}>
      <Spinner visible={loading} />
      <Semantic.Icon name="sync alternate" />
    </Semantic.Button>
  );
}

function wrappedWithSSML(textToTest: string, phoneticText: string) {
  return `<phoneme alphabet="ipa" ph="${phoneticText}">
            ${textToTest}
          </phoneme>`;
}
function wrappedAsLexeme(textToTest: string, phoneticText: string) {
  const graphemeList = textToTest.split('\n');
  return `<lexeme>
            ${graphemeList.map((grapheme) => `<grapheme>${grapheme}</grapheme>`).join('')}
            <phoneme>${phoneticText}</phoneme>
          </lexeme>`;
}
