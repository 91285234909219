import { BlockList, CompositionListItem } from '@visikon/core-models/content';
import axios, { AxiosError } from 'axios';
import { useRecoilState } from 'recoil';
import { tokenState } from 'redux-recoil-sync.state';
import { URLS } from './urls';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryConfig } from './react-query-utils';
import { APIUtils } from 'commons/utils';

export const COMPOSITOON_CACHE_KEY: Readonly<string> = 'compositions';

export function useComposition(id: string) {
  const [token] = useRecoilState(tokenState);

  return useQuery(
    [COMPOSITOON_CACHE_KEY, 'single', id],
    async () => {
      const result = await axios.get<BlockList>(URLS.compositions.get(id!), APIUtils.tokenHeader(token!));

      return result.data;
    },
    { ...QueryConfig, enabled: !!token && id !== 'new' },
  );
}

export function useAllCompositions() {
  const [token] = useRecoilState(tokenState);

  return useQuery<CompositionListItem[], AxiosError>(
    [COMPOSITOON_CACHE_KEY, 'list'],
    async () => {
      const result = await axios.get<CompositionListItem[]>(URLS.compositions.all(), APIUtils.tokenHeader(token!));

      return result.data;
    },
    { ...QueryConfig, enabled: !!token },
  );
}

export function useCopyComposition(id: string) {
  const queryClient = useQueryClient();
  const [token] = useRecoilState(tokenState);

  return useMutation<BlockList, AxiosError, string>({
    mutationFn: async (name) => {
      const request = await axios.post<BlockList>(URLS.compositions.copy(id), undefined, {
        params: { name },
        ...APIUtils.tokenHeader(token!),
      });

      return request.data;
    },
    onSuccess(newComposition) {
      queryClient.invalidateQueries([COMPOSITOON_CACHE_KEY, 'list']);
      queryClient.setQueryData([COMPOSITOON_CACHE_KEY, 'single', newComposition._id], newComposition);
    },
  });
}
