import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Content } from '@visikon/core-models';
import { ImageFile } from 'components/mediaLibrary/images/ImageFile';
import { IState } from 'reducers/reducers';
import { loadImage } from 'actions/MediaActions';
import { ObjectId } from '@visikon/core-models/base';

interface IPropsFromState {
  language: Content.LanguageCode;
  variation: ObjectId;
  image?: Content.Image;
}
interface IPropsFromDispatch {
  loadImage: (id: ObjectId) => void;
}
interface IOwnProps {
  imageId: ObjectId;
  className?: string;
}

type Props = IPropsFromState & IPropsFromDispatch & IOwnProps;
/**
 * This is a connected ImageFileWrapper which loads an Image Object from an ID.
 */
class CImageFileWrapper extends React.Component<Props> {
  componentDidMount() {
    this.props.loadImage(this.props.imageId);
  }

  render() {
    if (this.props.image) {
      return (
        <ImageFile
          className={this.props.className}
          image={this.props.image}
          language={this.props.language}
        />
      );
    }
    return null;
  }
}

const mapStateToProps: MapStateToProps<IPropsFromState, IOwnProps, IState> = (state, props) => {
  const image = (state.media.imagesByFolder.root || []).find((img) => img._id === props.imageId);

  return {
    language: state.language.activeLanguage,
    variation: state.language.activeMediaVariation,
    image,
  };
};

const mapDispatchToProps: MapDispatchToProps<IPropsFromDispatch, IOwnProps> = (dispatch) => ({
  loadImage: (id: ObjectId) => {
    dispatch(loadImage(id));
  },
});

export const ImageFileWrapper = connect(mapStateToProps, mapDispatchToProps)(CImageFileWrapper);
