import OtpInput from 'react-otp-input';

interface IProps {
  value: any;
  onChange: any;
}

export function TFAInput(props: IProps) {
  return (
    <OtpInput
      value={props.value}
      onChange={props.onChange}
      numInputs={6}
      containerStyle={{
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '25px',
      }}
      inputStyle={{
        width: '3rem',
        height: '3rem',
        fontSize: '2rem',
        margin: '4px',
        borderRadius: 4,
        border: '1px solid rgba(0,0,0,0.3)',
        padding: 0,
      }}
      renderSeparator={<span>-</span>}
      renderInput={(inputProps) => <input {...inputProps} />}
    />
  );
}
