import { Action } from 'redux';
import { Content } from '@visikon/core-models';
import { isType } from 'typescript-fsa';
import {
  mediaLibraryError,
  listImagesInFolderSuccess,
  listVideosInFolderSuccess,
  updateVideoSuccess,
  listMediaVariationsSuccess,
  createMediaVariationSuccess,
  saveText,
  saveTextSuccess,
  listTextsSuccess,
  loadTextIntoEditorSuccess,
  saveTextError,
  loadImageSuccess,
  loadVideoSuccess,
  isUploading,
  setNewlyCreatedId,
  clearNewlyCreatedId,
  updateBlockSuccess,
  uploadThumbnailSuccess,
  clearUploadThumbnailSuccess,
} from 'actions/MediaActions';

export interface IState {
  readonly newlyCreatedId?: string;
  readonly isUploading: boolean;
  // Images
  readonly imagesByFolder: {
    [folderId: string]: Content.Image[];
  };
  // Videos
  readonly videosByFolder: {
    [folderId: string]: Content.Video[];
  };
  readonly updateVideoSuccess?: Content.Video;
  // Texts
  readonly texts: Content.Text[];
  readonly activeEditorText?: Content.Text;
  readonly isSavingText: boolean;
  readonly saveTextError?: string;
  // Media variations
  readonly mediaVariations: Content.MediaVariation[];
  readonly error?: string;

  readonly blocksByFolder: any;
  uploadThumbnailSuccess?: string;
}

const clearError: (state: IState) => IState = (state) => ({ ...state, error: undefined });

const initialState: IState = {
  isUploading: false,
  imagesByFolder: {},
  videosByFolder: {},
  blocksByFolder: {},
  mediaVariations: [],
  texts: [],
  isSavingText: false,
  uploadThumbnailSuccess: undefined,
};

export function mediaReducer(state: IState = initialState, action: Action): IState {
  if (isType(action, mediaLibraryError)) {
    return { ...state, error: action.payload.msg };
  }

  if (isType(action, isUploading)) {
    return { ...state, isUploading: action.payload };
  }

  if (isType(action, setNewlyCreatedId)) {
    return { ...state, newlyCreatedId: action.payload };
  }

  if (isType(action, clearNewlyCreatedId)) {
    return { ...state, newlyCreatedId: undefined };
  }


  /* ****** */
  /* Images */
  /* ****** */
  // Todo: Possibly remove old?
  if (isType(action, loadImageSuccess)) {
    const folderId = action.payload.folder || 'root';
    const imagesByFolder = { ...state.imagesByFolder };
    imagesByFolder[folderId] = [...(imagesByFolder[folderId] || []), action.payload];
    return clearError({ ...state, imagesByFolder });
  }

  if (isType(action, listImagesInFolderSuccess)) {
    const imagesByFolder = { ...state.imagesByFolder };
    const { folderId } = action.payload;
    imagesByFolder[folderId] = action.payload.result;
    return clearError({ ...state, imagesByFolder });
  }

  if (isType(action, uploadThumbnailSuccess)) {
    return { ...state, uploadThumbnailSuccess : action.payload };
  }
  if (isType(action, clearUploadThumbnailSuccess)) {
    return { ...state, uploadThumbnailSuccess: undefined };
  }

  /* ****** */
  /* Videos */
  /* ****** */
  // Todo: Possibly remove old?
  if (isType(action, loadVideoSuccess)) {
    const folderId = action.payload.folder || 'root';
    const videosByFolder = { ...state.videosByFolder };
    videosByFolder[folderId] = [...(videosByFolder[folderId] || []), action.payload];
    return clearError({ ...state, videosByFolder });
  }

  if (isType(action, listVideosInFolderSuccess)) {
    const videosByFolder = { ...state.videosByFolder };
    const { folderId } = action.payload;
    videosByFolder[folderId] = action.payload.result;
    return clearError({ ...state, videosByFolder });
  }

  if (isType(action, updateVideoSuccess)) {
    return clearError( { ...state, updateVideoSuccess : action.payload }) ;
  }

  if (isType(action, updateBlockSuccess)) {
    return {
      ...state,
      blocksByFolder: action.payload,
    };
  }

  /* **************** */
  /* Media Variations */
  /* **************** */
  if (isType(action, listMediaVariationsSuccess)) {
    return {
      ...state,
      mediaVariations: action.payload,
    };
  }

  /* ***** */
  /* Texts */
  /* ***** */
  if (isType(action, saveText)) {
    return {
      ...state,
      isSavingText: true,
      saveTextError: undefined,
    };
  }

  if (isType(action, saveTextSuccess)) {
    const textList = (state.texts || []).filter((t) => t._id !== action.payload._id);
    const texts = [...textList, action.payload].sort((a, b) => (b.createdAt - a.createdAt));
    return {
      ...state,
      texts,
      isSavingText: false,
    };
  }

  if (isType(action, saveTextError)) {
    return {
      ...state,
      isSavingText: false,
      saveTextError: action.payload,
    };
  }

  if (isType(action, listTextsSuccess)) {
    const texts = (action.payload || []).sort((a, b) => (b.createdAt - a.createdAt));
    return {
      ...state,
      texts,
    };
  }

  if (isType(action, loadTextIntoEditorSuccess)) {
    const { texts } = state;

    // Add text to list of texts if not already there.
    if (state.texts.find((text) => text._id === action.payload._id) === undefined) {
      texts.push(action.payload);
    }

    return {
      ...state,
      texts,
      activeEditorText: action.payload,
    };
  }

  if (isType(action, createMediaVariationSuccess)) {
    const mediaVariations = [...state.mediaVariations];
    mediaVariations.push(action.payload);
    return {
      ...state,
      mediaVariations,
    };
  }

  return state;
}
