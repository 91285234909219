import { connect } from 'react-redux';
import { IState } from 'reducers/reducers';
import { RouteComponentProps } from 'react-router';
import { WelcomePublic } from 'components/WelcomePublic';
import { FrontPage } from 'components/FrontPage';

interface IProps extends RouteComponentProps<{}> {
  isLoggedIn: boolean;
}

function CFrontPageContainer({ isLoggedIn }: IProps) {
  if (isLoggedIn) {
    return <FrontPage />;
  }
  return <WelcomePublic />;
}

const mapStateToProps = (state: IState) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

export const FrontPageContainer = connect(
  mapStateToProps,
)(CFrontPageContainer);
