// tslint:disable
import React from 'react';
import { findDOMNode } from 'react-dom';
import {
  DragSource,
  DropTarget,
  ConnectDropTarget,
  ConnectDragSource,
  DropTargetMonitor,
  DropTargetConnector,
  DragSourceConnector,
  DragSourceMonitor,
} from 'react-dnd';
import { XYCoord } from 'dnd-core';

export const TYPE_CARD = 'card';

const style = {
  boxShadow: '0 1px 2px 0 rgba(34,36,38,.15)',
  borderRadius: '.28571429rem',
  border: '1px solid rgba(34,36,38,.15)',
  // border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
};

const cardSource = {
  beginDrag(props: CardProps) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};

const cardTarget = {
  // TODO: Horrible function, candidate for refactoring
  /* eslint-disable consistent-return */
  // @ts-ignore
  hover(props: CardProps, monitor: DropTargetMonitor<CardProps>, component: Card | null) {
    const horizontal = !!props.horizontal;
    const vertical = !horizontal;

    if (!component) {
      return null;
    }
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen

    // eslint-disable-next-line react/no-find-dom-node
    const hoverBoundingRect = (findDOMNode(component) as Element).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    // Get horizontal middle
    const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

    // Get Pixels to left
    const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (vertical && dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (vertical && dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Dragging right
    if (horizontal && dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
      return;
    }

    // Dragging left
    if (horizontal && dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
      return;
    }

    // Time to actually perform the action
    props.moveCard(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.

    // eslint-disable-next-line no-param-reassign
    monitor.getItem().index = hoverIndex;
  },
};

export interface CardDragProperties {
  id: any;
  index: number;
}

export interface CardProps extends CardDragProperties {
  horizontal?: boolean;
  style?: React.CSSProperties;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onDragStart?(): void;
  onDragEnd?(): void;
}

interface CardSourceCollectedProps {
  isDragging: boolean
  connectDragSource: ConnectDragSource
}

interface CardTargetCollectedProps {
  connectDropTarget: ConnectDropTarget
}

type ComponentProps = CardProps & CardSourceCollectedProps & CardTargetCollectedProps;

class Card extends React.Component<ComponentProps> {
  componentDidUpdate(oldProps: ComponentProps) {
    if (!oldProps.isDragging && this.props.isDragging && this.props.onDragStart) {
      this.props.onDragStart();
    }
    if (oldProps.isDragging && !this.props.isDragging && this.props.onDragEnd) {
      this.props.onDragEnd();
    }
  }

  public render() {
    const {
      isDragging, connectDragSource, connectDropTarget, children,
    } = this.props;
    const opacity = isDragging ? 0 : 1;

    return connectDragSource(
      connectDropTarget(<div style={{ ...(this.props.style || style), opacity }}>{children}</div>),
    );
  }
}

export const DraggableCard = DropTarget<CardProps, CardTargetCollectedProps>(
  TYPE_CARD,
  cardTarget,
  (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(
  DragSource<CardProps, CardSourceCollectedProps>(
    TYPE_CARD,
    cardSource,
    (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  )(Card),
);
