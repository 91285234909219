import styled from "styled-components";
import * as Semantic from 'semantic-ui-react';

export const BOTTOM_HEIGHT = '217px';

export const Header = styled.div`
  position: relative;
  height: 40px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
`;

export const SubHeader = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-right: 16px;
`;

/*
 * Wrapper components.
 */
export const Composer = styled.div`
    position: fixed;
    left: 285px;
    top: 36px;
    right: 10px;
    bottom: 10px;
    display: flex;
    flex-direction: column;
`;

export const DeleteOverlay = styled.div<{ visible: boolean }>`
    display: ${(p) => (p.visible ? 'inherit' : 'none')}
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
`;

export const MenuContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px;
`;

/*
 * Manus
 */

export const UpperArea = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    width: 100%;
    overflow: auto;
`;

export const VideoPreviewArea = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const BottomArea = styled.div`
    display: flex;
    align-items: center;
    height: ${BOTTOM_HEIGHT};
    width: 100%;
    border-top: 1px solid #ccc;
`;

export const NameField = styled(Semantic.Input)`
    flex: 1;
    height: 40px;
`;
