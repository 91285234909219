import { ImageFile } from 'components/mediaLibrary/images/ImageFile';
import { API } from 'config';
import { Content } from '@visikon/core-models';
import { getTranslation } from '@visikon/core-models/typeUtils';
import React from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { ArrayElement } from 'components/contentTypes/utils';
import { CopyOnClick } from 'components/CopyOnClick';

const EmbedTextArea = styled.textarea`
  font-family: monospace, monospace;
  line-height: 1.4em;
  font-size: 1.1em;
  margin-top: 10px;
  height: calc(100% - 49px);
  width: 100%;
  resize: none;
  border: 1px solid lightgrey;
`;

const ContentDiv = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: 10px;
  }

  & > div:last-child {
    margin-left: 10px;
  }
`;

const ImageWrapper = styled.div`
  width: 532px;

  & > div > img {
    max-width: 532px;
  }
`;

type Translation = ArrayElement<Content.Image['translations']>;

function getEmbedUrl(image: Content.Image, trans: Translation) {
  // return `${API_ROOT}v1/media/image/file/${image._id}/${trans.language}?modified=${trans.lastModified}`;
  return `${API.baseURL}media/image/file/${image._id}/${trans.language}`;
}

function getEmbedCode(image: Content.Image, trans: Translation) {
  return `<img src="${getEmbedUrl(image, trans)}" width="560"  alt=""/>`;
}

interface IProps {
  image: Content.Image;
  onDismiss(): void;
}
export const EmbedImageDialog = ({ onDismiss, image }: IProps) => {
  const textarea = React.useRef<HTMLTextAreaElement>(null);

  const trans = getTranslation<Content.Image>(image, 'da');
  if (trans === undefined) {
    return <div>no!</div>;
  }

  const selectAll = () => {
    if (textarea.current) {
      textarea.current.focus();
      textarea.current.select();
    }
  };

  return (
    <Modal open onClose={onDismiss} closeOnEscape>
      <Modal.Header>{image.name}</Modal.Header>
      <Modal.Content>
        <ContentDiv>
          <div style={{ flex: 3 }}>
            <Header size="medium">Image preview:</Header>
            <ImageWrapper>
              <ImageFile
                image={image}
                // width={532}
                language={trans!.language}
              />
            </ImageWrapper>
          </div>
          <div style={{ flex: 2 }}>
            <div style={{ display: 'flex' }}>
              <Header size="medium" style={{ flex: 1 }}>
                Embed code:
              </Header>
              <CopyOnClick text={() => getEmbedCode(image, trans!)} popupPosition="top center">
                <Button size="small">Copy to clipboard</Button>
              </CopyOnClick>
            </div>
            <EmbedTextArea
              ref={textarea}
              onChange={() => {
                /* nothing */
              }}
              value={getEmbedCode(image, trans!)}
              readOnly
              onClick={selectAll}
              spellCheck={false}
            />
          </div>
        </ContentDiv>
      </Modal.Content>
    </Modal>
  );
};
