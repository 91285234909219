import { atom, useRecoilState } from 'recoil';
import { History, LocationState } from 'history';
import { useHistory } from 'react-router-dom';
import { logger } from '../../commons/logger';

const URL_SUFFIX = '#search';

export type SearchFilter = {
  text?: string,
  category?: string,
  page?: number
};

export type SearchFilterMap = {
  [key: string]: SearchFilter
};

const searchState = atom<SearchFilterMap>({
  key: 'searchState',
  default: {},
});

export function useSearchState() {
  const history = useHistory();
  const [searchFilters, setSearchFilters] = useRecoilState(searchState);
  const { pathname, hash } = location;

  // Only return data if the url contains #search as search filters should only be remembered
  // when using browser navigation, and not when using the navigation menu
  const searchFilter = (hash === URL_SUFFIX && searchFilters[pathname]) ? searchFilters[pathname] : {};

  const setSearchFilter = (filter: SearchFilter | undefined) => {
    logger.debug('setSearchFilter', filter);
    const clone = { ...searchFilters };

    if (filter) clone[pathname] = filter; // Set filter
    else delete clone[pathname]; // Clear filter

    setSearchFilters(clone);
    updateUrl(history, filter);
  };

  return { searchFilter, setSearchFilter };
}

function updateUrl(history: History<LocationState>, filter: SearchFilter | undefined) {
  if (filter && location.hash !== URL_SUFFIX) history.push(`${location.pathname}${URL_SUFFIX}`);
}
