import React from 'react';
import { Content } from '@visikon/core-models';
import { ERROR_IMAGE, PLACEHOLDER_IMAGE } from 'commons/utils';
import { API } from 'config';
import { getTranslationToUse } from '../utils';
import { WarningOverlay } from '../WarningOverlay';

interface IProps {
  className?: string;
  image: Content.Image;
  language: Content.LanguageCode;
}

/**
 * This function loads an image file (in base64) from the server,
 * using authentication.
 */
export function ImageFile(props: IProps) {
  const [url, setUrl] = React.useState('');
  const [showOverlay, setShowOverlay] = React.useState(false);

  const { image, language } = props;

  React.useEffect(() => {
    const translation = getTranslationToUse(image, language);

    if (!translation) {
      setUrl(PLACEHOLDER_IMAGE);
      return;
    }

    setUrl(`${API.baseBlobUrl}${translation.src}`);
    setShowOverlay(language !== translation.language);
  }, [image._id, language, image]);

  return (
    <div style={{ position: 'relative', display: 'flex' }}>
      <img alt="" className={props.className} src={url} onError={() => setUrl(ERROR_IMAGE)} />

      {showOverlay ? <WarningOverlay>No image for selected language</WarningOverlay> : null}
    </div>
  );
}
