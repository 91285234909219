import { LanguageCode } from '@visikon/core-models/content';
import { Blueprint } from '@visikon/core-models/encoding';
import * as Semantic from 'semantic-ui-react';
import moment from 'moment';
import { langFromCode } from '../../../../../commons/languages';
import { BlockDurations } from './EncodeNewDurations';
import * as Styles from './EncodeNewBlueprintTable.styles'

export type BlockSummary = {
  index: number,
  maxDuration: number,
  audioDelay: number,
  audioDelayPct: number,
  language: LanguageCode,
  metadata: {
    blockId: string;
    name: string,
    video: {
      variation: string,
      language: LanguageCode,
      duration: number,
      durationPct: number,
      hasMedia: boolean,
    };
    speak: {
      variation: string,
      language: LanguageCode,
      duration: number,
      durationPct: number,
      hasMedia: boolean,
    };
  }
};

export function BlueprintTable({ blueprint }: { blueprint: Blueprint }) {
  const asMillis = (n: number | undefined) => n ? Math.round(n * 1000) : 0;

  const maxDuration = blueprint.header.blocks
    .map(block => Math.max(asMillis(block.video.duration), asMillis(block.speak.duration) + (blueprint.body.audioDelay || 0)))
    .reduce((prev, curr) => Math.max(prev, curr));

  const asPct = (duration: number) => duration > 0 ? duration / (maxDuration / 100) : 0;

  const asSummaryMetadata = (i: number) => {
    const m = blueprint.header.blocks[i];
    const paths = blueprint.body.blocks[i]

    return {
      ...m,
      video: {
        ...m.video,
        duration: asMillis(m.video.duration),
        durationPct: asPct(asMillis(m.video.duration)),
        hasMedia: !!paths.video.src,
      },
      speak: {
        ...m.speak,
        duration: asMillis(m.speak.duration),
        durationPct: asPct(asMillis(m.speak.duration)),
        hasMedia: !!paths.speak.src,
      }
    }
  };

  const blockSummary = (index: number) => ({
    maxDuration,
    index: index + 1,
    audioDelay: blueprint.body.audioDelay || 0,
    audioDelayPct: asPct((blueprint.body.audioDelay || 0) as number),
    language: blueprint.header.language,
    metadata: asSummaryMetadata(index),
  } as BlockSummary);

  return (
    <Semantic.Table celled>
      <Semantic.Table.Body>
        {blueprint.header.blocks.map((metadata, index) =>
          <BlueprintRow key={index} data={blockSummary(index)} />
        )}
      </Semantic.Table.Body>
    </Semantic.Table>
  );
}

function BlueprintRow({ data }: { data: BlockSummary }) {
  const hasWarning = !data.metadata.video.hasMedia || !data.metadata.speak.hasMedia;
  const format = hasWarning ? { color: 'red', fontWeight: 'bold' } : undefined;

  return (
    <Semantic.Table.Row key={data.index}>
      <Semantic.Table.Cell style={{ ...format }}>{data.index}. {data.metadata.name}</Semantic.Table.Cell>
      <Semantic.Table.Cell><BlueprintBlock data={data} /></Semantic.Table.Cell>
    </Semantic.Table.Row>
  );
}

function BlueprintBlock({ data }: { data: BlockSummary }) {
  const flag = (lang?: string) => lang && lang !== data.language ? <Styles.Flag name={langFromCode(lang as LanguageCode).flag as any} /> : <span style={{height: 14}} />;
  return (<div style={{display: 'flex', gap: 8}}>
    <Styles.LanguageContainer>
      {flag(data.metadata.video.language)}
      {flag(data.metadata.speak.language)}
    </Styles.LanguageContainer>
    <div style={{fontSize: 'x-small'}}>
      <div>{moment.utc(data.metadata.video.duration).format('mm:ss')}</div>
      <div>{moment.utc(data.metadata.speak.duration).format('mm:ss')}</div>
    </div>
    <div style={{width: '360px'}}>
      <BlockDurations data={data} />
    </div>
    <div style={{fontSize: 'x-small'}}>
      <div>
        <Semantic.Icon name="film" style={{color: 'darkgrey'}} />
        {data.metadata.video.variation}
      </div>
      <div>
        <Semantic.Icon name="microphone" style={{color: 'darkgrey'}} />
        {data.metadata.speak.variation}
      </div>
    </div>
  </div>)
}
