import axios, { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { QueryConfig } from '../react-query-utils';
import { useAzureToken } from './getAzureSpeechToken';
import { LanguageCode } from '@visikon/core-models/languageTypes';
import { staticVoiceData } from 'containers/composer/block/text2speech/StaticVoiceData';
import { V2API } from 'config';
import { APIUtils } from 'commons/utils';
import { useRecoilState } from 'recoil';
import { tokenState } from 'redux-recoil-sync.state';
import { logger } from 'commons/logger';

const AZURE_API = 'https://northeurope.tts.speech.microsoft.com/cognitiveservices/v1';
const PHONETICS_FILE_URL = (lang: string) => `${V2API.baseBlobUrl}azure_phonetics_${lang}.xml?buster=${Date.now()}`;
const PHONETICS_UPDATE_API = `${V2API.baseURL}text/azurePhonetics`;

type XMLUpdateRequest = {
  lexeme: string;
  grapheme: string;
  deleteLexeme?: boolean;
  lang: string;
};

async function fetchAzurePhoneticsConfig(lang: string) {
  const url = PHONETICS_FILE_URL(lang);
  const xmlData = fetch(url, {
    cache: 'no-cache',
  })
    .then((response) => response.text())
    .then((data) => {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(data, 'text/xml');

      return xmlDoc;
    })
    .catch((err) => logger.error(err));

  return xmlData;
}

export async function getAllLexemes(lang: string) {
  const xmlDoc = await fetchAzurePhoneticsConfig(lang);

  // Get all lexemes
  let lexemes = xmlDoc?.getElementsByTagName('lexeme');

  // If lexemes is undefined, initialize it as an empty collection
  if (!lexemes) {
    lexemes = document.createDocumentFragment().children;
  }

  return lexemes;
}

export function useFetchSynthesizedAudio(
  request: { text: string; activeLanguage: LanguageCode; selectedVoiceName?: string; selectedVoiceGender?: string; isPhoneticsTest?: boolean },
  open: boolean,
) {
  const voiceNameFormat = (code: string, name: string) => `${code}-${name}Neural`;

  const activeLanguageVoiceValues = staticVoiceData.find((lang) => lang.value === request.activeLanguage);
  const { value: lang, code: langCode, preferredVoice } = activeLanguageVoiceValues!;
  const selectedVoiceGender = request.selectedVoiceGender || 'Female';
  const voiceName = voiceNameFormat(langCode!, request.selectedVoiceName || preferredVoice[selectedVoiceGender]);

  const url = PHONETICS_FILE_URL(lang);

  const externalFile = `<lexicon uri="${url}"/>`;

  const ssmlRequest = `
     <speak version="1.0"
        xmlns="http://www.w3.org/2001/10/synthesis"
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
        xsi:schemaLocation="http://www.w3.org/2001/10/synthesis
       http://www.w3.org/TR/speech-synthesis/synthesis.xsd" xml:lang="${langCode}">
      <voice name="${voiceName}">
        ${request.isPhoneticsTest ? '' : externalFile}
        ${request.text}
      </voice>
    </speak>
  `;

  const newAzureToken = useAzureToken();

  return useQuery<string>(
    ['synthesizedAudio', request.text, request.selectedVoiceName],
    async () => {
      const azureToken = await newAzureToken();
      const azureHeaders = {
        'X-Microsoft-OutputFormat': 'riff-48khz-16bit-mono-pcm',
        'Content-Type': 'application/ssml+xml',
        Authorization: `Bearer ${azureToken}`,
      };

      try {
        const res = await axios.post(AZURE_API, ssmlRequest, {
          headers: { ...azureHeaders },
          responseType: 'blob',
        });

        return res.data;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching from Azure API:', error);
        throw error;
      }
    },
    { ...QueryConfig, enabled: open },
  );
}

interface CoreValidationError {
  errors: string[];
}

export function useUpdatePhoneticsXML() {
  /**
   * This function is used to update the phonetics XML file in Azure.
   *
  * Example payload:
    {
      "lexeme": "<lexeme><grapheme>pamol</grapheme><grapheme>Pamol</grapheme><phoneme>pamɒːl</phoneme></lexeme>",
      "grapheme": "<grapheme>pamol</grapheme>",
      "containerName": "",
      "blobName": "azure_phonetics",
      "deleteLexeme": false
    }
 */
  const [token] = useRecoilState(tokenState);

  return useMutation<XMLUpdateRequest, AxiosError<CoreValidationError>, XMLUpdateRequest>(async (payload) => {
    const { lang, ...restPayload } = payload;
    const settings = { containerName: '', blobName: `azure_phonetics_${lang}` };
    const res = await axios.post(PHONETICS_UPDATE_API, { ...restPayload, ...settings }, APIUtils.tokenHeader(token!));

    return res.data;
  });
}
