import React from 'react';
import { DraggableCard } from './DraggableCard';

export interface IProps {
  itemStyle?: React.CSSProperties;
  horizontal?: boolean;
  items: Array<{
    id: string;
    element: JSX.Element;
  }>;
  onReorder(from: number, to: number): void;
  onDragStart?(): void;
  onDragEnd?(): void;
}

export function DraggableList({ items, itemStyle, horizontal, onReorder, onDragStart, onDragEnd }: IProps) {
  return (
    <>
      {items.map((item, i) => (
        <DraggableCard
          id={item.id}
          key={item.id}
          index={i}
          horizontal={horizontal}
          style={itemStyle}
          moveCard={onReorder}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        >
          {item.element}
        </DraggableCard>
      ))}
    </>
  );
}
