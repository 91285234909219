import { tokenState } from 'redux-recoil-sync.state';
import { useRecoilState } from 'recoil';
import { io } from 'socket.io-client';
import { useEffect } from 'react';
import { logger } from 'commons/logger';

export const socket = io({
  auth: { token: 'INVALID_PLACEHOLDER' },
  path: "/api/socket.io/",
  autoConnect: false,
});

socket.on('connect', () => {
  const { transport } = socket.io.engine;

  logger.info(`Connected to backend Socket.io server using ${transport.name}`);
});

export function SocketHandler() {
  const [token] = useRecoilState(tokenState);

  useEffect(() => {
    if (token) {
      socket.auth = { token };
      socket.disconnect().connect();
      logger.info('Token updated, reconnecting to backend Socket.io server');
    }

    return () => {
      socket.disconnect();
      logger.info('SocketHandler unmounting, closing Socket.io connection...');
    };
  }, [token]);

  return <></>;
}
