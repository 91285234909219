import React from 'react';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import { ICommonBaseProps } from '../CustomTypeInput';

export interface IParametersForBool {
  type: 'boolean';
  desc: string;
}

interface IComponentBoolInputProps extends ICommonBaseProps, IParametersForBool {
  value?: boolean;
  onChange(key: string, val: boolean): void;
}

export class BoolTypeInput extends React.Component<IComponentBoolInputProps> {
  constructor(props: IComponentBoolInputProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(_: React.SyntheticEvent<HTMLInputElement>, data: CheckboxProps) {
    this.props.onChange(this.props.pKey, data.checked || false);
  }

  render() {
    if (this.props.isTranslation) {
      return null;
    }

    return (
      <Checkbox style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 20 }} checked={this.props.value || false} label={this.props.desc} onChange={this.handleChange} />
    );
  }
}

interface IComponentBoolRenderProps extends ICommonBaseProps, IParametersForBool {
  value: boolean;
}
export function BoolTypeCompact({ desc, value }: IComponentBoolRenderProps) {
  return (
    <>
      <h4 className="ui header" style={{ margin: 0 }}>{desc}</h4>
      <p>
        <i className="ui icon question circle" />
        {value ? 'True' : 'False'}
      </p>
    </>
  );
}
