import { APIUtils } from 'commons/utils';
import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { tokenState } from 'redux-recoil-sync.state';
import { ObjectId } from '@visikon/core-models/base';
import { TextMetadataList } from '@visikon/core-models/v2/metadata';
import { URLS } from './urls';
import { QueryConfig } from './react-query-utils';

const LIST_TEXTS_KEY = 'listTexts';

export function useListTexts( archived: boolean ) {
  const [token] = useRecoilState(tokenState);

  return useQuery(
    [LIST_TEXTS_KEY, archived],
    async () => {
      const data = await axios.get<TextMetadataList>(URLS.texts.get(archived), APIUtils.tokenHeader(token!));
      return data.data;
    },
    { ...QueryConfig, enabled: !!token, keepPreviousData: true },
  );
}

export function useGetTextById(id: ObjectId) {
  const [token] = useRecoilState(tokenState);

  return useQuery(
    ['texts', 'entries', { id }],
    async () => {
      const data = await axios.get(URLS.texts.getById(id), APIUtils.tokenHeader(token!));
      return data.data;
    },
    { ...QueryConfig, enabled: !!token },
  );
}

export function usePostText() {
  const queryClient = useQueryClient();
  const [token] = useRecoilState(tokenState);

  return useMutation<any, Error, any>(
    async (payload: any) => {
      const result = await axios.post(URLS.texts.post(), payload, APIUtils.tokenHeader(token!));
      return result.data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries([LIST_TEXTS_KEY]),
    },
  );
}

export function useUpdateText(id: ObjectId) {
  const queryClient = useQueryClient();
  const [token] = useRecoilState(tokenState);

  return useMutation<any, Error, any>(
    async (payLoad: any) => {
      const result = await axios.put(URLS.texts.put(id), payLoad, APIUtils.tokenHeader(token!));
      return result.data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries([LIST_TEXTS_KEY]),
    },
  );
}
