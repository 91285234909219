import { listContentByType } from 'actions/ContentActions';
import { clearCreateSuccess, createBatch, createNamedUser } from 'actions/UserActions';
import { Content } from '@visikon/core-models';
import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Link, Route, RouteComponentProps } from 'react-router-dom';
import { IState } from 'reducers/reducers';
import { Button, Modal } from 'semantic-ui-react';
import { CreateForm } from './CreateForm';

const CONTENT_NAME = 'mitforlobPack';

interface IPropsFromState {
  availableResources: Content.CustomType[];
  createSuccess: boolean;
}

interface ILocalState {}

interface IPropsFromDispatch {
  clearCreateSuccess(): void;

  createBatch(count: number, expiration: number, resources: string[], selectedDomain?: string): void;

  createNamedUser(name: string, expiration: number, resources: string[]): void;

  loadAvailableResources(): void;
}

type IProps = IPropsFromState & IPropsFromDispatch & RouteComponentProps<void>;

export const CREATE_NAMED_USER_ROUTE = 'createNamedUser';
export const CREATE_BATCH_ROUTE = 'createBatch';

class CUsersRoot extends React.Component<IProps, ILocalState> {
  constructor(props: IProps) {
    super(props);

    // Render stuff
    this.renderUsersRoot = this.renderUsersRoot.bind(this);
    this.renderCreateBatchDialog = this.renderCreateBatchDialog.bind(this);
    this.renderCreateNamedUserDialog = this.renderCreateNamedUserDialog.bind(this);

    this.closeDialogs = this.closeDialogs.bind(this);

    // Handle stuff
    this.handleCreateBatch = this.handleCreateBatch.bind(this);
    this.handleCreateNamedUser = this.handleCreateNamedUser.bind(this);
  }

  closeDialogs() {
    const { match, history, clearCreateSuccess: clearCreateSuccess1 } = this.props;

    clearCreateSuccess1();
    history.push(match.url);
  }

  renderUsersRoot() {
    const { match } = this.props;

    return (
      <div key="root">
        <h1>Users</h1>
        <br />
        <div style={{ display: 'flex', marginTop: 40 }}>
          <div style={{ marginRight: 10 }}>
            <Link to={`${match.url}/createBatch`}>
              <Button primary>Create user batch</Button>
            </Link>
          </div>
          <Link to={`${match.url}/createNamedUser`}>
            <Button>Create named user</Button>
          </Link>
        </div>
      </div>
    );
  }

  handleCreateBatch(count: number, expiration: number, resources: string[], selectedDomain?: string) {
    this.props.createBatch(count, expiration, resources, selectedDomain);
  }

  handleCreateNamedUser(name: string, expiration: number, resources: string[]) {
    this.props.createNamedUser(name, expiration, resources);
  }

  renderCreateBatchDialog() {
    const { availableResources, createSuccess } = this.props;

    const modal = (
      <Modal key="createBatchDialog" open centered={false} onClose={this.closeDialogs}>
        <Modal.Header>Create user batch</Modal.Header>
        <Modal.Content style={{ minHeight: 700 }}>
          <CreateForm createSuccess={createSuccess} resources={availableResources} contentName={CONTENT_NAME} onCreate={this.handleCreateBatch} />
        </Modal.Content>
      </Modal>
    );
    return [this.renderUsersRoot(), modal];
  }

  renderCreateNamedUserDialog() {
    const { createSuccess, availableResources } = this.props;

    const modal = (
      <Modal key="createNamedDialog" open centered={false} onClose={this.closeDialogs}>
        <Modal.Header>Create named user</Modal.Header>
        <Modal.Content style={{ minHeight: 700 }}>
          <CreateForm createSuccess={createSuccess} resources={availableResources} contentName={CONTENT_NAME} onCreate={this.handleCreateNamedUser} />
        </Modal.Content>
      </Modal>
    );

    return [this.renderUsersRoot(), modal];
  }

  render() {
    const { match } = this.props;

    return (
      <>
        <Route path={match.url} exact render={this.renderUsersRoot} />
        <Route path={`${match.url}/${CREATE_BATCH_ROUTE}`} render={this.renderCreateBatchDialog} />
        <Route path={`${match.url}/${CREATE_NAMED_USER_ROUTE}`} render={this.renderCreateNamedUserDialog} />
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<IPropsFromState, {}, IState> = (state) => {
  const availableResources = state.content.data[CONTENT_NAME] || [];
  return {
    availableResources,
    createSuccess: state.users.creationSuccess,
  };
};

const mapDispatchToProps: MapDispatchToProps<IPropsFromDispatch, {}> = (dispatch) => ({
  loadAvailableResources() {
    dispatch(listContentByType(CONTENT_NAME));
  },
  createBatch(count, expiration, resources, selectedDomain) {
    dispatch(createBatch({ count, expiration, resources, selectedDomain }));
  },
  createNamedUser(name, expiration, resources) {
    dispatch(createNamedUser({ name, expiration, resources }));
  },
  clearCreateSuccess() {
    dispatch(clearCreateSuccess());
  },
});

export const UsersRoot = connect(mapStateToProps, mapDispatchToProps)(CUsersRoot);
