import React, { useCallback, useEffect, useRef, useState } from 'react';

interface ExpireProps {
  delay?: number;
  children?: React.ReactNode;
  onHide?: () => void;
}

export function Expire({ delay = 1000, children, onHide }: ExpireProps) {
  const [visible, setVisible] = useState(true);
  const timeoutRef = useRef<number>();

  const onHideCallback = useCallback(() => {
    if (onHide) onHide();
  }, [onHide]);

  useEffect(() => {
    // Make visiable again and clear old timeout
    setVisible(true);
    clearTimeout(timeoutRef.current);

    timeoutRef.current = window.setTimeout(() => {
      setVisible(false);
      onHideCallback();
    }, delay);

    return () => clearTimeout(timeoutRef.current);
  }, [delay, onHideCallback]);

  if (!visible) return null;

  return <>{children}</>;
}
