import { invalidateQueries } from 'actions/ReactQueryActions';
import { QueryKey } from 'react-query';
import { Reducer } from 'redux';
import { isType } from 'typescript-fsa';

export interface IReactQueryState {
  invalidateQueries?: {
    issued_at: Date;
    queryKey: QueryKey;
  };
}

export const reactQueryReducer: Reducer<IReactQueryState> = (state = {}, action) => {
  if (isType(action, invalidateQueries)) {
    return {
      ...state,
      invalidateQueries: {
        issued_at: new Date(),
        queryKey: action.payload,
      },
    };
  }

  return state;
};
