import { ProgramMultiPicker } from 'containers/content/ProgramMultiPicker';
import { Users } from '@visikon/core-models';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Accordion,
  Button,
  Checkbox,
  Divider,
  Dropdown,
  DropdownProps,
  Form,
  FormField,
  Icon,
  Input,
  Label,
  Modal,
  Statistic,
} from 'semantic-ui-react';
import { userTypeOptions } from './UserType';

type CMSUser = Users.CMSUser;

interface IProps {
  createSuccess: boolean;
  backUrl: string;
  user: CMSUser;
  onSave(user: CMSUser): void;
  onClose(): void;
  editReviewer(id: string, reviewer?: boolean): void;
}

interface IState {
  isRunning: boolean;
  showResourceEdit: boolean;
  user: CMSUser;
  usernameError?: boolean;
  userTypeError?: boolean;
  emailError?: boolean;
  reviewer: boolean;
  openConfirmDialog: boolean;
  newPassword: string;
}

export class EditCMSUser extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    /* eslint-disable react/no-unused-state */
    this.state = {
      isRunning: false,
      showResourceEdit: true,
      user: props.user,
      usernameError: false,
      userTypeError: true,
      emailError: false,
      reviewer: false,
      openConfirmDialog: false,
      newPassword: '',
    };

    this.handleOnSave = this.handleOnSave.bind(this);
    this.handleUserTypeChange = this.handleUserTypeChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleAccessChange = this.handleAccessChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  handleOnSave() {
    const { user, usernameError, emailError } = this.state;
    if (usernameError || emailError) {
      return;
    }
    this.setState({ isRunning: true });
    this.props.onSave(user);
  }

  handleOnChange() {
    const { user } = this.state;

    this.props.editReviewer(user._id, !user.reviewer);
    this.setState({ reviewer: !user.reviewer });
  }

  handleUserTypeChange(_: React.ChangeEvent<HTMLInputElement>, data: DropdownProps) {
    const val = (data.value || '').toString();
    if (Users.isCorrectCMSUserType(val)) {
      const { user } = this.state;
      user.type = val;
      this.setState({ user });
    }
  }

  handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    const email = event.target.value;
    const emailError = (email || '').trim() === '';
    if (emailError) {
      this.setState({ emailError });
    } else {
      const { user } = this.state;
      user.email = email;
      this.setState({ user });
    }
  }

  handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newPassword = event.target.value;
    if(newPassword.length > 0) {
      const { user } = this.state;
      user.password = newPassword;
      this.setState({ user, newPassword });
    }
  }

  handleAccessChange(id: string) {
    const { user } = this.state;

    if (user.access.indexOf(id) === -1) {
      user.access = [...user.access, id]; // Add `id`
    } else {
      user.access = user.access.filter((i) => i !== id); // Remove `id`
    }
    this.setState({});
  }

  render() {
    const { user, emailError, newPassword } = this.state;

    return (
      <Modal key="modalCMSUser" size="large" open centered={false} onClose={this.props.onClose}>
        <Modal.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Button icon="arrow left" as={Link} to={this.props.backUrl} />
            {' '}
&nbsp;Edit CMSUser
          </div>
          <div>
            <Button color="blue" onClick={this.handleOnSave}>
              Save
            </Button>
            {this.props.createSuccess && <Label color="green">Success</Label>}
          </div>
        </Modal.Header>
        <Modal.Content style={{ minHeight: 700 }} scrolling>
          <Form>
            <Form.Field>
              <label>Username</label>
              <Input value={user.username} />
            </Form.Field>
            <Form.Field>
              <label>Password</label>
              <Input value={newPassword} placeholder="********" onChange={this.handlePasswordChange} />
            </Form.Field>
            <Form.Field>
              <label>Email</label>
              <Input value={user.email} error={emailError} onChange={this.handleEmailChange} />
            </Form.Field>
            <Form.Field>
              <label>User type</label>
              <Dropdown
                placeholder="Admin"
                fluid
                selection
                options={userTypeOptions}
                value={user.type}
                onChange={this.handleUserTypeChange}
              />
            </Form.Field>
            <FormField>
              <label>Add user as reviewer</label>
              <Checkbox checked={user.reviewer} onChange={this.handleOnChange} />
            </FormField>
          </Form>

          <Divider style={{ marginTop: 20 }} />

          <Accordion style={{ marginBottom: 25 }}>
            <Accordion.Title
              active={this.state.showResourceEdit}
              onClick={() => this.setState((s) => ({ showResourceEdit: !s.showResourceEdit }))}
            >
              <Icon name="dropdown" />
              Access rights
            </Accordion.Title>
            <Accordion.Content active={this.state.showResourceEdit}>
              <Statistic horizontal size="small" label="programs" value={(this.state.user.access || []).length} />
              <ProgramMultiPicker checked={this.state.user.access} onClick={this.handleAccessChange} />
            </Accordion.Content>
          </Accordion>
        </Modal.Content>
      </Modal>
    );
  }
}
