import React from 'react';
import { Table } from 'semantic-ui-react';
import { ObjectId } from '@visikon/core-models/base';

type Props = {
  items: Array<{
    _id: ObjectId;
    name: string;
    speaks: Array<{
      variation: string;
      text: string;
    }>;
  }>;
  onClick: (id: string) => void;
};

export function SpeakManuscriptsTable({ items, onClick }: Props) {
  const firstCellStyle: React.CSSProperties = {
    fontWeight: 'bold',
    color: 'rgba(0,0,0,.87)',
    width: 'max-content',
  };
  const alternateShades = (index: number) => {
    const darkShadeValue = 10;
    const lightShadeValue = 7;
    const opacityValue = index % 2 === 0 ? darkShadeValue : lightShadeValue;
    return `rgb(0 0 0 / ${opacityValue}0%)`;
  };

  return (
    <>
      <p style={{ textAlign: 'center' }}>Showing {items.length} Blocks and their YELLOW speaks</p>
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Block Name</Table.HeaderCell>
            <Table.HeaderCell>Variation Name</Table.HeaderCell>
            <Table.HeaderCell>Manuscript Text</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {items.map((item, i) => (
            <Table.Row key={i} style={{ cursor: 'pointer' }} onClick={() => onClick(item._id)}>
              <Table.Cell style={firstCellStyle}>{item.name}</Table.Cell>
              <Table.Cell style={{ whiteSpace: 'break-spaces', width: '27%' }}>
                {item.speaks.map((speak, j) => (
                  <div key={j} style={{ margin: '1em 0', color: alternateShades(j) }}>
                    {speak.variation}
                  </div>
                ))}
              </Table.Cell>
              <Table.Cell>
                {item.speaks.map((speak, j) => (
                  <div key={j} style={{ margin: '1em 0', color: alternateShades(j) }}>
                    {speak.text}
                  </div>
                ))}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
