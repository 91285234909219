import { PLACEHOLDER_IMAGE } from 'commons/utils';
import { useCallback, useEffect, useState } from 'react';
import * as Semantic from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import * as Styles from './Thumbnail.styles';
import { ThumbnailView } from './ThumbnailView';
import { FrameGrabView } from './ThumbnailFrameGrabView';
import { MediaLibraryView } from './ThumbnailMediaLibraryView';
import { clearUploadThumbnailSuccess, uploadThumbnail } from '../../../../../actions/MediaActions';
import { IState } from '../../../../../reducers/reducers';
import { useGetImageTranslationsById } from 'api/imageLibraryApi';
import { getTranslationFromArray } from '@visikon/core-models/typeUtils';

interface Props {
  openThumbnailModal: boolean;
  blockListId: string;
  listName: string;
  lang: string;
  thumbnail?: string;
  onCloseModal: () => void;
  onOpenModal: () => void;
  oncloseModalSuccess: () => void;
  children: JSX.Element;
  onChange: (refId: string, thumbSrc?: string) => void;
}

export enum ThumbnailModalStates {
  View= 'view',
  Grab = 'grab',
  Pick = 'pick'
}
export const ThumbnailViewLabels = {
  view: 'Thumbnail',
  grab: 'Capture frame',
  pick: 'Pick from Media Library'
}

const { View, Grab, Pick } = ThumbnailModalStates;

export function BlockListThumbnail(props: Props) {
  const { children, openThumbnailModal, blockListId, listName, lang, thumbnail, onChange, onCloseModal, oncloseModalSuccess, onOpenModal } = props;
  const [thumbnailSrc, setThumbnailSrc] = useState(thumbnail || PLACEHOLDER_IMAGE);
  const [thumbnailRefID, setThumbnailRefID] = useState('');
  const [state, setState] = useState<ThumbnailModalStates>(View);

  const dispatch = useDispatch();
  const { uploadSuccess } = useSelector((s: IState) => ({
    uploadSuccess: s.media.uploadThumbnailSuccess,
  }));

  const closeAfterSave = useCallback(() => {
    dispatch(clearUploadThumbnailSuccess());
    oncloseModalSuccess();
  },[dispatch, oncloseModalSuccess]);

  useEffect(() => {
    // Close modal
    if(uploadSuccess) {
      onChange(uploadSuccess);
      closeAfterSave();
    }
  }, [uploadSuccess, onChange, closeAfterSave]);


  const useQuery = useGetImageTranslationsById(thumbnailRefID);
  // TODO: Refactor to handle query errors (query data being undefined)
  const imageForTranslation = getTranslationFromArray(useQuery.data!, lang) as any;

  useEffect(() => {
    if (thumbnailRefID) setThumbnailSrc(imageForTranslation?.src)
  }, [thumbnailRefID, imageForTranslation]);


  const handleSave = () => {
    if(thumbnailSrc?.startsWith('data:image')) {
      const file = getThumbnailFile(listName, thumbnailSrc);
      const name = `${blockListId}_${Date.now()}_${file.name}`;
      dispatch(uploadThumbnail({name, file}));
    } else {
      onChange(thumbnailRefID, thumbnailSrc);
      closeAfterSave();
    }
  };

  const handlePick = (refID?: string) => {
    setThumbnailRefID(refID || '');
    if (!refID) setThumbnailSrc(PLACEHOLDER_IMAGE);
    setState(View);
  };

  return (
    <Semantic.Modal size={state === Pick ? 'fullscreen' : 'small'}
      open={openThumbnailModal}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <Styles.ModalHeader>{ThumbnailViewLabels[state]}</Styles.ModalHeader>

      <Semantic.Modal.Content scrolling style={{ maxHeight: '90vh', overflow: 'auto' }}>
        {state === View &&
          <ThumbnailView thumbnailSrc={thumbnailSrc} onChange={setState} />
        }

        {state === Grab &&
          <FrameGrabView player={children} blockListId={blockListId} onPick={handlePick} />
        }

        {state === Pick &&
          <MediaLibraryView onPick={handlePick} />
        }
      </Semantic.Modal.Content>

      <Semantic.Modal.Actions>
        {state === View && <>
          <Semantic.Button primary content="Set" onClick={handleSave} disabled={thumbnailSrc === thumbnail} />
          <Semantic.Button secondary onClick={onCloseModal} content="Close" />
        </>}

        {state !== View &&
          <Semantic.Button onClick={() => setState(View)} content="Cancel" />
        }
      </Semantic.Modal.Actions>
    </Semantic.Modal>
  );
}

function dataURItoBlob(dataURI: string) {
  const binary = atob(dataURI.split(',')[1]);

  // writing the bytes of the string to an ArrayBuffer
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }

  return new Blob([new Uint8Array(array)], { type: 'image/png' });
}

function getThumbnailFile(name: string, src: string) {
  const blob = dataURItoBlob(src);

  return new File([blob], `${name.split(' ').join('_').replace('.', '')}.png`, {
    type: 'image/png',
    lastModified: new Date().getTime(),
  });
}
