import { Flag, Popup } from 'semantic-ui-react';
import moment from 'moment';
import { Encoding } from '@visikon/core-models/encoding';
import { LanguageCode } from '@visikon/core-models/content';
import { langFromCode } from '../../../../commons/languages';
import * as Styles from './EncoderTimestamp.styles';

export function EncoderTimestamp({encodings, language, hash}: {encodings: {[key: string]: Encoding} | undefined, language: LanguageCode, hash: string}) {
  const encoding = encodings && encodings[language];
  if(!encoding) {
    return null;
  }

  const flag = (lang: string) => <Flag name={langFromCode(lang as LanguageCode).flag as any} />;
  const dateString = (timestamp: number) => moment(timestamp).format('DD.MM.YYYY HH:mm')
  const color = (e: Encoding) => e.input.header.hash === hash ? 'green' : 'red';

  const timestamp = (
    <div>
      <Styles.Timestamp color={color(encoding)}>{dateString(encoding.output.lastModified)}</Styles.Timestamp>
      {flag(language)}
    </div>
  );

  if(Object.keys(encodings).length < 2) {
    return timestamp;
  }

  const popup = (<div>
    {Object.keys(encodings).map(lang => (
      <div key={lang} style={lang === language ? {fontWeight: 'bold', color: color(encodings[lang])} : undefined}>
        {dateString(encodings[lang].output.lastModified)} {flag(lang)}
      </div>
    ))}
  </div>);

  return ( <Popup
    content={popup}
    trigger={timestamp}
  />);
}
