export enum Environment {
  Local = 'Local',
  QA = 'QA',
  Prod = 'Prod',
}

export function getEnv(): Environment {
  const { host } = window.location;

  if (process.env.NODE_ENV === 'development') {
    return Environment.Local;
  }

  if (host.includes('azurestaticapps.net') || host.startsWith('qa.') || host.includes('beta.')) {
    return Environment.QA;
  }

  return Environment.Prod;
}
