import actionCreatorFactory from 'typescript-fsa';
import { Users } from '@visikon/core-models';

const actionCreator = actionCreatorFactory('Users');

export const userActionError = actionCreator<string>('USER_ACTION_ERRRO');
export const clearUserActionError = actionCreator('CLEAR_USER_ACTION_ERROR');
export const clearCreateSuccess = actionCreator('CLEAR_CREATE_SUCCESS');

export interface ICreateBatchType {
  count: number;
  expiration: number;
  resources: string[];
  selectedDomain?: string;
}
export const createBatch = actionCreator<ICreateBatchType>('CREATE_BATCH');
export const createBatchSuccess = actionCreator('CREATE_BATCH_SUCCESS');

export interface ICreateNamedUserType {
  name: string;
  expiration: number;
  resources: string[];
}
export const createNamedUser = actionCreator<ICreateNamedUserType>('CREATE_NAMED_USER');
export const createNamedUserSuccess = actionCreator('CREATE_NAMES_USER_SUCCESS');

export const listNamedUsers = actionCreator('LIST_NAMES_USERS');
export const listNamedUsersSuccess = actionCreator<Users.EndUser[]>('LIST_NAMES_USERS_SUCCESS');

// Namespace action
export const listAllNamespaces = actionCreator('LIST_ALL_NAMESPACES');
export const listAllNamespacesSuccessful = actionCreator<Users.Namespace[]>('LIST_ALL_NAMESPACES_SUCCESFUL');

export interface ICreateNamespace {
  name: string;
  description: string;
  apiKeys: [];
}

export interface ICreateKey {
  namespace: string;
  description: string;
}

export interface IDeleteNamespace {
  namespace: string;
}

export interface IEditNamespace {
  oldNamespace: string;
  namespace: string;
  namespaceDescription: string;
}

export const createNamespace = actionCreator<ICreateNamespace>('CREATE_NAMESPACE');
export const createNamespaceSuccessful = actionCreator('CREATE_NAMESPACE_SUCCESSFUL');

export const createSpecificKey = actionCreator<ICreateKey>('CREATE_KEY');
export const createSpecificKeySuccessful = actionCreator('CREATE_KEY_SUCCESSFUL');

export const deleteANamespace = actionCreator<IDeleteNamespace>('DELETE_NAMESPACE');
export const deleteANamespaceSuccessful = actionCreator('DELETE_NAMESPACE_SUCCESSFUL');

export const updateNamespace = actionCreator<IEditNamespace>('UPDATE_NAMESPACE');
export const updateNamespaceSuccessful = actionCreator('UPDATE_NAMESPACE_SUCCESSFUL');

// CMSUSer action

export interface ICreateCMSUser {
  userType: string;
  email: string;
  username: string;
  password: string;
  namespace: string;
}

export const createCMSUser = actionCreator<ICreateCMSUser>('CREATE_CMSUSER');
export const createCMSUserSuccessful = actionCreator('CREATE_CMSUSER_SUCCESSFUL');

export interface ListCMSUsers {
  namespace: string;
}
export const listCMSUsers = actionCreator<ListCMSUsers>('LIST_CMSUSERS');
export const listCMSUsersSuccessful = actionCreator<Users.CMSUser[]>('LIST_CMSUSERS_SUCCESSFUL');

export interface IDeleteCMSUser {
  username: string;
}
export const deleteCMSUser = actionCreator<IDeleteCMSUser>('DELETE_CMSUSER');
export const deleteCMSUserSuccessful = actionCreator('DELETE_CMSUSER_SUCCESSFUL');

export const editCMSUser = actionCreator<Users.CMSUser>('EDIT_CMSUSER');
export const editCMSUserSuccessful = actionCreator('EDIT_CMSUSER_SUCCESSFUL');

export const editUser = actionCreator<Users.EditProfileData>('EDIT_USER');
export const editUserSuccessful = actionCreator('EDIT_USER_SUCCESSFUL');

export const getCMSUser = actionCreator('GET_CMSUSER');
export const getCMSUserSuccessful = actionCreator('GET_CMSUSERSUCCESSFUL');

export const generateTwoFactorAuth = actionCreator<string>('GENERATED_TWO_FACTOR_AUTHENTICATION');
export const generateTwoFactorAuthSuccesful = actionCreator<{ QrData: string }>('GENERATED_TWO_FACTOR_AUTHENTICATION_SUCCESSFUL');

export const turnOnTwoFactorAuth = actionCreator<string>('TWO_FACTOR_AUTHENTICATION_ACTIVATE');
export const turnOnTwoFactorAuthSuccessful = actionCreator('TWO_FACTOR_AUTHENTICATION_ACTIVATE_SUCCESSFUL');

export const editReviewer = actionCreator<{ id: string; reviewer: boolean }>('EDIT_REVIEWER');
export const editReviewerSuccess = actionCreator('EDIT_REVIEWER_SUCCESS');
