import { LanguageCode } from '@visikon/core-models/content';
import { DropdownItemProps } from 'semantic-ui-react';
import { LanguageSelector as LanguageDropdown } from '../../../components/LanguageSelector';
import { languages } from '../../../commons/languages';

export function LanguageSelector({ state, filter }: { state: [LanguageCode, (l: LanguageCode) => void], filter?: (l: DropdownItemProps) => boolean }) {
  const [outputLanguage, setOutputLanguage] = state;

  const languageChoices = filter ? languages.filter(filter) : languages;

  return (
    <LanguageDropdown
      activeLang={outputLanguage}
      onLanguageChanged={setOutputLanguage}
      languageChoices={languageChoices}
    />
  );
}
