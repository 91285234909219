
export type VideoClip = {
  audio?: ClipResource,
  video?: ClipResource,
};

export type ClipResource = {
  type: ClipResourceType,
  src?: string,
  duration?: number,
};

export enum ClipResourceType {
  Video = 'video', Audio = 'audio'
}
