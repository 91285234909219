import { Composition } from '@visikon/core-models/content';
import { getTranslationFromArray } from '@visikon/core-models/typeUtils';
import { useGetImageTranslationsById } from 'api/imageLibraryApi';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'reducers/reducers';
import { ThumbnailPreview } from './Card';
import { Image } from 'semantic-ui-react';
import { PLACEHOLDER_IMAGE } from 'commons/utils';
import { BlobUrls } from 'commons/local-storage';
import { getLanguage } from 'sagas/saga-helpers';

interface Props {
  composition?: Pick<Composition, 'thumbnailRefId' | 'thumbnailSrc'>;
}

interface CompositionRefThumbnailProps {
  refId: string;
}

function CompositionPlaceholderThumbnail() {
  return (
    <Image
      src={PLACEHOLDER_IMAGE}
      style={{
        objectFit: 'contain',
        height: 163,
        backgroundColor: '#bcbec0',
        position: 'relative',
      }}
    />
  );
}

function ThumbnailWithFallback({ path }: { path?: string }) {
  const [imageError, setImageError] = useState(false);

  if (imageError || !path) return <CompositionPlaceholderThumbnail />;

  const src = BlobUrls.genericUrl(path);
  return <ThumbnailPreview onError={() => setImageError(true)} src={src} />;
}

function CompositionRefrenceThumbnail({ refId }: CompositionRefThumbnailProps) {
  const language = useSelector(getLanguage);
  const query = useGetImageTranslationsById(refId);

  const imageForTranslation = query.data && getTranslationFromArray(query.data, language);
  return <ThumbnailWithFallback path={imageForTranslation?.src} />;
}

export function CompositionCard({ composition }: Props) {
  if (composition?.thumbnailRefId) return <CompositionRefrenceThumbnail refId={composition.thumbnailRefId} />;
  if (composition?.thumbnailSrc) return <ThumbnailWithFallback path={composition.thumbnailSrc} />;

  return <CompositionPlaceholderThumbnail />;
}

export function useSetThumbnail(composition: Composition | undefined) {
  const [showThumbURL, setThumbURL] = useState<string | undefined>('');
  const [imageByTranslationSrc, setImageByTranslationSrc] = useState('');
  const language = useSelector((state: IState) => state.language.activeLanguage) || 'ab';
  const useQuery = useGetImageTranslationsById(composition?.thumbnailRefId || '');
  const imageForTranslation = getTranslationFromArray(useQuery.data!, language) as any;

  useEffect(() => {
    setImageByTranslationSrc(imageForTranslation?.src);
  }, [language, imageForTranslation]);

  useEffect(() => {
    let thumbUrl: string | undefined;
    if (composition?.thumbnailSrc) {
      thumbUrl = imageByTranslationSrc ? `${imageByTranslationSrc}` : `${composition.thumbnailSrc}`;
    }
    setThumbURL(thumbUrl);
  }, [composition?.thumbnailSrc, imageByTranslationSrc]);

  return showThumbURL;
}
