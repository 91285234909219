import { CSSProperties, useState } from 'react';
import { Button, Confirm } from "semantic-ui-react";

export function DeleteButton({ onDelete, dontShowModal, style }: { onDelete: () => void, dontShowModal?: boolean, style?: CSSProperties }) {
  const [modalVisible, setModalVisible] = useState(false);
  // eslint-disable-next-line no-param-reassign

  const toggleEntry = () => {
    setModalVisible(false);
    onDelete()
  }

  return (
    <>
      <Confirm
        open={modalVisible}
        content="Are you sure you want to delete this item?"
        confirmButton="Yes"
        onCancel={() => setModalVisible(false)}
        onConfirm={toggleEntry}
      />
      <Button
        icon
        negative
        title="Delete item"
        style={{ margin: '16px 16px 0 0', position: 'absolute', right: '4px', ...style }}
        onClick={() => {
          if (dontShowModal) {
            toggleEntry();
          } else {
            setModalVisible(true);
          }
        }
        }
      >
        <i className="trash alternate outline icon" />
      </Button>
    </>
  );
}