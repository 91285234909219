import { Confirm } from 'semantic-ui-react';
import { PublisherPreview } from './PublisherPreview';
import { useGetVideosPreview } from '../../../api/publisherApi';
import { Spinner as SpinnerComponent } from '../../../components/spinner';

type Props = {
  id: string | undefined,
  open: boolean,
  onCancel: () => void,
  onConfirm: () => void,
};

export function PublisherModal({id, open, onCancel, onConfirm}: Props) {
  const {data, error, isError, isLoading} = useGetVideosPreview(id);

  const modalContent = (<div style={{padding: 32, display: 'flex', flexDirection: 'column', gap: 16}}>
    <Spinner visible={isLoading} />
    <Error error={error} />
    <PublisherPreview data={data} />
  </div>);

  return (
    <Confirm
      open={open}
      header={isError ? 'Unable to validate, publish anyway?' : 'Publish new version?'}
      confirmButton="YES"
      content={modalContent}
      onCancel={onCancel}
      onConfirm={data && onConfirm}
    />
  );
}

function Spinner({visible}: {visible: boolean}) {
  if(!visible) {
    return <></>;
  }

  return (<div style={{margin: 32}}>
    <SpinnerComponent visible={visible} text="Validating..." />
  </div>);
}

function Error({error}: {error: Error | null}) {
  return (<div style={{color: 'red'}}>
    {error && error.message}
  </div>);
}