import { useState } from 'react';
import * as Semantic from 'semantic-ui-react';
import styled from 'styled-components';

interface IProps {
  isOpen: boolean;
  onDismiss(): void;
  onSet(delay: number): void;
  audioDelay: number;
}

const InputWrapper = styled.div`
`;
const Label = styled.div`
`;
const ModalWrapper = styled(Semantic.Modal)`
  width: 265px!important;
`;
const MHeader = styled(Semantic.Modal.Header)`
  display: flex!important;
  flex-direction: row!important;
  justify-content: space-between!important;
`;
const ContentWrapper = styled(Semantic.Modal.Content)`
  display: flex!important;
  flex-direction: column!important;
`;

const AnimatedContent = styled.div<{ visible: boolean }>`
  position: absolute;
  transition: 0.4s;
  top: ${(props) => (props.visible ? '0' : '-100px')};
  width: 100%;
  height: 100%;
  background: #28bd48;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
`;

const Button = styled(Semantic.Button)`
  margin-top: 0.8em !important;
  overflow: hidden;
  position: relative;
`;

const ContentActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  margin-top: 8px;
`;

const SetButton = ({ onClick, text, disabled }: { onClick: any, text: string, disabled?:boolean }) => {
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <>
      <Button primary
              disabled={disabled}
              onClick={() => { setVisible(true); onClick(); }}>
        {text}
        <AnimatedContent visible={visible}>
          <i className="check icon" />
        </AnimatedContent>
      </Button>
    </>
  );
};

export const AudioDelayInputModal = ({
  isOpen, onDismiss, onSet, audioDelay,
}: IProps) => {
  const [delay, setDelay] = useState<string>(audioDelay?.toString())

  const handleSetButton = () => {
    if(delay) {
      onSet(Number(delay));
    }
    onDismiss();
  };

  return (
    <ModalWrapper open={isOpen} onClose={onDismiss} closeOnEscape>
      <MHeader>
        Set Audio Delay
        <Semantic.Popup trigger={<Semantic.Icon circular name="exclamation" size="small" />}>
          <Semantic.Header as="h2" content="Caution" />
          <p>
            If set more than
            {' '}
            <b>500ms</b>
            {' '}
            there&apos;s a risk of audio exceeding the video length.
          </p>
          <p>It won&apos;t be visible in the Preview but it will be encoded in the final output.</p>
          <p>If exceeded the last frame of the video will be extended for the length of the audio.</p>
          <p>Except for the last block. There the video will have the original length but audio will be cut off.</p>
        </Semantic.Popup>
      </MHeader>

      <ContentWrapper>
        <InputWrapper className="ui labeled input">
          <input type="number" value={delay} onChange={(e) => setDelay(e.target.value)} />
          <Label className="ui label">
            ms
          </Label>
        </InputWrapper>
        <ContentActionsContainer>
          <SetButton onClick={() => handleSetButton()} disabled={!delay} text="Set" />
          <Semantic.Button secondary onClick={onDismiss} content="Close" />
        </ContentActionsContainer>
      </ContentWrapper>
    </ModalWrapper>
  );
};
