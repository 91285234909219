import React from 'react';
import {
  Transition, Grid, Button, Label, Input, Dropdown, DropdownProps,
} from 'semantic-ui-react';
import { userTypeOptions } from './UserType';

interface IProps {
  createSuccess: boolean;
  namespace: string;
  errorMessage?: string;
  onCreate(userType: string, email: string, username: string, passsword: string, namespace: string): void;
}

interface IState {
  isRunning: boolean;
  userNameValue: string;
  userNameValueError?: boolean;
  passwordValue: string;
  passwordValueError?: boolean;
  typeOfUserValue: string;
  typeOfUserValueError?: boolean;
  emailValue: string;
  emailValueError?: boolean;
}

export class CreateCMSUser extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isRunning: false,
      userNameValue: '',
      userNameValueError: true,
      passwordValue: '',
      passwordValueError: true,
      typeOfUserValue: 'Admin',
      typeOfUserValueError: true, // eslint-disable-line
      emailValue: '',
      emailValueError: true,
    };

    this.handleOnCreate = this.handleOnCreate.bind(this);
    this.handleTypeUser = this.handleTypeUser.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleUsername = this.handleUsername.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
  }

  handleOnCreate() {
    const {
      userNameValue, userNameValueError, passwordValue, passwordValueError, typeOfUserValue, emailValue, emailValueError,
    } = this.state;
    if (userNameValueError || passwordValueError || emailValueError) {
      return;
    }
    this.setState({ isRunning: true });
    this.props.onCreate(typeOfUserValue, emailValue, userNameValue, passwordValue, this.props.namespace);
    this.setState({ isRunning: false });
  }

  handleTypeUser(_: React.ChangeEvent<HTMLInputElement>, data: DropdownProps) {
    if (data.value != null) {
      this.setState({ typeOfUserValue: data.value.toString() });
    }
  }

  handleEmail(event: React.ChangeEvent<HTMLInputElement>) {
    const emailValue = event.target.value;
    const emailValueError = (emailValue || '').trim() === '';
    this.setState({ emailValue, emailValueError });
  }

  handleUsername(event: React.ChangeEvent<HTMLInputElement>) {
    const userNameValue = event.target.value;
    const userNameValueError = (userNameValue || '').trim() === '';
    this.setState({ userNameValue, userNameValueError });
  }

  handlePassword(event: React.ChangeEvent<HTMLInputElement>) {
    const passwordValue = event.target.value;
    const passwordValueError = (passwordValue || '').trim() === '';
    this.setState({ passwordValue, passwordValueError });
  }

  render() {
    const {
      isRunning, userNameValue, userNameValueError, passwordValue, passwordValueError, emailValue, emailValueError,
    } = this.state;
    const createButtonDisabled = isRunning || userNameValueError || passwordValueError || emailValueError;

    return (
      <Transition.Group animation="horizontal flip" duration={500}>
        <div style={{
          position: 'absolute', width: '100%', height: '100%', paddingBottom: 100,
        }}
        />
        <Grid columns={2} style={{ height: '100%' }}>
          <Grid.Column>
            <label>
              Select user type:
              <br />
            </label>
            {' '}
            <br />
            <Dropdown
              placeholder="Account type"
              fluid
              selection
              options={userTypeOptions}
              onChange={this.handleTypeUser}
            />
            <br />

            <label>
              <b>Mail address:</b>
              <br />
              <Input
                value={emailValue}
                error={emailValueError}
                onChange={this.handleEmail}
              />
            </label>

            <br />
            <br />

            <label>
              <b>Username:</b>
              <br />
              <Input
                value={userNameValue}
                error={userNameValueError}
                onChange={this.handleUsername}
              />
            </label>

            <br />
            <br />

            <label>
              <b>Password:</b>
              <br />
              <Input
                value={passwordValue}
                error={passwordValueError}
                onChange={this.handlePassword}
              />
            </label>

            <br />
            <br />

            <Button color="blue" disabled={createButtonDisabled} onClick={this.handleOnCreate}>Create</Button>
            {this.props.createSuccess && this.props.errorMessage === undefined && <Label color="green">Success</Label>}
            {this.props.errorMessage && <Label color="red">Username is not unique</Label>}
          </Grid.Column>
        </Grid>
      </Transition.Group>
    );
  }
}
