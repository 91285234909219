import { Action } from 'redux';
import { Folders } from '@visikon/core-models';
import { isType } from 'typescript-fsa';
import {
  listFoldersError,
  listFoldersSuccess,
} from 'actions/FolderActions';

export interface IState {
  readonly folderTree?: Folders.FolderTree;
  readonly error?: string;
}

export function foldersReducer(state: IState = {}, action: Action): IState {
  if (isType(action, listFoldersError)) {
    return { ...state, error: action.payload.msg };
  }

  if (isType(action, listFoldersSuccess)) {
    // Add folders and remove error
    return { ...state, folderTree: action.payload, error: undefined };
  }

  return state;
}
