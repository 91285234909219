import { authenticate } from 'actions/AuthActions';
import { TFAInput } from 'containers/profilesettings/TFAInput';
import React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { IState } from 'reducers/reducers';
import {
  Button, Form, Header, Message, Transition,
} from 'semantic-ui-react';

interface ILocalState {
  username: string;
  password: string;
  tfaToken: string;
  visible: boolean;
}

interface IPropsFromDispatch {
  onLoginClick: (username: string, password: string, tfaToken?: string) => void;
}

interface IOwnProps {
  isLoggedIn: boolean;
  error: string | undefined;
  token: string | undefined;
}

type IProps = IOwnProps & IPropsFromDispatch & RouteComponentProps<{}>;

class LoginContainerHat extends React.Component<IProps, ILocalState> {
  usernameField: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      username: '',
      password: '',
      tfaToken: '',
      visible: false,
    };
  }

  componentDidMount() {
    if (this.usernameField && this.usernameField.current) {
      this.usernameField.current.focus();
    }
  }

  onChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ username: event.target.value });
  };

  onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: event.target.value });
  };

  onChangeTfaToken = (tfaToken: string) => {
    this.setState({
      tfaToken,
    });
  };

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    this.props.onLoginClick(this.state.username, this.state.password, this.state.tfaToken);
    this.setState({ visible: true });
    event.preventDefault();
  };

  render() {
    if (this.props.isLoggedIn) {
      // @ts-ignore: Object is of type 'unknown'.
      const redirectUrl = this.props.location.state ? this.props.location.state.from.pathname : '/';
      return (
        <Route>
          <Redirect to={{ pathname: redirectUrl }} />
        </Route>
      );
    }
    return (
      <div style={{ width: '300px' }}>
        <Header as="h1">Login</Header>
        <Form onSubmit={this.onSubmit}>
          <Form.Field>
            <label>Username</label>
            {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
            <input ref={this.usernameField} autoFocus placeholder="Username" onChange={this.onChangeUsername} />
          </Form.Field>
          <Form.Field>
            <label>Password</label>
            <input placeholder="Password" type="password" onChange={this.onChangePassword} />
          </Form.Field>

          {this.props.error === 'Invalid two factor token.' && (
            <Transition visible={this.state.visible}>
              <Form.Field>
                <label>Enter the 6 digit code generated by your mobile application</label>
                <TFAInput value={this.state.tfaToken} onChange={this.onChangeTfaToken} />
              </Form.Field>
            </Transition>
          )}

          <Button type="submit">Login</Button>
        </Form>
        {this.props.error && this.props.error !== 'Invalid two factor token.' && (
        <Message negative>
          <Message.Header>Authentication error</Message.Header>
          <p>{this.props.error}</p>
        </Message>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: IState, ownProps: RouteComponentProps<{}>) => ({
  isLoggedIn: state.auth.isLoggedIn,
  token: state.auth.token,
  error: state.auth.error,
  ...ownProps,
});

const mapDispatchToProps: MapDispatchToProps<IPropsFromDispatch, {}> = (dispatch) => ({
  onLoginClick: (username, password, tfaToken) => {
    dispatch(authenticate({ username, password, tfaToken }));
  },
});

export const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(LoginContainerHat);
