import { TagList } from 'components/tags/TagList';
import React, { useState } from 'react';
import { Card, Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { CompositionCard } from './CompositionCard';
import { CompositionCopyModal } from './modals/Copy.modal';
import { CompositionListItem } from '@visikon/core-models/content';

const CopyIconWrapper = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: rgba(255, 255, 255, 0.7);
  width: 25px;
  height: 24px;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  padding: 0;
  line-height: 13px;
`;
interface ICopyProps {
  onClick(e: React.MouseEvent): void;
}
const CopyButton = ({ onClick }: ICopyProps) => (
  <Popup
    trigger={
      <CopyIconWrapper onClick={onClick}>
        <Icon name="copy outline" className="right floated" />
      </CopyIconWrapper>
    }
    inverted
    content="Copy"
  />
);

interface IOwnProps {
  composition: CompositionListItem;
  showCopy?: boolean;
  onClick?(id: string): void;
}

type IProps = IOwnProps;

export const ThumbnailPreview = styled.img`
  position: relative;
  width: 290px;
  height: auto;
`;

export const VideoPreview = styled.video`
  position: relative;
  width: 290px;
  height: 158px;
`;

export function BlockListCard({ composition, showCopy, onClick }: IProps) {
  const [showCopyModal, setShowCopyModal] = useState<boolean>(false);

  function handleCopyClick(e: React.MouseEvent) {
    e.stopPropagation();
    setShowCopyModal(true);
  }

  return (
    <>
      <Card onClick={() => onClick && onClick(composition._id)}>
        <CompositionCard composition={composition} />
        {!!showCopy && <CopyButton onClick={(e) => handleCopyClick(e)} />}
        <Card.Content>
          <Card.Header>{composition.name}</Card.Header>
          <Card.Meta>
            <span className="date">{new Date(composition.createdAt).toISOString().slice(0, 10)}</span>
          </Card.Meta>
        </Card.Content>
        <Card.Content extra>
          <TagList tagList={composition.tags} readOnly />
        </Card.Content>
      </Card>
      {showCopyModal && (
        <CompositionCopyModal compositionId={composition?._id} compositionName={composition.name} onClose={() => setShowCopyModal(false)} />
      )}
    </>
  );
}
