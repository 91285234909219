import { useGetLastModified } from 'api/lastModified';
import { Button, Popup } from 'semantic-ui-react';

type Props = {
  id?: string;
  lastModified?: number;
  disabled?: boolean;
  onSave: () => void;
};

export function SaveButton({ id, lastModified, disabled, onSave }: Props) {
  const query = useGetLastModified(id);

  const isModified = (timestamp?: number) => !!timestamp && timestamp !== query.data?.lastModified;

  const handleSave = async () => {
    const refetched = await query.refetch();
    if (!isModified(refetched.data?.lastModified)) {
      onSave();
    }
  };

  if (isModified(lastModified)) {
    return <DisabledSaveButton />;
  }

  return (
    <Button positive disabled={disabled} onClick={handleSave}>
      Save
    </Button>
  );
}

function DisabledSaveButton() {
  const button = (
    <div>
      <Button negative disabled>
        <i className="exclamation circle icon" /> Save
      </Button>
    </div>
  );

  return <Popup trigger={button}>Modified by a different user, reload your browser!</Popup>;
}
