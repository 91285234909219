import { CustomTypeWithPublishState } from '../CustomTypeEditor';
import { useState } from 'react';
import { Button, Confirm } from 'semantic-ui-react';

export function ArchiveButton({ entry, onToggle }: { entry: CustomTypeWithPublishState; onToggle: (entry: CustomTypeWithPublishState) => void }) {
  const [modalVisible, setModalVisible] = useState(false);
  const archived = entry.archived === true;

  return (
    <>
      <Confirm
        open={modalVisible}
        content={`Are you sure you want to ${archived ? 're-activate' : 'archive'} this item?`}
        confirmButton="Yes"
        onCancel={() => setModalVisible(false)}
        onConfirm={() => {
          onToggle(entry);
          setModalVisible(false);
        }}
      />
      <Button
        icon
        positive={archived}
        negative={!archived}
        title={`${archived ? 'Re-activate' : 'Archive'} item`}
        style={{ marginLeft: 16 }}
        onClick={() => setModalVisible(true)}
      >
        <i className={`${archived ? 'check' : 'trash alternate outline'} icon`} />
      </Button>
    </>
  );
}
