import { PaginationProps } from 'components/semantic/PaginationState';
import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Input, Pagination } from 'semantic-ui-react';
import styled from 'styled-components';

const DEBOUNCE_TIME_OUT_MS = 750;

type Search = {
    categories: string[];
    onCategoryChange: (category: string) => void;
    onSearch: (searchTerm: string) => void;
    activePage: string | number;
    totalPages: number;
    onPageChange: (page: number) => void;
};

const PaginationDiv = styled.div`
    margin-left: 20px;
    width: 400px;
`;

export function SearchPagination(props: Search) {
    const EMPTY_SEARCH = '';
    const { onSearch, categories, activePage, totalPages, onPageChange, onCategoryChange } = props;
    const [selectedCategory, setSelectedCategory] = useState(categories[0]);
    const [searchTerm, setSearchTerm] = useState<string>(EMPTY_SEARCH);

    // introduced extra layer to wait for user typing completion 
    // AND don't execute on first render
    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return () => null;
        }
        const delayDebounceFn = setTimeout(() => {
            onSearch(searchTerm)
        }, DEBOUNCE_TIME_OUT_MS)

        return () => clearTimeout(delayDebounceFn)

        // eslint-disable-next-line
    }, [searchTerm])

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: 400 }}>
                <Input
                    fluid
                    size="large"
                    value={searchTerm}
                    placeholder='Search ...'
                    onChange={(e) => setSearchTerm(e.target.value)}
                    label={
                        (<Dropdown
                            value={selectedCategory}
                            onChange={(e: BaseSyntheticEvent) => {
                                const categoryName = e.target.textContent;
                                setSelectedCategory(categoryName);
                                onCategoryChange(categoryName)
                            }}
                            options={categories.map((cat) => ({ key: cat, value: cat, text: cat }))}
                        />
                        )}
                    action={
                        <Button
                            icon="times"
                            disabled={!searchTerm || searchTerm === EMPTY_SEARCH}
                            onClick={() => setSearchTerm(EMPTY_SEARCH)} />
                    }
                />
            </div>
            <PaginationDiv>
                {totalPages > 0 ?
                    (<Pagination
                        activePage={activePage}
                        totalPages={totalPages}
                        onPageChange={(_: any, paginationData: PaginationProps) => {
                            onPageChange(Number(paginationData.activePage));
                        }}
                        firstItem={null}
                        lastItem={null}
                        pointing
                    />) : null
                }
            </PaginationDiv>
        </div>
    );
}
