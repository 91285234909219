import { Content } from '@visikon/core-models';
import { createImage } from 'actions/MediaActions';
import { useListImages } from 'api/imageLibraryApi';
import { SearchCategories } from 'components/mediaLibrary/constants';
import { ImageCreateDialog } from 'components/mediaLibrary/images/ImageCreateDialog';
import { SearchPagination } from 'components/search/search-pagination';
import { Spinner } from 'components/spinner';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
import { IState } from 'reducers/reducers';
import { languageState } from 'redux-recoil-sync.state';
import { Checkbox } from 'semantic-ui-react';
import { ImageContainer } from './ImageContainer';
import { useHistory } from 'react-router-dom';

const PAGE_SIZE = 15;
const FOLDER_ID = 'root';

interface IProps {
  onlyPicker?: boolean;
  onImagePick?: (image: Content.Image) => void;
}

export function ImageLibraryContainer(props: IProps) {
  const [page, setPage] = useState(1); // start on page 1
  const [search, setSearch] = useState(SearchCategories.All); // use All as default
  const [searchTerm, setSearchTerm] = useState('');
  const [showArchived, setShowArchived] = useState(false);

  const isUploading: boolean = useSelector((state: IState) => state.media.isUploading);

  const [language] = useRecoilState(languageState);

  const useQuery = useListImages(page, PAGE_SIZE, search, searchTerm, showArchived);
  const { data } = useQuery;

  const dispatch = useDispatch();
  const history = useHistory();

  const images = data
    ? data.entries.map((img: Content.Image) => (
        <ImageContainer
          onClick={() => {
            if (props.onlyPicker && props.onImagePick) {
              props!.onImagePick(img);
            } else {
              history.push(`/media/images/${img._id}`);
            }
          }}
          language={language}
          key={img._id}
          image={img}
        />
      ))
    : [];

  const imagesContainer = useQuery.isFetching ? (
    <Spinner visible={useQuery.isFetching} />
  ) : (
    <div className="ui cards" style={{ maxWidth: 1400 }}>
      {images}
    </div>
  );

  return (
    <div>
      <h1>Image Library</h1>

      <div style={{ display: 'flex' }}>
        <div style={{ minWidth: 200 }}>
          <ImageCreateDialog
            isUploading={isUploading}
            onCreate={(name, tags, file) => {
              const folder = FOLDER_ID;
              dispatch(createImage({ name, tags, file, folder }));
            }}
          />
        </div>
        <div style={{ flexGrow: 1, marginLeft: 10 }}>
          <Checkbox label="Show archive" checked={showArchived} onChange={() => setShowArchived(!showArchived)} style={{ marginBottom: 10 }} toggle />
          <div style={{ display: 'flex' }}>
            <div style={{ marginBottom: 30, width: 900 }}>
              <SearchPagination
                categories={[SearchCategories.All, SearchCategories.Names, SearchCategories.Tags]}
                onCategoryChange={(cat) => {
                  setSearch(cat as SearchCategories);
                  setPage(1);
                }}
                onSearch={(newSearchTerm) => {
                  setPage(1);
                  setSearchTerm(newSearchTerm);
                }}
                activePage={page}
                totalPages={data ? data.pages : 0}
                onPageChange={(pageNumber) => {
                  setPage(pageNumber);
                }}
              />
              {!useQuery.isFetching && <div>Found {`${data?.count} images - Showing ${data?.entries.length} `}</div>}
            </div>
          </div>

          {imagesContainer}
        </div>
      </div>
    </div>
  );
}
