import styled from 'styled-components';
import { BOTTOM_HEIGHT } from '../Composer.styles';

export const VideoPreview = styled.video`
  width: 240px;
  height: 135px;
`;

export const BlockWrapper = styled.div<{ highlighted: boolean }>`
  position: relative;
  width: 250px;
  max-height: ${BOTTOM_HEIGHT};
  padding-bottom: 5px;
  margin: 5px;
  margin-bottom: 15px;
  cursor: grab;
  border-radius: 8px;
  border: 1px solid lightgrey;
  overflow: hidden;
  z-index: 0;

  ${(p) =>
    p.highlighted &&
    `
        &::after {
            content: ""; // ::before and ::after both require content
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: black;
            opacity: .2;
        }
    `}

  div:first-child {
  }

  div:last-child {
    text-align: center;
  }
`;

export const SettingsButtonHolder = styled.div<{ disabled?: boolean; pos?: number }>`
  position: absolute;
  top: ${(p) => (p.pos || 0) * 35 + 4}px;
  right: 4px;
  z-index: 2;
  color: white;
  cursor: ${(p) => (p.disabled ? 'grab' : 'pointer')};

  i {
    background-color: lightgrey;
    color: black;
  }
`;

export const SettingsWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  top: 0;
  right: 10px;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  // opacity: .6;
  z-index: 1;

  div {
    padding-bottom: 6px;

    &:last-child {
      padding-bottom: 0;
    }
  }
`;

export const AlertWrapper = styled.div`
  position: absolute;
  font-size: 4em;
  color: #ff5600;
  text-shadow: #000 0 0 15px;
  background-color: #ffff0044;
  z-index: 2;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 25%;
`;

export const DurationWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 47px;
  width: calc(100% - 92px);
  border: 0.1px solid aliceblue;
  border-radius: 6px;
  overflow: hidden;
`;

type DurationProps = {
  width: number,
  opacity?: number,
}

export const Duration = styled.div<DurationProps>`
  width: ${props => props.width}%;
  height: 13px;
  opacity: ${props => props.opacity || 0.4};
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: aliceblue;
`;

export const TrashContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 10px;
`;
