import { Content } from '@visikon/core-models';
import { languages } from 'commons/languages';
import { ContentUtils } from 'commons/utils';

const BASE_LANGUAGE: Content.LanguageCode = languages[0].value as Content.LanguageCode;

type MediaType = Content.Image | Content.Video;

export function getTranslationToUse(mediaType: MediaType, language: Content.LanguageCode) {
  if (ContentUtils.isEmpty(mediaType)) {
    return undefined;
  }

  const directTrans = mediaType.translations.find((t) => t.language === language);
  if (directTrans) {
    return directTrans;
  }

  const baseTranslation = mediaType.translations.find((t) => t.language === BASE_LANGUAGE);
  if (baseTranslation) {
    return baseTranslation;
  }
  return mediaType.translations[0];
}
