import { TagList } from 'components/tags/TagList';
import { ObjectId } from '@visikon/core-models/base';
import styled from 'styled-components';
import { CompositionCard } from 'containers/composer/compositions/components/CompositionCard';
import { useComposition } from 'api/compositionApi';

interface IOwnProps {
  compositionId: ObjectId;
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 4px;

  h4 {
    margin-top: 0;
  }
`;

const CompositionImageWrapper = styled.div`
  pointer-events: none;
  position: relative;
  border: 1px dashed grey;
  padding: 5px 5px 2px 5px;
`;

type IProps = IOwnProps;

export function CompositionWrapper({ compositionId }: IProps) {
  const compositionQuery = useComposition(compositionId);
  const composition = compositionQuery.data;

  const statusText = compositionQuery.isError ? 'Error loading composition :(' : 'Loading composition name...';
  const compositionName = composition?.name ?? statusText;

  return (
    <div style={{ display: 'flex' }}>
      <Wrapper>
        <h4>{compositionName}</h4>
        <div style={{ display: 'flex' }}>
          <CompositionImageWrapper>
            <CompositionCard composition={composition} />
          </CompositionImageWrapper>
          <div style={{ maxWidth: 300, paddingLeft: 20 }}>
            <h4 style={{ marginBottom: 0 }}>Composition Tags</h4>
            <TagList readOnly tagList={composition ? composition?.tags : []} />
          </div>
        </div>
      </Wrapper>
    </div>
  );
}
