import actionCreatorFactory from 'typescript-fsa';
import { UploadTypes, Content } from '@visikon/core-models';
import { ObjectId } from '@visikon/core-models/base';

const actionCreator = actionCreatorFactory('MediaLibrary');

export const mediaLibraryError = actionCreator<{ msg: string, status?: number }>('MEDIA_LIBRARY_ERROR');

/* **************** */
/* Media Variations */
/* **************** */
export const listMediaVariations = actionCreator('LIST_MEDIA_VARIATIONS');
export const listMediaVariationsSuccess = actionCreator<Content.MediaVariation[]>('LIST_MEDIA_VARIATIONS_SUCCESS');
export const createMediaVariation = actionCreator<UploadTypes.MediaVariation>('CREATE_MEDIA_VARIATION');
export const createMediaVariationSuccess = actionCreator<Content.MediaVariation>('CREATE_MEDIA_VARIATION_SUCCESS');

// Common types
interface ListSuccessType<T> { folderId: string; result: T[]; }
export const isUploading = actionCreator<boolean>('IS_UPLOADING');
export const setNewlyCreatedId = actionCreator<string>('SET_CREATED_ID');
export const clearNewlyCreatedId = actionCreator('CLEAR_CREATED_ID');

/* ****** */
/* Images */
/* ****** */
export interface UploadImageFile { image: Content.Image; language: Content.LanguageCode; variation: ObjectId; file: File; }
export const uploadImageFile = actionCreator<UploadImageFile>('UPLOAD_IMAGE');
// export const uploadImageFileSuccess = actionCreator<Content.Image>("UPLOAD_IMAGE_SUCCESS");

export const loadImage = actionCreator<ObjectId>('LOAD_IMAGE_BY_ID');
export const loadImageSuccess = actionCreator<Content.Image>('LOAD_IMAGE_BY_ID_SUCCESS');

export const createImage = actionCreator<UploadTypes.Image>('CREATE_IMAGE');
export const updateImage = actionCreator<Content.Image>('UPDATE_IMAGE');

export const listImagesInFolder = actionCreator<string>('LIST_IMAGES_IN_FOLDER');
export const listImagesInFolderSuccess = actionCreator<ListSuccessType<Content.Image>>('LIST_IMAGES_IN_FOLDER_SUCCESS');

export interface UploadThumbnail { name: string; file: File; }
export const uploadThumbnail = actionCreator<UploadThumbnail>('UPLOAD_THUMBNAIL');
export const uploadThumbnailSuccess = actionCreator<string>('UPLOAD_THUMBNAIL_SUCCESS');
export const clearUploadThumbnailSuccess = actionCreator('CLEAR_UPLOAD_THUMBNAIL_SUCCESS');

/* ****** */
/* Videos */
/* ****** */
export interface UploadVideoFile { video: Content.Video; language: Content.LanguageCode; variation: ObjectId; file: File; duration: number; }
export const uploadVideoFile = actionCreator<UploadVideoFile>('UPLOAD_VIDEO');
export interface UploadVideoThumbnail { video: Content.Video; language: Content.LanguageCode; variation: ObjectId; file: File; }
export const uploadVideoThumbnail = actionCreator<UploadVideoThumbnail>('UPLOAD_VIDEO_THUMBNAIL');
export interface UploadVideoSubtitles { video: Content.Video; language: Content.LanguageCode; variation: ObjectId; file: File; }
export const uploadVideoSubtitles = actionCreator<UploadVideoSubtitles>('UPLOAD_VIDEO_SUBTITLES');

export interface BlockListThumbnail { blockList: Content.BlockList; language: Content.LanguageCode; file: File; }
export const uploadBlockListThumbnail = actionCreator<BlockListThumbnail>('UPLOAD_BLOCK_LIST_THUMBNAIL');
export const updateBlockSuccess = actionCreator<Content.BlockList>('UPDATE_BLOCK_SUCCESS');

export const loadVideo = actionCreator<ObjectId>('LOAD_VIDEO_BY_ID');
export const loadVideoSuccess = actionCreator<Content.Video>('LOAD_VIDEO_BY_ID_SUCCESS');

export const createVideo = actionCreator<UploadTypes.Video>('CREATE_VIDEO');
export const createVideoSuccess = actionCreator('CREATE_VIDEO_SUCCESS');

export const updateVideo = actionCreator<Content.Video>('UPDATE_VIDEO');
export const updateVideoSuccess = actionCreator<Content.Video>('UPDATE_VIDEO_SUCCESS');

export const listVideosInFolder = actionCreator<string>('LIST_VIDEOS_IN_FOLDER');
export const listVideosInFolderSuccess = actionCreator<ListSuccessType<Content.Video>>('LIST_VIDEOS_IN_FOLDER_SUCCESS');

/* ***** */
/* Texts */
/* ***** */
export const saveText = actionCreator<UploadTypes.Text | Content.Text>('SAVE_TEXT');
export const saveTextSuccess = actionCreator<Content.Text>('SAVE_TEXT_SUCCESS');
export const saveTextError = actionCreator<string>('SAVE_TEXT_ERROR');

export const loadTextIntoEditor = actionCreator<ObjectId>('LOAD_TEXT_INTO_EDITOR');
export const loadTextIntoEditorSuccess = actionCreator<Content.Text>('LOAD_TEXT_INTO_EDITOR_SUCCESS');

export const listTexts = actionCreator('LIST_TEXTS');
export const listTextsSuccess = actionCreator<Content.Text[]>('LIST_TEXTS_SUCCESS');
