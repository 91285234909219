import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RouteComponentProps, Route } from 'react-router';
import {
  listAllNamespaces,
  clearCreateSuccess,
  createNamespace,
  createSpecificKey,
  updateNamespace,
  createCMSUser,
  listCMSUsers,
  editCMSUser,
  editReviewer,
} from 'actions/UserActions';
import { IState } from 'reducers/reducers';
import {
  Table, Button, Modal, Input, Statistic, Icon,
} from 'semantic-ui-react';
import { Namespace, CMSUser, CMSUserKinds } from '@visikon/core-models/user';
import { Link } from 'react-router-dom';
import { CreateNamespaceDialog } from './CreateNamespace';
import { CreateSpecificKey } from './CreateSpecificKey';
import { EditNamespace } from './EditNamespace';
import { CreateCMSUser } from './CreateCMSUser';
import { EditCMSUser } from './EditCMSUser';
import { userTypeOptions } from './UserType';
import { logger } from '../../commons/logger';

function prettyPrintUserType(userType: CMSUserKinds) {
  const result = userTypeOptions.find((option) => option.value === userType);
  if (result) {
    return result.text;
  }
  return 'Unknown user type';
}

interface IPropsFromState {
  namespaces: Namespace[];
  cmsUsers: CMSUser[];
  createSuccess: boolean;
  errorMessage?: string;
}
interface IPropsFromDispatch {
  listNamespaces(): void;
  listCMSUsers(namespace: string): void;
  clearCreateSuccess(): void;
  createNamespace(name: string, description: string, apiKeys: []): void;
  createSpecificKey(namespace: string, description: string): void;
  updateNamespace(oldNamespace: string, namespace: string, namespaceDescription: string): void;
  createCMSUser(userType: string, email: string, username: string, password: string, namespace: string): void;
  editCMSUser(user: CMSUser): void;
  editReviewer(id: string, reviewer: boolean): void;
}
interface ILocalState {
  activeNamespace?: Namespace;
  activeUser?: CMSUser;
}

type IProps = IPropsFromState & IPropsFromDispatch & RouteComponentProps<any> & ILocalState;

class CNamespaceRoot extends React.Component<IProps, ILocalState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    // Render row and table
    this.renderRow = this.renderRow.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.renderCMSUserRow = this.renderCMSUserRow.bind(this);
    this.selectCellContent = this.selectCellContent.bind(this);

    // Handle onclick
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleCMSClick = this.handleCMSClick.bind(this);

    // Render modal
    this.renderModalInformation = this.renderModalInformation.bind(this);
    this.renderCreateKeyModal = this.renderCreateKeyModal.bind(this);
    this.renderCreateNamespaceModal = this.renderCreateNamespaceModal.bind(this);
    this.renderEditNamespaceModal = this.renderEditNamespaceModal.bind(this);
    this.renderCreateCMSUser = this.renderCreateCMSUser.bind(this);
    this.renderEditCMSUser = this.renderEditCMSUser.bind(this);

    // Close
    this.closeDialogs = this.closeDialogs.bind(this);
  }

  componentDidMount() {
    if (this.props.namespaces === undefined || this.props.namespaces.length === 0) {
      this.props.listNamespaces();
    }
  }

  componentDidUpdate(oldProps: IProps) {
    if (oldProps.namespaces.length === 0 && this.props.namespaces.length > 0) {
      // We got some data.
      const parts = this.props.history.location.pathname.split('/');
      if (parts.length > 3) {
        const result = this.props.namespaces.find((n) => n._id === parts.pop());
        if (result) {
          this.setActiveNamespace(result);
        }
      }
    }
    if (this.props.location !== oldProps.location) {
      this.props.clearCreateSuccess();
    }
  }

  handleRowClick(namespace: Namespace) {
    const loc = this.props.location;
    this.props.history.push(`${loc.pathname}/${namespace.name}/${namespace._id}`);
    this.setActiveNamespace(namespace);
  }

  setActiveNamespace(namespace: Namespace) {
    this.props.listCMSUsers(namespace.name);
    this.setState({ activeNamespace: namespace });
  }

  renderRow(namespace: Namespace) {
    return (
      <Table.Row key={namespace._id} style={{ cursor: 'pointer' }} onClick={() => this.handleRowClick(namespace)}>
        <Table.Cell>{namespace.description}</Table.Cell>
        <Table.Cell>{namespace.name}</Table.Cell>
        <Table.Cell>{namespace.apiKeys.length}</Table.Cell>
      </Table.Row>
    );
  }

  renderTable() {
    return (
      <>
        <Link to={`${this.props.match.url}/createNamespace`}>
          <Button color="blue">Create Namespace</Button>
        </Link>
        <h1>Namespaces</h1>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Name / Code</Table.HeaderCell>
              <Table.HeaderCell>Keys</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.props.namespaces.map(this.renderRow)}</Table.Body>
        </Table>
        <Statistic horizontal>
          <Statistic.Value>{this.props.namespaces.length}</Statistic.Value>
          <Statistic.Label>Namespaces</Statistic.Label>
        </Statistic>
      </>
    );
  }

  closeDialogs() {
    this.props.history.push(this.props.match.url);
  }

  handleCMSClick(user: CMSUser) {
    const loc = this.props.location;
    this.props.history.push(`${loc.pathname}/editCMSUser`);
    this.setState({ activeUser: user });
  }

  renderCMSUserRow(cmsUser: CMSUser) {
    return (
      <Table.Row key={cmsUser._id} style={{ cursor: 'pointer' }} onClick={() => this.handleCMSClick(cmsUser)}>
        <Table.Cell>{cmsUser.username}</Table.Cell>
        <Table.Cell>{cmsUser.email}</Table.Cell>
        <Table.Cell>{prettyPrintUserType(cmsUser.type)}</Table.Cell>
      </Table.Row>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  selectCellContent(event: any) {
    const sel = window.getSelection();
    if (sel) {
      try {
        sel.selectAllChildren(event.target);
      } catch (e) {
        logger.warn("Selection didn't work");
      }
    }
  }

  renderModalInformation() {
    const { activeNamespace } = this.state;
    if (activeNamespace === undefined) {
      return null;
    }

    const {
      description, name, apiKeys, embedKey,
    } = activeNamespace;
    return (
      <Modal key="modalInformation" open centered={false} onClose={this.closeDialogs}>
        <Modal.Header style={{ fontWeight: 'normal', display: 'flex', justifyContent: 'space-between' }}>
          <div>
            Namespace
            {' '}
            <b>{description}</b>
            <br />
            <span style={{ fontSize: '0.7em', color: 'grey' }}>
              (
              {name}
              )
            </span>
          </div>
          <div>
            <Link to={`${this.props.match.url}/editNamespace`}>
              <Button color="blue">Edit Namespace</Button>
            </Link>
            <Link to={`${this.props.match.url}/createSpecificKey`}>
              <Button color="blue">Add key</Button>
            </Link>
          </div>
        </Modal.Header>
        <Modal.Content style={{ minHeight: 700 }} scrolling>
          <Modal.Description>
            <h3>API keys</h3>
            <Table celled fixed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={{ width: '30%' }}>Key Description</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '70%' }}>Keys</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {apiKeys.map((r) => (
                  <Table.Row key={r.key} style={{ cursor: 'pointer' }}>
                    <Table.Cell style={{ width: '30%' }}>{r.description}</Table.Cell>
                    <Table.Cell style={{ width: '70%' }} onClick={this.selectCellContent}>
                      {r.key}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <br />

            <Input label="Embed Key" value={embedKey} style={{ width: '400px' }} />

            <br />
            <br />

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h3 style={{ margin: 0 }}>Users</h3>
              <Link to={`${this.props.match.url}/createCMSUser`}>
                <Button color="blue">Create CMSUser</Button>
              </Link>
            </div>
            <Table celled selectable fixed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Username</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{this.props.cmsUsers.map(this.renderCMSUserRow)}</Table.Body>
            </Table>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }

  // Create Namespace modal
  renderCreateNamespaceModal() {
    return <CreateNamespaceDialog onClose={this.closeDialogs} createSuccess={this.props.createSuccess} onCreate={this.props.createNamespace} />;
  }

  // Create specific key modal
  renderCreateKeyModal() {
    const { activeNamespace } = this.state;
    if (activeNamespace === undefined) {
      return null;
    }

    /* eslint-disable @typescript-eslint/naming-convention */
    const { _id, name } = activeNamespace;
    return (
      <Modal key="modalKey" open centered={false} onClose={this.closeDialogs}>
        <Modal.Header>
          <Link to={`${this.props.match.url}/${name}/${_id}`}>
            <Button icon color="blue">
              <Icon name="arrow left" />
            </Button>
          </Link>
          {' '}
                    &nbsp; Create Key
        </Modal.Header>
        <Modal.Content style={{ minHeight: 700 }}>
          <CreateSpecificKey onCreate={this.props.createSpecificKey} createSuccess={this.props.createSuccess} name={name} />
        </Modal.Content>
      </Modal>
    );
  }

  renderEditNamespaceModal() {
    const { activeNamespace } = this.state;
    if (activeNamespace === undefined) {
      return null;
    }

    const {
      _id, name, description, apiKeys,
    } = activeNamespace;
    return (
      <Modal key="modaledit" open centered={false} onClose={this.closeDialogs}>
        <Modal.Header>
          <Link to={`${this.props.match.url}/${name}/${_id}`}>
            <Button icon color="blue">
              <Icon name="arrow left" />
            </Button>
          </Link>
          {' '}
                    &nbsp; Edit Namespace
        </Modal.Header>
        <Modal.Content style={{ minHeight: 700 }}>
          <EditNamespace
            onCreate={this.props.updateNamespace}
            createSuccess={this.props.createSuccess}
            name={name}
            namedescription={description}
            keydescription={apiKeys}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderCreateCMSUser() {
    const { activeNamespace } = this.state;
    if (activeNamespace === undefined) {
      return null;
    }

    const { _id, name } = activeNamespace;

    return (
      <Modal key="modalCMSUser" open centered={false} onClose={this.closeDialogs}>
        <Modal.Header>
          <Link to={`${this.props.match.url}/${name}/${_id}`}>
            <Button icon color="blue">
              <Icon name="arrow left" />
            </Button>
          </Link>
          {' '}
                    &nbsp; Create CMSUser
        </Modal.Header>
        <Modal.Content style={{ minHeight: 700 }}>
          <CreateCMSUser
            errorMessage={this.props.errorMessage}
            onCreate={this.props.createCMSUser}
            createSuccess={this.props.createSuccess}
            namespace={name}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderEditCMSUser() {
    const { activeNamespace, activeUser } = this.state;
    if (activeNamespace === undefined || activeUser === undefined) {
      return null;
    }

    const { _id, name } = activeNamespace;

    return (
      <EditCMSUser
        backUrl={`${this.props.match.url}/${name}/${_id}`}
        onSave={this.props.editCMSUser}
        onClose={this.closeDialogs}
        createSuccess={this.props.createSuccess}
        user={activeUser}
        editReviewer={this.props.editReviewer}
      />
    );
  }

  render() {
    return (
      <>
        {this.renderTable()}
        {/* <Route path={this.props.match.url} exact={true} render={this.renderTable} /> */}
        <Route path={`${this.props.match.url}/createNamespace`} render={this.renderCreateNamespaceModal} />
        <Route path={`${this.props.match.url}/createSpecificKey`} render={this.renderCreateKeyModal} />
        <Route path={`${this.props.match.url}/editNamespace`} render={this.renderEditNamespaceModal} />
        <Route path={`${this.props.match.url}/:name/:id`} exact render={this.renderModalInformation} />
        <Route path={`${this.props.match.url}/createCMSUser`} exact render={this.renderCreateCMSUser} />
        <Route path={`${this.props.match.url}/:name/:id/editCMSUser`} exact render={this.renderEditCMSUser} />
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<IPropsFromState, {}, IState> = (state) => ({
  namespaces: state.users.namespaces,
  createSuccess: state.users.creationSuccess,
  cmsUsers: state.users.cmsUsers,
  errorMessage: state.users.error,
});

const mapDispatchToProps: MapDispatchToProps<IPropsFromDispatch, {}> = (dispatch) => ({
  listNamespaces() {
    dispatch(listAllNamespaces());
  },
  clearCreateSuccess() {
    dispatch(clearCreateSuccess());
  },
  createNamespace(name, description, apiKeys) {
    dispatch(createNamespace({ name, description, apiKeys }));
  },
  createSpecificKey(namespace, description) {
    dispatch(createSpecificKey({ namespace, description }));
  },
  updateNamespace(oldNamespace, namespace, namespaceDescription) {
    dispatch(updateNamespace({ oldNamespace, namespace, namespaceDescription }));
  },
  createCMSUser(userType, email, username, password, namespace) {
    dispatch(createCMSUser({
      userType, email, username, password, namespace,
    }));
  },
  // TODO: Maybe add clearCMSUsers (when switching Namespace..)
  listCMSUsers(namespace) {
    dispatch(listCMSUsers({ namespace }));
  },
  editCMSUser(user) {
    dispatch(editCMSUser(user));
  },
  editReviewer(id, reviewer) {
    dispatch(editReviewer({ id, reviewer }));
  },
});

export const NamespaceRoot = connect(mapStateToProps, mapDispatchToProps)(CNamespaceRoot);
