import * as Semantic from 'semantic-ui-react';
import * as React from 'react';
import { useFetchSynthesizedAudio } from 'api/v2/text2speechApi';
import { LanguageCode } from '@visikon/core-models/languageTypes';
import { Spinner } from 'components/spinner';

export default function Text2SpeechModal({
  text,
  activeLanguage,
  selectedVoiceName,
  selectedVoiceGender,
  onAttached,
  children,
}: {
  text: string;
  activeLanguage: LanguageCode;
  onAttached: (blob: Blob) => void;
  selectedVoiceName: string;
  selectedVoiceGender: string;
  children: JSX.Element;
}) {
  const [open, setOpen] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const synthesizedSpeak = useFetchSynthesizedAudio({ text, activeLanguage, selectedVoiceName, selectedVoiceGender }, open);

  React.useEffect(() => {
    if (synthesizedSpeak.isSuccess) {
      setLoaded(true);
    }

    const blob = synthesizedSpeak.data as unknown as Blob;

    if (blob?.size === 0) {
      return;
    }

    if (open && loaded) {
      onAttached(blob);
      setOpen(false);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      setLoaded(false);
    };
  }, [onAttached, open, synthesizedSpeak, loaded]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Semantic.Modal
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        trigger={children}
        style={{ height: '100%' }}
        basic
        dimmer="blurring"
        inverted="true"
      >
        <Spinner visible={open} />
      </Semantic.Modal>
    </>
  );
}
