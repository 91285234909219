import styled from 'styled-components';
import { Dispatch, PropsWithChildren, SetStateAction, useEffect, useState } from 'react';
import { Button, Flag, Icon } from 'semantic-ui-react';
import { Content } from '@visikon/core-models';
import { langFromCode } from '../../../commons/languages';
import { LanguageCode } from '@visikon/core-models/content';

const TranslateToggleButtonWrapper = styled.div`
  z-index: 2;
`;

const HeaderWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  position: sticky;
  top: 0;
  z-index: 1;
  transition: 0.5s;
  background: ${(props) => props.color};
`;

export interface ListHeaderWrapperProps extends PropsWithChildren<{}> {
  header: string;
  subTitle: string;
  showTranslation: boolean;
  setShowTranslation: Dispatch<SetStateAction<boolean>>;
  toggleTranslationPermission: boolean;
  hasChanges: boolean;
  activeLanguage: Content.LanguageCode;
  languages?: Content.LanguageCode[];
  handleSave: () => void;
  handleLanguageChange(lang: Content.LanguageCode): void;
}

export const ListHeaderWrapper = (headerProps: ListHeaderWrapperProps) => {
  const { header, subTitle, showTranslation, setShowTranslation, toggleTranslationPermission, children, hasChanges, handleSave } = headerProps;
  const [navBackground, setNavBackground] = useState('');

  useEffect(() => {
    const scroll = () => {
      const backgroundcolor = window.scrollY < 70 ? ' ' : 'white';
      setNavBackground(backgroundcolor);
    };
    document.addEventListener('scroll', scroll);
    return () => {
      document.removeEventListener('scroll', scroll);
    };
  }, []);

  const translateButtonText = showTranslation ? 'Show edit' : 'Show translate';

  const langFlags = headerProps.languages && headerProps.languages.length <= 1 ? null : (
    <div>
      {headerProps.languages?.map((l) => (<Flag name={langFromCode(l as LanguageCode).flag as any} key={l} />))}
    </div>);

  return (
    <>
      <h2 className="ui header">
        {header}
        <div className="sub header">{subTitle}</div>
      </h2>
      <HeaderWrap color={navBackground}>
        <Button positive onClick={() => handleSave()} disabled={!hasChanges}>
          <Icon name="save" />
          Save
        </Button>
        {toggleTranslationPermission && (
          <TranslateToggleButtonWrapper>
            <Button onClick={() => setShowTranslation(!showTranslation)}>{translateButtonText}</Button>
          </TranslateToggleButtonWrapper>
        )}

        {children}
      </HeaderWrap>

      { langFlags }
    </>
  );
};

export const HeaderWrapper = styled.div`
  margin-left: auto;
  z-index: 2;
`;
