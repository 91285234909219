import { ValidatedProgramTranslation } from '@visikon/core-models/publisher';
import { Flag, Tab } from 'semantic-ui-react';
import styled from 'styled-components';
import { getTranslationFromArray } from '@visikon/core-models/typeUtils';
import { VideoPlayListContent } from '@visikon/core-models/models/content/video-lists';
import { getVideoPlayLists, hasVideoPlayList } from '@visikon/core-models/helpers/content/video-lists.helpers';
import { AccordionItem, SimpleAccordion } from '../../../components/semantic/SimpleAccordion';
import { langFromCode } from '../../../commons/languages';
import { VideosPreview } from './VideosPreview';

const items = (translation: ValidatedProgramTranslation) => {
  const result = [] as AccordionItem[];

  Object.keys(translation.data.content).forEach(key => {
    const videoLists = getVideoPlayLists(translation.data.content[key]);
    videoLists.forEach((videoList, i) => {
      const sectionList = getTranslationFromArray<VideoPlayListContent>(videoList.translations, translation.language);

      result.push({
        title: <>
          {sectionList?.language !== translation.language &&
            <Flag name={langFromCode(sectionList?.language || translation.language).flag as any} />
          }
          {key}
          {videoLists.length > 1 &&
            <span> - Video Playlist {i}</span>
          }
        </>,
        content: <VideosPreview language={translation.language} sectionList={sectionList} warnings={translation.validation.warnings || []} />,
      });
    });
  });

  return result;
};

export function PublisherTab({translation}: {translation: ValidatedProgramTranslation}) {
  if (!hasVideoPlayList(translation)) {
    return <TabPane>No video content</TabPane>;
  }

  return (<TabPane>
    {!translation.validation.valid && <>
      <b>Possible errors:</b>
      <ul>
        {translation.validation.warnings?.map((warning, i) =>
          <li key={i} style={{color: 'red'}}>{warning.text}</li>
        )}
      </ul>
    </>}

    <SimpleAccordion items={items(translation)} initialIndex={0} />
  </TabPane>);
}

const TabPane = styled(Tab.Pane)`
  height: calc(100vh - 24rem);
  overflow-y: auto;
`;
