import * as Semantic from 'semantic-ui-react';
import { Encoding } from '@visikon/core-models/encoding';
import { LanguageCode } from '@visikon/core-models/content';
import { BlobUrls } from '../../../../../commons/local-storage';
import * as Styles from './EncodedVideo.modal.styles';

interface Props {
  openVideoModal: boolean;
  closeVideoModal: () => void;
  encodings?: { [key: string]: Encoding },
  language: LanguageCode,
}

export const EncodedVideoModal = ({ openVideoModal, closeVideoModal, encodings, language }: Props) => {
  if (!openVideoModal) {
    return null;
  }
  const encoding = encodings && encodings[language]?.output;

  return (
    <Semantic.Modal open={openVideoModal} size='large' onClose={closeVideoModal} style={{ width: 'fit-content' }}>
      <Semantic.Modal.Header>
        <Styles.Header>
          <div>Encoded video</div>
        </Styles.Header>
      </Semantic.Modal.Header>
      <Semantic.Modal.Content scrolling>
        <Styles.VideoContainer>
          <div style={{ width: 620 }}>
            <Styles.VideoWrapper className='video'>
              {encoding &&
                <video
                  controls
                  width={620}
                  src={`${BlobUrls.encodedResourceUrl(encoding.videoSrc)}`}
                  poster={`${BlobUrls.encodedResourceUrl(encoding.thumbnailSrc)}`}
                  crossOrigin='true'>
                  { encoding.subtitles &&
                  <track
                    label={encoding.subtitles.label}
                    kind="subtitles"
                    srcLang={encoding.subtitles.key}
                    src={`${BlobUrls.encodedResourceUrl(encoding.subtitles.src)}`}
                    default /> }
                </video>
              }
            </Styles.VideoWrapper>
          </div>
        </Styles.VideoContainer>
      </Semantic.Modal.Content>
    </Semantic.Modal>
  );
};
