import React from 'react';
import {
  Transition, Grid, Input, Button, Label,
} from 'semantic-ui-react';

interface IProps {
  createSuccess: boolean;
  name: string;
  onCreate(namespace: string, description: string): void;
}

interface IState {
  isRunning: boolean;
  descriptionValue: string;
  descriptionValueError?: boolean;
}

export class CreateSpecificKey extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = { isRunning: false, descriptionValue: '', descriptionValueError: true };
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);

    // Create new key
    this.handleOnCreate = this.handleOnCreate.bind(this);
  }

  handleDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
    const descriptionValue = event.target.value;
    const descriptionValueError = (descriptionValue || '').trim() === '';
    this.setState({ descriptionValue, descriptionValueError });
  }

  handleOnCreate() {
    const { descriptionValue, descriptionValueError } = this.state;
    if (descriptionValueError) {
      return;
    }
    this.setState({ isRunning: true });
    this.props.onCreate(this.props.name, descriptionValue);
  }

  render() {
    const { createSuccess } = this.props;
    const { isRunning, descriptionValue, descriptionValueError } = this.state;
    const createButtonDisabled = isRunning || createSuccess || descriptionValueError;
    return (
      <Transition.Group animation="horizontal flip" duration={500}>
        <div style={{
          position: 'absolute', width: '100%', height: '100%', paddingBottom: 100,
        }}
        />
        <Grid columns={2} style={{ height: '100%' }}>
          <Grid.Column>
            <b>
              <label>
                Description:
                <br />
                <Input
                  value={descriptionValue}
                  error={descriptionValueError}
                  onChange={this.handleDescriptionChange}
                  label="description"
                  labelPosition="right"
                />
              </label>
            </b>
            <br />
            <br />
            <br />
            <br />
            <br />
            <div style={{ position: 'absolute', bottom: 0 }}>
              <Button primary disabled={createButtonDisabled} loading={isRunning && !createSuccess} onClick={this.handleOnCreate}>CREATE KEY</Button>
              {this.props.createSuccess && <Label color="green">Success</Label>}
            </div>
          </Grid.Column>
        </Grid>
      </Transition.Group>
    );
  }
}
