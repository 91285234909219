import * as React from 'react';

export const LexemeContents = (props: { entry: Element }) => {
  const graphemeList = Array.from(props.entry.getElementsByTagName('grapheme'));
  const [phoneme] = Array.from(props.entry.getElementsByTagName('phoneme'));

  return (
    <>
      {graphemeList.map((grapheme, index) => (
        <p key={index}>
          <FakeTag as="grapheme">{grapheme.textContent}</FakeTag>
        </p>
      ))}
      <p>
        <FakeTag as="phoneme">{phoneme.textContent}</FakeTag>
      </p>
    </>
  );
};

function FakeTag(props: { as: string; children: React.ReactNode }) {
  return (
    <>
      <span style={{ color: 'lightgray' }}>{`<${props.as}>`}</span>
      {props.children}
      <span style={{ color: 'lightgray' }}>{`</${props.as}>`}</span>
    </>
  );
}
