import { EditMode, EditorValue, EditorPage, toTranslations, toEditorValues } from 'components/documents/EditorPage';
import { FixedAtTop } from 'components/FixedAtTop';
import { Content } from '@visikon/core-models';
import React, { useEffect, useState } from 'react';
import { Button, Flag, Form, Icon } from 'semantic-ui-react';
import { Value, Schema } from 'slate';
import { useHistory, useParams } from 'react-router-dom';
import { emptySlateDocument, getNewDocumentText, NEW_RESOURCE_ID } from 'commons/utils';
import { useGetTextById, usePostText, useUpdateText } from 'api/textLibraryApi';
import { useRecoilState } from 'recoil';
import { languageState, userTypeState } from 'redux-recoil-sync.state';
import { logger } from 'commons/logger';
import { AlertMessage, EditModeButtons, MessageHandling, Tags } from './Textrender';
import { langFromCode } from '../../commons/languages';
import { ArchiveButton } from '../../components/ArchiveButton';
import { Container, Flex1, MessageContainer } from 'containers/mediaLibrary/TextContainer.styles';

function DefaultEditorMode(userType: any) {
  return () => (userType === 'Translator' ? EditMode.Translate : EditMode.Default);
}

export const TextEditorContainer = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const [defaultLanguage] = useRecoilState(languageState); // This is the start language
  const [userType] = useRecoilState(userTypeState);

  const { mutateAsync: saveText, data: postData } = usePostText();
  const { mutateAsync: updateText } = useUpdateText(params.id);
  const { data } = useGetTextById(params.id);

  const [editMode, setEditMode] = useState<EditMode>(DefaultEditorMode(userType));
  const [tags, setTags] = useState<Content.Tag[]>([]);
  const [name, setName] = useState<string>('');
  const [archived, setArchived] = useState(false);
  const [saveReady, setSaveReady] = useState(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [message, setMessage] = useState<AlertMessage | null>(null);
  const [editorValues, setEditorValues] = useState<EditorValue[]>([]);

  const now = () => new Date().getTime();

  useEffect(() => {
    const textId = params.id;
    // Initialize text loads
    if (textId !== undefined && textId !== NEW_RESOURCE_ID) {
      return;
    }
    // or start with empty document
    if (textId === NEW_RESOURCE_ID) {
      const welcomeText = getNewDocumentText(defaultLanguage);
      const content = Value.fromJSON(emptySlateDocument(defaultLanguage));
      setName(welcomeText);
      setEditorValues([{ language: defaultLanguage, content, lastModified: now() }]);
    }
  }, [data, postData, params.id, defaultLanguage]);

  useEffect(() => {
    if (data && data !== NEW_RESOURCE_ID) {
      setName(data.name);
      setTags(data.tags);
      setArchived(data.archived);
      const translationsEditorValues = toEditorValues(data.translations);
      setEditorValues(translationsEditorValues);
      logger.debug('Used query data');
    }

    if (postData) {
      const newId = postData.metadata._id;
      history.replace(`/media/textEdit/${newId}`); // maybe just navigate to update with server state? we might have created multiple language entries
    }
  }, [data, postData, defaultLanguage, history]);

  useEffect(()=>{
    if(saveReady) {
      onSave();
    }
    // eslint-disable-next-line
  },[saveReady])

  const onTagAdded = (tag: Content.Tag) => {
    tags.push(tag);
    setTags(tags);
    setHasChanges(true);
  };

  const onTagRemoved = (tag: Content.Tag) => {
    const tagsFilter = tags.filter((t) => t !== tag);
    setTags(tagsFilter);
    setHasChanges(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onTextChange = (content: Value, language: Content.LanguageCode, docSchema?: Schema) => {
    const oldValues = editorValues.filter((v: any) => v.language !== language);
    if (content === null) { // delete
      setEditorValues([...oldValues]);
    } else {
      setEditorValues([...oldValues, { language, content, docSchema, lastModified: now() }]);
    }
    setHasChanges(true);
  };

  const onNameChange = (change: React.ChangeEvent<HTMLInputElement>) => {
    setName(change.target.value);
    setHasChanges(true);
  };

  const createText = () => {
    const translations = toTranslations(editorValues);
    const newText: any = {
      name,
      tags,
      translations,
      archived,
    };

    saveText(newText)
      .then(() => {
        setMessage({ text: 'Text created!', type: 'success' });
        setHasChanges(false);
        setSaveReady( false );
      })
      .catch((reason) => {
        setMessage({ text: `Could not save text : ${reason}`, type: 'error' });
      });
  };

  const update = () => {
    const texts = data;
    texts.name = name;
    texts.tags = tags;
    texts.translations = toTranslations(editorValues);
    texts.archived = archived;

    updateText(texts)
      .then(() => {
        setHasChanges(false);
        setSaveReady( false );
        setMessage({ text: `Saved text`, type: 'success' });
      })
      .catch((reason) => {
        const text = reason.request.status === 409 ? 'Conflict! Reload and try again' : `Could not update text : '${reason}'`;
        setMessage({ text, type: 'error' });
      });
  };

  const onSave = () => {
    const textId = params.id;
    if (textId === NEW_RESOURCE_ID) {
      createText();
    } else {
      update();
    }
  };

  const onToggleArchived = () => {
    setArchived( !archived )
    setSaveReady( true );
  };

  // const saveButtonClassname = message == null ? 'loading' : '';
  const saveButtonClassname = '';

  const languageFlags = editorValues.map((editorValue, index) => {
    const lang = langFromCode(editorValue.language);
    return (<Flag key={index} name={lang.flag as any} />);
  });

  return (
    <div>
      <Flex1>
        <ArchiveButton entry={{ archived }} onToggle={onToggleArchived} style={ { marginTop: 0}} />
      </Flex1>

      <div style={{ minWidth: 122.5, display: 'inline-block' }}>
        <FixedAtTop fixOffset={10} showOverlay style={{ background: 'white', zIndex: 10 }}>
          <Button className={saveButtonClassname} disabled={!hasChanges} primary onClick={() => setSaveReady(true)}>
            <Icon name='save' />
            Save text
          </Button>
        </FixedAtTop>
        <MessageContainer>
          {message !== null && MessageHandling(message, () => setMessage(null))}
        </MessageContainer>
      </div>

      {EditModeButtons(userType, setEditMode)}

      <Container>
        <Flex1>
          <Form>
            <Form.Field>
              <label>Name</label>
              <input value={name} onChange={onNameChange} placeholder='Text name' />
            </Form.Field>
          </Form>
        </Flex1>
      </Container>
      {Tags(userType, tags, onTagAdded, onTagRemoved)}
      <div>
        {languageFlags}
      </div>
      <br />
      {editorValues.length > 0 && (
        <EditorPage
          editMode={editMode}
          onValueChange={onTextChange}
          values={editorValues}
          primaryLanguage={defaultLanguage}
          userType={userType} />
      )}
    </div>
  );
};
