import React from 'react';
import {
  Button, Dropdown, DropdownProps, Input,
} from 'semantic-ui-react';
import { SearchFilter, useSearchState } from './search-state';

type Props = {
  categories?: string[]
};

const getCategory = (params: SearchFilter | undefined, categories?: string[]) => (categories && !params?.category ? undefined : params?.category);

export function SearchField({ categories }: Props) {
  const { searchFilter, setSearchFilter } = useSearchState();

  // Only display field names in the search dropdown if there are more than one, in which case "All" is prepended
  const fieldNames = categories && categories.length > 1
    ? ['All', ...categories]
    : undefined;

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    
    setSearchFilter({ ...searchFilter, page: 1, text: value, category: getCategory(searchFilter, fieldNames) });
  };

  const handleCategoryChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const value = typeof data.value === 'string' ? data.value : undefined;

    setSearchFilter({ ...searchFilter, page: 1, category: value, text: searchFilter?.text });
  };

  const handleReset = () => {
    setSearchFilter(undefined);
  };

  return (
    <div>
      <Input
        fluid
        size="large"
        value={searchFilter?.text || ''}
        placeholder="Search ..."
        onChange={handleTextChange}
        label={
                    fieldNames ? (
                      <Dropdown
                        value={searchFilter?.category || fieldNames[0]}
                        onChange={handleCategoryChange}
                        options={fieldNames.map((fieldName) => ({
                          key: fieldName,
                          value: fieldName,
                          text: fieldName,
                        }))}
                      />
                    ) : 'Search'
                }
        action={
          <Button icon="times" disabled={!(searchFilter?.text && searchFilter.text.length > 0)} onClick={() => handleReset()} />
                }
      />
    </div>
  );
}
