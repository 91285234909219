import { VideoLibraryContainer } from 'containers/mediaLibrary/VideoLibraryContainer';
import { Content } from '@visikon/core-models';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { EmbedMainWrapper } from './EmbedShared';
import { EmbedVideoDialog } from './EmbedVideoDialog';

const VideoLibraryWrapper = styled.div`
    &>div {
        margin-top: 20px;
    }

    &>div>h1 {
        display: none;
    }
`;

type IProps = RouteComponentProps<{}>;

interface IState {
  activeVideo: Content.Video | undefined;
}
export class EmbedVideos extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = { activeVideo: undefined };

    this.onVideoPick = this.onVideoPick.bind(this);
  }

  onVideoPick(video: Content.Video) {
    this.setState({ activeVideo: video });
  }

  render() {
    return (
      <EmbedMainWrapper>
        <div>
          <Link to="/" style={{ marginLeft: 20 }}>
            <Button basic color="blue" icon labelPosition="left">
              <Icon name="arrow left" />
              Back
            </Button>
          </Link>

          <VideoLibraryWrapper>
            <VideoLibraryContainer onlyPicker onVideoPick={this.onVideoPick} />
          </VideoLibraryWrapper>

          {this.state.activeVideo
                        && (
                        <EmbedVideoDialog
                          video={this.state.activeVideo}
                          onDismiss={() => this.setState({ activeVideo: undefined })}
                        />
                        )}
        </div>
      </EmbedMainWrapper>
    );
  }
}
