import { Block, LanguageCode } from '@visikon/core-models/content';
import { DraggableList } from 'components/dragNdrop/DraggableList';
import * as Semantic from 'semantic-ui-react';
import * as Styles from './ManusBlocks.styles';
import { ManusPart } from './ManusPart';
import { ManusPrintButton } from './modals/ManusPrint.button';
import { CompositionBlock } from '@visikon/core-models/models/composer/blocks';

type Props = {
    language: LanguageCode,
    name: string,
    compositionBlocks: CompositionBlock[],
    blocks: Block[],
    higlightedBlock: string | undefined,
    onHover: (id: string) => void,
    onReorder: (from: number, to: number) => void,
    onClick: (block: Block) => void
};

export function ManusBlocks({ language, name, compositionBlocks, blocks, higlightedBlock, onHover, onReorder, onClick }: Props) {
    const manusPartList = compositionBlocks.map((b) => ({
        id: b.key!,
        element: (
            <ManusPart
                key={b.key}
                blockKey={b.key!}
                block={blocks.find((block) => block._id === b.blockId) as Block}
                variation={b.speakVariation}
                onHover={onHover}
                isHighlighted={b.key === higlightedBlock}
                lang={language}
                onClick={onClick}
            />
        ),
    }));

    return (
        <Styles.ManusArea>
            <Semantic.Label attached="top" size="large">
                <div style={{ position: 'relative' }}>
                    Manuscript
                    <ManusPrintButton name={name} selectedBlocks={compositionBlocks} allBlocks={blocks} language={language} />
                </div>
            </Semantic.Label>
            <Styles.ManusEdit style={{ marginTop: '2.5rem !important' }}>
                <DraggableList items={manusPartList} itemStyle={{}} onReorder={onReorder} />
            </Styles.ManusEdit>
        </Styles.ManusArea>
    );
}
