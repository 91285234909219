import React from 'react';
import { Form, Header, Modal, Button } from 'semantic-ui-react';

export interface IProps {
  linkText: string;
  url: string;
  hasSelection: boolean;
  visible: boolean;
  onDismiss(): void;
  onLinkInsert(linkText: string, url: string): void;
}

interface IState {
  url: string;
  linkText: string;
}

const initialState = () => ({ url: '', linkText: '' });

export class LinkModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = initialState();

    this.onChangeLinkText = this.onChangeLinkText.bind(this);
    this.onChangeUrl = this.onChangeUrl.bind(this);
    this.onClickOk = this.onClickOk.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }

  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(oldProps: IProps) {
    if (oldProps.linkText !== this.props.linkText) {
      this.setState({ linkText: this.props.linkText });
    }
    if (oldProps.url !== this.props.url) {
      this.setState({ url: this.props.url });
    }
  }

  onChangeLinkText(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ linkText: event.target.value });
  }

  onChangeUrl(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ url: event.target.value });
  }

  onClickOk() {
    const { linkText, url } = this.state;
    this.props.onLinkInsert(linkText, url);
    this.setState(initialState());
  }

  onDismiss() {
    this.setState(initialState());
    this.props.onDismiss();
  }

  render() {
    const { hasSelection, visible } = this.props;

    const title = hasSelection ? 'Create link' : 'Insert link';
    return (
      <Modal open={visible} onClose={this.onDismiss}>
        <Header icon="linkify" content={title} />
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>
                URL
                {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
                <input autoFocus placeholder="https://" value={this.state.url} onChange={this.onChangeUrl} />
              </label>
            </Form.Field>
            <Form.Field>
              <label>
                Link text
                <input placeholder="Click here" value={this.state.linkText} onChange={this.onChangeLinkText} />
              </label>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={this.onDismiss}>
            Cancel
          </Button>
          <Button positive icon="checkmark" labelPosition="right" content={title} onClick={this.onClickOk} />
        </Modal.Actions>
      </Modal>
    );
  }
}
