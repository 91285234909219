import axios from 'axios';
import { API } from 'config';
import { APIUtils } from 'commons/utils';

/*
/folder/name/:name
/folder/:id
/folder/
*/
export const getFolderTree = (token: string, folderId: string) => {
  const url = new URL(`/folder/tree/${folderId}/`, API.baseURL).toString();
  return axios.get(url, APIUtils.tokenHeader(token));
};

export const getFolderById = (token: string, folderId: string) => {
  const url = new URL(`/folder/id/${folderId}/`, API.baseURL).toString();
  return axios.get(url, APIUtils.tokenHeader(token));
};

export const getFoldersByName = (token: string, name: string) => {
  const url = new URL(`/folder/name/${name}/`, API.baseURL).toString();
  return axios.get(url, APIUtils.tokenHeader(token));
};
