import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import {
  generateTwoFactorAuth, editUser, turnOnTwoFactorAuth, getCMSUser, clearCreateSuccess,
} from 'actions/UserActions';
import { RouteComponentProps } from 'react-router-dom';
import { IState } from 'reducers/reducers';
import { EditProfileData } from '@visikon/core-models/user';
import { ProfileSetting } from './ProfileSetting';
import { logger } from '../../commons/logger';

interface IPropsFromDispatch {
  editUser(user: EditProfileData): void;
  clearCreateSuccess(): void;
  getCMSUser(): void;
  generateTwoFactorAuth(qrCode: string): void;
  turnOnTwoFactorAuth(tfaToken: string): void;
}

interface ILocalState {
  currentUser?: EditProfileData;
  qrCode?: string;
}
interface IPropsFromState {
  createSuccess: boolean;
  currentUser?: EditProfileData;
  qrCode?: string;
  tfaToken?: string;
}

type IProps = IPropsFromDispatch & ILocalState & RouteComponentProps<any> & IPropsFromState;

class CProfileRoot extends React.Component<IProps, ILocalState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if (this.props.currentUser === undefined) {
      this.getActiveUSer();
    }
    this.props.clearCreateSuccess();
  }

  componentDidUpdate() {
    if (this.props.location) {
      setTimeout(() => {
        this.props.clearCreateSuccess();
      }, 6000);
    }
  }

  getActiveUSer() {
    this.props.getCMSUser();
  }

  render() {
    const { currentUser } = this.props;
    if (currentUser === undefined) {
      logger.warn('User is undefined!', currentUser);
      return null;
    }
    return (
      <>
        <ProfileSetting
          user={currentUser}
          onSave={this.props.editUser}
          onCreate={this.props.generateTwoFactorAuth}
          image={this.props.qrCode}
          onActivate={this.props.turnOnTwoFactorAuth}
          onPasswordSave={this.props.editUser}
          createSuccess={this.props.createSuccess}
        />
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<IPropsFromState, {}, IState> = (state) => ({
  createSuccess: state.users.creationSuccess,
  currentUser: state.auth.currentUser,
  qrCode: state.auth.qrCode,
  tfaToken: state.auth.tfaToken,
});

const mapDispatchToProps: MapDispatchToProps<IPropsFromDispatch, {}> = (dispatch) => ({
  editUser(user) {
    dispatch(editUser(user));
  },
  clearCreateSuccess() {
    dispatch(clearCreateSuccess());
  },
  getCMSUser() {
    dispatch(getCMSUser());
  },
  generateTwoFactorAuth(qrCode) {
    dispatch(generateTwoFactorAuth(qrCode));
  },
  turnOnTwoFactorAuth(tfaToken) {
    dispatch(turnOnTwoFactorAuth(tfaToken));
  },
});

export const ProfileRoot = connect(mapStateToProps, mapDispatchToProps)(CProfileRoot);
