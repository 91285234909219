import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const VideoWrapper = styled.div`
  cursor: pointer;
  min-height: 150px;
`;
