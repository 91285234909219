import styled from 'styled-components';
import * as Semantic from 'semantic-ui-react';

export const Button = styled(Semantic.Button)`
    height: 40px;
    width: 40px;
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
`;
