import { Content, TypeUtils } from '@visikon/core-models';
import { useHistory } from 'react-router-dom';
import { TextItemView } from 'components/mediaLibrary/texts/TextItemView';
import { useListTexts } from 'api/textLibraryApi';
import { useRecoilState } from 'recoil';
import { userTypeState } from 'redux-recoil-sync.state';
import { Spinner } from 'components/spinner';
import { Checkbox, Label } from 'semantic-ui-react';
import { NewTextButton } from './Textrender';
import { useLocalStorageState } from '../../commons/utils';
import { Container, Flex1 } from './TextContainer.styles';

/* eslint-disable react/require-default-props */
interface IOwnProps {
  onlyPicker?: boolean;
  onTextPick?: (image: Content.Text) => void;
}

export const TextLibraryContainer = ({ onlyPicker, onTextPick }: IOwnProps) => {
  const history = useHistory();
  const { value: archived, setValue: setArchived } = useLocalStorageState<boolean>("TextLibraryContainer", false );
  const useQuery = useListTexts(archived);
  const [userType] = useRecoilState(userTypeState);
  const hideTags = !TypeUtils.hasAccess(userType, TypeUtils.PermissionLevels.EDITOR_PERMISSION_LEVEL);

  const HandleClickText = (text: any) => {
    if (onlyPicker && onTextPick) {
      onTextPick(text);
    } else {
      history.push(`/media/textEdit/${text._id}`);
    }
  };

  const images = useQuery.data?.entries.map((e) => (
    <TextItemView
      key={e.metadata._id}
      text={e.metadata}
      languages={e.translations!}
      onlyPicker={!onlyPicker}
      onClick={() => {
        HandleClickText(e.metadata);
      }}
      onTagAdded={() => ''}
      onTagRemoved={() => ''}
      hideTags={hideTags}
    />
  ));

  return (
    <div>
      <h1>Text Library</h1>

      <Container style={{ flexDirection: 'column' }}>
        <Container style={{ flexDirection: 'row', marginBottom: 10 }}>
          <div >
            {NewTextButton(onlyPicker!, userType)}
          </div>

          <Flex1 style={ { marginLeft: 10, marginTop: 5}}>
            <Checkbox
              label='Show archived'
              checked={archived}
              onChange={() => {
                setArchived(!archived);
              }}
              style={{ paddingTop: '5', paddingLeft: '5' }}
              toggle
            />
          </Flex1>
          <div>
          { useQuery.isFetching && <Label>Fetching...</Label> }
          </div>
        </Container>
        <Flex1 style={{ flexGrow: 1 }}>
          {useQuery.isLoading ? <Spinner visible={useQuery.isLoading} /> : <div className='ui cards'>{images}</div>}
        </Flex1>
      </Container>
      <div style={{ display: 'flex', justifyContent: 'center', padding: 15 }} />
    </div>
  );
};
