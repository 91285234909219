import { Block, LanguageCode } from '@visikon/core-models/content';
import { matchLanguageOrFallback } from '@visikon/core-models/helpers/language-fallback';
import { CompositionBlock } from '@visikon/core-models/models/composer/blocks';

export const ManusExportHeaders = ['Language', 'Block Id', 'Name', 'Variation', 'Text', 'Translation'];

export type ManusCsvData = {
  filename: string,
  data: string[][]
}

type ManusExport = {
  blockId: string,
  blockName: string,
  variation: string,
  text: string,
};

export function getCsvData(selectedBlocks: CompositionBlock[], allBlocks: Block[], language: LanguageCode, name: string): ManusCsvData {
  const data = appendHeaders(
    toManusExport(selectedBlocks, allBlocks, language),
    language
  );
  const filename = extractFileName(language, name);

  return {data, filename};
}

export function extractFileName(language: LanguageCode, name: string) {
  return `${language}_${name.replaceAll(' ', '_')}`;
}

export function appendHeaders(rows: ManusExport[], language: LanguageCode) {
  return [
    ManusExportHeaders,
    ...rows.map(row => [
      language,
      row.blockId || '',
      row.blockName || '',
      row.variation || '',
      row.text || '',
    ])
  ];
}

function toManusExport(selectedBlocks: CompositionBlock[], allBlocks: Block[], language: LanguageCode) {
  return selectedBlocks.map((b) => {
    const block = allBlocks.find((bl) => bl._id === b.blockId);
    const speak = block && matchLanguageOrFallback(block.speaks, language, b.speakVariation);

    return {
      blockId: b.blockId,
      blockName: block?.name,
      variation: b.speakVariation,
      text: speak?.text,
    };
  }) as ManusExport[];
}
