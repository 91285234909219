import { LanguageCode } from '@visikon/core-models/languageTypes';

export function removeTag(str: string, tagName: string) {
  const regex = new RegExp(`<\\/?${tagName}[^>]*>`, 'gi');
  const result = str.replace(regex, '');
  return result;
}

export function cleanWrapperTag(str: string, tagName: string) {
  const contentWithNoWrapper = removeTag(str, tagName);
  const withCleanWrapper = `<${tagName}>${contentWithNoWrapper}</${tagName}>`;
  return withCleanWrapper;
}

export function sortAlphabetically(array: Element[], lang: LanguageCode): Element[] {
  return array.sort((a, b) => {
    const aText = Array.from(a.getElementsByTagName('grapheme')).map((item) => item.textContent)?.[0] ?? '';
    const bText = Array.from(b.getElementsByTagName('grapheme')).map((item) => item.textContent)?.[0] ?? '';
    return aText.localeCompare(bText, lang);
  });
}
