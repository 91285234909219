import { StatusValue } from '@visikon/core-models/content';
import { useState } from 'react';
import { Dropdown, DropdownProps, Label, LabelProps } from 'semantic-ui-react';
import styled from 'styled-components';

const defaultLabelProps: LabelProps = { circular: true, empty: true };
function statusOptions(textLabels: IProps['labels']): StatusOption[] {
  return [
    {
      key: 'GREEN',
      text: textLabels ? textLabels.green : 'Green',
      value: 'GREEN',
      label: { ...defaultLabelProps, color: 'green' },
    },
    {
      key: 'YELLOW',
      text: textLabels ? textLabels.yellow : 'Yellow',
      value: 'YELLOW',
      label: { ...defaultLabelProps, color: 'yellow' },
    },
    {
      key: 'RED',
      text: textLabels ? textLabels.red : 'Red',
      value: 'RED',
      label: { ...defaultLabelProps, color: 'red' },
    },
  ];
}

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 5px;
  min-height: 32px;
  min-width: 32px;
`;

interface StatusOption {
  key: string;
  text: string;
  value: StatusValue;
  label: LabelProps;
}

interface IProps {
  labels?: {
    red: string;
    yellow: string;
    green: string;
  };
  active?: StatusValue;
  initialValue?: StatusValue;
  onChange(status: StatusValue): void;
}

interface StatusDropdownProps extends DropdownProps {
  value: StatusValue;
}

export const StatusPicker = (props: IProps) => {
  const options = statusOptions(props.labels);
  const [stateValue, setStateValue] = useState<StatusValue>(props.initialValue ?? options[0].value);

  const value = props.active || stateValue;
  const labelProps = options.find((i) => i.value === value)!.label;

  const handleChange = (_: never, data: StatusDropdownProps) => {
    setStateValue(data.value);
    props.onChange(data.value);
  };

  return (
    <StatusContainer>
      <Dropdown
        trigger={<Label {...labelProps} />}
        onChange={handleChange}
        labeled
        inline
        options={options}
        defaultValue={props.active ?? stateValue}
      />
    </StatusContainer>
  );
};
