import { trimAll } from './string';

export function deDuplicate<T>(input: T[]) {
  return Array.from(new Set(input));
}

export function joinDeduplicated<T>(arr1: T[], ...arr2: T[]): T[] {
  return Array.from(
    new Set([...arr1, ...arr2])
  );
}

export function joinAndTrimDeduplicated(arr1: string[], ...arr2: string[]) {
  return joinDeduplicated(
    trimAll(arr1),
    ...trimAll(arr2)
  );
}
