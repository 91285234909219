import { Content } from '@visikon/core-models';

interface IProps {
  text: string;
  onDelete?: (text: Content.Tag) => void;
  readOnly?: boolean;
}

export const Tag = (props: IProps) => (
  <div className="ui label" style={{ marginTop: 5 }}>
    {props.text}
    {!props.readOnly && <i onClick={() => props.onDelete && props.onDelete(props.text)} className="delete icon" />}
  </div>
);
