import { Block, LanguageCode } from '@visikon/core-models/content';
import { DataRowExporter } from 'commons/utils';
import * as Semantic from 'semantic-ui-react';
import { getCsvData } from './ManusExportTransformations';
import { CompositionBlock } from '@visikon/core-models/models/composer/blocks';

type Props = {
  name: string;
  selectedBlocks: CompositionBlock[];
  allBlocks: Block[];
  language: LanguageCode;
};

export function ManusExportButton({ selectedBlocks, allBlocks, language, name }: Props) {
  const handleExportClick = () => {
    const { data, filename } = getCsvData(selectedBlocks, allBlocks, language, name);

    const exporter = new DataRowExporter(data);
    exporter.DownloadAsCsv(filename, '#');
  };

  return (
    <>
      <Semantic.Button basic size="mini" onClick={handleExportClick}>
        <Semantic.Icon name="download" />
        Export
      </Semantic.Button>
    </>
  );
}
