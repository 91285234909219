import { useRef } from "react";
import { fromEvent } from 'file-selector';
import { logger } from "commons/logger";
import { Dropdown, Icon } from "semantic-ui-react";

type Props = {
  text: string,
  onFilePick: (files: File[]) => void,
};

export function FileUploadButton({ text, onFilePick }: Props) {
  const fileInput = useRef<HTMLInputElement>(null);

  // From Dropzone source
  // eslint-disable-next-line class-methods-use-this
  const isEvtWithFiles = (event: any) => {
    if (!event.dataTransfer) {
      return !!event.target && !!event.target.files;
    }
    // https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/types
    // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Recommended_drag_types#file
    return Array.prototype.some.call(
      event.dataTransfer.types,
      (type: string) => type === 'Files' || type === 'application/x-moz-file',
    );
  }

  const onFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isEvtWithFiles(event)) {
      return;
    }
    const files = await fromEvent(event.nativeEvent);
    // Add preview
    for (const file of files) {
      try {
        // @ts-ignore
        (file as any).preview = window.URL.createObjectURL(file);
      } catch (e) {
        logger.warn("Couldn't generate preview");
      }
    }
    onFilePick(files as any);
  }

  const onButtonClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  }


  return (
    <>
      <Dropdown.Item onClick={onButtonClick}>
        <Icon name='upload' />
        <span className='text'>{text}</span>
      </Dropdown.Item>

      <input
        title="File Input"
        type="file"
        ref={fileInput}
        style={{ display: 'none' }}
        onChange={onFileInputChange}
      />
    </>
  );
}
