import { loadVideo } from 'actions/MediaActions';
import { VideoFile } from 'components/mediaLibrary/videos/VideoFile';
import { TagList } from 'components/tags/TagList';
import { Content } from '@visikon/core-models';
import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { IState } from 'reducers/reducers';
import styled from 'styled-components';
import { ObjectId } from '@visikon/core-models/base';

interface IPropsFromState {
  language: Content.LanguageCode;
  mediaVariation: ObjectId;
  video?: Content.Video;
}
interface IPropsFromDispatch {
  loadVideo: (id: ObjectId) => void;
}
interface IOwnProps {
  videoId: ObjectId;
  className?: string;
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 4px;

  h4 {
    margin-top: 0;
  }
`;

const VideoImageWrapper = styled.div`
  position: relative;
`;

// const IconWrapper = styled.div`
//     position: absolute;
//     z-index: 2;
//     width: 100%;
//     text-align: center;
//     margin-top: 25%;

//     &>i {
//         color: white;
//         font-size: 4em;
//         text-shadow: 0px 0px 10px #000;
//     }
// `;

type Props = IPropsFromState & IPropsFromDispatch & IOwnProps;
/**
 * This is a connected VideoFileWrapper which loads an Video Object from an ID.
 */
class CVideoFileWrapper extends React.Component<Props> {
  componentDidMount() {
    this.props.loadVideo(this.props.videoId);
  }

  render() {
    const { video, className, language, mediaVariation } = this.props;
    if (video) {
      return (
        <div style={{ display: 'flex' }}>
          <Wrapper>
            <h4>{video.name}</h4>
            <div style={{ display: 'flex' }}>
              {mediaVariation && (
                <VideoImageWrapper>
                  <VideoFile className={className} video={video} language={language} />
                </VideoImageWrapper>
              )}
              <div style={{ maxWidth: 300, paddingLeft: 20 }}>
                <h4 style={{ marginBottom: 0 }}>Video Tags</h4>
                <TagList readOnly tagList={video.tags} />
              </div>
            </div>
          </Wrapper>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps: MapStateToProps<IPropsFromState, IOwnProps, IState> = (state, props) => {
  const video = (state.media.videosByFolder.root || []).find((img) => img._id === props.videoId);

  return {
    language: state.language.activeLanguage,
    mediaVariation: state.language.activeMediaVariation,
    video,
  };
};

const mapDispatchToProps: MapDispatchToProps<IPropsFromDispatch, IOwnProps> = (dispatch) => ({
  loadVideo: (id: ObjectId) => {
    dispatch(loadVideo(id));
  },
});

export const VideoFileWrapper = connect(mapStateToProps, mapDispatchToProps)(CVideoFileWrapper);
