import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('PUBLISHER');

export interface IPublishedContent {
  _id: string;
  originalId: string;
  version: any;
  publishedAt: number;
  publishedBy: string;
  namespace: string;
  disabled: boolean;
  approved: boolean;
}

export const clearCreateSuccess = actionCreator('CLEAR_CREATE_SUCCESS');
export const publishedApiError = actionCreator<{ msg: string; payload?: any }>('API_ERROR');

export const publishContent = actionCreator<{ id: string }>('PUBLISH_CONTENT');
export const publishContentSuccessful = actionCreator('PUBLISH_CONTENT_SUCCESSFUL');

export const getALlPublishedVersionById = actionCreator<string>('GET_ALL_PUBLISHED_VERSION_BY_ID');
export const getALlPublishedVersioSuccess = actionCreator<IPublishedContent[]>('GET_ALL_PUBLISHED_VERSION_BY_ID_SUCCESS');

export const toggleDisable = actionCreator<{ id: string; originalId: string }>('TOGGLE_DISABLE');
export const toggleDisableSuccess = actionCreator('TOGGLE_DISABLE_SUCCESS');

export const toggleApproved = actionCreator<{ id: string; originalId: string }>('TOGGLE_APPROVED');
export const toggleApprovedSuccess = actionCreator('TOGGLE_APPROVED_SUCCESS');
