import { Icon, Popup, Table } from 'semantic-ui-react';
import { useState } from 'react';
import { sortBy } from 'lodash-es';
import { LocalStorage } from '../commons/local-storage';

type Header = {
  key?: string;
  label: string;
};
type Row = {
  id: string;
  cells: (JSX.Element | string)[];
};
type SortInfo = {
  column: string;
  order: 'ascending' | 'descending';
};
const storageKey = (name: string) => `sort-info-for-${name}`;

const sortRows = (headers: Header[], rows: Row[], sorted: SortInfo) => {
  const getCellValueForColumn = (row: Row) => row.cells[headers.findIndex((header) => header.key === sorted.column)] as string;
  const getCellValue = (row: Row) => getCellValueForColumn(row)?.toLocaleLowerCase();

  return sorted.order === 'ascending' ? sortBy(rows, getCellValue) : sortBy(rows, getCellValue).reverse();
};

const loadSortInfo = (typeName: string) => LocalStorage.load(storageKey(typeName), { column: 'date', order: 'descending' }) as SortInfo;

const mapToSortInfo = (key?: string, order?: string) =>
  ({
    column: key,
    order: order === 'ascending' ? 'descending' : 'ascending',
  } as SortInfo);

export function SortableTable({
  typeName,
  headers,
  rows,
  onRowClick,
  onCopy,
}: {
  typeName: string;
  headers: Header[];
  rows: Row[];
  onRowClick: (id: string) => void;
  onCopy: (id: string) => void;
}) {
  const [sorted, setSorted] = useState<SortInfo>(loadSortInfo(typeName));

  return (
    <Table className="sortable celled table">
      <Table.Header>
        <TableHeader
          headers={headers}
          onColumnClick={(key?: string) => {
            const sortInfo = mapToSortInfo(key, sorted.order);
            setSorted(sortInfo);
            LocalStorage.save(storageKey(typeName), sortInfo);
          }}
          sorted={sorted}
        />
      </Table.Header>

      <Table.Body>
        {sortRows(headers, rows, sorted).map((row, i) => (
          <TableRow key={i} {...row} onClick={onRowClick} onCopy={onCopy} />
        ))}
      </Table.Body>
    </Table>
  );
}

// eslint-disable-next-line react/no-unused-prop-types
function TableHeader({ headers, onColumnClick, sorted }: { headers: Header[]; onColumnClick: (key?: string) => void; sorted: SortInfo }) {
  return (
    <Table.Row>
      {headers?.map((header, i) => (
        <Table.HeaderCell
          key={i}
          sorted={sorted?.column === header.key ? sorted?.order : undefined}
          onClick={() => {
            if (header.key) onColumnClick(header.key);
          }}
        >
          {header.label}
        </Table.HeaderCell>
      ))}
      <Table.HeaderCell />
    </Table.Row>
  );
}

function TableRow({ id, cells, onClick, onCopy }: Row & { onClick: (id: string) => void; onCopy: (id: string) => void }) {
  return (
    <Table.Row style={{ cursor: 'pointer' }}>
      {cells.map((cell, i) => (
        <Table.Cell key={i} onClick={() => onClick(id)}>
          {cell}
        </Table.Cell>
      ))}
      <Table.Cell>
        <Popup
          trigger={
            <Icon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                onCopy(id);
              }}
              name="copy outline"
              className="right floated"
            />
          }
          inverted
          content="Copy"
        />
      </Table.Cell>
    </Table.Row>
  );
}
