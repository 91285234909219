import React from 'react';
import { InputOnChangeData, Form } from 'semantic-ui-react';
import { ICommonBaseProps } from '../CustomTypeInput';

export interface IParametersForNumber {
  type: 'number';
  desc: string;
}

interface IComponentNumberInputProps extends ICommonBaseProps, IParametersForNumber {
  value?: number;
  onChange(key: string, val: number | '-'): void;
}

export class NumberTypeInput extends React.Component<IComponentNumberInputProps> {
  constructor(props: IComponentNumberInputProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) {
    const value = data.value.replace(/[^0-9.-]/g, '').replace(/(\..*)\./g, '$1');
    if (value === '-') {
      this.props.onChange(this.props.pKey, value);
    } else {
      this.props.onChange(this.props.pKey, Number(value));
    }
  }

  render() {
    if (this.props.isTranslation) {
      return null;
    }

    return (
      <Form.Input
        style={{ flex: 0, width: 200 }}
        value={this.props.value || ''}
        onChange={this.handleChange}
        label={<h4>{this.props.desc}</h4>}
      />
    );
  }
}
