import { Change, InvalidReason, Schema, SchemaProperties, Value, Block } from 'slate';

export function translationSchema(value: Value): Schema {
  return Schema.fromJS(translationSchemaProperties(value));
}

const translationNormalization = (change: Change, reason: InvalidReason, context: Record<string, any>) => {
  const { index, rule, node } = context;

  switch (reason) {
    case 'child_type_invalid': {
      if (change.operations.get(0).type === 'set_value') {
        const offendingChild = node.nodes.get(index);
        const wantedType = rule.nodes[index].types[0];
        change.setNodeByKey(offendingChild.key, wantedType);
      } else {
        change.undo();
      }
      break;
    }
    case 'child_required': {
      const type = rule.nodes[index].types[0];
      change.insertNodeByKey(node.key, index, Block.create(type));
      break;
    }
    case 'child_unknown': {
      const action = change.operations.get(0).type;
      // An extra unknown node was added bacause of a split
      if (action === 'split_node') {
        change.undo();
        break;
      }
      break;
    }
    default:
  }
};

export function translationSchemaFromSave(value: SchemaProperties): Schema {
  // eslint-disable-next-line no-param-reassign
  value.document!.normalize = translationNormalization;
  return Schema.fromJS(value);
}

function translationSchemaProperties(value: Value): SchemaProperties {
  const nodes = value.document.nodes.toArray().map((n) => ({ types: [n.type], min: 1, max: 1 }));
  return {
    document: {
      nodes,
      normalize: translationNormalization,
    },
  };
}
