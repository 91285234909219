import { NEW_RESOURCE_ID } from 'commons/utils';
import { typeDescriptionDisplayName } from 'components/contentTypes/ContentDescriptors';
import { ITypeDescription, ITypeHeaderProps } from 'components/contentTypes/CustomTypeInput';
import { ListHeaderWrapper, ListHeaderWrapperProps } from './headers/ListHeaderWrapper';
import { LanguageCode } from '@visikon/core-models/content';

interface CustomTypeEditorHeaderProps {
  id: string;
  type: string;
  typeDescription: ITypeDescription;
  toggleTranslationPermission: boolean;
  showTranslation: boolean;
  setShowTranslation: (value: boolean) => void;
  activeLanguage: LanguageCode;
  languages?: LanguageCode[];
  hasChanges: boolean;
  handleSave: () => void;
  handleLanguageChange: (lang: LanguageCode) => void;
}

export function CustomTypeEditorHeader({
  id,
  type,
  typeDescription,
  showTranslation,
  setShowTranslation,
  toggleTranslationPermission,
  activeLanguage,
  languages,
  hasChanges,
  handleSave,
  handleLanguageChange,
}: CustomTypeEditorHeaderProps) {
  const subTitle = id === NEW_RESOURCE_ID ? 'Create new' : 'Edit';
  const listHeaderWrapperProps: ListHeaderWrapperProps = {
    header: typeDescription ? typeDescriptionDisplayName(typeDescription) : 'Undefined type',
    subTitle,
    showTranslation,
    setShowTranslation,
    toggleTranslationPermission,
    activeLanguage,
    languages,
    hasChanges,
    handleSave,
    handleLanguageChange,
  };

  const headerProps: ITypeHeaderProps = {
    type,
    id,
    typeDescription,
  };

  const HeaderComponent = typeDescription?.meta.header;
  const hasExtendedHeader = !!HeaderComponent;

  if (hasExtendedHeader) {
    listHeaderWrapperProps.children = <HeaderComponent {...headerProps} />;
  }

  return <ListHeaderWrapper {...listHeaderWrapperProps} />;
}
