import { Content } from '@visikon/core-models';
import { getTranslation } from '@visikon/core-models/typeUtils';
import { Environment } from 'commons/env';
import { API, appEnv } from 'config';

export type Subtitle = {
  key: string,
  label: string,
  src: string
};

export function getUriForSubtitles(video: Content.Video, language: Content.LanguageCode, subtitlesKey: string) {
  const translationsWithSubtitles = video.translations.filter((t) => t.subtitles);
  if (translationsWithSubtitles.length === 0) {
    return undefined;
  }

  const trans = getTranslation(video, language);
  if (!trans || !trans.subtitles || !trans.subtitles.find((subtitles) => subtitles.key === subtitlesKey)) {
    return undefined;
  }

  const transToUse = translationsWithSubtitles.find((t) => t.language === trans.language) ? trans : translationsWithSubtitles[0];
  if (appEnv === Environment.Local) {
    const modificationDate = trans.lastModified;
    return `${API.baseURL}media/subtitles/${video._id}/${transToUse.language}/dummy_variation/${subtitlesKey}?lastModified=${modificationDate}`;
  }

  return `${API.baseBlobUrl}${trans.subtitles.find((subtitles) => subtitles.key === subtitlesKey)?.src}`;
}
