import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  `;

export const Flex1 = styled.div`
  flex: 1;
  `;

export const MessageContainer = styled.div`
  position: absolute;
  top: 42px;
  right: 60px;
`;
