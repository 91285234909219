import styled from 'styled-components';

export const Container = styled.div`
  width: 335px;
  border-radius: 3px;
  border: 1px solid #cecece;
  margin: 7px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;
