import { AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import { IState } from '../reducers/reducers';
import * as AuthActions from '../actions/AuthActions';

export const getToken = (state: IState) => state.auth.token;
export const getLoadedImages = (state: IState) => state.media.imagesByFolder;
export const getLoadedVideos = (state: IState) => state.media.videosByFolder;
export const getMediaVariation = (state: IState) => state.language.activeMediaVariation;
export const getLanguage = (state: IState) => state.language.activeLanguage;

export function* handleAuthorizationError(response: AxiosResponse | undefined) {
  if (response !== undefined) {
    if (response.status === 401) {
      yield put(AuthActions.logOut());
    }

    if (response.status === 403) {
      yield put(AuthActions.forbiddenError());
    }
  }
}
