import { Content } from '@visikon/core-models';
import { Block, BlockCreation, BlockList, LanguageCode, Tag } from '@visikon/core-models/content';
import * as Actions from 'actions/BlockActions';
import { ArrayUtils, NEW_RESOURCE_ID } from 'commons/utils';
import { DraggableList } from 'components/dragNdrop/DraggableList';
import md5 from 'md5';
import React, { Dispatch, FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { UpdateStatus } from 'reducers/compositionsReducer';
import { IState } from 'reducers/reducers';
import { BlockEditModal } from '../block/BlockEditModal';
import { ManusBlocks } from './components/ManusBlocks';
import { MediaLibraryLink } from './components/MediaLibraryLink';
import { ComposerMenu } from './components/ComposerMenu';
import { BlockPickerButton, getBlockFromId } from './components/modals/BlockPicker.button';
import { BlockListPlayer } from './components/player/BlockListPlayer';
import { Status } from './components/Status';
import { Tags } from './components/Tags';
import { TimeLine } from './components/TimeLine';
import { TimelineCard } from './components/TimelineCard';
import * as Styles from './Composer.styles';
import { EncoderMenu } from './components/EncoderMenu';
import { cleanupEmpty } from '@visikon/core-models/helpers/object-helpers';
import { buildBlueprint } from '@visikon/core-models/helpers/composer/blueprint-helpers';
import { CompositionBlock, EditBlock } from '@visikon/core-models/models/composer/blocks';
import { getCompositionBlocks, getEditBlock } from '@visikon/core-models/helpers/composer/composition-block-helpers';
import { useGetImageTranslationsById } from 'api/imageLibraryApi';
import { getTranslationFromArray } from '@visikon/core-models/typeUtils';
import { useSetThumbnail } from './components/CompositionCard';
import { Blueprint } from '@visikon/core-models/encoding';
import { useComposition } from 'api/compositionApi';

const DEFAULT_AUDIO_DELAY = 500;

type ReduxState = {
  lang: Content.LanguageCode;
  blocks: Block[];
  newlySavedCompositionId?: string;
  newlyCreatedBlockId?: string;
  newlyCreatedBlockError: boolean;
  status?: UpdateStatus;
};

type PropsFromQuery = {
  composition?: BlockList | undefined;
};

interface ReduxActions {
  listBlocks(): void;
  clearSavedId(): void;
  updateBlock(blockCreation: BlockCreation): void;
  addNewBlock(blockCreation: BlockCreation): void;
  addNewBlockClear(): void;
}

type RouterProps = RouteComponentProps<{ id: string; blockId?: string }>;
type Props = PropsFromQuery & RouterProps & ReduxState & ReduxActions;

interface ComponentState {
  isPlaying: boolean;
  highlightBlock?: string;
  blockToEdit?: EditBlock;

  compositionId?: string;
  compositionBlocks: CompositionBlock[];
  name: string;
  tags: Tag[];
  thumbnailSrc?: string;
  thumbnailRefId?: string;
  audioDelay: number;
  hashedReduxState: string;
}

function hashState(options: {
  compositionBlocks: CompositionBlock[];
  audioDelay: number;
  thumbnailSrc?: string;
  thumbnailRefId?: string;
  name: string;
  tags?: string[];
}) {
  return md5(
    JSON.stringify(
      cleanupEmpty({
        compositionBlocks: options.compositionBlocks,
        audioDelay: options.audioDelay,
        thumbnailSrc: options.thumbnailSrc,
        thumbnailRefId: options.thumbnailRefId,
        name: options.name,
        tags: options.tags,
      }),
    ),
  );
}

class CBlockComposer extends React.Component<Props, ComponentState> {
  // eslint-disable-next-line react/no-unused-class-component-methods
  preview: React.RefObject<HTMLVideoElement>;

  constructor(props: Props) {
    super(props);

    // eslint-disable-next-line react/no-unused-class-component-methods
    this.preview = React.createRef();

    this.state = {
      isPlaying: false,

      compositionBlocks: [],
      name: '',
      tags: [],
      thumbnailSrc: '',
      hashedReduxState: '',
      audioDelay: DEFAULT_AUDIO_DELAY,
    };

    this.handleBlockReorder = this.handleBlockReorder.bind(this);
    this.handleBlockPicked = this.handleBlockPicked.bind(this);
    this.handleBlockHover = this.handleBlockHover.bind(this);
    this.handleVariationChange = this.handleVariationChange.bind(this);
    this.handleListNameChange = this.handleListNameChange.bind(this);
  }

  componentDidMount() {
    if (this.props.blocks.length === 0) {
      this.props.listBlocks();
    } else {
      this.loadComposition();
    }
  }

  static selectedCompositionEquals(prevProps: Props, currProps: Props) {
    const prevComposition = prevProps.composition;
    const currentComposition = currProps.composition;
    return JSON.stringify(prevComposition) === JSON.stringify(currentComposition);
  }

  componentDidUpdate(oldProps: Props, prevState: ComponentState) {
    const idChanged = oldProps.match.params.id !== this.props.match.params.id;
    const blockIdChanged = oldProps.match.params.blockId !== this.props.match.params.blockId;
    const blocksLoaded = oldProps.blocks.length === 0 && this.props.blocks.length > 0;
    const compositionLoaded = oldProps.composition === undefined && this.props.composition !== undefined;
    const compositionChanged = !CBlockComposer.selectedCompositionEquals(oldProps, this.props);

    if (idChanged || blockIdChanged || compositionLoaded || blocksLoaded || compositionChanged) {
      this.loadComposition();

      if (this.props.match.params.blockId) {
        const block = this.props.blocks.find((b) => b._id === this.props.match.params.blockId);
        if (block) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState((state) => ({
            blockToEdit: getEditBlock(state.compositionBlocks, block),
          }));
        }
      }
    }

    if (
      oldProps.newlySavedCompositionId !== this.props.newlySavedCompositionId &&
      typeof this.props.newlySavedCompositionId === 'string' &&
      this.props.match.params.id === NEW_RESOURCE_ID
    ) {
      this.props.match.params.id = this.props.newlySavedCompositionId;
      window.history.replaceState({}, '', `/block/composer/${this.props.newlySavedCompositionId}`);
    }

    if (oldProps.newlySavedCompositionId === undefined && this.props.newlySavedCompositionId !== undefined) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ compositionId: this.props.newlySavedCompositionId });
      this.props.clearSavedId();
    }

    // // A new block was added succesfull
    if (oldProps.newlyCreatedBlockId === undefined && this.props.newlyCreatedBlockId !== undefined) {
      const block = getBlockFromId(this.props.newlyCreatedBlockId, this.props.lang, this.props.blocks);
      this.handleBlockPicked(block);
      this.props.addNewBlockClear();
    }

    if (prevState.compositionId !== this.state.compositionId) {
      const currentComposition = this.props.composition;

      if (currentComposition && currentComposition.audioDelay) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ audioDelay: currentComposition.audioDelay });
      }
    }
  }

  getKeyForNewBlockId(blockId: string) {
    const existingCount = this.state.compositionBlocks.filter((bl) => bl.blockId === blockId).length;
    return `${blockId}-${existingCount}`;
  }

  loadComposition() {
    const list = this.props.composition;

    if (list) {
      const { _id, name, tags, thumbnailSrc, audioDelay, thumbnailRefId } = list;

      const reduxState = {
        compositionBlocks: getCompositionBlocks(list),
        audioDelay: audioDelay || DEFAULT_AUDIO_DELAY,
        name,
        tags,
        compositionId: _id,
        thumbnailSrc,
        thumbnailRefId,
      };

      this.setState({
        ...reduxState,
        hashedReduxState: hashState(reduxState),
      });
    }
  }

  handleBlockPicked(block: { blockId: string; videoVariation: string | undefined; speakVariation: string | undefined } | undefined) {
    if (!block) {
      return;
    }

    const newBlock = {
      ...block,
      key: this.getKeyForNewBlockId(block.blockId),
    };
    this.setState((s) => ({ compositionBlocks: [...s.compositionBlocks, newBlock] }));
  }

  handleBlockReorder(from: number, to: number) {
    this.setState((s) => ({ compositionBlocks: ArrayUtils.arrayMove(s.compositionBlocks, from, to) }));
  }

  handleBlockHover(key: string) {
    if (!this.state.isPlaying) {
      this.setState({ highlightBlock: key });
    }
  }

  handleListNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ name: event.target.value });
  }

  handleVariationChange(type: 'video' | 'speak', blockKey: string, variation: string) {
    const idx = this.state.compositionBlocks.findIndex((b) => b.key === blockKey);
    if (idx === -1) {
      return;
    }

    const copy = { ...this.state.compositionBlocks[idx] };
    if (type === 'video') {
      copy.videoVariation = variation;
    }
    if (type === 'speak') {
      copy.speakVariation = variation;
    }

    this.setState((prevState) => ({ compositionBlocks: Object.assign([], prevState.compositionBlocks, { [idx]: copy }) }));
  }

  handleDeleteBlock(blockKey: string) {
    this.setState((s) => ({ compositionBlocks: s.compositionBlocks.filter((b) => b.key !== blockKey) }));
  }

  renderBlockList() {
    const { lang } = this.props;

    const cardList = this.state.compositionBlocks.map((b, i) => ({
      id: b.key!,
      element: (
        <TimelineCard
          key={b.key}
          block={this.props.blocks.find((block) => block._id === b.blockId) as Block}
          blockKey={b.key!}
          lang={lang}
          speakVariation={b.speakVariation}
          videoVariation={b.videoVariation}
          isHighlighted={b.key === this.state.highlightBlock}
          onVariationChange={this.handleVariationChange}
          onHover={this.handleBlockHover}
          onEditClick={(block) => {
            window.history.replaceState({}, '', `/block/composer/${this.props.match.params.id}/${block._id}`);
            this.setState({ blockToEdit: { block, localBlock: b } });
          }}
          onDeleteBlock={() => this.handleDeleteBlock(b.key!)}
          audioDelay={this.state.audioDelay}
          index={i + 1}
        />
      ),
    }));

    return (
      <div style={{ display: 'flex' }}>
        <DraggableList items={cardList} horizontal itemStyle={{ width: 250, display: 'flex' }} onReorder={this.handleBlockReorder} />
      </div>
    );
  }

  handleAudioDelayInput(value: number) {
    this.setState({ audioDelay: value });
  }

  handleThumbnailChange(refId: string, thumbSrc?: string) {
    if (thumbSrc) this.setState({ thumbnailSrc: thumbSrc });
    this.setState({ thumbnailRefId: refId });
  }

  render() {
    const { audioDelay, compositionId, blockToEdit, tags, name, compositionBlocks, hashedReduxState, highlightBlock, thumbnailRefId } = this.state;

    if (this.props.blocks.length === 0) {
      return 'Loading';
    }

    const hashedState = hashState(this.state);

    return (
      <>
        <ImageSrcByLanguage id={thumbnailRefId} language={this.props.lang}>
          {(imageSrc: string) => (
            <WithBlueprintWrapper state={this.state} composition={this.props.composition} blocks={this.props.blocks} lang={this.props.lang}>
              {(blueprint: Blueprint) => (
                <Styles.Composer>
                  <Styles.Header>
                    <Styles.MenuContainer>
                      <ComposerMenu
                        compositionId={compositionId}
                        name={name}
                        composition={this.props.composition}
                        compositionBlocks={compositionBlocks}
                        thumbnail={imageSrc}
                        thumbnailRefId={thumbnailRefId}
                        audioDelay={audioDelay}
                        tags={tags}
                        hasChanges={hashedReduxState !== hashedState}
                        hash={blueprint.header.hash}
                      />
                      <Styles.NameField label="Video name" value={name} onChange={this.handleListNameChange} />
                      <EncoderMenu
                        language={this.props.lang}
                        compositionId={compositionId}
                        name={name}
                        composition={this.props.composition}
                        compositionBlocks={compositionBlocks}
                        thumbnail={imageSrc}
                        audioDelay={audioDelay}
                        blueprint={blueprint}
                        hasChanges={hashedReduxState !== hashedState}
                        onAudioDelayChange={(value) => this.handleAudioDelayInput(value)}
                        onThumbnailChange={(refID, thumbSrc) => this.handleThumbnailChange(refID, thumbSrc)}
                      />
                      <MediaLibraryLink composition={this.props.composition} />
                    </Styles.MenuContainer>
                  </Styles.Header>
                  <Status status={this.props.status} />
                  <Styles.SubHeader>
                    <Tags
                      tags={tags}
                      onAdd={(tag) => {
                        this.setState((s) => ({ tags: [...(s.tags || []), tag] }));
                      }}
                      onRemove={(tag) => {
                        this.setState((s) => ({ tags: s.tags.filter((t) => t !== tag) }));
                      }}
                    />
                  </Styles.SubHeader>
                  <Styles.UpperArea>
                    <ManusBlocks
                      language={this.props.lang}
                      name={name}
                      compositionBlocks={compositionBlocks}
                      blocks={this.props.blocks}
                      higlightedBlock={highlightBlock}
                      onHover={(id) => {
                        this.handleBlockHover(id);
                      }}
                      onReorder={(from, to) => {
                        this.handleBlockReorder(from, to);
                      }}
                      onClick={(block: Block) => {
                        window.history.replaceState({}, '', `/block/composer/${compositionId}/${block._id}`);
                        this.setState((prevState) => ({
                          blockToEdit: getEditBlock(prevState.compositionBlocks, block),
                        }));
                      }}
                    />
                    <Styles.VideoPreviewArea>
                      <BlockListPlayer
                        blockList={compositionBlocks}
                        lang={this.props.lang}
                        audioDelay={audioDelay}
                        thumbnail={imageSrc}
                        notifyPlaying={(idx) => {
                          if (idx < compositionBlocks.length) {
                            this.setState((prevState) => ({
                              highlightBlock: prevState.compositionBlocks[idx].blockId,
                              isPlaying: true,
                            }));
                          }
                        }}
                        notifyStopping={() => this.setState({ highlightBlock: undefined, isPlaying: false })}
                      />
                    </Styles.VideoPreviewArea>
                  </Styles.UpperArea>
                  <Styles.BottomArea>
                    <BlockPickerButton
                      language={this.props.lang}
                      blocks={this.props.blocks}
                      onBlockPick={this.handleBlockPicked}
                      onBlockCreate={this.props.addNewBlock}
                    />
                    <TimeLine>{this.renderBlockList()}</TimeLine>
                  </Styles.BottomArea>
                </Styles.Composer>
              )}
            </WithBlueprintWrapper>
          )}
        </ImageSrcByLanguage>
        {blockToEdit && (
          <BlockEditModal
            open
            lang={this.props.lang}
            initialBlock={blockToEdit.block}
            videoVariation={blockToEdit.localBlock.videoVariation}
            speakVariation={blockToEdit.localBlock.speakVariation}
            onSave={(blockCreation) => {
              this.props.updateBlock(blockCreation);
              this.setState({ blockToEdit: undefined });
            }}
            onDismiss={() => {
              window.history.replaceState({}, '', `/block/composer/${this.props.match.params.id}`);
              this.setState({ blockToEdit: undefined });
            }}
          />
        )}
      </>
    );
  }
}

function stateSelector(state: IState): ReduxState {
  return {
    lang: state.language.activeLanguage,
    blocks: state.blocks.all,
    newlySavedCompositionId: state.blockLists.newlySavedCompositionId,
    newlyCreatedBlockId: state.blocks.newlyCreatedBlockId,
    newlyCreatedBlockError: state.blocks.newlyCreatedBlockError,
    status: state.blockLists.status,
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>): ReduxActions {
  return {
    listBlocks: () => {
      dispatch(Actions.listBlocks());
    },
    clearSavedId: () => {
      dispatch(Actions.saveCompositionClear());
    },
    updateBlock: (blockCreation) => {
      dispatch(Actions.updateBlock(blockCreation));
    },
    addNewBlock: (blockCreation) => {
      dispatch(Actions.addNewBlockToComposer(blockCreation));
    },
    addNewBlockClear: () => {
      dispatch(Actions.addNewBlockToComposerClear());
    },
  };
}

export function BlockComposer(routerProps: RouterProps) {
  const compositionId = routerProps.match.params.id;
  const dispatch = useDispatch();
  const stateProps = useSelector(stateSelector);
  const dispatchProps = mapDispatchToProps(dispatch);
  const compositionQuery = useComposition(compositionId);
  const composition = compositionQuery.data;

  return <CBlockComposer {...stateProps} {...dispatchProps} {...routerProps} composition={composition} />;
}

type IImageSrcByLanguage = {
  id?: string;
  language: LanguageCode;
  children: any;
};

// ImageSrcByLanguage(): This is a way to use hooks with class components
// It will be simplified as soon as BlockComposer gets rewritten as a Functional component
export const ImageSrcByLanguage: FunctionComponent<IImageSrcByLanguage> = (props) => {
  const { id = '', language, children } = props;

  const [imageSrc, setImageSrc] = React.useState('');
  const useQuery = useGetImageTranslationsById(id);
  // TODO: Refactor to handle query errors (query data being undefined)
  const imageForTranslation = getTranslationFromArray(useQuery.data!, language) as any;

  React.useEffect(() => {
    setImageSrc(imageForTranslation?.src);
  }, [language, imageForTranslation]);

  return children(imageSrc);
};

type IWithBlueprintWrapper = {
  state: ComponentState;
  composition?: BlockList;
  lang: Content.LanguageCode;
  blocks: Block[];
  children?: any;
};

const WithBlueprintWrapper: FunctionComponent<IWithBlueprintWrapper> = ({ state, composition, blocks, lang, children }) => {
  const thumbnailUrl = useSetThumbnail(composition);
  const blueprint = buildBlueprint({ ...state, allBlocks: blocks, language: lang, thumbnailSrc: thumbnailUrl, blockListId: composition?._id });

  return children(blueprint);
};
