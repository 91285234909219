import { StatusValue, LanguageCode } from '@visikon/core-models/content';
import * as Semantic from 'semantic-ui-react';
import * as Styles from './ManusPrint.modal.styles'

export interface IManusPart {
  name: string;
  variation: string;
  text: string;
  status: StatusValue;
  duration: number;
}

export interface IManusPrint {
  name: string;
  language: LanguageCode;
  manus: IManusPart[];
}
interface IProps {
  data: IManusPrint;
}

const TextPart = (props: { part: IManusPart }) => {
  const p = props.part;
  // eslint-disable-next-line consistent-return
  const statusText = (() => {
    // eslint-disable-next-line default-case
    switch (p.status) {
      case 'GREEN': return 'Done';
      case 'RED': return 'Review missing';
      case 'YELLOW': return 'Ready for speak';
    }
  })();

  // eslint-disable-next-line consistent-return
  const color = (() => {
    // eslint-disable-next-line default-case
    switch (p.status) {
      case 'GREEN': return 'rgb(33, 186, 69)';
      case 'RED': return 'rgb(219, 40, 40)';
      case 'YELLOW': return 'rgb(251, 189, 8)';
    }
  })();

  return (
    <Semantic.Table.Row style={{ pageBreakInside: 'avoid' }}>
      <Semantic.Table.Cell style={{ borderLeft: `4px solid ${color}` }}>
        <div>Name:</div>
        <Styles.Item style={{paddingBottom: 8}}>
          {p.name}
        </Styles.Item>

        <div>Variation:</div>
        <Styles.Item style={{paddingBottom: 8}}>
          {p.variation}
        </Styles.Item>

        <div>Video duration:</div>
        <Styles.Item>
          {p.duration ? `${Math.round(p.duration)} sec` : 'n/a'}
        </Styles.Item>

        <div>Status:</div>
        <Styles.Item>
          {statusText}
        </Styles.Item>
      </Semantic.Table.Cell>
      <Semantic.Table.Cell>
        <div style={{ lineHeight: '1.8em' }}>{p.text}</div>
      </Semantic.Table.Cell>
    </Semantic.Table.Row>
  );
};

export const ManusPrint = ({ data }: IProps) => {
  const { name, manus } = data;
  return (
    <Styles.Container>
      <h1>{name}</h1>
      <br />

      <Semantic.Table celled>
        <Semantic.Table.Header>
          <Semantic.Table.Row>
            <Semantic.Table.HeaderCell>Block/Variation</Semantic.Table.HeaderCell>
            <Semantic.Table.HeaderCell>Text</Semantic.Table.HeaderCell>
          </Semantic.Table.Row>
        </Semantic.Table.Header>

        <Semantic.Table.Body>
          {manus.map((m) => (<TextPart key={m.name + m.variation} part={m} />))}
        </Semantic.Table.Body>
      </Semantic.Table>

    </Styles.Container>
  );
};
