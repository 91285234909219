import React from 'react';
import { Content } from '@visikon/core-models';
import { Tag } from './Tag';

interface IProps {
  onTagRemoved?: (tag: Content.Tag) => void;
  onTagAdded?: (tag: Content.Tag) => void;
  tagList: Content.Tag[];
  availableTags?: Content.Tag[];
  sorted?: boolean;
  header?: string;
  readOnly?: boolean;
}
interface ILocalState {
  isAddingTag: boolean;
  editText: string;
}

export class TagList extends React.Component<IProps, ILocalState> {
  constructor(props: IProps) {
    super(props);
    this.state = { isAddingTag: false, editText: '' };

    this.addTag = this.addTag.bind(this);
    this.handleAddBegin = this.handleAddBegin.bind(this);
    this.handleAddOnBlur = this.handleAddOnBlur.bind(this);
    this.handleAddOnChange = this.handleAddOnChange.bind(this);
    this.handleAddKeyPress = this.handleAddKeyPress.bind(this);
  }

  handleAddBegin() {
    this.setState({ isAddingTag: true });
  }

  handleAddOnBlur() {
    this.setState({ isAddingTag: false, editText: '' });
  }

  handleAddOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ editText: event.target.value });
  }

  handleAddKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' || event.key === 'Tab') {
      this.addTag();
      event.preventDefault();
    }
  }

  addTag() {
    const tagText = this.state.editText.trim();
    if (tagText !== '' && this.props.onTagAdded) {
      this.props.onTagAdded(tagText);
      this.setState({ editText: '' });
    }
  }

  render() {
    return (
      <div style={{ zIndex: 10 }}>
        {this.props.tagList?.map((tag, i) => (
          <Tag onDelete={this.props.onTagRemoved} key={i} text={tag} readOnly={this.props.readOnly} />
        ))}
        {this.state.isAddingTag && (
          <div className="ui left icon mini input" style={{ display: 'inline-block', marginLeft: '0.5rem', marginTop: '10px' }}>
            <i className="tags icon" />

            {/* eslint-disable jsx-a11y/no-autofocus */}
            <input
              placeholder="Enter tag"
              type="text"
              value={this.state.editText}
              autoFocus
              onBlur={this.handleAddOnBlur}
              onChange={this.handleAddOnChange}
              onKeyDown={this.handleAddKeyPress}
            />
          </div>
        )}
        {this.props.header && this.props.tagList.length === 0 && !this.state.isAddingTag && (
          <h5 className="ui header" style={{ display: 'inline-block', margin: '15px 0 0 0' }}>
            {this.props.header}
          </h5>
        )}
        {!this.state.isAddingTag && !this.props.readOnly && (
          <div style={{ display: 'inline-block', marginLeft: '0.5rem', marginTop: '15px', cursor: 'pointer' }}>
            {/* eslint-disable-next-line  */}
            <a onClick={this.handleAddBegin}>
              <i className="large green add icon circle" />
            </a>
          </div>
        )}
      </div>
    );
  }
}
