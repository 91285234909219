import { TagList } from 'components/tags/TagList';
import { Content } from '@visikon/core-models';
import moment from 'moment';
import React from 'react';
import { Button, Icon, Input, InputOnChangeData, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { ObjectId } from '@visikon/core-models/base';
import { VideoFile } from './VideoFile';
import { FilePickerButton } from '../../FilePickerButton';
import { Subtitle } from './Subtitles';
import { API } from '../../../config';
import { useGetIdByTypeAndName } from '../../../api/contentApi';
import { downloadFile } from '../../../commons/download-file';
import { getTranslationToUse } from '../utils';

export type VideoViewSize = 'large' | 'normal' | 'small';

interface IProps {
  onTagAdded: (tag: Content.Tag) => void;
  onTagRemoved: (tag: Content.Tag) => void;
  onClick?: () => void;
  onTitleChange: (title: string) => void;
  onUploadSubtitles: (video: Content.Video, language: Content.LanguageCode, variation: ObjectId, file: File) => void;
  video: Content.Video;
  language: Content.LanguageCode;
  mediaVariation: ObjectId;
  readOnly?: boolean;
  size?: VideoViewSize;
  showControls?: boolean;
  showThumbnail?: boolean;
  id?: string;
}

interface IState {
  isEditingTitle: boolean;
  editTitle: string;
}

export class VideoView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isEditingTitle: false,
      editTitle: props.video.name,
    };

    this.getCardWidth = this.getCardWidth.bind(this);

    this.onTitleEdit = this.onTitleEdit.bind(this);
    this.onTitleEditConfirm = this.onTitleEditConfirm.bind(this);
    this.onTitleEditCancel = this.onTitleEditCancel.bind(this);
    this.onTitleKeyPress = this.onTitleKeyPress.bind(this);
  }

  getCardWidth() {
    if (!this.props.size) {
      return undefined;
    }

    switch (this.props.size) {
      case 'small':
        return 250;
      case 'normal':
        return 400;
      case 'large':
        return 600;
      default:
        return 400;
    }
  }

  onTitleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      this.onTitleEditConfirm();
    }
  }

  onTitleEdit(_: any, data: InputOnChangeData) {
    this.setState({ editTitle: data.value });
  }

  onTitleEditCancel() {
    this.setState({ isEditingTitle: false, editTitle: this.props.video.name });
  }

  onTitleEditConfirm() {
    this.props.onTitleChange(this.state.editTitle);
    this.setState({ isEditingTitle: false });
  }

  render() {
    const { video, onClick, readOnly, showControls, showThumbnail, language, mediaVariation, onTagAdded, onTagRemoved, id, onUploadSubtitles } =
      this.props;
    const cursor = onClick ? 'pointer' : 'default';
    const width = this.getCardWidth();

    const nameOnClick =
      onClick ||
      (() => {
        this.setState((s) => ({ isEditingTitle: !s.isEditingTitle }));
      });

    const videoTranslation = getTranslationToUse(video, language) as Content.VideoTranslation;
    const videoSubtitles = videoTranslation?.subtitles;

    return (
      <div className="card" style={{ width }}>
        <div className="video" onClick={onClick} style={{ cursor, minHeight: 150 }}>
          <VideoFile
            id={id}
            showThumbnail={showThumbnail}
            showControls={showControls}
            width={width}
            video={video}
            language={language}
            subtitles={videoSubtitles}
          />
        </div>
        <div className="content" style={{ position: 'relative' }}>
          {this.state.isEditingTitle && (
            <div className="header">
              <Input
                action
                type="text"
                size="mini"
                placeholder="Name"
                style={{ width: '100%' }}
                value={this.state.editTitle}
                onChange={this.onTitleEdit}
                onKeyPress={this.onTitleKeyPress}
              >
                {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
                <input autoFocus />
                <Button positive icon="checkmark" onClick={this.onTitleEditConfirm} />
                <Button negative icon="close" onClick={this.onTitleEditCancel} />
              </Input>
            </div>
          )}
          {!this.state.isEditingTitle && (
            <div className="header" onClick={nameOnClick} style={{ cursor: 'pointer' }}>
              {video.name}
              {onClick === undefined && <Icon style={{ marginLeft: 10 }} name="pencil" size="small" color="grey" />}
            </div>
          )}
          <div className="meta" onClick={onClick} style={{ cursor }}>
            {/* Show fromNow() time with actual timestamp in popup */}
            <Popup
              trigger={<TimeStamps {...{ video, language }} />}
              content={<div>{moment(video.createdAt).format('LLL')})</div>}
              position="top right"
              hideOnScroll
              mouseEnterDelay={500}
            />
          </div>
          <div className="description">
            <TagList readOnly={readOnly} onTagAdded={onTagAdded} onTagRemoved={onTagRemoved} tagList={video.tags} header="Tags" />
          </div>
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              fontSize: '0.7em',
              lineHeight: '0.7em',
              color: '#0005',
            }}
          >
            NS: {video.namespace ? video.namespace : 'N/A'}
          </div>

          {showControls && (
            <SubtitlesToolbar
              video={{ _id: video._id, name: video.name }}
              language={language}
              subtitles={videoSubtitles}
              onUpload={(file) => {
                onUploadSubtitles(video, language, mediaVariation, file);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

function TimeStamps({ video, language }: { video: Content.Video; language: Content.LanguageCode }) {
  const translation = video.translations.find((t) => t.language === language);
  const created = moment(video.createdAt).fromNow();
  const updated = moment(translation?.lastModified).fromNow();

  const lastUpdated = translation && created !== updated && (
    <span style={{ fontStyle: 'italic', fontSize: '0.8em' }}>
      (Updated
      {moment(translation?.lastModified).format('DD.MM.YYYY hh:mm:ss')})
    </span>
  );

  return (
    <span>
      {created} {lastUpdated || ''}
    </span>
  );
}

const SubtitlesToolbar = React.memo(
  SubtitlesToolbarImpl,
  (prevProps, nextProps) => prevProps.video.name === nextProps.video.name && prevProps.subtitles === nextProps.subtitles,
);

const SubtitlesContainer = styled.div`
  margin-top: 0.5em;
`;

function SubtitlesToolbarImpl(options: {
  video: { _id: string; name: string };
  language: Content.LanguageCode;
  subtitles?: Subtitle[];
  onUpload: (file: File) => void;
}) {
  const { video, language, subtitles = [], onUpload } = options;

  const fileName = subtitles.length > 0 ? subtitles[0].src : undefined;

  return (
    <SubtitlesContainer>
      <div className="header" style={{ display: 'flex' }}>
        <Button
          icon="video"
          title="Download video"
          onClick={() => downloadFile(`${API.baseURL}media/video/file/${video._id}/${language}`, `${video.name}.mp4`)}
        />
        <ManuscriptButton videoName={video.name} />
        <Input action fluid readOnly type="text" size="mini" placeholder="Subtitles" style={{ width: '100%' }} value={fileName}>
          {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
          <input autoFocus />

          <FilePickerButton
            size="mini"
            icon="pencil"
            buttonText="Subtitle file"
            multiple={false}
            accept=".vtt"
            onFilePick={(files) => {
              if (files?.length > 0) onUpload(files[0]);
            }}
          />
        </Input>
      </div>
    </SubtitlesContainer>
  );
}

const ManuscriptButton = ({ videoName }: { videoName: string }) => {
  const { data } = useGetIdByTypeAndName('BlockList', videoName);

  return (
    <>
      {data?.found && (
        <Button
          icon="comment"
          title="Download manuscript"
          onClick={() => downloadFile(`${API.baseURL}blocklist/manuscript/${data.id}`, `${videoName}.txt`)}
        />
      )}
    </>
  );
};
