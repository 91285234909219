import { SearchCategories } from 'components/mediaLibrary/constants';
import { SearchPagination } from 'components/search/search-pagination';
import { Spinner } from 'components/spinner';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { IState } from 'reducers/reducers';
import { languageState } from 'redux-recoil-sync.state';
import { Checkbox, Dimmer, Loader } from 'semantic-ui-react';
import { getView, toggleView, View } from '../blockUtils';
import BlockList from './BlockList';
import { BlockCards } from './components/BlockCards';
import { BlocksHeader } from './components/BlocksHeader';
import { BlocksTable } from './components/BlocksTable';
import useBlockParams from './UseBlockParams';

interface OwnProps {
  useAsPicker?: boolean;
  onClick?: (id: string) => void;
}

export function BlockOverview(props: OwnProps) {
  const [showList, setShowList] = useState(getView() === View.LIST);
  const [search, setSearch] = useState(SearchCategories.All); // use All as default
  const [searchTerm, setSearchTerm] = useState<string>('');
  const location = useLocation();
  const history = useHistory();

  const isUploading: boolean = useSelector((state: IState) => state.blocks.isUploading);
  const [language] = useRecoilState(languageState);

  const [page, setPage] = useState(1); // start on page 1
  const [showArchived, setShowArchived] = useState(false);

  const useQuery = useBlockParams(page, search, searchTerm, showArchived);
  const blocks: BlockList | undefined = useQuery ? useQuery.data : ({} as BlockList);

  const categories = [SearchCategories.All, SearchCategories.Names, SearchCategories.Tags, SearchCategories.Manuscript];

  const handleOnClickBlock = (id: string) => {
    if (props.onClick) {
      props.onClick(id);
    } else {
      history.push({ pathname: `/block/overview/${id}`, state: { background: location } });
    }
  };

  const renderUploadingOverlay = () => {
    if (!isUploading) {
      return null;
    }

    return (
      <Dimmer active inverted>
        <Loader size="small">Doing some hard work. Please wait</Loader>
      </Dimmer>
    );
  };

  const blockList = (): JSX.Element | null => {
    if (!blocks) {
      return null;
    }
    return showList ? (
      <BlocksTable items={blocks.entries} language={language} onClick={handleOnClickBlock} />
    ) : (
      <BlockCards items={blocks.entries} language={language} onClick={handleOnClickBlock} />
    );
  };

  return (
    <div>
      {renderUploadingOverlay()}
      {!blocks ? null : (
        <div>
          <BlocksHeader
            showList={showList}
            useAsPicker={props.useAsPicker}
            onCreateBlock={() => history.push(`/block/overview/new`)}
            onToogleList={() => {
              setShowList(!showList);
              toggleView();
            }}
          >
            <div>
              <Checkbox
                label="Show archive"
                checked={showArchived}
                onChange={() => setShowArchived(!showArchived)}
                style={{ marginBottom: 10 }}
                toggle
              />
              <SearchPagination
                activePage={page}
                categories={categories}
                onCategoryChange={(cat) => {
                  setSearch(cat as SearchCategories);
                  setPage(1);
                }}
                onPageChange={(pageNumber) => setPage(pageNumber)}
                onSearch={(term) => {
                  setPage(1);
                  setSearchTerm(term);
                }}
                totalPages={blocks.pages}
              />
              {!useQuery.isFetching && <div>Found {`${blocks?.count} blocks - Showing ${blocks?.entries.length} `}</div>}
            </div>
          </BlocksHeader>

          {useQuery.isFetching ? <Spinner visible={useQuery.isFetching} /> : blockList()}
        </div>
      )}
    </div>
  );
}
