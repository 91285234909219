import { Content } from '@visikon/core-models';
import { LanguageCode } from '@visikon/core-models/content';
import { useToggle } from 'commons/toggle';
import * as Semantic from 'semantic-ui-react';
import { BlockListPlayer } from '../player/BlockListPlayer';
import { BlockListThumbnail } from './Thumbnail.modal';
import * as Styles from './Thumbnail.styles';
import { CompositionBlock } from '@visikon/core-models/models/composer/blocks';

type Props = {
    language: LanguageCode,
    compositionId: string | undefined,
    name: string,
    composition: Content.BlockList | undefined,
    selectedBlocks: CompositionBlock[],
    thumbnail?: string,
    audioDelay: number,
    onChange: (file: string) => void,
};

export function ThumbnailMenuItem(props: Props) {
    const { language, compositionId, name, composition, selectedBlocks, thumbnail, audioDelay, onChange } = props;

    const [modalVisible, toggleModal] = useToggle();

    return (
        <>
            {compositionId && composition && modalVisible &&
                <BlockListThumbnail
                    openThumbnailModal={modalVisible}
                    onOpenModal={toggleModal}
                    onCloseModal={toggleModal}
                    oncloseModalSuccess={toggleModal}
                    blockListId={compositionId}
                    lang={language}
                    listName={name}
                    thumbnail={thumbnail}
                    onChange={onChange}
                >
                    <Styles.VideoPreviewArea>
                        <BlockListPlayer
                          blockList={selectedBlocks}
                          lang={language}
                          audioDelay={audioDelay}
                          thumbnail={thumbnail}
                        />
                    </Styles.VideoPreviewArea>
                </BlockListThumbnail>
            }
            <Semantic.Dropdown.Item
                icon="image"
                text="Thumbnail"
                onClick={toggleModal}
            />
        </>
    );
}
