import axios from 'axios';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { APIUtils } from '../commons/utils';
import { tokenState } from '../redux-recoil-sync.state';
import { URLS } from './urls';
import { QueryConfig } from './react-query-utils';
import { useSocketQueryInvalidation } from 'commons/sockets';

type Response = {
  lastModified?: number;
};

const QUERY_KEY = 'last-modified';

async function queryForLastModified(id?: string, token?: string) {
  const result = await axios.get<Response>(URLS.lastModified.get(id!), APIUtils.tokenHeader(token!, { 'x-polling': true })).then((res) => res.data);

  return result;
}

export function useGetLastModified(id?: string) {
  const [token] = useRecoilState(tokenState);
  const queryKey = [QUERY_KEY, id];

  useSocketQueryInvalidation(id, QUERY_KEY);

  return useQuery<Response, Error>(
    queryKey,
    async () => {
      const result = await queryForLastModified(id, token);
      return result;
    },
    {
      ...QueryConfig,
      enabled: !!token && !!id,
      staleTime: 2 * 60 * 60 * 1000, // 2 hours
      cacheTime: 0, // We wan't to refetch every time this query becomes active again
      refetchOnWindowFocus: true,
    },
  );
}
