import styled from 'styled-components';
import { DurationProps } from './EncodeNewDurations';

export const Duration = styled.div<DurationProps>`
  width: ${props => props.width}px;
  height: 14px;
  opacity: ${props => props.opacity || 0.4};
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: grey;
  border: 1px solid darkgray;
`;

export const DelayDuration = styled(Duration)`
  border: 1px dotted black;
  background: initial;
  opacity: initial;
`;

export const MissingMedia = styled.div`
  width: 100%;
  text-align: center;
  color: red;
  font-size: x-small;
`;
