import styled from 'styled-components';

export const EditorPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const EditorTranslateWrapper = styled.div<{ singleMode: boolean }>`
  width: ${(p) => (p.singleMode ? '1070px' : '100%')};
  // width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
`;

export const EditorContainer = styled.div<{ readOnly?: boolean; translateMode?: boolean }>`
  margin-top: 30px;
  padding: 10px;
  flex: 1;
  border: 1px solid #bfbebf;
  // -webkit-box-shadow: 0px 0px 10px 0px #cacaca;
  // -moz-box-shadow: 0px 0px 10px 0px #cacaca;
  box-shadow: 0px 0px 10px 0px #cacaca;
  background-color: ${(props) => (props.readOnly ? '#f3f3f3' : 'white')};
  .editor {
    min-height: 600px;
    padding: 20px;

    &.readOnly {
      color: #383838;
      cursor: default;
    }

    ${(p) =>
      p.translateMode &&
      `
            > * {
                border: 1px solid #b7b7b7;
            }

            > *:focus {
                border: 1px solid #000;
                outline: 0px solid transparent;
            }
        `}
  }
`;

export const TranslateContainer = styled.div`
  flex: 1;
  margin-top: 30px;
  padding: 30px;
  min-height: 600px;
  border: 1px solid #bfbebf;
  box-shadow: 0px 0px 10px 0px #cacaca;
`;

export const ToolbarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  min-height: 78px;

  * .ui.buttons {
    // Button groups
    margin-right: 5px;
    background-color: #e8e8e8;

    &.active {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
