import * as Semantic from 'semantic-ui-react';
import { useCopyComposition } from 'api/compositionApi';
import { FormEvent, useRef } from 'react';

interface Props {
  compositionId: string;
  compositionName?: string;
  onClose(): void;
  onSuccess?(blockListId: string): void;
}

export const CompositionCopyModal = ({ compositionId, compositionName, onClose, onSuccess }: Props) => {
  // State
  const nameRef = useRef<HTMLInputElement>(null);
  const copyMutation = useCopyComposition(compositionId);
  const isCopying = copyMutation.isLoading;

  const handleCopy = async (ev: FormEvent) => {
    ev.preventDefault();

    const name = nameRef.current!.value;
    const newComposition = await copyMutation.mutateAsync(name);
    onSuccess?.(newComposition._id);
    onClose();
  };

  return (
    <Semantic.Modal as={Semantic.Form} open onClose={onClose} onSubmit={handleCopy}>
      <Semantic.Modal.Header>
        Create copy
        {compositionName && `of '${compositionName}'`}
      </Semantic.Modal.Header>
      <Semantic.Modal.Content>
        <Semantic.Form.Field>
          <label>Name of copy</label>
          {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
          <input required autoFocus placeholder="Name of copy" defaultValue={compositionName} ref={nameRef} />
        </Semantic.Form.Field>
      </Semantic.Modal.Content>

      <Semantic.Modal.Actions>
        <Semantic.Button color="black" onClick={onClose} disabled={isCopying}>
          Cancel
        </Semantic.Button>
        <Semantic.Button type="submit" content="Alright, copy!" labelPosition="right" icon="checkmark" positive disabled={isCopying} />
        {isCopying && (
          <Semantic.Dimmer active inverted>
            <Semantic.Loader>Copying...</Semantic.Loader>
          </Semantic.Dimmer>
        )}
      </Semantic.Modal.Actions>
    </Semantic.Modal>
  );
};
