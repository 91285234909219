import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Icon, Menu } from 'semantic-ui-react';

export const UtilitiesSubMenu = ({ path }: { path: string }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const utilities = [{ name: 'Manuscripts for speaks', url: '/block/speakManuscripts' }];
  const active = (index: number) => path.startsWith(utilities[index].url);

  useEffect(() => {
    const isAnyUtilityOpen = utilities.filter((item) => path.startsWith(item.url)).length === 0;

    if (isAnyUtilityOpen) {
      setActiveIndex(-1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const handleClick = (e: React.MouseEvent, titleProps: { index: number }) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  return (
    <Accordion>
      <Accordion.Title active={activeIndex === 0} index={0} onClick={handleClick}>
        <Menu.Item>
          <Icon name="dropdown" />
          Utilities
        </Menu.Item>
      </Accordion.Title>
      {utilities.map((item, index) => (
        <Accordion.Content key={index} active={activeIndex === 0} style={{ display: activeIndex === 0 ? 'contents' : 'none' }}>
          <Menu.Item as={Link} to={utilities[index].url} style={{ paddingLeft: '2.2em' }} active={active(index)}>
            {utilities[index].name}
          </Menu.Item>
        </Accordion.Content>
      ))}
    </Accordion>
  );
};
