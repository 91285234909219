import { connect, MapDispatchToProps } from 'react-redux';
import { Content } from '@visikon/core-models';
import { IState } from 'reducers/reducers';
import { LanguageSelector } from 'components/LanguageSelector';
import { changeLanguage } from 'actions/LanguageActions';
import styled from 'styled-components';

const LanguageSelectorWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 5px;
    margin-right: 15px;

    @media print {
        display: none;
    }
`;

interface IPropsFromState {
  language: Content.LanguageCode;
}
interface IPropsFromDispatch {
  changeLanguage: (lang: Content.LanguageCode) => void;
}
type IProps = IPropsFromState & IPropsFromDispatch;

function CCMSLanguageSelectContainer(props: IProps) {
  return (
    <LanguageSelectorWrapper>
      <LanguageSelector activeLang={props.language} onLanguageChanged={props.changeLanguage} />
    </LanguageSelectorWrapper>
  );
}

const mapStateToProps: (state: IState) => IPropsFromState = (state) => ({
  language: state.language.activeLanguage,
});

const mapDispatchToProps: MapDispatchToProps<IPropsFromDispatch, {}> = (dispatch) => ({
  changeLanguage: (lang: Content.LanguageCode) => {
    dispatch(changeLanguage(lang));
  },
});

export const CMSLanguageSelectContainer = connect(mapStateToProps, mapDispatchToProps)(CCMSLanguageSelectContainer);
