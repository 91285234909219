import React, { CSSProperties } from 'react';
import { fromEvent } from 'file-selector';
import {
  Button, SemanticICONS, Icon, SemanticCOLORS,
} from 'semantic-ui-react';
import { SemanticSIZES } from 'semantic-ui-react/dist/commonjs/generic';
import { logger } from '../commons/logger';

interface IProps {
  onFilePick: (files: File[]) => void;
  buttonText?: string;
  style?: CSSProperties;
  accept?: string;
  multiple?: boolean;
  disabled?: boolean;
  icon?: SemanticICONS;
  size?: SemanticSIZES;
  color?: SemanticCOLORS;
  primary?: boolean;
}

export class FilePickerButton extends React.Component<IProps, {}> {
  fileInput: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);

    this.fileInput = React.createRef();

    this.onDrop = this.onDrop.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
    this.onFileInputChange = this.onFileInputChange.bind(this);
  }

  onDrop(files: File[]) {
    this.props.onFilePick(files);
  }

  onButtonClick() {
    if (this.fileInput.current) {
      this.fileInput.current.click();
    }
  }

  // From Dropzone source
  // eslint-disable-next-line class-methods-use-this
  isEvtWithFiles(event: any) {
    if (!event.dataTransfer) {
      return !!event.target && !!event.target.files;
    }
    // https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/types
    // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Recommended_drag_types#file
    return Array.prototype.some.call(
      event.dataTransfer.types,
      (type: string) => type === 'Files' || type === 'application/x-moz-file',
    );
  }

  async onFileInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!this.isEvtWithFiles(event)) {
      return;
    }
    const files = await fromEvent(event.nativeEvent);
    // Add preview
    for (const file of files) {
      try {
        // @ts-ignore
        (file as any).preview = window.URL.createObjectURL(file);
      } catch (e) {
        logger.warn("Couldn't generate preview");
      }
    }
    this.props.onFilePick(files as any);
  }

  render() {
    const icon = this.props.icon ? <Icon name={this.props.icon} /> : undefined;
    return (
      <>
        <Button style={this.props.style} size={this.props.size} color={this.props.color} primary={this.props.primary} onClick={this.onButtonClick}>
          {icon && icon}
          {this.props.buttonText || 'Pick file'}
        </Button>
        <input
          title="File Input"
          type="file"
          ref={this.fileInput}
          accept={this.props.accept}
          multiple={this.props.multiple}
          style={{ display: 'none' }}
          onChange={this.onFileInputChange}
        />
      </>
    );
  }
}
