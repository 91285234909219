import { useRef, useState } from 'react';
import { Button, Modal, ModalProps } from 'semantic-ui-react';
import { PLACEHOLDER_IMAGE } from 'commons/utils';
import { VideoFile } from './VideoFile';
import { VideoDetailsProps, IState } from './VideoDetails';

interface ThumbnailFramePickerProps extends ModalProps {
  onUploadThumbnail: VideoDetailsProps['onUploadThumbnail'];
  video: VideoDetailsProps['video'];
  language: IState['activeLanguage'];
  variationId: IState['activeVariation']['_id'];
}
export function ThumbnailFramePickerModal({ onUploadThumbnail, video, language, variationId, ...modalProps }: ThumbnailFramePickerProps) {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [dataURL, setDataURL] = useState<string>();
  const buttonStyle = { verticalAlign: 'top', marginLeft: 15 };
  const previewRef = useRef<HTMLImageElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const dataURItoBlob = (dataURI: string) => {
    const binary = atob(dataURI.split(',')[1]);

    // writing the bytes of the string to an ArrayBuffer
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/png' });
  };

  const onSaveVideoFrame = () => {
    const blob = dataURItoBlob(dataURL!);
    const file = new File([blob], `${video.name}.png`, { type: 'image/png', lastModified: new Date().getTime() });

    onUploadThumbnail(language, variationId, file);
  };

  const captureFrame = () => {
    const videoElm = videoRef.current;
    const imgFromCanvas = previewRef.current;

    if (!videoElm || !imgFromCanvas) {
      // TODO: Handle
      return;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = videoElm.videoWidth * 0.5;
    canvas.height = videoElm.videoHeight * 0.5;
    // var hRatio = sa / video.videoWidth;
    // var vRatio = sm / video.videoHeight;
    // var ratio = Math.min(hRatio, vRatio);
    if (!ctx) {
      // TODO: Handle
      return;
    }

    ctx.drawImage(videoElm, 0, 0, canvas.width, canvas.height);
    const newDataURL = canvas.toDataURL('image/png', 'quality');

    imgFromCanvas.src = newDataURL;
    setDisabled(false);
    setDataURL(newDataURL);
  };

  return (
    <Modal {...modalProps}>
      <Modal.Header>Grab a thumbnail from the video</Modal.Header>

      <Modal.Content scrolling style={{ minHeight: 600 }}>
        <div style={{ display: 'flex' }}>
          <VideoFile videoRef={videoRef} id={video._id} showThumbnail={false} showControls width={600} video={video} language={language} />
        </div>

        <h3 style={{ color: 'grey' }}>Video frame</h3>
        <div style={{ display: 'inline-block', position: 'relative' }}>
          <img ref={previewRef} alt="" className="medium ui image" id="img_from_canvas" src={PLACEHOLDER_IMAGE} />
        </div>
        <div style={{ ...buttonStyle, display: 'inline-block', width: 285 }}>
          <Button className="ui button" onClick={captureFrame}>
            Capture frame
          </Button>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button positive content="Save" onClick={onSaveVideoFrame} disabled={disabled} />
      </Modal.Actions>
    </Modal>
  );
}
