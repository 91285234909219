import axios from 'axios';
import { API } from 'config';
import { APIUtils } from 'commons/utils';
import {
  ICreateBatchType,
  ICreateNamedUserType,
  ICreateNamespace,
  ICreateKey,
  IDeleteNamespace,
  IEditNamespace,
  ICreateCMSUser,
  ListCMSUsers,
  IDeleteCMSUser,
} from 'actions/UserActions';
import { CMSUser, EditProfileData } from '@visikon/core-models/user';

export const getUsersInfo = (token: string) => {
  const url = new URL('./users/info', API.baseURL);
  return axios.get(url.toString(), APIUtils.tokenHeader(token));
};

// export const listNamedUsers = (token: string) => {
//   const url = new URL('./users/named', API.baseURL);
//   return axios.get(url.toString(), APIUtils.tokenHeader(token));
// };

export const createBatch = (token: string, batch: ICreateBatchType) => {
  const url = new URL('./users/createBatch', API.baseURL);
  return axios.post(url.toString(), batch, APIUtils.tokenHeader(token));
};

export const createNamedUser = (token: string, user: ICreateNamedUserType) => {
  const url = new URL('./users/named', API.baseURL);
  return axios.post(url.toString(), user, APIUtils.tokenHeader(token));
};

// New request to backend - list all namespaces
export const listAllNamespaces = (token: string) => {
  const url = new URL('./users/listNamespaces', API.baseURL);
  return axios.get(url.toString(), APIUtils.tokenHeader(token));
};

// Create new namespace
export const createNamespace = (token: string, namespace: ICreateNamespace) => {
  const url = new URL('./users/createNamespace', API.baseURL);
  return axios.post(url.toString(), namespace, APIUtils.tokenHeader(token));
};

// Create new key
export const createSpecificKey = (token: string, key: ICreateKey) => {
  const url = new URL('./users/createSpecificApiKey', API.baseURL);
  return axios.post(url.toString(), key, APIUtils.tokenHeader(token));
};

// Delete namespace
export const deleteNamespace = (token: string, namespace: IDeleteNamespace) => {
  const url = new URL('./users/deleteANamespace', API.baseURL);
  return axios.post(url.toString(), namespace, APIUtils.tokenHeader(token));
};

export const updateNamespace = (token: string, namespace: IEditNamespace) => {
  const url = new URL('./users/updateNamespace', API.baseURL);
  return axios.post(url.toString(), namespace, APIUtils.tokenHeader(token));
};

export const createCMSUser = (token: string, cmsUser: ICreateCMSUser) => {
  const url = new URL('./users/createAdminUser', API.baseURL);
  return axios.post(url.toString(), cmsUser, APIUtils.tokenHeader(token));
};

export const listCMSUsers = (token: string, cmsUser: ListCMSUsers) => {
  const url = new URL('./users/listCMSUsers', API.baseURL);
  return axios.post(url.toString(), cmsUser, APIUtils.tokenHeader(token));
};

export const deleteCMSUser = (token: string, cmsUser: IDeleteCMSUser) => {
  const url = new URL('./users/deleteCMSUser', API.baseURL);
  return axios.post(url.toString(), cmsUser, APIUtils.tokenHeader(token));
};

export const editCMSUser = (token: string, cmsUser: CMSUser) => {
  const url = new URL('./users/editCMSUser', API.baseURL);
  return axios.post(url.toString(), cmsUser, APIUtils.tokenHeader(token));
};

export const editUser = (token: string, data: EditProfileData) => {
  const url = new URL('./users/editUser', API.baseURL);
  return axios.post(url.toString(), data, APIUtils.tokenHeader(token));
};

export const getCMSUser = (token: string) => {
  const url = new URL('./users/getCMSUser', API.baseURL);
  return axios.get(url.toString(), APIUtils.tokenHeader(token));
};

export const generateTwoFactorAuth = (token: string) => {
  const url = new URL('./users/setupTFA', API.baseURL);
  return axios.post(url.toString(), undefined, APIUtils.tokenHeader(token));
};

export const turnOnTwoFactorAuth = (token: string, tfaToken: string) => {
  const url = new URL('./users/turnOnTFA', API.baseURL);
  return axios.post(url.toString(), { tfaToken }, APIUtils.tokenHeader(token));
};

export const editReviewer = (token: string, id: string, reviewer: boolean) => {
  const url = new URL('./editReviewer', API.baseURL);
  return axios.post(url.toString(), { id, reviewer }, APIUtils.tokenHeader(token));
};
