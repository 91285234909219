import React from 'react';
import { Content } from '@visikon/core-models';
import { ERROR_IMAGE } from 'commons/utils';
import { API } from 'config';
import { ObjectId } from '@visikon/core-models/base';

interface IProps {
  width?: number;
  height?: number;
  className?: string;
  style?: React.CSSProperties;
  video: Content.Image;
  language: Content.LanguageCode;
  variation: ObjectId;
  lastModified: number;
}
interface ILocalState {
  url: string | undefined;
  showOverlay: boolean;
  showFlag?: string;
}

/**
 * This class loads an image file (in base64) from the server,
 * using authentication.
 */
export class VideoThumbnailFile extends React.Component<IProps, ILocalState> {
  constructor(props: IProps) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { url: undefined, showOverlay: false };
    this.loadImage = this.loadImage.bind(this);
  }

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate(oldProps: IProps) {
    if (JSON.stringify(oldProps.video) !== JSON.stringify(this.props.video)) {
      this.loadImage();
    }
  }

  loadImage() {
    const {
      video, language, variation, lastModified,
    } = this.props;
    const url = `${API.baseURL}media/videoThumbnail/${video._id}/${language}/${variation}?modified=${lastModified}`;
    this.setState({ url });
  }

  render() {
    return (
      <img alt="" className={this.props.className} src={this.state.url} style={this.props.style} onError={() => { this.setState({ url: ERROR_IMAGE }); }} />
    );
  }
}
