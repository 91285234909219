import styled from 'styled-components';

export const ManusPartContainer = styled.div<{ highlighted: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 5px;
  border-bottom: 1px dotted #aaa;
  cursor: grab;
  background-color: ${(p) => (p.highlighted ? '#ccc' : 'inherit')};
  user-select: none;
`;

export const LabelContainer = styled.div`
    padding-top: 2px;
    padding-right: 5px;
`;

export const NoSpeak = styled.div`
  font-style: italic;
  color: grey;
`;
