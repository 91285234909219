import { Content } from '@visikon/core-models';
import { Block, LanguageCode } from '@visikon/core-models/content';
import { PLACEHOLDER_VIDEO } from 'commons/utils';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Button, Confirm, Icon, Label, Popup, SemanticCOLORS } from 'semantic-ui-react';
import { getBlockResourceUrl } from '../../blockResourceSelectors';
import { checkIfImageExists } from './player/BlockListPlayer';
import * as Styles from './TimelineCard.styles';
import { matchLanguageOrFallback } from '@visikon/core-models/helpers/language-fallback';

interface IProps {
  block: Block;
  blockKey: string;
  videoVariation?: string;
  speakVariation?: string;
  lang: Content.LanguageCode;
  isHighlighted: boolean;
  audioDelay: number;
  index: number;
  onHover(id?: string): void;
  onVariationChange(type: 'video' | 'speak', blockKey: string, variation: string): void;
  onEditClick(block: Block): void;
  onDeleteBlock(key: any): void;
}
const BlockMissing = (isHighlighted: boolean) => (
  <Styles.BlockWrapper highlighted={isHighlighted}>
    <div
      style={{
        minHeight: 150,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Icon name="warning sign" color="red" size="big" />
      Block missing
    </div>
  </Styles.BlockWrapper>
);

export const TimelineCard = ({
  block,
  blockKey,
  videoVariation,
  speakVariation,
  lang,
  isHighlighted,
  audioDelay,
  onHover,
  onVariationChange,
  onEditClick,
  onDeleteBlock,
  index
}: IProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [showPosterURL, setPosterURL] = useState('');
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);

  const matchingVideo = matchLanguageOrFallback(block.videos, lang, videoVariation);
  const videoSrc = getBlockResourceUrl(matchingVideo, block)

  useEffect(() => {
    if (ref.current) {
      const elm = ref.current;
      elm.addEventListener('mouseenter', onMouseEnter);
      elm.addEventListener('mouseleave', onMouseLeave);
      return () => {
        elm.removeEventListener('mouseenter', onMouseEnter);
        elm.removeEventListener('mouseleave', onMouseLeave);
      };
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  useEffect(() => {
    if (videoSrc) {
      checkIfImageExists(videoSrc, (exists) => {
        if (exists) {
          setPosterURL(videoSrc);
        }
      });
    }
  }, [videoSrc]);

  if (!block) {
    return BlockMissing(isHighlighted);
  }

  function onMouseEnter() {
    onHover(blockKey);
  }
  function onMouseLeave() {
    onHover(undefined);
  }

  let img;
  if (videoSrc) {
    img = <Styles.VideoPreview preload="auto" src={videoSrc} poster={showPosterURL} controls={false} autoPlay={false} />;
  } else {
    img = (
      <div
        style={{
          height: 135,
          width: '100%',
          background: `url(${PLACEHOLDER_VIDEO})`,
          backgroundSize: 'cover',
          backgroundPositionX: 'center',
          backgroundPositionY: 'center',
        }}
      />
    );
  }

  const getVariations = (withVariations: Array<{variations: string[]}>) => {
    const dropdownItems = withVariations.map((v) => v.variations[0]);
    const deDuplified = new Set(dropdownItems);

    return Array.from(deDuplified.values()).map(v => ({key: v, text: v, value: v}));
  }

  const videoVariationOptions = getVariations(block.videos);
  const speakVariationOptions = getVariations(block.speaks);

  function renderSettingsButton() {
    const icon = showSettings ? 'times' : 'cog';
    return (
      <Styles.SettingsButtonHolder onClick={() => setShowSettings(!showSettings)}>
        <Icon circular name={icon} disabled={showSettings} />
      </Styles.SettingsButtonHolder>
    );
  }

  function renderEditButton() {
    return (
      <Styles.SettingsButtonHolder pos={1} onClick={() => onEditClick(block)}>
        <Icon circular name="pencil" />
      </Styles.SettingsButtonHolder>
    );
  }

  function renderVariationOptionContent(type: 'video' | 'speak', arr: Array<{ value: string; text: string }>) {
    return (
      <div style={{ textAlign: 'center' }}>
        {arr.map((v, i) => (
          <Button
            key={i}
            style={{ marginBottom: 5 }}
            basic
            fluid
            onClick={() => {
              onVariationChange(type, blockKey, v.value);
            }}
          >
            {v.text}
          </Button>
        ))}
      </div>
    );
  }

  function renderSettings() {
    if (!showSettings) {
      return null;
    }

    const customBlockName = (
      <p style={{ padding: '1.5rem' }}>
        Are you sure you want to delete this block: <b>{block.name}</b>
      </p>
    );

    return (
      <Styles.SettingsWrapper>
        <span>
          Video variation:{' '}
          <Popup
            content={renderVariationOptionContent('video', videoVariationOptions)}
            on="click"
            trigger={<Button basic size="mini" content={videoVariation} />}
          />
        </span>
        <span style={{ marginTop: 10 }}>
          Speak variation:{' '}
          <Popup
            content={renderVariationOptionContent('speak', speakVariationOptions)}
            on="click"
            trigger={<Button basic size="mini" content={speakVariation} />}
          />
        </span>
        <Styles.TrashContainer>
          <Button onClick={() => setConfirmModalVisible(true)} icon="trash alternate outline" size="small" negative />
          <Confirm
            open={confirmModalVisible}
            content={customBlockName}
            onCancel={() => setConfirmModalVisible(false)}
            onConfirm={() => {
              onDeleteBlock(blockKey);
              setConfirmModalVisible(false);
            }}
          />
        </Styles.TrashContainer>
      </Styles.SettingsWrapper>
    );
  }

  function renderAlert() {
    if (showSettings) {
      return null;
    }

    const hasIncorrectVideoVariation = videoVariationOptions.find((v) => v.value === videoVariation) === undefined;
    const hasIncorrectSpeakVariation = speakVariationOptions.find((v) => v.value === speakVariation) === undefined;

    if (hasIncorrectSpeakVariation || hasIncorrectVideoVariation) {
      return (
        <Styles.AlertWrapper>
          <Icon name="warning sign" />
        </Styles.AlertWrapper>
      );
    }

    return null;
  }

  const missingVideos = block.videos.length === 0;
  const activeVideoVariation = block.videos.filter((v) => v.variations[0] === videoVariation)[0];
  const videoVariationStatus = activeVideoVariation?.status || '';
  const videoHasPlaceholder = activeVideoVariation?.videoFile?.contentType.split('/').includes('image');
  const statusColor = (missingVideos || videoHasPlaceholder ? 'YELLOW' : videoVariationStatus).toLowerCase() as SemanticCOLORS;

  function renderVideoStatus(color: SemanticCOLORS) {
    return (
      <div style={{ position: 'absolute' }}>
        <Label color={color}>
          <Icon name="video" style={{ margin: 0 }} />
        </Label>
      </div>
    );
  }

  return (
    <Styles.BlockWrapper ref={ref} highlighted={isHighlighted && !showSettings}>
      <BlockDurations block={block} videoVariation={videoVariation} speakVariation={speakVariation} audioDelay={audioDelay || 0} language={lang} />
      {renderAlert()}
      {renderSettingsButton()}
      {renderEditButton()}
      {renderSettings()}
      {videoVariationStatus && renderVideoStatus(statusColor)}
      {img}
      <div>{index}. {block.name}</div>
    </Styles.BlockWrapper>
  );
};

function BlockDurations({ block, videoVariation, speakVariation, audioDelay, language }: { block: Content.Block, videoVariation?: string, speakVariation?: string, audioDelay: number, language: LanguageCode }) {
  const durationInMillis = (duration?: number) => (!duration) ? 0 : Math.round(duration * 1000);

  const blockVideo = matchLanguageOrFallback(block.videos, language, videoVariation);
  const videoDuration = durationInMillis(blockVideo?.videoFile?.duration);

  const blockSpeak = matchLanguageOrFallback(block.speaks, language, speakVariation);
  const speakDuration = durationInMillis(blockSpeak?.audioFile?.duration);
  const speakDurationWithDelay = speakDuration && speakDuration + audioDelay;

  if (videoDuration === 0 && speakDuration === 0) {
    return null;
  }

  const onePct = (videoDuration > speakDurationWithDelay ? videoDuration : speakDurationWithDelay) / 100;

  return (
    <Styles.DurationWrapper>
      <BlockDuration duration={videoDuration} onePct={onePct} />
      <BlockDuration duration={speakDuration} onePct={onePct} delay={audioDelay} />
    </Styles.DurationWrapper>
  );
}

function BlockDuration({ duration, onePct, delay }: { duration: number, onePct: number, delay?: number }) {
  const durationPct = duration / onePct;
  const durationStr = duration >= 0 ? moment.utc(duration).format('mm:ss') : '';

  return (<div style={{display: 'flex'}}>
    {!!delay && !!duration &&
      <Styles.Duration width={delay / onePct} opacity={0.6} />
    }
    <Styles.Duration width={durationPct}>
      {!!duration && durationStr}
    </Styles.Duration>
  </div>);
}
