import { EditMode } from 'components/documents/EditorPage';
import { Expire } from 'components/Expire';
import { TagList } from 'components/tags/TagList';
import { Content, TypeUtils, Users } from '@visikon/core-models';
import { Link } from 'react-router-dom';
import { Button, Icon, Message } from 'semantic-ui-react';

export type AlertMessage = {
  text: any;
  type: 'success' | 'error' | 'warning' | 'info';
}
export const MessageHandling = ( message: AlertMessage, hideMessage: () => void ) => {
    return (
      <Expire
        delay={3000}
        onHide={() => {
          hideMessage();
        }}
      >
        <Message
          className="slim"
          success={ message.type === "success" }
          error={ message.type === "error" }
          info={ message.type === "info" }
          warning={ message.type === "warning" }
          attached="top"
        >
          {message.text}
        </Message>
      </Expire>
    );
  return null;
};

export const EditModeButtons = (userType: Users.AnyUserKinds, setEditMode: (edit: EditMode) => void) => {
  if (TypeUtils.hasAccess(userType, TypeUtils.PermissionLevels.EDITOR_PERMISSION_LEVEL)) {
    return (
      <>
        <Button
          onClick={() => {
            setEditMode(EditMode.Default);
          }}
        >
          <Icon name="file text" />
          Normal mode
        </Button>
        <Button
          onClick={() => {
            setEditMode(EditMode.Split);
          }}
        >
          <Icon name="columns" />
          Side-by-side mode
        </Button>
        <Button
          onClick={() => {
            setEditMode(EditMode.Translate);
          }}
        >
          <Icon name="globe" />
          Translate mode
        </Button>
        <br />
        <br />
      </>
    );
  }
  return null;
};

export const Tags = (userType: Users.AnyUserKinds, tags: any, tagAdded: (tag: Content.Tag) => void, tagRemoved: (tag: Content.Tag) => void) => {
  if (!TypeUtils.hasAccess(userType, TypeUtils.PermissionLevels.EDITOR_PERMISSION_LEVEL)) {
    return null;
  }

  return (
    <div style={{ width: 400 }}>
      <TagList header="Tags" tagList={tags} onTagAdded={tagAdded} onTagRemoved={tagRemoved} />
    </div>
  );
};

export const NewTextButton = (onlyPicker: boolean, userType: Users.AnyUserKinds) => {
  if (onlyPicker || !TypeUtils.hasAccess(userType, TypeUtils.PermissionLevels.EDITOR_PERMISSION_LEVEL)) {
    return null;
  }
  return (
    <div style={{flex: 1, width: '200'}}>
      <Link to="/media/textEdit/new">
        <Button positive>
          <Icon name="file outline" />
          New text
        </Button>
      </Link>
    </div>
  );
};
