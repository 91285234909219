import React from 'react';
import { clearApiError, listProgramCodes } from 'actions/ContentActions';
import { Action, CustomType, LanguageCode, MitForlobProgram, ProgramAction } from '@visikon/core-models/content';
import { getTranslation } from '@visikon/core-models/typeUtils';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IState } from 'reducers/reducers';
import { Button, Header, Icon, Input, Message, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { Translation } from '@visikon/core-models/languageTypes';
import { StartCode } from './StartCode';
import { Spinner } from '../../components/spinner';
import { Environment, getEnv } from '../../commons/env';
import { CopyOnClick } from 'components/CopyOnClick';
import { DefaultSite } from '@visikon/core-models/i18n/languages';

const ErrorMessageWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  width: 600px;
  display: flex;
  align-items: center;
  pointer-events: none;
`;

interface IProgramRow {
  id: string;
  name: string;
  subName: string;
  department: string;
  organization: string;
  anatomy?: string;
  logo?: { _idRef: string };
  enableDirectAccess?: boolean;
  startCode?: string;
  archived?: boolean;
}

interface IPropsFromState {
  programs: IProgramRow[];
  programActions?: ProgramAction[];
  activeLanguage: LanguageCode;
  serverError?: string;
}

interface IPropsFromDispatch {
  fetchData(): void;

  clearServerError(): void;
}

interface ILocalState {
  search: string;
}

type IProps = IPropsFromState & IPropsFromDispatch & RouteComponentProps<any>;

class InnerStartCodeOverviewContainer extends React.Component<IProps, ILocalState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      search: '',
    };

    this.renderRow = this.renderRow.bind(this);
    this.renderAlias = this.renderAlias.bind(this);
    this.renderProgram = this.renderProgram.bind(this);
    this.renderError = this.renderError.bind(this);
  }

  componentDidMount() {
    if (this.props.programs === undefined || this.props.programs.length === 0) {
      this.props.fetchData();
    }
  }

  componentDidUpdate(oldProps: IProps) {
    if (oldProps.serverError === undefined && this.props.serverError !== undefined) {
      setTimeout(() => {
        this.props.clearServerError();
      }, 3000);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  actionTranslator(actionType: Action['type'], value: string) {
    switch (actionType) {
      case 'NAVIGATE':
        return `Navigate to route '${value}'`;
      case 'EXPIRATION':
        return `User expires after '${value}' days `;
      case 'EXPIRATION_DATE':
        return `Link expires after '${value}'`;
      case 'VIDEO':
        return `Launch video with id '${value}'`;
      case 'VIDEO_FULLSCREEN':
        return `Launch video with id '${value}' in full screen`;
      case 'TOGGLE':
        return `Toggle the value of '${value}'`;
      default:
        return '';
    }
  }

  descripeActions(actions: Action[] | undefined): JSX.Element {
    if (!actions) {
      return <></>;
    }
    return (
      <div>
        {actions.map((action) => (
          <li>{this.actionTranslator(action.type, action.payload)} </li>
        ))}
      </div>
    );
  }

  renderAlias(startCode: string) {
    const aliases: JSX.Element[] = [];
    this.props.programActions?.forEach((action) => {
      const enviroment = getEnv() === Environment.Prod ? '' : 'qa.';
      const mtDomain = DefaultSite;
      const aliasLink = `https://${enviroment}${mtDomain}/start/${action.alias}`;
      if (action.startCode === startCode) {
        aliases.push(
          <div style={{ paddingBottom: '5px' }}>
            <div style={{ fontWeight: 'bold' }}>{action.alias}</div>
            <CopyOnClick text={aliasLink}>
              <div style={{ fontWeight: 'bold', fontStyle: 'italic', paddingBottom: '2px' }}>
                <Icon name="copy outline" />
                {aliasLink}
              </div>
            </CopyOnClick>
            {this.descripeActions(action.actions)}
          </div>,
        );
      }
    });
    return aliases;
  }

  // eslint-disable-next-line class-methods-use-this
  renderProgram(program: IProgramRow) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          onClick={() => {
            this.props.history.push(`/collections/mitforlob/${program.id}`);
          }}
        >
          <div style={{ fontWeight: 'bold' }}>{program.name}</div>
          <div>{program.subName}</div>
          <div>{program.organization}</div>
          <div>{program.department}</div>
        </div>
        <div style={{ display: 'flex' }}>
          {program.anatomy && (
            <div data-tooltip={program.anatomy as any}>
              <Icon name={program.anatomy as any} size="big" />
            </div>
          )}
          {program.archived && (
            <div data-tooltip="Archived">
              <Icon name="trash" color="red" />
            </div>
          )}
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderRow(program: any) {
    return (
      <Table.Row key={program._id} style={{ verticalAlign: 'top' }}>
        <Table.Cell>{this.renderProgram(program)}</Table.Cell>
        <Table.Cell>{this.renderAlias(program.startCode)}</Table.Cell>
        <CodeCell>
          <StartCode programId={program.id} />
        </CodeCell>
      </Table.Row>
    );
  }

  renderError() {
    if (this.props.serverError === undefined) {
      return null;
    }

    let errorMessage = 'Unexpected error. Try again.';

    if (this.props.serverError === 'code_not_unique') {
      errorMessage = 'Generated code was not unique. Try again.';
    }

    return (
      <Message floating negative size="large">
        <Message.Header>Error</Message.Header>
        <p>{errorMessage}</p>
      </Message>
    );
  }

  render() {
    const { programs, programActions } = this.props;

    if (programs === undefined || programs.length === 0) {
      return <Spinner text="Loading..." visible />;
    }

    const filteredPrograms = programs.filter((program) => {
      const search = this.state.search.toLowerCase();
      if (search === '') {
        return true;
      }
      if (search === 'alias') {
        return program.startCode && programActions?.some((action) => action.startCode === program.startCode);
      }
      if (search === 'startcode') {
        return program.startCode && program.startCode !== '';
      }
      if (search === 'archived') {
        return program.archived;
      }
      if (search === '!archived') {
        return !program.archived;
      }
      if (search === 'male') {
        return program.anatomy === 'male';
      }
      if (search === 'female') {
        return program.anatomy === 'female';
      }
      if (search === 'generic') {
        return program.anatomy === 'generic';
      }
      return (
        program.name?.toLowerCase().includes(search) ||
        program.subName?.toLowerCase().includes(search) ||
        program.department?.toLowerCase().includes(search) ||
        program.organization?.toLowerCase().includes(search) ||
        program.startCode?.toLowerCase().includes(search) ||
        program.anatomy?.toLowerCase().includes(search)
      );
    });

    return (
      <>
        <ErrorMessageWrapper>{this.renderError()}</ErrorMessageWrapper>

        <Header as="h2">
          <Header.Content>
            Start Codes Overview
            <Header.Subheader>Digital code cards</Header.Subheader>
          </Header.Content>
        </Header>

        <Input
          fluid
          size="large"
          value={this.state.search}
          placeholder="Search by name, subname, department, organization, startcode, alias, archived, !archived and anatomy"
          onChange={(e) => this.setState({ search: e.target.value })}
          action={
            <Button
              icon="times"
              onClick={() => {
                this.setState({ search: '' });
              }}
            />
          }
        />

        <Table celled striped selectable collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Program</Table.HeaderCell>
              <Table.HeaderCell>Aliases</Table.HeaderCell>
              <Table.HeaderCell>Start code</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{filteredPrograms.map((d) => this.renderRow(d))}</Table.Body>
        </Table>
      </>
    );
  }
}

function sortRows(programs: IProgramRow[]): IProgramRow[] {
  return [...programs].sort((a, b) => {
    const orgCompare = (a.organization || '').localeCompare(b.organization || '');
    if (orgCompare !== 0) {
      return orgCompare;
    }
    const depCompare = (a.department || '').localeCompare(b.department || '');
    if (depCompare !== 0) {
      return depCompare;
    }
    const nameCompare = (a.name || '').localeCompare(b.name || '');
    if (nameCompare !== 0) {
      return nameCompare;
    }
    const subCompare = (a.subName || '').localeCompare(b.subName || '');
    if (subCompare !== 0) {
      return subCompare;
    }
    return a.id.localeCompare(b.id);
  });
}

const mapStateToProps: MapStateToProps<IPropsFromState, IProps, IState> = (state, props) => {
  const programs = (state.content.data.mitforlobPack ? state.content.data.mitforlobPack : []) as MitForlobProgram[];
  const mappedPrograms = programs
    .map((d) => {
      // @ts-ignore
      const trans = getTranslation(d as MitForlobProgram, props.activeLanguage) as Exclude<Translation<CustomType>, undefined>;
      return {
        ...trans,
        // @ts-ignore
        enableDirectAccess: (d as MitForlobProgram).enableDirectAccess,
        archived: (d as any).archived,
        startCode: (d as any).startCode,
      };
    })
    .map((d) => ({ ...d.data, id: d._id, enableDirectAccess: d.enableDirectAccess, archived: d.archived, startCode: d.startCode })) as IProgramRow[];
  const sorted = sortRows(mappedPrograms);
  return {
    programs: sorted,
    programActions: state.content.programActions,
    activeLanguage: state.language.activeLanguage,
    serverError: state.content.error,
  };
};
const mapDispatchToProps: MapDispatchToProps<IPropsFromDispatch, {}> = (dispatch) => ({
  fetchData: () => {
    dispatch(listProgramCodes());
  },
  clearServerError: () => {
    dispatch(clearApiError());
  },
});
export const StartCodeOverviewContainer = connect(mapStateToProps, mapDispatchToProps)(InnerStartCodeOverviewContainer);

const CodeCell = styled(Table.Cell)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
`;
