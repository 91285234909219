import {
  authenticateFailed, authenticateSuccessful, forbiddenError, forbiddenErrorClear, logOutSuccessful,
} from 'actions/AuthActions';
import jwtDecode from 'jwt-decode';
import { Content, Users } from '@visikon/core-models';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { getCMSUserSuccessful, generateTwoFactorAuthSuccesful } from 'actions/UserActions';

export interface IAuthRequest {
  readonly username: string;
  readonly password: string;
  readonly tfaToken?: string;
}

export interface IAuthToken {
  readonly token: string;
}
export interface IAuthError {
  readonly msg: string;
}

export interface IState {
  readonly isLoggedIn: boolean;
  readonly forbiddenError?: boolean;
  readonly qrCode?: string;
  tfaToken?: string;
  readonly currentUser?: Users.CMSUser;
  readonly token?: string;
  readonly error?: string;
  readonly namespace?: string;
  readonly userType?: Users.AnyUserKinds;
  readonly languagesConfig?: {
    referenceLanguage: Content.LanguageCode;
    destinationLanguages: Content.LanguageCode[];
  };
}

export function authReducer(state: IState = { isLoggedIn: false }, action: Action): IState {
  if (isType(action, authenticateSuccessful)) {
    const tokenContent = jwtDecode(action.payload.token);
    return {
      isLoggedIn: true, token: action.payload.token, namespace: tokenContent.namespace, userType: tokenContent.type, languagesConfig: tokenContent.languagesConfig,
    };
  }

  if (isType(action, logOutSuccessful)) {
    return { isLoggedIn: false };
  }

  if (isType(action, authenticateFailed)) {
    return { isLoggedIn: false, error: action.payload.msg };
  }

  if (isType(action, forbiddenError)) {
    return { ...state, forbiddenError: true };
  }

  if (isType(action, forbiddenErrorClear)) {
    return { ...state, forbiddenError: false };
  }
  if (isType(action, getCMSUserSuccessful)) {
    // @ts-ignore
    return { ...state, currentUser: action.payload };
  }
  if (isType(action, generateTwoFactorAuthSuccesful)) {
    return { ...state, qrCode: action.payload.QrData };
  }

  return state;
}
