import { BlockVideoChange } from '@visikon/core-models/content';
import { API } from 'config';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ClipboardLinkButton } from '../../../../components/ClipboardLinkButton';

const ENCODE_URL = API.baseEncoderUrl;
const API_HEADER = { 'x-api-key': 'gQzB7EwqGsrrPpWrxl9F1toCnNAOKW1vKyf3B1ZOXzY5HyTUV0', 'Content-Type': 'application/json' };

const H3 = styled.h3`
  padding: 0;
  margin-bottom: 0;
`;

interface MetaResponse {
  meta: string[];
}

interface IProps {
  video: BlockVideoChange;
}
export const WorkFileList = ({ video }: IProps) => {
  const [fileList, setFileList] = useState<string[] | undefined>(undefined);

  useEffect(() => {
    const requestUrl = `${ENCODE_URL}meta?url=${video.existingSource}`;
    fetch(requestUrl, {
      headers: API_HEADER,
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((response: MetaResponse) => {
        const swiffer = response.meta.filter((m) => m.endsWith('.swf')).sort((a, b) => a.localeCompare(b));

        setFileList(swiffer);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderOriginalFilename() {
    if (!video.fileNameForShow) {
      return null;
    }
    return (
      <>
        <h3 style={{ marginBottom: 0, padding: 0 }}>Original filename</h3>
        <div>
          <ClipboardLinkButton cleanup>{video.fileNameForShow}</ClipboardLinkButton>
        </div>
      </>
    );
  }

  if (fileList === undefined) {
    return (
      <>
        {renderOriginalFilename()}
        <H3>Work files</H3>
        <div>Loading...</div>
      </>
    );
  }

  return (
    <>
      {renderOriginalFilename()}
      <H3>Work files</H3>
      <ul style={{ marginTop: 0 }}>
        {fileList.map((f) => (
          <li key={f}>
            <ClipboardLinkButton cleanup>{f}</ClipboardLinkButton>
          </li>
        ))}
      </ul>
    </>
  );
};
