import styled from 'styled-components';

export const ProgressBarWrapper = styled.div`
    display: block;
    max-width: 100%;
    border: none;
    height: 30px;
    padding-top: 10px;
    cursor: pointer;
`;

export const ProgressBarOuter = styled.div`
    position: relative;
    max-width: 100%;
    border: none;
    background: rgba(0,0,0,.1);
    padding: 0;
    border-radius: .28571429rem;
    height: 10px;
`;

export const ProgressBarInner = styled.div`
    display: block;
    // line-height: 1;
    background: #888;
    border-radius: .28571429rem;
    height: 100%;
`;
export const Marker = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #333;
`;
