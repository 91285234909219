import styled from 'styled-components';
import { ProfileSelector } from 'components/ProfileSelector';

const ProfileSelectorWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 5px;
  margin-right: 100px;

  @media print {
    display: none;
  }
`;

export function ProfileSelectContainer() {
  return (
    <ProfileSelectorWrapper>
      <ProfileSelector />
    </ProfileSelectorWrapper>
  );
}
