import { VideoClip } from './video-player.models';

export function getDurations(clips: VideoClip[]): number[] {
  return clips.map(clip => {
    if (clip.video?.src && clip.video.duration && clip.video.duration > 0) {
      return clip.video.duration;
    }
    if (clip.audio?.src && clip.audio.duration) {
      return clip.audio.duration;
    }
    return 0;
  })
}

export function getNumbers(sourceList: VideoClip[], currentIndex: number, currentPos: number) {
  const durations = getDurations(sourceList);
  const durationInTotal = durations.reduce((acc, dur) => acc + dur, 0);
  const timeInPreviousIndexes = durations.reduce((acc, dur, i) => {
    if (i < currentIndex) {
      return acc + dur;
    }
    return acc;
  }, 0);

  const currentTimeTotal = timeInPreviousIndexes + currentPos;
  const percentage = durationInTotal > 0 ? (currentTimeTotal / durationInTotal) * 100 : 0;

  return {durations, durationInTotal, currentTimeTotal, percentage};
}

export function formatDuration(duration: number) {
  const minutes = Math.floor(duration / 60);
  const seconds = Math.round(duration % 60);

  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
  const secondsStr = seconds < 10 ? `0${seconds}` : seconds;

  return `${minutesStr}:${secondsStr}`;
}
