import useRefState from 'commons/useRefState';
import { useEffect, useState } from 'react';
import { Dropdown, DropdownOnSearchChangeData, DropdownProps } from 'semantic-ui-react';
import { joinAndTrimDeduplicated } from '../../../../commons/array-utils';
import { normalize } from '../../../../commons/string';

type Props = {
  defaultValue?: string[],
  blockVariations: string[],
  placeholder?: string,
  closeOnChange?: boolean,
  allowAdditions?: boolean,
  onChange(value: string[]): void,
};

export const VariationDropDown = (props: Props) => {
  const { defaultValue = [], blockVariations, onChange, placeholder, allowAdditions = true, closeOnChange } = props;

  const [searchString, setSearchString] = useState<string>('');
  const [selectedVariations, setSelectedVariations] = useRefState<string[]>(joinAndTrimDeduplicated(defaultValue));
  const [allVariations, setAllVariations] = useState<string[]>(() => joinAndTrimDeduplicated(blockVariations, ...selectedVariations));

  useEffect(() => {
    setAllVariations(
      joinAndTrimDeduplicated(blockVariations, ...selectedVariations)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockVariations]);

  const addVariation = (s: string) => {
    setAllVariations((old) => joinAndTrimDeduplicated(old, s));
  }

  const handleSearch = (_: any, data: DropdownOnSearchChangeData) => {
    setSearchString(normalize(data.searchQuery));
  };

  const handleAddItem = (_: any, data: DropdownProps) => {
    addVariation(data.value as string);
    setSearchString('');
  };

  const handleChange = (_: any, data: any) => {
    const sanitized = [data.value?.trim()];

    onChange(sanitized);
    setSelectedVariations(sanitized);
    setSearchString('');
  };

  return (
    <Dropdown
      placeholder={placeholder}
      searchQuery={searchString}
      onSearchChange={handleSearch}
      onAddItem={handleAddItem}
      onChange={handleChange}
      fluid
      search
      selection
      allowAdditions={allowAdditions}
      options={toDropdownItems(allVariations)}
      closeOnChange={closeOnChange}
      defaultValue={selectedVariations?.length > 0 && selectedVariations[0]}
    />
  );
};

function toDropdownItems(items: string[]) {
  return items.map(s => ({ key: s, text: s, value: s }));
}
