import { copyContent, listContentByType } from 'actions/ContentActions';
import { doesTypeExist, getTypeDescription, typeDescriptionDisplayName } from 'components/contentTypes/ContentDescriptors';
import { ITypeDescription } from 'components/contentTypes/CustomTypeInput';
import { CustomTypeList } from 'components/contentTypes/CustomTypeList';
import { Users } from '@visikon/core-models';
import { CustomType, LanguageCode } from '@visikon/core-models/content';
import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { IState } from 'reducers/reducers';
import { Button, Checkbox } from 'semantic-ui-react';

interface IPropsFromState {
  data: CustomType[];
  activeLanguage: LanguageCode;
  userType: Users.AnyUserKinds;
  languagesConfig?: Users.CMSUser['languagesConfig'];
}
interface IPropsFromDispatch {
  fetchData(name: string): void;
  createCopy(id: string): void;
}
interface IProps extends IPropsFromState, IPropsFromDispatch {
  name: string;
  newButtonText?: string;
  editButtonText?: string;
  onPick?(id: string): void;
  handleOnClickNewButton?(): void;
  handleOnEditClick?(id: string): void;
  onSave?(id: string): void;
}

interface ILocalState {
  typeNotFoundError: boolean;
  typeDescription?: ITypeDescription;
  showArchived: boolean;
}

class CCustomTypeListContainer extends React.Component<IProps, ILocalState> {
  constructor(props: IProps) {
    super(props);

    this.state = { typeNotFoundError: false, showArchived: false };

    this.renderContentTypeError = this.renderContentTypeError.bind(this);
    this.updateForType = this.updateForType.bind(this);
  }

  updateForType(name: string) {
    if (!doesTypeExist(name)) {
      this.setState({ typeNotFoundError: true });
      return;
    }

    this.setState({ typeDescription: getTypeDescription(name), typeNotFoundError: false });
    this.props.fetchData(name);
  }

  componentDidMount() {
    this.updateForType(this.props.name);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps: IProps) {
    if (this.props.name !== newProps.name) {
      this.updateForType(newProps.name);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderContentTypeError() {
    return (
      <>
        <h1 className="header">Woops</h1>
        <p>Content type not found</p>
      </>
    );
  }

  render() {
    if (this.state.typeNotFoundError) {
      return this.renderContentTypeError();
    }

    if (!this.state.typeDescription) {
      return null;
    }

    // is normal mode - as opposed to picker mode
    const normalMode = this.props.onPick === undefined;
    const hasCopy = normalMode && this.props.userType !== 'Translator';

    let { data } = this.props;
    if (this.props.userType === 'Translator') {
      data = data.filter((d) => d.translations.find((t) => t.language === this.props.languagesConfig!.referenceLanguage) !== undefined);
    }

    return (
      <>
        {/* Only show he ader and buttons when NOT picking an item */}
        {normalMode
                    && (
                    <>
                      <h1 className="header">
                        {typeDescriptionDisplayName(this.state.typeDescription)}
                        s
                      </h1>
                      <p>{this.state.typeDescription.meta.description}</p>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {hasCopy && (
                        <Button positive onClick={this.props.handleOnClickNewButton}>{this.props.newButtonText || 'Create new'}</Button>
                        )}
                        <Checkbox
                          label="Show archive"
                          checked={this.state.showArchived}
                          onChange={() => this.setState((prevState) => ({ ...prevState, showArchived: !prevState.showArchived }))}
                          toggle
                        />
                      </div>
                    </>
                    )}

        <br />
        <br />
        <CustomTypeList
          typeDescription={this.state.typeDescription!}
          list={data.filter((row) => (this.state.showArchived ? row.archived : !row.archived))}
          onClickEdit={this.props.handleOnEditClick}
          onPick={this.props.onPick}
          onCopy={hasCopy ? this.props.createCopy : undefined}
        />
      </>
    );
  }
}
const mapStateToProps: MapStateToProps<IPropsFromState, IProps, IState> = (state, props) => {
  const contentType = props.name;
  const data = contentType in state.content.data ? state.content.data[contentType] : [];
  return {
    data,
    activeLanguage: state.language.activeLanguage,
    userType: state.auth.userType || 'NoUser',
    languagesConfig: state.auth.languagesConfig,
  };
};
const mapDispatchToProps: MapDispatchToProps<IPropsFromDispatch, {}> = (dispatch) => ({
  fetchData: (name: string) => {
    dispatch(listContentByType(name));
  },
  createCopy: (id: string) => {
    dispatch(copyContent({ contentId: id }));
  },
});
export const CustomTypeListContainer = connect(mapStateToProps, mapDispatchToProps)(CCustomTypeListContainer);
