import { Content } from '@visikon/core-models';
import React from 'react';
import {
  Modal, Dropdown, DropdownProps, Icon, SemanticICONS, SemanticCOLORS,
} from 'semantic-ui-react';
import { languages } from 'commons/languages';
import { ImageContainer } from 'containers/mediaLibrary/ImageContainer';
import { ArchiveButton } from 'components/ArchiveButton';
import { ObjectId } from '@visikon/core-models/base';
import { LanguageSelector } from '../../LanguageSelector';
import { FilePicker } from '../../FilePicker';

interface IProps {
  onDismiss: () => void;
  onUploadTranslation: (language: Content.LanguageCode, variation: ObjectId, file: File) => void;
  image: Content.Image;
  language: Content.LanguageCode; // This is the language selected in the whole CMS GUI
  mediaVariations: Content.MediaVariation[];
  toggleArchive: (image: Content.Image) => void
}

interface IState {
  isSelectingVariation: boolean;
  activeLanguage: Content.LanguageCode;
  activeVariation: Content.MediaVariation;
}

export class ImageDetails extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = { isSelectingVariation: false, activeLanguage: this.props.language, activeVariation: (this.props.mediaVariations[0] || '') };

    this.renderImageSection = this.renderImageSection.bind(this);
    this.renderVariationsSection = this.renderVariationsSection.bind(this);
    this.renderUploadSection = this.renderUploadSection.bind(this);

    this.mediaVariationsChoice = this.mediaVariationsChoice.bind(this);

    this.handleUploadImage = this.handleUploadImage.bind(this);

    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleVariationChange = this.handleVariationChange.bind(this);
  }

  handleUploadImage(files: File[]) {
    const { activeLanguage, activeVariation } = this.state;

    const file = files[0];
    if (file) {
      this.props.onUploadTranslation(activeLanguage, activeVariation._id, file);
    }
  }

  handleLanguageChange(code: Content.LanguageCode) {
    this.setState({ activeLanguage: code });
  }

  handleVariationChange(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) {
    const choice = this.props.mediaVariations.find((v) => v._id === data.value);
    if (choice) {
      this.setState({ isSelectingVariation: false, activeVariation: choice });
    } else {
      this.setState({ isSelectingVariation: false });
    }
  }

  languageDropdownChoice() {
    const translations = this.props.image.translations.map((t) => (t.language));
    return languages.map((l) => {
      const hasTranslation = translations.indexOf(l.value as Content.LanguageCode) !== -1;
      const icon: SemanticICONS = hasTranslation ? 'check circle outline' : 'circle outline';
      const color: SemanticCOLORS = hasTranslation ? 'green' : 'grey';
      return { ...l, icon: <Icon name={icon} color={color} /> };
    });
  }

  mediaVariationsChoice() {
    const variations = this.props.image.translations.map((t) => (t.mediaVariation));
    return this.props.mediaVariations.map((v) => {
      const hasVariation = variations.indexOf(v._id) !== -1;
      const active = v._id === this.state.activeVariation._id;
      const icon: SemanticICONS = hasVariation ? 'check circle outline' : 'circle outline';
      const color: SemanticCOLORS = hasVariation ? 'green' : 'grey';
      return ({
        text: v.name, value: v._id, active, selected: active, icon: <Icon name={icon} color={color} />,
      });
    });
  }

  renderVariationsSection() {
    const { activeVariation } = this.state;
    const langChoices = this.languageDropdownChoice();
    const variationChoices = this.mediaVariationsChoice();
    return (
      <>
        <h2 className="ui header">Variations</h2>
        <h4>Language</h4>
        <LanguageSelector
          activeLang={this.state.activeLanguage}
          onLanguageChanged={this.handleLanguageChange}
          languageChoices={langChoices}
        />

        <h4>Media variation</h4>
        {this.state.isSelectingVariation
                    && (
                    <Dropdown
                      className="mini icon"
                      floating
                      fluid
                      labeled
                      options={variationChoices}
                      search
                      searchInput={{ autoFocus: true }}
                      selectOnBlur={false}
                      onBlur={() => { this.setState({ isSelectingVariation: false }); }}
                      onChange={this.handleVariationChange}
                      selection
                      text="Search..."
                    />
                    )}
        {!this.state.isSelectingVariation
                    && (
                    <button type="button" className="ui mini left button" onClick={() => { this.setState({ isSelectingVariation: true }); }}>
                      {activeVariation.name}
                    </button>
                    )}
      </>
    );
  }

  renderUploadSection() {
    const translationExists = this.props.image.translations.find((t) => t.language === this.state.activeLanguage) !== undefined;
    const uploadText = translationExists ? 'Replace image file' : 'Upload image file';
    return (
      <>
        <h4>{uploadText}</h4>
        <FilePicker
          fileTypeText="image"
          accept="image/png,image/jpeg,image/gif"
          onFilePick={this.handleUploadImage}
          multiple={false}
        />
      </>
    );
  }

  renderImageSection() {
    return (
      <div className="ui cards">
        <ImageContainer
          className="ui image massive"
          image={this.props.image}
          language={this.state.activeLanguage}
          mediaVariation={this.state.activeVariation._id}
        />
      </div>
    );
  }

  render() {
    const handleToggle = (archived: boolean) => {
      this.props.toggleArchive({
        ... this.props.image,
        archived,
      })
    };

    return (
      <Modal open onClose={this.props.onDismiss} size="large">
        <Modal.Header>Image details</Modal.Header>
        <ArchiveButton entry={this.props.image} onToggle={handleToggle} />
        <Modal.Content scrolling style={{ minHeight: 880 }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: 620 }}>
              {this.renderImageSection()}
            </div>
            <div style={{ flex: 1 }}>
              {this.renderVariationsSection()}
              {this.renderUploadSection()}
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}
