import * as UserActions from 'actions/UserActions';
import * as ContentAPI from 'api/contentApi';
import * as UserApi from 'api/userApi';
import { AxiosResponse } from 'axios';
import { Content, Users } from '@visikon/core-models';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { DataRowExporter, StringUtils } from 'commons/utils';
import { getToken } from './saga-helpers';
import { logger } from '../commons/logger';
import { extractProgramNames, getUserBatchCsvData } from './UserBatchExportTransformations';

function* createBatchSaga(action: Action<UserActions.ICreateBatchType>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const batch = action.payload;
    const batchResponse: AxiosResponse = yield call(UserApi.createBatch, token, batch);
    const programListResponse: AxiosResponse = yield call(ContentAPI.listEntries, token, 'mitforlobPack');

    const generatedBatch: { batch: Users.EndUserBatch; codes: string[] } = batchResponse.data;
    const programList: Content.CustomType[] = programListResponse.data;
    const programIds = batch.resources;

    const programNameData = extractProgramNames(programList, programIds);
    const { selectedDomain } = generatedBatch.batch;

    // Download codes
    const { data, fileName } = getUserBatchCsvData(generatedBatch.codes, programNameData, selectedDomain);
    const exporter = new DataRowExporter(data);
    exporter.DownloadAsCsv(fileName, '#');

    yield put(UserActions.createBatchSuccess());
  } catch (e) {
    yield put(UserActions.userActionError(e.message));
  }
}

function* createNamedUserSaga(action: Action<UserActions.ICreateNamedUserType>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const user = action.payload;
    const response: AxiosResponse = yield call(UserApi.createNamedUser, token, user);

    const result: { user: Users.EndUser; code: string } = response.data;

    // Download result as file
    const fileName = `named_user_${StringUtils.getFormattedTime()}.txt`;
    const content = `${user.name}\t${result.code}`;
    StringUtils.downloadTextFile(fileName, content);

    yield put(UserActions.createNamedUserSuccess());
    yield put(UserActions.listNamedUsers());
  } catch (e) {
    yield put(UserActions.userActionError(e.message));
  }
}

function* getAllNamespaces() {
  // const token = yield select(getToken);
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const response: AxiosResponse = yield call(UserApi.listAllNamespaces, token);
    const result: Users.Namespace[] = response.data;
    yield put(UserActions.listAllNamespacesSuccessful(result));
  } catch (e) {
    yield put(UserActions.userActionError(e.message));
  }
}

function* createNamespaceSaga(action: Action<UserActions.ICreateNamespace>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const namespace = action.payload;
    const response: AxiosResponse = yield call(UserApi.createNamespace, token, namespace);
    const result: { namespace: Users.Namespace; code: string } = response.data;
    logger.info('Namespace created', result);

    yield put(UserActions.createNamespaceSuccessful());
  } catch (e) {
    yield put(UserActions.userActionError(e.message));
  }
}

function* createSpecificKeySaga(action: Action<UserActions.ICreateKey>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const key = action.payload;
    const response: AxiosResponse = yield call(UserApi.createSpecificKey, token, key);
    logger.info('Specific key created', response);

    yield put(UserActions.createSpecificKeySuccessful());
  } catch (e) {
    yield put(UserActions.userActionError(e.message));
  }
}

function* deleteANamespaceSaga(action: Action<UserActions.IDeleteNamespace>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const namespace = action.payload;
    const response: AxiosResponse = yield call(UserApi.deleteNamespace, token, namespace);
    const result: { namespace: Users.Namespace; code: string } = response.data;
    logger.info('Namespace deleted', result);

    yield put(UserActions.deleteANamespaceSuccessful());
  } catch (e) {
    yield put(UserActions.userActionError(e.message));
  }
}

function* updateNamespaceSaga(action: Action<UserActions.IEditNamespace>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const namespace = action.payload;
    const response: AxiosResponse = yield call(UserApi.updateNamespace, token, namespace);
    const result: { namespace: Users.Namespace; code: string } = response.data;
    logger.info('Namespace updated', result);

    yield put(UserActions.updateNamespaceSuccessful());
  } catch (e) {
    yield put(UserActions.userActionError(e.message));
  }
}

function* createCMSUserSaga(action: Action<UserActions.ICreateCMSUser>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const cmsUser = action.payload;
    const response: AxiosResponse = yield call(UserApi.createCMSUser, token, cmsUser);
    const result: { namespace: Users.Namespace; code: string } = response.data;
    logger.info('CMSUser created', result);

    yield put(UserActions.createCMSUserSuccessful());
  } catch (e) {
    yield put(UserActions.userActionError(e.message));
  }
}

function* listCMSUsersSaga(action: Action<UserActions.ListCMSUsers>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const cmsUserList = action.payload;
    const response: AxiosResponse = yield call(UserApi.listCMSUsers, token, cmsUserList);
    const result: Users.CMSUser[] = response.data;
    yield put(UserActions.listCMSUsersSuccessful(result));
  } catch (e) {
    yield put(UserActions.userActionError(e.message));
  }
}

function* deleteCMSUserSaga(action: Action<UserActions.IDeleteCMSUser>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const cmsUser = action.payload;
    const response: AxiosResponse = yield call(UserApi.deleteCMSUser, token, cmsUser);
    const result = response.data;
    yield put(UserActions.deleteCMSUserSuccessful(result));
  } catch (e) {
    yield put(UserActions.userActionError(e.message));
  }
}

function* editCMSUserSaga(action: Action<Users.CMSUser>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const cmsUser = action.payload;
    const response: AxiosResponse = yield call(UserApi.editCMSUser, token, cmsUser);
    const result = response.data;
    yield put(UserActions.editCMSUserSuccessful(result));
  } catch (e) {
    yield put(UserActions.userActionError(e.message));
  }
}

function* editUserSaga(action: Action<Users.EditProfileData>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const data = action.payload;

    const response: AxiosResponse = yield call(UserApi.editUser, token, data);
    const result = response.data;
    yield put(UserActions.editUserSuccessful(result));
  } catch (e) {
    yield put(UserActions.userActionError(e.message));
  }
}

function* generateTwoFactorAuthSaga() {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const response: AxiosResponse = yield call(UserApi.generateTwoFactorAuth, token);
    const result = response.data;
    yield put(UserActions.generateTwoFactorAuthSuccesful(result));
  } catch (error) {
    logger.error('Error in generateTwoFactorAuthSaga()', error);
    yield put(UserActions.userActionError(error.message));
  }
}

function* turnOnTwoFactorAuthSaga(action: Action<string>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const response: AxiosResponse = yield call(UserApi.turnOnTwoFactorAuth, token, action.payload);
    const result = response.data;
    yield put(UserActions.getCMSUser());
    yield put(UserActions.turnOnTwoFactorAuthSuccessful(result));
  } catch (error) {
    yield put(UserActions.userActionError(error.message));
  }
}

function* getCMSUserSaga() {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const response: AxiosResponse = yield call(UserApi.getCMSUser, token);
    const result = response.data;
    yield put(UserActions.getCMSUserSuccessful(result));
  } catch (error) {
    yield put(UserActions.userActionError(error.message));
  }
}

function* editReviewer(action: Action<{ id: string; reviewer: boolean }>) {
  try {
    // @ts-ignore
    const token = yield select(getToken);
    const { id, reviewer } = action.payload;
    const response: AxiosResponse = yield call(UserApi.editReviewer, token, id, reviewer);
    if (response.status === 200) {
      yield put(UserActions.editReviewerSuccess());
    } else {
      yield put(UserActions.userActionError(response.data));
    }
  } catch (e) {
    yield put(UserActions.userActionError(e.message));
  }
}

export function* usersSaga() {
  yield takeEvery(UserActions.createBatch, createBatchSaga);
  yield takeEvery(UserActions.createNamedUser, createNamedUserSaga);
  yield takeEvery(UserActions.listAllNamespaces, getAllNamespaces);
  yield takeEvery(UserActions.createNamespace, createNamespaceSaga);
  yield takeEvery(UserActions.createSpecificKey, createSpecificKeySaga);
  yield takeEvery(UserActions.deleteANamespace, deleteANamespaceSaga);
  yield takeEvery(UserActions.updateNamespace, updateNamespaceSaga);
  yield takeEvery(UserActions.createCMSUser, createCMSUserSaga);
  yield takeEvery(UserActions.listCMSUsers, listCMSUsersSaga);
  yield takeEvery(UserActions.deleteCMSUser, deleteCMSUserSaga);
  yield takeEvery(UserActions.editCMSUser, editCMSUserSaga);
  yield takeEvery(UserActions.generateTwoFactorAuth, generateTwoFactorAuthSaga);
  yield takeEvery(UserActions.turnOnTwoFactorAuth, turnOnTwoFactorAuthSaga);
  yield takeEvery(UserActions.getCMSUser, getCMSUserSaga);
  yield takeEvery(UserActions.editUser, editUserSaga);
  yield takeEvery(UserActions.editReviewer, editReviewer);
}
