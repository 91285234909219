import * as Styles from '../variationParts/speak/SpeakVariation.styles';
import * as React from 'react';

export const hasUnacceptedValues = (phoneticsText: string) => {
  // Combine all accepted values into a single regex pattern
  let pattern = ALL_PHONETIC_CHARACTERS.concat(ENGLISH_ALPHABET)
    .map((value) => value.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')) // Escape special characters
    .join('|'); // Join with OR operator

  // Add start (^) and end ($) anchors to match the entire string
  pattern = `^(${pattern})*$`;

  // Create a new RegExp object
  const regex = new RegExp(pattern);

  // Test the phoneticsText against the regex
  const hasWrongValues = !regex.test(phoneticsText);

  return hasWrongValues;
};

const ACCEPTED_VALUES = [
  'ɑ',
  'ɑː',
  'ɛ',
  'ɛː',
  'ɔ',
  'ɒ',
  'ɒː',
  'ɔː',
  'ɐ',
  'æː',
  'e',
  'ø',
  'øː',
  'ə',
  'eː',
  'i',
  'iː',
  'o',
  'œ',
  'œː',
  'oː',
  'u',
  'uː',
  'y',
  'yː',
  'b',
  'd',
  'ð',
  'f',
  'g',
  'h',
  'j',
  'kʰ',
  'l',
  'm',
  'n',
  'ŋ',
  'pʰ',
  'ʔ',
  'ʁ',
  'ɐ̯',
  's',
  'ɕ',
  't',
  'v',
  'w',
];

const ENGLISH_ALPHABET = [
  'a',
  'e',
  'i',
  'o',
  'u',
  'b',
  'c',
  'd',
  'f',
  'g',
  'h',
  'j',
  'k',
  'l',
  'm',
  'n',
  'p',
  'q',
  'r',
  's',
  't',
  'v',
  'w',
  'x',
  'y',
  'z',
];

const SUPPORT_CHARACTERS = ['ˈ', 'ˌ', '.', 'ː', '‿'];

const ALL_PHONETIC_CHARACTERS = ACCEPTED_VALUES.concat(SUPPORT_CHARACTERS);

const handleClick = (event: React.MouseEvent<HTMLElement>, letter: string, callBack?: (character: string) => void) => {
  // Clear all current selections
  window.getSelection()?.removeAllRanges();

  const range = document.createRange();

  if (event.target instanceof Node) {
    range.selectNode(event.target);

    // Add the Range to the set of window selections
    window.getSelection()?.addRange(range);
  }
  callBack?.(letter);
};

const createLetters = (
  start: number,
  end: number,
  onClick: (event: React.MouseEvent<HTMLElement>, letter: string, callBack?: (character: string) => void) => void,
  passToEditor?: (character: string) => void,
) =>
  ALL_PHONETIC_CHARACTERS.reduce((acc, v, i) => {
    if (i >= start && i < end) {
      acc.push(
        <Styles.PhoneticLetter key={i} onClick={(e: React.MouseEvent<HTMLElement>) => onClick(e, v, passToEditor)}>
          {v}
        </Styles.PhoneticLetter>,
      );
    }
    return acc;
  }, [] as JSX.Element[]);

export const accepted_vowels = (passToEditor?: (character: string) => void) => createLetters(0, 25, handleClick, passToEditor);
export const accepted_consonants = (passToEditor?: (character: string) => void) =>
  createLetters(25, ACCEPTED_VALUES.length, handleClick, passToEditor);
export const accepted_support_characters = (passToEditor?: (character: string) => void) =>
  createLetters(ACCEPTED_VALUES.length, ALL_PHONETIC_CHARACTERS.length, handleClick, passToEditor);
