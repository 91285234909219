import React from 'react';
import {
  Transition, Grid, Button, Label, Input,
} from 'semantic-ui-react';
import { APIKey } from '@visikon/core-models/user';

interface IProps {
  createSuccess: boolean;
  name: string;
  namedescription: string;
  keydescription: APIKey[];
  onCreate(oldNamespace: string, namespace: string, namespaceDescription: string): void;
}

interface IState {
  isRunning: boolean;
  namespaceValue: string;
  namespaceError?: boolean;
  namespaceDescription: string;
  namespaceDescriptionError?: boolean;
  keyDescription: string;
}

export class EditNamespace extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      isRunning: false, namespaceValue: this.props.name, namespaceError: false, namespaceDescription: this.props.namedescription, namespaceDescriptionError: false, keyDescription: '',
    };
    this.handleOnCreate = this.handleOnCreate.bind(this);
    this.handleNamespaceChange = this.handleNamespaceChange.bind(this);
    this.handleNamespaceDescriptionChange = this.handleNamespaceDescriptionChange.bind(this);
  }

  handleOnCreate() {
    const {
      namespaceValue, namespaceError, namespaceDescription, namespaceDescriptionError,
    } = this.state;
    if (namespaceError || namespaceDescriptionError) {
      return;
    }
    // eslint-disable-next-line react/no-unused-state
    this.setState({ isRunning: true });
    this.props.onCreate(this.props.name, namespaceValue, namespaceDescription);
  }

  handleNamespaceChange(event: React.ChangeEvent<HTMLInputElement>) {
    const namespaceValue = event.target.value;
    const namespaceError = (namespaceValue || '').trim() === '';
    this.setState({ namespaceValue, namespaceError });
  }

  handleNamespaceDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
    const namespaceDescription = event.target.value;
    const namespaceDescriptionError = (namespaceDescription || '').trim() === '';
    this.setState({ namespaceDescription, namespaceDescriptionError });
  }

  render() {
    const { namespaceDescription, namespaceDescriptionError } = this.state;
    return (
      <Transition.Group animation="horizontal flip" duration={500}>
        <div style={{
          position: 'absolute', width: '100%', height: '100%', paddingBottom: 100,
        }}
        />
        <Grid columns={2} style={{ height: '100%' }}>
          <Grid.Column>
            <b>
              <label>
                Name description:
                <br />
                <Input
                  value={namespaceDescription}
                  error={namespaceDescriptionError}
                  onChange={this.handleNamespaceDescriptionChange}
                  label="description"
                  labelPosition="right"
                />
              </label>
            </b>
            <br />
            <br />
            <Button color="blue" onClick={this.handleOnCreate}>Save</Button>
            {this.props.createSuccess && <Label color="green">Success</Label>}
          </Grid.Column>
        </Grid>
      </Transition.Group>
    );
  }
}
