import React from 'react';
import {
  Transition, Grid, Input, Button, Label, Message, Modal,
} from 'semantic-ui-react';

interface IProps {
  createSuccess: boolean;
  onCreate(name: string, description: string, apiKeys: []): void;
  onClose(): void;
}

interface IState {
  isRunning: boolean;
  nameValue: string;
  nameValueError?: string;
  descriptionValue: string;
  descriptionError?: boolean;
  watermarkValue?: string;
}

export class CreateNamespaceDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isRunning: false,
      nameValue: '',
      nameValueError: 'empty',
      descriptionValue: '',
      descriptionError: true,
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);

    this.handleOnCreate = this.handleOnCreate.bind(this);
  }

  handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    const regEx = /^[A-z0-9_]+$/;

    const nameValue = event.target.value;
    let nameValueError;
    if ((nameValue || '').trim() === '') {
      nameValueError = 'empty';
    } else if (!regEx.test(nameValue)) {
      nameValueError = 'Name can only contain letters, numbers and underscores';
    }

    this.setState({ nameValueError, nameValue });
  }

  handleDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
    const descriptionValue = event.target.value;
    const descriptionError = (descriptionValue || '').trim() === '';
    this.setState({ descriptionError, descriptionValue });
  }

  handleOnCreate() {
    const {
      nameValue, nameValueError, descriptionValue, descriptionError,
    } = this.state;
    if (nameValueError || descriptionError) {
      return;
    }
    this.setState({ isRunning: true });
    this.props.onCreate(nameValue, descriptionValue, []);
  }

  render() {
    const { createSuccess } = this.props;
    const {
      isRunning, nameValue, nameValueError, descriptionValue, descriptionError,
    } = this.state;
    const createButtonDisabled = isRunning || createSuccess || nameValueError !== undefined || descriptionError;
    return (
      <Modal open centered={false} onClose={this.props.onClose}>
        <Modal.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>Create Namespace</div>
          <div>
            <Button primary disabled={createButtonDisabled} loading={isRunning && !createSuccess} onClick={this.handleOnCreate}>CREATE NAMESPACE</Button>
            {this.props.createSuccess && <Label color="green">Success</Label>}
          </div>
        </Modal.Header>
        <Modal.Content style={{ minHeight: 700 }}>

          <Transition.Group animation="horizontal flip" duration={500}>
            <Grid columns={2} style={{ height: '100%' }}>
              <Grid.Column>
                <label>
                  <b>Description</b>
                  <br />
                  <Input
                    value={descriptionValue}
                    error={descriptionError}
                    onChange={this.handleDescriptionChange}
                  />
                </label>

                <br />
                <br />

                <label>
                  <b>Name / code</b>
                  <br />
                  <Input
                    value={nameValue}
                    error={nameValueError !== undefined}
                    onChange={this.handleNameChange}
                  />
                </label>
                {nameValueError !== undefined && nameValueError !== 'empty' && (
                <Message negative>
                  <Message.Header>Name error</Message.Header>
                  <p>{nameValueError}</p>
                </Message>
                )}
              </Grid.Column>
            </Grid>
          </Transition.Group>

        </Modal.Content>
      </Modal>
    );
  }
}
