import { connect, MapDispatchToProps } from 'react-redux';
import { Content } from '@visikon/core-models';
import { IState } from 'reducers/reducers';
import { VideoView, VideoViewSize } from 'components/mediaLibrary/videos/VideoView';
import { updateVideo, uploadVideoSubtitles } from 'actions/MediaActions';
import { ObjectId } from '@visikon/core-models/base';

interface IPropsFromState {
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  token?: string;
  languageFromState: Content.LanguageCode;
  mediaVariationFromState: ObjectId;
}
interface IPropsFromDispatch {
  addTag: (tag: Content.Tag) => void;
  removeTag: (tag: Content.Tag) => void;
  titleChange: (tilte: string) => void;
  uploadSubtitles: (video: Content.Video, language: Content.LanguageCode, variation: ObjectId, file: File) => void;
}

/* eslint-disable react/require-default-props */
interface IOwnProps {
  video: Content.Video;
  onClick?: () => void;
  readOnly?: boolean;
  size?: VideoViewSize;
  showControls?: boolean;
  showThumbnail?: boolean;
  language?: Content.LanguageCode;
  mediaVariation?: ObjectId;
  id?: string;
}

function CVideoContainer(props: IPropsFromState & IPropsFromDispatch & IOwnProps) {
  const {
    onClick, addTag, removeTag, titleChange, readOnly, video, language, languageFromState,
    mediaVariation, mediaVariationFromState, id, showControls, size, uploadSubtitles, showThumbnail,
  } = props;

  return (
    <VideoView
      id={id}
      onClick={onClick}
      onTagAdded={addTag}
      onTagRemoved={removeTag}
      onTitleChange={titleChange}
      readOnly={readOnly}
      video={video}
      language={language || languageFromState}
      mediaVariation={mediaVariation || mediaVariationFromState}
      size={size}
      showControls={showControls}
      showThumbnail={showThumbnail}
      onUploadSubtitles={uploadSubtitles}
    />
  );
}

const mapStateToProps: (state: IState) => IPropsFromState = (state) => ({
  languageFromState: state.language.activeLanguage,
  mediaVariationFromState: state.language.activeMediaVariation,
});

const mapDispatchToProps: MapDispatchToProps<IPropsFromDispatch, IOwnProps> = (dispatch, ownProps) => ({
  addTag: (tag: Content.Tag) => {
    if (!tag) {
      return;
    }
    const { video } = ownProps;
    const tagIdx = video.tags.indexOf(tag);
    if (tagIdx === -1) {
      const copy = JSON.parse(JSON.stringify(video));
      copy.tags.push(tag);
      dispatch(updateVideo(copy));
    }
  },
  removeTag: (tag: Content.Tag) => {
    if (!tag) {
      return;
    }
    const { video } = ownProps;
    const tagIdx = video.tags.indexOf(tag);
    if (tagIdx >= 0) {
      const copy = JSON.parse(JSON.stringify(video));
      copy.tags.splice(copy.tags.indexOf(tag), 1);
      dispatch(updateVideo(copy));
    }
  },
  titleChange: (title: string) => {
    if (!title) {
      return;
    }
    const { video } = ownProps;
    const copy = JSON.parse(JSON.stringify(video));
    copy.name = title;
    dispatch(updateVideo(copy));
  },
  uploadSubtitles: (video, language, variation, file) => {
    dispatch(uploadVideoSubtitles({
      video, language, variation, file,
    }));
  },
});

export const VideoContainer = connect(mapStateToProps, mapDispatchToProps)(CVideoContainer);
