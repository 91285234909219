import { Content } from '@visikon/core-models';
import { LanguageCode } from '@visikon/core-models/content';
import { Blueprint } from '@visikon/core-models/encoding';
import * as Semantic from 'semantic-ui-react';
import { AudioDelayMenuItem } from './modals/AudioDelay.button';
import { EncodeNewMenuItem } from './modals/EncodeNew.button';
import { ThumbnailMenuItem } from './modals/Thumbnail.button';
import { EncodedVideoButton } from './modals/EncodedVideo.button';
import { EncoderTimestamp } from './EncoderTimestamp';
import InlineSpinner from '../../../../components/InlineSpinner';
import { useEncoderStatusPoll, useEncodingsByCompositionId } from '../../../../api/v2/encoderApi';
import { getLatestEncodings } from '@visikon/core-models/helpers/composer/blueprint-helpers';
import { CompositionBlock } from '@visikon/core-models/models/composer/blocks';

type Props = {
    language: LanguageCode,
    compositionId: string | undefined,
    name: string,
    composition: Content.BlockList | undefined,
    compositionBlocks: CompositionBlock[],
    thumbnail?: string,
    audioDelay: number,
    blueprint: Blueprint,
    hasChanges: boolean,
    onAudioDelayChange: (value: number) => void,
    onThumbnailChange: (refId: string, thumbSrc?: string) => void,
};

export function EncoderMenu(props: Props) {
    const { language, compositionId: _id, name, composition, compositionBlocks, thumbnail: thumbnailSrc, audioDelay, blueprint, hasChanges, onAudioDelayChange, onThumbnailChange } = props;
    const {data: statusResponse} = useEncoderStatusPoll();
    const {data} = useEncodingsByCompositionId(_id);
    const encodings = getLatestEncodings(data?.encodings);

    const isRunning = statusResponse
                   && statusResponse.status === 'running'
                   && statusResponse.id === _id
                   && statusResponse.language === language;

    return (<>
      <Semantic.Menu style={{marginTop: 0}}>
        <Semantic.Menu.Item header>
            <span>Encoder</span>
            {isRunning &&
              <InlineSpinner />
            }
            <EncoderTimestamp encodings={encodings} language={language} hash={blueprint.header.hash} />
        </Semantic.Menu.Item>
          <Semantic.Menu.Menu position="right">
              <Semantic.Dropdown item icon="ellipsis horizontal">
                  <Semantic.Dropdown.Menu>
                      <Semantic.Menu.Header>Encoder</Semantic.Menu.Header>
                      <EncodeNewMenuItem
                        blueprint={blueprint}
                        disabled={hasChanges}
                      />
                      <Semantic.Dropdown.Divider />

                      <Semantic.Menu.Header>Configuration</Semantic.Menu.Header>
                      <AudioDelayMenuItem
                        value={audioDelay}
                        onChange={onAudioDelayChange} />
                      <ThumbnailMenuItem
                        language={language}
                        compositionId={_id}
                        name={name}
                        composition={composition}
                        selectedBlocks={compositionBlocks}
                        thumbnail={thumbnailSrc}
                        audioDelay={audioDelay}
                        onChange={onThumbnailChange} />

                      {Object.keys(encodings).length > 0 && <>
                          <Semantic.Dropdown.Divider />
                          <Semantic.Menu.Header>Preview</Semantic.Menu.Header>
                          <EncodedVideoButton encodings={encodings} language={language} />
                      </>}
                  </Semantic.Dropdown.Menu>
              </Semantic.Dropdown>
          </Semantic.Menu.Menu>
      </Semantic.Menu>
    </>);
}
