import { atom } from 'recoil';
import { LanguageCode } from '@visikon/core-models/content';

export const tokenState = atom<string | undefined>({
  key: 'tokenState',
  default: undefined,
});

export const languageState = atom<LanguageCode>({
  key: 'languageState',
  default: 'da',
});

export const userTypeState = atom<any>({
  key: 'userTypeState',
  default: undefined,
});

export const azureTokenState = atom<{
  token?: string;
  fetchedAt?: number;
}>({
  key: 'azureTokenState',
  default: {},
});
